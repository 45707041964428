import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { Draggable } from "react-beautiful-dnd";
import { GrAdd, GrDrag, GrSubtract } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import FilledButton from "../../components/buttons/FilledButton";
import MyTippy from "../../components/MyTippy";
import WithDirtyDot from "../../components/WithDirtyDot";
import styles from "./FlowItem.module.css";
import { attachFlow, detachFlow } from "./flowSequenceEditorSlice";

export interface FlowItemProps {
  flow: api.FlowSequenceFlow;
  index: number;
  isDetached?: boolean | null;
}

export default function FlowItem(props: FlowItemProps): ReactElement {
  const dispatch = useDispatch();

  function attachFlowHandler() {
    dispatch(attachFlow({ flowId: props.flow.id }));
  }

  function detachFlowHandler() {
    dispatch(detachFlow({ flowId: props.flow.id }));
  }

  const titleUi = (
    <div className={styles.title}>
      <WithDirtyDot isDirty={props.flow.isDirty}>
        <Link to={`/flows/${props.flow.id}/edit`}>{props.flow.name}</Link>
      </WithDirtyDot>
    </div>
  );

  function buildDetachedItemUi() {
    return (
      <div className={classNames(styles.root, styles.root__detached)}>
        <span className={classNames(styles.dragHandle, styles.hidden)}>
          <GrDrag size={14} />
        </span>
        {titleUi}
        <MyTippy content="Attach flow">
          <FilledButton className={styles.action} onClick={attachFlowHandler}>
            <GrAdd size={14} />
          </FilledButton>
        </MyTippy>
      </div>
    );
  }

  function buildAttachedItemUi() {
    return (
      <Draggable
        draggableId={`${props.flow.id}-${props.flow.version}`}
        index={props.index}
      >
        {(provided) => (
          <div
            className={styles.root}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <span className={styles.dragHandle} {...provided.dragHandleProps}>
              <GrDrag size={14} />
            </span>
            {titleUi}
            <span className={styles.tag}>version {props.flow.version}</span>
            <MyTippy content="Detach flow. You can re-attach it at any time.">
              <FilledButton
                className={styles.action}
                onClick={detachFlowHandler}
              >
                <GrSubtract size={14} />
              </FilledButton>
            </MyTippy>
          </div>
        )}
      </Draggable>
    );
  }

  return props.isDetached ? buildDetachedItemUi() : buildAttachedItemUi();
}
