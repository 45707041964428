import React, { ReactElement, useEffect, useRef } from "react";
import { FaRegFileVideo } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";

import { ReactComponent as CloudinaryLogo } from "../../../../assets/cloudinaryLogo.svg";
import FlatButton from "../../../../components/buttons/FlatButton";
import Input from "../../../../components/forms/Input";
import MyTippy from "../../../../components/MyTippy";
import { useCloudinary } from "../../../integrations/cloudinary/CloudinaryProvider";
import { MessageEditorProps } from "../MessageEditor";
import styles from "./VideoMessage.module.css";

export default function VideoMessage({
  message,
  placeholder,
  onChange,
  onKeyDown,
  control,
}: MessageEditorProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    control?.({ focus: () => inputRef.current?.focus() });
  }, [control]);

  const cloudinary = useCloudinary();

  const { addToast } = useToasts();

  return (
    <div className={styles.root}>
      <Input
        ref={inputRef}
        styleVariant="minimal"
        className={styles.input}
        type="text"
        value={`${message}`}
        title={`${message}`}
        placeholder={placeholder ?? "Enter URL..."}
        onBlurValue={(value) => {
          if (value !== message) {
            onChange(value);
          }
        }}
        blurOnEnterOrEsc
        onKeyDown={(event) => {
          if (onKeyDown?.(event)) {
            event.preventDefault();
          }
        }}
        leading={<FaRegFileVideo size={18} />}
      />
      {cloudinary.getCloudinaryAsset && (
        <MyTippy content="Insert from Cloudinary">
          <FlatButton
            onClick={async () => {
              if (cloudinary.getCloudinaryAsset) {
                const asset = await cloudinary.getCloudinaryAsset();
                if (asset.resource_type === "video") {
                  onChange(asset.effective_url);
                } else {
                  addToast("You can only insert a video.", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }
            }}
          >
            <CloudinaryLogo style={{ height: 14 }} />
          </FlatButton>
        </MyTippy>
      )}
    </div>
  );
}
