import { ChatJson } from "@xflr6/chatbot";
import axios from "axios";

import { buildAuthHeader } from "../auth";
import { IdentifierOrigin, SettingsPatch } from "../sharedTypes";
import { baseUrl } from "../utils";

export interface ChatSequenceResponse {
  id: number;
  identifier: string;
  identifierOrigin: IdentifierOrigin;
  history: {
    histories: (ChatJson | null)[];
    score: number | null;
    completed: boolean;
  };
  score: number | null;
  completed: boolean;
  createdAt: string;
  evaluations: Record<string, string>[];
  settings: SettingsPatch;
  userSettings: SettingsPatch;
  flows: {
    id: number;
    name: string;
    tName: Record<string, string>;
    version: string;
  }[];
}

export async function getChatSequenceResponse(
  id: number,
  identifier: string
): Promise<ChatSequenceResponse> {
  const _identifier = encodeURIComponent(identifier);
  const response = await axios.get(
    `${baseUrl}/flow_sequences/${id}/history/${_identifier}`,
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function destroyChatSequenceResponse(
  id: number,
  identifier: string
): Promise<void> {
  const _identifier = encodeURIComponent(identifier);
  await axios.delete(`${baseUrl}/flow_sequences/${id}/history/${_identifier}`, {
    headers: { ...buildAuthHeader() },
  });
}
