import { AnswerDefinition, OrdChatFlowDefinition } from "@xflr6/chatbot";

import { MCFlowEditorError } from "../../errors";

export function updateAnswerFields(
  promptIndex: number,
  answerIndex: number,
  fields: Partial<AnswerDefinition>,
  flowDef: OrdChatFlowDefinition
): void {
  const promptDef = flowDef.promptsArray[promptIndex].definition;
  const answers = promptDef.answers;
  if (!Array.isArray(answers)) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has non-array answers: ${answers}`
    );
  }

  answers[answerIndex] = { ...answers[answerIndex], ...fields };
}
