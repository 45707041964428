import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";

import BigPictureStats from "../../featuresCommon/stats/BigPictureStats";
import styles from "./PathAnalyticsView.module.css";
import TreeView from "./treeView";

export interface PathAnalyticsViewProps {
  flowStats: api.FlowStats;
}

export default function PathAnalyticsView({
  flowStats,
}: PathAnalyticsViewProps): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.bigPicture}>
        <BigPictureStats stats={flowStats} />
      </div>
      <div className={styles.analytics}>
        <TreeView data={flowStats.pathAnalytics} />
      </div>
    </div>
  );
}
