import {
  ChatFlow,
  ChatFlowDefinition,
  RemoteMessageShape,
} from "@xflr6/chatbot";
import { getLatestFlowVersion } from "@xflr6/chatbot-api";
import React from "react";

import AnswerSamplesMessage from "../AnswerSamplesMessage";
import AnswerStatsMessage from "../AnswerStatsMessage";

export function getMatchingRemotePromptNames(
  definition: ChatFlowDefinition,
  urlPattern: RegExp
): string[] {
  return Object.keys(definition.prompts).filter((name) => {
    const promptDef = definition.prompts[name];
    return (
      promptDef.contentType === "remote" &&
      promptDef.message != null &&
      urlPattern.test(
        ((promptDef.message as unknown) as RemoteMessageShape).url
      )
    );
  });
}

/**
 * Call in your ChatFlow#onDefinitionProcessed methods to set a builder for
 * answer samples prompts.
 */
export function setAnswerSamplesBuilder(
  chatFlow: ChatFlow,
  definition: ChatFlowDefinition,
  urlPattern: RegExp
): void {
  getMatchingRemotePromptNames(definition, urlPattern).forEach((name) =>
    chatFlow.setHistoryRecdComponentBuilder(
      name,
      (historyItem, positionInfo, promptInput, skipAnimation) => {
        return (
          <AnswerSamplesMessage
            historyItem={historyItem}
            positionInfo={positionInfo}
            promptInput={promptInput}
            skipAnimation={skipAnimation}
          />
        );
      }
    )
  );
}

/**
 * Call in your ChatFlow#onDefinitionProcessed methods to set a builder for
 * answer stats prompts.
 */
export function setAnswerStatsBuilder(
  chatFlow: ChatFlow,
  definition: ChatFlowDefinition,
  urlPattern: RegExp
): void {
  getMatchingRemotePromptNames(definition, urlPattern).forEach((name) =>
    chatFlow.setHistoryRecdComponentBuilder(
      name,
      (historyItem, positionInfo, promptInput, skipAnimation) => {
        return (
          <AnswerStatsMessage
            historyItem={historyItem}
            positionInfo={positionInfo}
            promptInput={promptInput}
            skipAnimation={skipAnimation}
          />
        );
      }
    )
  );
}

const REMOTE_NEXT_KEY_PATTERN = /^remote\?chatId=(\d+)\.start$/;

export async function appendVersionsToRemoteNextKeys(
  flowDefinition: ChatFlowDefinition
): Promise<void> {
  const itemsToUpdate: [{ nextKey?: string | null }, string][] = [];
  Object.values(flowDefinition.prompts).forEach((prompt) => {
    const match = prompt.nextKey?.match(REMOTE_NEXT_KEY_PATTERN);
    if (match) {
      itemsToUpdate.push([prompt, match[1]]);
    }
    if (Array.isArray(prompt.answers)) {
      prompt.answers.forEach((answer) => {
        const match = answer.nextKey?.match(REMOTE_NEXT_KEY_PATTERN);
        if (match) {
          itemsToUpdate.push([answer, match[1]]);
        }
      });
    }
  });

  for (const item of itemsToUpdate) {
    const chatId = parseInt(item[1], 10);
    const version = await getLatestFlowVersion(chatId);
    item[0].nextKey = `remote?chatId=${chatId}&version=${version}.start`;
  }
}
