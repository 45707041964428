import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FaRegClone, FaRegEye, FaUsers, FaWrench } from "react-icons/fa";
import { MdHistory, MdShare } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useAppDispatch } from "../../app/store";
import BackButton from "../../components/buttons/BackButton";
import FlatButton from "../../components/buttons/FlatButton";
import MenuItem from "../../components/menus/MenuItem";
import MenuItemLink from "../../components/menus/MenuItemLink";
import MenuSection from "../../components/menus/MenuSection";
import Modal from "../../components/Modal";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import AccessManager from "../../features/flowEditor/AccessManager";
import E2eTestForm from "../../features/flowEditor/E2eTestForm";
import EmbedForm, {
  EmbedFormController,
} from "../../features/flowEditor/EmbedForm";
import FlowEditor from "../../features/flowEditor/FlowEditor";
import {
  selectFlowFlowSequence,
  selectFlowName,
  selectFlowOwner,
  selectFlowOwnership,
  selectIsFlowLoaded,
  selectUpdating,
} from "../../features/flowEditor/flowEditorSlice";
import NamesEditor from "../../features/flowEditor/NamesEditor";
import PublishButton from "../../features/flowEditor/PublishButton";
import SaveButton from "../../features/flowEditor/SaveButton";
import SettingsForm from "../../features/flowEditor/SettingsForm";
import { updateFlowName } from "../../features/flowStats/flowStatsSlice";
import { loadIntegrations } from "../../features/integrations/integrationsSlice";
import { SettingsFormController } from "../../featuresCommon/SettingsForm";
import SharedByBadge from "../../featuresCommon/SharedByBadge";
import { FlowTopBarTab, FlowTopBarTabs } from "../FlowTopBarTabs";
import styles from "./FlowEditPage.module.css";

function CloneFlowMenuItem({ id }: { id: number }) {
  const history = useHistory();
  const { addToast, removeToast } = useToasts();

  const [isCreating, setIsCreating] = useState(false);

  return (
    <MenuItem
      disabled={isCreating}
      onClick={async () => {
        if (window.confirm("Create a duplicate of this flow?")) {
          setIsCreating(true);
          try {
            const cloneId = await api.cloneFlow(id);
            let toastId: string;
            addToast(
              <div className={styles.clonedToast}>
                Flow duplicated
                <FlatButton
                  onClick={() => {
                    removeToast(toastId);
                    history.push(`/flows/${cloneId}/edit`);
                  }}
                >
                  Go
                </FlatButton>
              </div>,
              {
                appearance: "success",
                autoDismiss: true,
              },
              (toastId_) => {
                toastId = toastId_;
              }
            );
            setIsCreating(false);
          } catch (error) {
            addToast("Failed to duplicate chat flow.", {
              appearance: "error",
              autoDismiss: true,
            });
            setIsCreating(false);
          }
        }
      }}
    >
      <FaRegClone />
      &nbsp;Duplicate
    </MenuItem>
  );
}

export default function FlowEditPage(): ReactElement {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);

  const dispatch = useAppDispatch();

  const isFlowLoaded = useSelector(selectIsFlowLoaded);
  const flowSequence = useSelector(selectFlowFlowSequence);
  const name = useSelector(selectFlowName);
  const [updating] = useSelector(selectUpdating);
  const ownership = useSelector(selectFlowOwnership);
  const owner = useSelector(selectFlowOwner);

  const { addToast } = useToasts();

  const [isE2eTestFormOpen, setIsE2eTestFormOpen] = useState(false);
  const [isEmbedFormOpen, setIsEmbedFormOpen] = useState(false);
  const [isAccessManagerOpen, setIsAccessManagerOpen] = useState(false);
  const [isSettingsFormOpen, setIsSettingsFormOpen] = useState(false);

  useEffect(() => {
    dispatch(loadIntegrations({ noReload: true }));
  }, [dispatch]);

  const titleUI = (
    <div className={styles.title}>
      {flowSequence != null && (
        <div className={styles.title_sequence}>
          {flowSequence.name}
          &nbsp;-
        </div>
      )}
      {/* Replaced NameEditor by NamesEditor, which is i18n enabled */}
      <NamesEditor onUpdate={(name) => dispatch(updateFlowName(name))} />
      {owner && <SharedByBadge user={owner} />}
    </div>
  );

  const embedFormController = useRef<EmbedFormController>();
  const settingsFormController = useRef<SettingsFormController>();

  return (
    <div className={styles.root}>
      <Modal
        title="End-to-end Test"
        style={{
          content: {
            width: "480px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isE2eTestFormOpen}
        onRequestClose={() => setIsE2eTestFormOpen(false)}
      >
        <E2eTestForm />
      </Modal>
      <Modal
        title="Embed/Share"
        style={{
          content: {
            width: "700px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isEmbedFormOpen}
        onRequestClose={() => {
          embedFormController.current?.save();
          setIsEmbedFormOpen(false);
        }}
      >
        <EmbedForm control={(c) => (embedFormController.current = c)} />
      </Modal>
      <Modal
        title="Manage Access"
        style={{
          content: {
            width: "700px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isAccessManagerOpen}
        onRequestClose={() => setIsAccessManagerOpen(false)}
      >
        <AccessManager flowId={id} />
      </Modal>
      <Modal
        title={`Settings: ${name}`}
        ariaHideApp={false}
        isOpen={isSettingsFormOpen}
        style={{
          content: {
            width: "600px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        disableClose={updating === "pending"}
        onRequestClose={() => {
          if (
            !settingsFormController.current?.isDirty() ||
            window.confirm(
              "You have unsaved changes. Discard them and close settings editor?"
            )
          ) {
            setIsSettingsFormOpen(false);
          }
        }}
      >
        <SettingsForm
          onSubmit={() => {
            setTimeout(() => setIsSettingsFormOpen(false), 0);
          }}
          onError={() => {
            addToast("Failed to update settings.", {
              appearance: "error",
              autoDismiss: true,
            });
          }}
          controller={(c) => (settingsFormController.current = c)}
        />
      </Modal>
      <TopBar
        leading={<BackButton disabled={!isFlowLoaded} />}
        title={titleUI}
        center={
          isFlowLoaded && flowSequence == null ? (
            <FlowTopBarTabs flowId={id} activeTab={FlowTopBarTab.Build} />
          ) : null
        }
        actions={[
          <PublishButton key="publish" />,
          <SaveButton key="save" saveDebounceMs={2000} />,
          <ProfileButton
            key="login"
            renderMenuItems={() => (
              <MenuSection title="Flow">
                {isFlowLoaded && flowSequence == null && (
                  <MenuItem
                    onClick={() => {
                      setIsE2eTestFormOpen(true);
                    }}
                  >
                    <FaRegEye size={16} />
                    &nbsp;End-to-end&nbsp;Test
                  </MenuItem>
                )}
                {isFlowLoaded && flowSequence == null && (
                  <MenuItem
                    onClick={() => {
                      setIsEmbedFormOpen(true);
                    }}
                  >
                    <MdShare size={16} />
                    &nbsp;Embed/Share
                  </MenuItem>
                )}
                {ownership === "own" && (
                  <MenuItem
                    onClick={() => {
                      setIsAccessManagerOpen(true);
                    }}
                  >
                    <FaUsers size={16} />
                    &nbsp;Manage&nbsp;Access
                  </MenuItem>
                )}
                {flowSequence == null && (
                  <MenuItem
                    onClick={() => {
                      setIsSettingsFormOpen(true);
                    }}
                  >
                    <FaWrench size={16} />
                    &nbsp;Settings
                  </MenuItem>
                )}
                {ownership === "own" && <CloneFlowMenuItem id={id} />}
                <MenuItemLink to={`/flows/${id}/versions`} autoClose={false}>
                  <MdHistory size={16} />
                  &nbsp;Version&nbsp;History
                </MenuItemLink>
              </MenuSection>
            )}
          />,
        ]}
      />
      <div className={styles.editor}>
        <FlowEditor flowId={id} />
      </div>
    </div>
  );
}
