import {
  OverallScoreArrayReducer,
  ScoreStrategy,
  ThreadScoreArrayReducer,
} from "@xflr6/chatbot";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import Select from "react-select";

import Checkbox from "../../../../components/forms/Checkbox";
import * as ops from "../operations";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";
import styles from "./ScoreStrategyView.module.css";

type ThreadOption = { value: ThreadScoreArrayReducer; label: string };

const THREAD_OPTIONS: ThreadOption[] = [
  { value: "sum", label: "Sum" },
  { value: "avg", label: "Average" },
  { value: "max", label: "Maximum" },
  { value: "first", label: "First" },
  { value: "last", label: "Last" },
];

function getThreadOption(value: string): ThreadOption | undefined {
  return THREAD_OPTIONS.find((o) => o.value === value);
}

type OverallOption = { value: OverallScoreArrayReducer; label: string };

const OVERALL_OPTIONS: OverallOption[] = [
  { value: "avg", label: "Average" },
  { value: "max", label: "Maximum" },
  { value: "first", label: "First" },
  { value: "last", label: "Last" },
];

function getOverallOption(value: string): OverallOption | undefined {
  return OVERALL_OPTIONS.find((o) => o.value === value);
}

const DEFAULT_STRATEGY: ScoreStrategy = { thread: "avg", overall: "avg" };

export default function ScoreStrategyView(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  const scoreStrategy = prompt.definition.scoreStrategy ?? DEFAULT_STRATEGY;

  function updateScoreStrategy(fields: Partial<ScoreStrategy>) {
    const updated = { ...scoreStrategy, ...fields };
    updateFields({
      scoreStrategy: equals(updated, DEFAULT_STRATEGY) ? undefined : updated,
    });
  }

  function buildThreadField(): ReactElement {
    return (
      <>
        <div className={styles.label}>Prompt score</div>
        <div className={styles.field}>
          <Select
            className={styles.select}
            options={THREAD_OPTIONS}
            value={getThreadOption(scoreStrategy.thread)}
            onChange={(selected) => {
              const value = (selected as ThreadOption | undefined)?.value;
              if (value != null) updateScoreStrategy({ thread: value });
            }}
          />
          <div className={styles.suffix}>of answer scores</div>
        </div>
      </>
    );
  }

  function buildOverallField(): ReactElement {
    return (
      <>
        <div className={styles.label}>If prompt visited multiple times</div>
        <div className={styles.field}>
          <Select
            className={styles.select}
            options={OVERALL_OPTIONS}
            value={getOverallOption(scoreStrategy.overall)}
            onChange={(selected) => {
              const value = (selected as OverallOption | undefined)?.value;
              if (value != null) updateScoreStrategy({ overall: value });
            }}
          />
          <div className={styles.suffix}>of prompt scores</div>
        </div>
      </>
    );
  }

  function buildLazyDisplayField(): ReactElement {
    return (
      <Checkbox
        label="Update score only when the prompt ends"
        checked={!!scoreStrategy.lazyDisplay}
        onChange={(event) => {
          updateScoreStrategy({ lazyDisplay: event.target.checked });
        }}
      />
    );
  }

  const isThreadApplicable =
    prompt.definition.messages != null ||
    (ops.promptHasRepeatableAnswers(prompt.definition) &&
      !prompt.definition.acceptsMultipleAnswers);

  return (
    <div>
      {isThreadApplicable && buildThreadField()}
      {buildOverallField()}
      {isThreadApplicable && buildLazyDisplayField()}
    </div>
  );
}
