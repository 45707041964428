import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/store";
import NamesEditorBase from "../../featuresCommon/NamesEditor";
import {
  selectFlowDirty,
  selectFlowLanguage,
  selectFlowLanguages,
  selectFlowName,
  selectFlowOwnership,
  selectFlowTName,
  updateFlow,
} from "./flowEditorSlice";

export default function NamesEditor({
  onUpdate,
}: {
  onUpdate?: (name: string) => void;
}): ReactElement | null {
  const dispatch = useAppDispatch();

  const name = useSelector(selectFlowName);
  const language = useSelector(selectFlowLanguage);
  const tName = useSelector(selectFlowTName);
  const languages = useSelector(selectFlowLanguages);

  const dirty = useSelector(selectFlowDirty);
  const ownership = useSelector(selectFlowOwnership);

  if (name == null || tName == null) return null;

  return (
    <NamesEditorBase
      name={name}
      language={language}
      tName={tName}
      languages={languages}
      isEditable={ownership === "own"}
      showDirtyDot={dirty ?? false}
      onSubmit={async (name, tName, closeEditor) => {
        const result = await dispatch(updateFlow({ name, tName }));
        closeEditor();
        if (result.meta.requestStatus !== "rejected") {
          onUpdate?.(name);
        }
      }}
    />
  );
}
