import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import ResponsesViewBase from "../../featuresCommon/stats/ResponsesView";
import ChatSequenceHistoryView from "./ChatSequenceHistoryView";
import {
  loadMoreResponses,
  loadResponse,
  loadResponses,
  selectResponseIndex,
  selectResponsesFilter,
  selectResponsesState,
} from "./flowSequenceStatsSlice";

export default function ResponsesView(): ReactElement {
  const dispatch = useDispatch();
  const filter = useSelector(selectResponsesFilter);
  const responsesState = useSelector(selectResponsesState);
  const currentResponseIndex = useSelector(selectResponseIndex);

  const handleLoadResponses = useCallback(
    (filter: api.ResponsesFilter) => {
      dispatch(loadResponses(filter));
    },
    [dispatch]
  );

  const handleLoadMoreResponses = useCallback(() => {
    dispatch(loadMoreResponses());
  }, [dispatch]);

  const handleLoadResponse = useCallback(
    (index: number) => {
      dispatch(loadResponse(index));
    },
    [dispatch]
  );

  return (
    <ResponsesViewBase
      filter={filter}
      responsesState={responsesState}
      currentResponseIndex={currentResponseIndex}
      onLoadResponses={handleLoadResponses}
      onLoadMoreResponses={handleLoadMoreResponses}
      onLoadResponse={handleLoadResponse}
      ResponseComponent={ChatSequenceHistoryView}
    />
  );
}
