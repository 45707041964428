import React, { ReactElement } from "react";

import Checkbox from "../../../../components/forms/Checkbox";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";

export default function RequireFeedback(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateCustom } = usePromptConfigEditorOps();

  const feedback = !!prompt.definition.custom?.feedback;
  return (
    <div>
      <Checkbox
        label="Request teacher's feedback"
        checked={feedback}
        onChange={(event) => {
          updateCustom({ feedback: event.target.checked });
        }}
      />
    </div>
  );
}
