import "@inovua/reactdatagrid-community/index.css";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import {
  TypeColumn,
  TypeSortInfo,
} from "@inovua/reactdatagrid-community/types";
import { TypeColumns } from "@inovua/reactdatagrid-community/types/TypeColumn";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { IoEye } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  ErrorLayout,
  SelectItemLayout,
} from "../../components/LoadingLayout";
import MyTippy from "../../components/MyTippy";
import CompletedIcon from "../../featuresCommon/stats/CompletedIcon";
import { primeFilters as primeSeqStatsFilters } from "../flowSequenceStats/flowSequenceStatsSlice";
import {
  primeTab as primeSeqStatsTab,
  RESPONSES_TAB as SEQ_RESPONSES_TAB,
} from "../flowSequenceStats/FlowSequenceStatsView";
import { primeFilters as primeFlowStatsFilters } from "../flowStats/flowStatsSlice";
import {
  primeTab as primeFlowStatsTab,
  RESPONSES_TAB as FLOW_RESPONSES_TAB,
} from "../flowStats/FlowStatsView";
import { selectResponsesState } from "./identifiersSlice";
import styles from "./ResponsesView.module.css";

const TYPE_NAMES: Record<string, string> = {
  flow: "Flow",
  flowSequence: "Sequence",
};

const LS_IDENTIFIERS_RESPONSES_VIEW_SORT_KEY = "identifiersResponsesViewSort";

interface ResourceLinkProps {
  id: number;
  type: string;
  identifier: string;
}

function ResourceLink(props: ResourceLinkProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <MyTippy content="View details">
      <FilledButton
        className={styles.resourceLink}
        onClick={() => {
          const filter = {
            filter: { fromDate: null, tillDate: null },
            responsesFilter: { search: props.identifier },
          };
          if (props.type === "flow") {
            dispatch(primeFlowStatsFilters(filter));
            primeFlowStatsTab(FLOW_RESPONSES_TAB);
            history.push(`/flows/${props.id}/stats`);
          } else if (props.type === "flowSequence") {
            dispatch(primeSeqStatsFilters(filter));
            primeSeqStatsTab(SEQ_RESPONSES_TAB);
            history.push(`/sequences/${props.id}/stats`);
          } else {
            console.error("`type` not recognized");
          }
        }}
      >
        <IoEye size={18} />
      </FilledButton>
    </MyTippy>
  );
}

export default function ResponsesView(): ReactElement {
  const { loading, loadError, responses } = useSelector(selectResponsesState);

  const commonColumnProps: Partial<TypeColumn> = {
    hideable: true,
    lockable: false,
    resizable: false,
  };
  const columns: TypeColumns = [
    {
      name: "name",
      header: "Name",
      minWidth: 200,
      defaultFlex: 3,
      ...commonColumnProps,
    },
    {
      name: "type",
      header: "Type",
      minWidth: 100,
      maxWidth: 200,
      defaultFlex: 1,
      ...commonColumnProps,
      render: ({ value }: { value: string }) => TYPE_NAMES[value] ?? "Unknown",
    },
    {
      name: "score",
      header: "Score",
      minWidth: 100,
      maxWidth: 200,
      defaultFlex: 1,
      ...commonColumnProps,
    },
    {
      name: "completed",
      header: "Completed",
      minWidth: 100,
      maxWidth: 200,
      defaultFlex: 1,
      textAlign: "center",
      ...commonColumnProps,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: boolean }) =>
        value ? <CompletedIcon size={18} /> : null,
    },
    {
      name: "resourceLinkProps",
      header: null,
      width: 50,
      sortable: false,
      showInContextMenu: false,
      textAlign: "center",
      ...commonColumnProps,
      // eslint-disable-next-line react/display-name
      render: ({ value }: { value: ResourceLinkProps }) => (
        <ResourceLink {...value} />
      ),
    },
  ];

  const dataSource = useMemo(() => {
    if (responses == null) return [];
    return responses.responseLog.map((r) => ({
      id: r.id,
      name: r.resource.name,
      type: r.resource.type,
      score: r.score,
      completed: r.completed,
      resourceLinkProps: {
        id: r.resource.id,
        type: r.resource.type,
        identifier: r.identifier,
      },
    }));
  }, [responses]);

  const [sortInfo, setSortInfo] = useState<TypeSortInfo>(
    JSON.parse(
      localStorage.getItem(LS_IDENTIFIERS_RESPONSES_VIEW_SORT_KEY) ??
        JSON.stringify([
          {
            name: "name",
            dir: 1,
          },
        ])
    )
  );
  useEffect(() => {
    localStorage.setItem(
      LS_IDENTIFIERS_RESPONSES_VIEW_SORT_KEY,
      JSON.stringify(sortInfo)
    );
  }, [sortInfo]);

  if (loadError) {
    return <ErrorLayout message={loadError} />;
  } else if (loading === "pending") {
    return <LoadingLayout />;
  } else if (responses == null) {
    return <SelectItemLayout message="Select a student identifier to view" />;
  } else {
    return (
      <ReactDataGrid
        className={styles.root}
        idProperty="id"
        columns={columns}
        dataSource={dataSource}
        enableSelection={false}
        defaultSortInfo={sortInfo}
        onSortInfoChange={setSortInfo}
      />
    );
  }
}
