import { OrdChatFlowDefinition, PromptMessageItem } from "@xflr6/chatbot";

import { MCFlowEditorError } from "../../errors";

export function updateMessageItemFields(
  promptIndex: number,
  messageIndex: number,
  fields: Partial<PromptMessageItem>,
  flowDef: OrdChatFlowDefinition
): void {
  const promptDef = flowDef.promptsArray[promptIndex].definition;
  const messages = promptDef.messages;
  if (messages == null) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has no \`messages\` array present`
    );
  }

  messages[messageIndex] = { ...messages[messageIndex], ...fields };
}
