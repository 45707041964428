import classNames from "classnames";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";

import styles from "./Input.module.css";

/**
 * `resetLocalValue` resets the local value to the `Input`s current value.
 */
export class InputController {
  constructor(readonly resetLocalValue: () => void) {}
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  styleVariant?: "minimal" | "outlined" | null;
  onBlurValue?: ((value: string) => void) | null;
  blurOnEnterOrEsc?: boolean | null;
  leading?: ReactElement | null;
  autoExpand?: boolean | null;
  isLoading?: boolean | null;
  isStatic?: boolean | null;
  // Disables hover/focus effects
  noHighlight?: boolean | null;
  controller?: (c: InputController) => void;
}

/**
 * `onBlurValue` is called back with the latest edited value whenever the
 * component is blurred. Use it to create a controlled input that updates app
 * state only when blurred. This feature only works when no `ref` prop is
 * provided to this component.
 * @constructor
 */
export default React.forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    styleVariant,
    className,
    style,
    value,
    onBlur,
    onChange,
    onBlurValue,
    blurOnEnterOrEsc,
    onKeyDown,
    leading,
    autoExpand,
    isLoading,
    isStatic,
    noHighlight,
    controller,
    ...restProps
  }: InputProps,
  ref
): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  const [localValue, setLocalValue] = useState<
    string | number | readonly string[] | undefined
  >(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (controller != null) {
      controller(
        new InputController(() => {
          setLocalValue(value);
        })
      );
    }
  }, [controller]);

  const inputElement = (
    <input
      size={1} // Otherwise the input has too large a minimum width
      ref={ref ?? inputRef}
      className={classNames(styles.input, className, {
        [`${styles.input__hasLeading}`]: leading != null,
      })}
      {
        // We use this convoluted construct to avoid adding a `value` prop if
        // no corresponding `value` prop has been passed into this component.
        // Otherwise, React issues a controlled-to-uncontrolled input warning.
        ...(value !== undefined && { value: localValue })
      }
      onBlur={(event) => {
        if (onBlurValue != null) {
          // Funnily enough, as per React's typing, an input can accept types
          // other than string, but when the input's `onChange` is called, it
          // always gives back a string value! To deal with this quirk, we had
          // to make `localValue` accept multiple types, but then to maintain
          // parity with `onChange`, forcefully convert localValue to a string.
          onBlurValue(`${localValue}`);
        }
        if (onBlur != null) onBlur(event);
      }}
      onChange={(event) => {
        setLocalValue(event.target.value);
        if (onChange != null) onChange(event);
      }}
      onKeyDown={(event) => {
        if (
          (event.key === "Escape" || event.key === "Enter") &&
          blurOnEnterOrEsc
        ) {
          inputRef.current?.blur();
        }
        if (onKeyDown != null) onKeyDown(event);
      }}
      {...restProps}
    />
  );

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.root__default]: styleVariant == null,
        [styles.root__minimal]: styleVariant === "minimal",
        [styles.root__outlined]: styleVariant === "outlined",
        [styles.root__isStatic]: isStatic,
        [styles.root__noHighlight]: noHighlight,
      })}
      style={style}
    >
      {inputElement}
      {leading != null && <div className={styles.leading}>{leading}</div>}
      {autoExpand && (
        <div
          className={classNames(styles.ghost, {
            [`${styles.ghost__hasLeading}`]: leading != null,
          })}
        >
          {localValue}&nbsp;
        </div>
      )}
      {isLoading && (
        <div className={styles.spinner}>
          <FaSpinner />
        </div>
      )}
    </div>
  );
});
