import React, { ReactElement } from "react";

import ButtonGroup from "../../../../components/buttons/ButtonGroup";
import FilledButton from "../../../../components/buttons/FilledButton";
import MyTippy from "../../../../components/MyTippy";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";

export default function ChoiceInputDisplayType(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  const type = prompt.definition.inputDisplayType;

  return (
    <ButtonGroup>
      <MyTippy content="Markdown not supported">
        <FilledButton
          isActive={type == null}
          onClick={() => updateFields({ inputDisplayType: null })}
        >
          Small
        </FilledButton>
      </MyTippy>
      <FilledButton
        isActive={type === "large"}
        onClick={() => updateFields({ inputDisplayType: "large" })}
      >
        Large
      </FilledButton>
      <FilledButton
        isActive={type === "stacked"}
        onClick={() => updateFields({ inputDisplayType: "stacked" })}
      >
        Stacked
      </FilledButton>
    </ButtonGroup>
  );
}
