import * as api from "@xflr6/chatbot-api";
import { durationInWords } from "@xflr6/utils";
import React, { ReactElement } from "react";

import styles from "./BigPictureStats.module.css";

function Stat({
  title,
  value,
}: {
  title: string;
  value: string | number;
}): ReactElement {
  return (
    <div className={styles.stat}>
      <div className={styles.stat_title}>{title}</div>
      <div className={styles.stat_value}>{value}</div>
    </div>
  );
}

export default function BigPictureStats({
  stats,
}: {
  stats: api.Stats;
}): ReactElement {
  const completionRate =
    stats.attempted === 0
      ? "-"
      : `${((stats.totalCompleted / stats.attempted) * 100).toFixed(2)}%`;

  const averageTimeSpent =
    stats.attempted === 0
      ? "-"
      : durationInWords(stats.totalTime / stats.attempted);

  const averageTimeToComplete =
    stats.totalCompleted === 0
      ? "-"
      : durationInWords(stats.totalCompletedTime / stats.totalCompleted);

  return (
    <div className={styles.root}>
      <div className={styles.padding} />
      <Stat title="Started" value={stats.attempted} />
      <Stat title="Completed" value={stats.totalCompleted} />
      <Stat title="Completion rate" value={completionRate} />
      <Stat title="Avg. time spent" value={averageTimeSpent} />
      <Stat title="Avg. time to complete" value={averageTimeToComplete} />
      <div className={styles.padding} />
    </div>
  );
}
