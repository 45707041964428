import { OrdChatFlowDefinition } from "@xflr6/chatbot";

import { moveElement } from "../../../../utils/array";

export function movePrompt(
  fromIndex: number,
  toIndex: number,
  flowDef: OrdChatFlowDefinition
): void {
  moveElement(flowDef.promptsArray, fromIndex, toIndex);
}
