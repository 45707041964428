import { ChatFlow, ChatFlowDefinition } from "@xflr6/chatbot";
import React from "react";

import MeetingMessage from "../MeetingMessage";

export interface MeetingMessageShape {
  url: string;
}

export type MeetingVendor = "whereby" | "other";

const WHEREBY_MEETING_PATTERN = /whereby\.com/;

export function getMeetingVendor(url: string): MeetingVendor {
  if (WHEREBY_MEETING_PATTERN.test(url)) {
    return "whereby";
  }
  return "other";
}

/**
 * Call in your ChatFlow#onDefinitionProcessed methods to set a builder for
 * "meeting" prompts.
 */
export function setMeetingBuilder(
  chatFlow: ChatFlow,
  definition: ChatFlowDefinition
): void {
  const promptNames = Object.keys(definition.prompts).filter(
    (name) => definition.prompts[name].contentType === "meeting"
  );
  promptNames.forEach((name) =>
    chatFlow.setHistoryRecdComponentBuilder(
      name,
      (historyItem, positionInfo, promptInput, skipAnimation) => {
        return (
          <MeetingMessage
            historyItem={historyItem}
            positionInfo={positionInfo}
            promptInput={promptInput}
            skipAnimation={skipAnimation}
          />
        );
      }
    )
  );
}
