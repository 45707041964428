import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import React, { ReactElement } from "react";

import BigPictureStats from "./BigPictureStats";
import styles from "./PromptAnalyticsView.module.css";

function PromptDataView({
  promptKey,
  data,
  isExpanded,
}: {
  promptKey: string;
  data: api.PromptData;
  isExpanded?: boolean | null;
}): ReactElement {
  return (
    <>
      <div
        className={classNames(styles.promptBox, {
          [styles.promptBox__isExpanded]: isExpanded,
        })}
        key={promptKey}
      >
        <div className={styles.box_key}>{promptKey}</div>
        <div className={styles.box_content}>
          <div className={styles.box_message}>{data.message}</div>
          <div className={styles.box_stat}>Reached: {data.total}</div>
        </div>
        {data.answers != null && (
          <div className={styles.answers}>
            {Object.entries(data.answers).map(([message, { count }]) => (
              <div className={styles.answerBox} key={message}>
                <div className={styles.box_content}>
                  <div className={styles.box_message}>{message}</div>
                  <div className={styles.box_stat}>
                    {count} of {data.total}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {data.expandedThread != null &&
        data.expandedThread.map((item, index) => (
          <PromptDataView
            promptKey={item.key}
            data={item.data}
            isExpanded
            key={index}
          />
        ))}
    </>
  );
}

export interface PromptAnalyticsViewProps {
  flowStats: api.FlowStats;
  showBigPicture?: boolean | null;
}

export default function PromptAnalyticsView({
  flowStats,
  showBigPicture,
}: PromptAnalyticsViewProps): ReactElement {
  return (
    <div className={styles.root}>
      {showBigPicture && (
        <div className={styles.bigPicture}>
          <BigPictureStats stats={flowStats} />
        </div>
      )}
      <div className={styles.analytics}>
        {flowStats.promptAnalytics.map(({ key, data }) => {
          return <PromptDataView promptKey={key} data={data} key={key} />;
        })}
      </div>
    </div>
  );
}
