import axios from "axios";

import { buildAuthHeader } from "../auth";
import {
  OwnerDetails,
  Ownership,
  Responses,
  ResponsesFilter,
  ResponseStub,
  Stats,
} from "../sharedTypes";
import { baseUrl } from "../utils";

export interface ChatSequenceResponseStub extends ResponseStub {
  items: {
    feedbackTotal: number;
    feedbackPending: number;
    scoreLaterTotal: number;
    scoreLaterPending: number;
    evaluationTotal: number;
    evaluationPending: number;
    externalAnswerTotal: number;
    externalAnswerPending: number;
  }[];
}

export type ChatSequenceResponses = Responses<ChatSequenceResponseStub>;

export interface FlowSequenceStats extends Stats {
  items: Record<string, Stats>;
  ownership: Ownership;
  user: OwnerDetails;
}

export async function getFlowSequenceStats(
  id: number,
  fromDate?: string | null,
  tillDate?: string | null,
  outlierThreshold?: number | null
): Promise<FlowSequenceStats> {
  const response = await axios.get(`${baseUrl}/flow_sequences/${id}/stats`, {
    headers: { ...buildAuthHeader() },
    params: {
      ...(fromDate != null ? { fromDate } : {}),
      ...(tillDate != null ? { tillDate } : {}),
      ...(outlierThreshold != null ? { outlierThreshold } : {}),
    },
  });
  return response.data;
}

export async function getChatSequenceResponses(
  id: number,
  options?: {
    fromDate?: string | null;
    tillDate?: string | null;
    filter?: ResponsesFilter | null;
    sinceId?: number | null;
    all?: boolean | null;
  }
): Promise<ChatSequenceResponses> {
  const response = await axios.get(
    `${baseUrl}/flow_sequences/${id}/responseLogs`,
    {
      headers: { ...buildAuthHeader() },
      params: {
        ...(options?.fromDate != null ? { fromDate: options.fromDate } : {}),
        ...(options?.tillDate != null ? { tillDate: options.tillDate } : {}),
        ...(options?.filter?.search != null
          ? { search: options.filter.search }
          : {}),
        ...(options?.filter?.completed
          ? { completed: options.filter.completed }
          : {}),
        ...(options?.filter?.inputsPending
          ? { inputsPending: options.filter.inputsPending }
          : {}),
        ...(options?.sinceId != null ? { sinceId: options.sinceId } : {}),
        ...(options?.all ? { all: "1" } : {}),
      },
    }
  );
  return response.data;
}
