import classNames from "classnames";
import React, { ReactElement, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";

import Popover from "../Popover";
import styles from "./ColorPicker.module.css";

export type ColorPickerProps = {
  value?: string | null;
  onChange?: (value: string) => void;
  popoverStyle?: Partial<CSSStyleDeclaration>;
} & React.ComponentPropsWithoutRef<"div">;

export default React.forwardRef<HTMLDivElement, ColorPickerProps>(
  function ColorPicker(
    {
      value,
      onChange,
      popoverStyle,
      className,
      style,
      onClick,
      ...restProps
    }: ColorPickerProps,
    ref
  ): ReactElement {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Popover
        positions={["bottom", "top"]}
        reposition
        align="start"
        isOpen={isOpen}
        containerStyle={popoverStyle}
        onClickOutside={() => setIsOpen(false)}
        content={
          <div className={styles.picker}>
            <HexColorPicker color={value ?? undefined} onChange={onChange} />
            <div className={styles.picker_input}>
              <span>#</span>
              <HexColorInput color={value ?? undefined} onChange={onChange} />
            </div>
          </div>
        }
      >
        <div
          className={classNames(
            styles.swatch,
            { [styles.swatch__isOpen]: isOpen },
            className
          )}
          style={{ ...style, ...{ backgroundColor: value ?? "#000000" } }}
          onClick={(event) => {
            setIsOpen(true);
            onClick?.(event);
          }}
          {...restProps}
          ref={ref}
        />
      </Popover>
    );
  }
);
