import axios from "axios";

import { buildAuthHeader } from "./auth";
import { baseUrl } from "./utils";

export async function getSignedUploadRequest(
  filePath: string,
  fileType: string
): Promise<{
  uploadURL: string;
  outputURL: string;
}> {
  const response = await axios.post(
    `${baseUrl}/aws/s3-sign-upload`,
    {
      filePath,
      fileType,
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function uploadToAws(
  uploadPath: string,
  file: File,
  onUploadProgress?: ((event: ProgressEvent) => void) | null
): Promise<string> {
  const { uploadURL, outputURL } = await getSignedUploadRequest(
    uploadPath,
    file.type
  );
  await axios.put(uploadURL, file, {
    headers: {
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    },
    onUploadProgress: onUploadProgress ?? undefined,
    ...buildAuthHeader(),
  });
  return outputURL;
}
