import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import ResponsesViewBase from "../../featuresCommon/stats/ResponsesView";
import ChatHistoryView from "./ChatHistoryView";
import {
  loadMoreResponses,
  loadResponse,
  loadResponses,
  selectResponseIndex,
  selectResponsesFilter,
  selectResponsesState,
} from "./flowStatsSlice";
import { selectFilter } from "./flowStatsSlice";

export default function ResponsesView({
  flowId,
}: {
  flowId: number;
}): ReactElement {
  const dispatch = useDispatch();
  const statsFilter = useSelector(selectFilter);
  const filter = useSelector(selectResponsesFilter);
  const responsesState = useSelector(selectResponsesState);
  const currentResponseIndex = useSelector(selectResponseIndex);

  const handleLoadResponses = useCallback(
    (filter: api.ResponsesFilter) => {
      dispatch(loadResponses(filter));
    },
    [dispatch]
  );

  const handleLoadMoreResponses = useCallback(() => {
    dispatch(loadMoreResponses());
  }, [dispatch]);

  const handleLoadResponse = useCallback(
    (index: number) => {
      dispatch(loadResponse(index));
    },
    [dispatch]
  );

  const handleDownloadResponses = useCallback(
    async (filter: api.ResponsesFilter, withHistories?: boolean) => {
      const url = await api.getChatResponsesDownloadLink(flowId, {
        ...statsFilter,
        filter,
        withHistories,
      });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `flow-${flowId}-response-data.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    [flowId, statsFilter]
  );

  return (
    <ResponsesViewBase
      filter={filter}
      responsesState={responsesState}
      currentResponseIndex={currentResponseIndex}
      onLoadResponses={handleLoadResponses}
      onLoadMoreResponses={handleLoadMoreResponses}
      onLoadResponse={handleLoadResponse}
      ResponseComponent={ChatHistoryView}
      onDownloadResponses={handleDownloadResponses}
    />
  );
}
