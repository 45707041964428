import { i18n } from "@xflr6/chatbot";

import en from "../locales/en/translation.json";
import hi from "../locales/hi/translation.json";

// Reuses i18n from the chatbot package, rather than creating another instance.
// If we create multiple i18n instances, it becomes difficult to control which
// one is in scope when we use react-i18next's `useTranslation` and `Trans`.
i18n.addResourceBundle("en", "translation", en);
i18n.addResourceBundle("hi", "translation", hi);

export default i18n;
