import React, { ReactElement, ReactNode, Suspense } from "react";

import FixedLoader from "../components/FixedLoader";
import { ImageData, ImageInputOptions } from "./ImageInput";

const ImageInput = React.lazy(() => import("./ImageInput"));

const ImageInputContext = React.createContext<
  (options: ImageInputOptions) => Promise<ImageData>
>(Promise.reject);

export const useImageInput = (): ((
  options: ImageInputOptions
) => Promise<ImageData>) => React.useContext(ImageInputContext);

export const ImageInputProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [
    imageInputState,
    setImageInputState,
  ] = React.useState<ImageInputOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: (imageData: ImageData) => void;
    reject: (reason?: string) => void;
  }>();

  function openImageInput(options: ImageInputOptions) {
    setImageInputState(options);
    return new Promise<ImageData>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }

  function handleClose(reason?: string) {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject(reason);
    }
    setImageInputState(null);
  }

  function handleImageAvailable(imageData: ImageData) {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(imageData);
    }
    setImageInputState(null);
  }

  return (
    <>
      <ImageInputContext.Provider value={openImageInput}>
        {children}
      </ImageInputContext.Provider>
      <Suspense fallback={<FixedLoader message="Loading image uploader..." />}>
        {Boolean(imageInputState) && (
          <ImageInput
            isOpen={Boolean(imageInputState)}
            onClose={handleClose}
            onImageAvailable={handleImageAvailable}
            {...imageInputState}
          />
        )}
      </Suspense>
    </>
  );
};
