import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";

import { AsyncStatus, RootState } from "../../app/store";
import {
  BLANK_FILTER,
  FlowAndSeqFilter,
} from "../../featuresCommon/FlowAndSeqFilterBar";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface FlowListState {
  loading: AsyncStatus;
  filter: FlowAndSeqFilter;
  data: api.Flows | null;
  error: ErrorData | null;
}

export const loadFlows = createAsyncThunk(
  "flowList/loadFlowsStatus",
  async (filter: FlowAndSeqFilter, thunkApi) => {
    try {
      const { ownership, searchTerm } = filter;
      return await api.listFlows({
        all: true,
        ...(ownership != null ? { ownership } : {}),
        ...(searchTerm.length > 0 ? { q: searchTerm } : {}),
      });
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const slice = createSlice({
  name: "flowList",
  initialState: {
    loading: "idle",
    filter: BLANK_FILTER,
    data: null,
    error: null,
  } as FlowListState,
  reducers: {
    clearFlows: (state: FlowListState) => {
      state.loading = "idle";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: {
    [`${loadFlows.pending}`]: (state: FlowListState, action) => {
      state.loading = "pending";
      state.filter = action.meta.arg;
      state.error = null;
    },
    [`${loadFlows.fulfilled}`]: (
      state: FlowListState,
      action: PayloadAction<api.Flows>
    ) => {
      state.loading = "fulfilled";
      state.data = action.payload;
    },
    [`${loadFlows.rejected}`]: (
      state: FlowListState,
      action: PayloadAction<ErrorData>
    ) => {
      state.loading = "rejected";
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { clearFlows } = slice.actions;

export const selectFlowList = (state: RootState): FlowListState =>
  state.flowList;

export default slice.reducer;
