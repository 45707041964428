import { OrdChatFlowDefinition } from "@xflr6/chatbot";

import { MCFlowEditorError } from "../../errors";

/**
 * Lossily converts prompt `messages` into a single `message`.
 * @param promptIndex
 * @param flowDef
 * @returns the number of message items lost in the conversion
 */
export function convertToMessage(
  promptIndex: number,
  flowDef: OrdChatFlowDefinition
): number {
  const promptDef = flowDef.promptsArray[promptIndex].definition;
  const messages = promptDef.messages;
  if (messages == null || messages.length === 0) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has no/empty \`messages\` array`
    );
  }
  promptDef.message = messages[0].message;
  promptDef.tMessage = messages[0].tMessage;
  promptDef.contentType = messages[0].contentType;
  promptDef.minNextPromptDelayMs = messages[0].minNextPromptDelayMs;
  const lostItemsCount = messages.length - 1;
  promptDef.messages = null;
  return lostItemsCount;
}
