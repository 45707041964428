import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";
import { equals, last } from "ramda";

import { AsyncStatus, RootState } from "../../app/store";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface IdentifiersState {
  filter: api.IdentifiersFilter;
  loading: AsyncStatus;
  loadError: ErrorData | null;
  identifiers: api.Identifiers | null;
  identifierIndex: number | null;
  responsesReqId: string | null;
  responsesLoading: AsyncStatus;
  responsesLoadError: ErrorData | null;
  responses: api.IdentifierResponses | null;
}

function resetData(state: IdentifiersState, loading: AsyncStatus) {
  state.loading = loading;
  state.loadError = null;
  state.identifiers = null;
  state.identifierIndex = null;
  state.responsesReqId = null;
  state.responsesLoading = "idle";
  state.responsesLoadError = null;
  state.responses = null;
}

export const loadIdentifiers = createAsyncThunk(
  "identifiers/loadIdentifiersStatus",
  async (filter: api.IdentifiersFilter, thunkApi) => {
    try {
      return await api.getIdentifiers({ filter });
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const loadMoreIdentifiers = createAsyncThunk(
  "identifiers/loadMoreIdentifiersStatus",
  async (payload: void, thunkApi) => {
    const { identifiers: state } = thunkApi.getState() as {
      identifiers: IdentifiersState;
    };
    if (state.identifiers != null) {
      try {
        return await api.getIdentifiers({
          filter: state.filter,
          sinceIdentifier: last(state.identifiers.identifiers)?.value,
        });
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadIdentifiers"))
      );
    }
  },
  {
    condition(payload, thunkApi) {
      const { identifiers: state } = thunkApi.getState() as {
        identifiers: IdentifiersState;
      };
      return state.loading !== "pending";
    },
  }
);

export const loadResponses = createAsyncThunk(
  "identifiers/loadResponsesStatus",
  async (index: number, thunkApi) => {
    const { identifiers: state } = thunkApi.getState() as {
      identifiers: IdentifiersState;
    };
    if (state != null && state.identifiers != null) {
      const identifier = state.identifiers.identifiers[index].value;
      try {
        return await api.getIdentifierResponses(identifier);
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadIdentifiers"))
      );
    }
  }
);

export const slice = createSlice({
  name: "identifiers",
  initialState: {
    filter: {},
    loading: "idle",
    loadError: null,
    identifiers: null,
    identifierIndex: null,
    responsesLoading: "idle",
    responsesLoadError: null,
    responses: null,
  } as IdentifiersState,
  reducers: {
    primeFilter: (
      state: IdentifiersState,
      action: PayloadAction<api.IdentifiersFilter>
    ) => {
      if (!equals(action.payload, state.filter)) {
        state.filter = action.payload;
        resetData(state, "idle");
      }
    },
  },
  extraReducers: (builder) => {
    // ** loadIdentifiers **
    builder.addCase(loadIdentifiers.pending, (state, action) => {
      state.filter = action.meta.arg;
      resetData(state, "pending");
    });
    builder.addCase(loadIdentifiers.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.identifiers = action.payload;
    });
    builder.addCase(loadIdentifiers.rejected, (state, action) => {
      state.loading = "rejected";
      state.loadError = action.payload;
    });

    // ** loadMoreIdentifiers **
    builder.addCase(loadMoreIdentifiers.pending, (state) => {
      state.loading = "pending";
      state.loadError = null;
    });
    builder.addCase(loadMoreIdentifiers.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.identifiers =
        state.identifiers == null
          ? action.payload
          : {
              ...action.payload,
              identifiers: state.identifiers.identifiers.concat(
                action.payload.identifiers
              ),
            };
    });
    builder.addCase(loadMoreIdentifiers.rejected, (state, action) => {
      state.loading = "rejected";
      state.loadError = action.payload;
    });

    // ** loadResponses **
    builder.addCase(loadResponses.pending, (state, action) => {
      state.responsesReqId = action.meta.requestId;
      state.identifierIndex = action.meta.arg;
      state.responsesLoading = "pending";
      state.responsesLoadError = null;
    });
    builder.addCase(loadResponses.fulfilled, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "fulfilled";
        state.responses = action.payload;
      }
    });
    builder.addCase(loadResponses.rejected, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "rejected";
        state.responsesLoadError = action.payload;
      }
    });
  },
});

export const { primeFilter } = slice.actions;

export const selectFilter = ({
  identifiers,
}: RootState): api.IdentifiersFilter => identifiers.filter;

export const selectIdentifiersState = ({
  identifiers,
}: RootState): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  identifiers: api.Identifiers | null;
} => ({
  loading: identifiers.loading,
  loadError: identifiers.loadError,
  identifiers: identifiers.identifiers,
});

export const selectIdentifierIndex = ({
  identifiers,
}: RootState): number | null => identifiers.identifierIndex;

export const selectResponsesState = ({
  identifiers,
}: RootState): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  responses: api.IdentifierResponses | null;
} => ({
  loading: identifiers.responsesLoading,
  loadError: identifiers.responsesLoadError,
  responses: identifiers.responses,
});

export default slice.reducer;
