import * as api from "@xflr6/chatbot-api";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import smartlookClient from "smartlook-client";

import { selectAuth } from "../auth/authSlice";

export default function Smartlook({
  projectKey,
}: {
  projectKey: string;
}): null {
  const { currentUser } = useSelector(selectAuth);
  const location = useLocation();

  useEffect(() => {
    smartlookClient.init(projectKey);
  }, [projectKey]);

  useEffect(() => {
    if (currentUser) {
      smartlookClient.identify(api.getUserId(currentUser), {});
    } else {
      smartlookClient.anonymize();
    }
  }, [currentUser]);

  useEffect(() => {
    smartlookClient.navigation(location.pathname);
  }, [location.pathname]);

  return null;
}
