import { PromptDefinition, RemoteMessageShape } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { FaSpinner } from "react-icons/fa";
import { useSelector } from "react-redux";
import { components, MenuProps, OptionProps } from "react-select";
import AsyncSelect from "react-select/async";

import {
  CHAT_ID_ARG,
  REMOTE_FLOW_PATTERN,
  REMOTE_FLOW_PREFIX,
} from "../../../featuresCommon/utils";
import { SelectStyles } from "../../../utils/reactSelect";
import { MARKDOWN_IMAGE } from "../../slateMessageEditor/imagesPlugin";
import {
  PromptEasyNamesEntry,
  selectPromptEasyNames,
} from "../selectPromptEasyNames";
import styles from "./PromptKeySelect.module.css";
import { buildPromptSuffix } from "./utils";

function LoadingIndicator() {
  return (
    <div className={styles.spinnerContainer}>
      <FaSpinner className={styles.spinner} />
    </div>
  );
}

function Menu(props: MenuProps) {
  return (
    <components.Menu {...props}>
      <>
        {props.selectProps.showRemoteOptions &&
          !props.selectProps.inputValue?.startsWith("#") && (
            <div className={styles.menuHint}>
              Enter &lsquo;#&rsquo; to search for flows
            </div>
          )}
        {props.children}
      </>
    </components.Menu>
  );
}

type PromptOptionData = {
  type: "prompt";
  value: PromptEasyNamesEntry;
  label: string;
};

type RemoteOptionData = {
  type: "remote";
  value: string;
  label: string;
  preview?: string;
};

type OptionData = PromptOptionData | RemoteOptionData;

function PromptOption(props: OptionProps) {
  const promptEasyNames = useSelector(selectPromptEasyNames);

  function buildMessagePreview(
    message: PromptDefinition["message"],
    contentType: PromptDefinition["contentType"]
  ): ReactNode {
    if (message == null) {
      return "[Empty]";
    } else {
      switch (contentType) {
        case null:
        case undefined:
          // Mask Markdown images to hide URL details
          return `${message}`.replaceAll(MARKDOWN_IMAGE, "[IMAGE]");
        case "remote":
          const url = ((message as unknown) as RemoteMessageShape).url;
          let match;
          if ((match = url.match(api.answerSamplesUrlPattern))) {
            const promptName = api.effectivePromptName(
              match.groups?.promptName
            );
            const promptSuffix = buildPromptSuffix(promptName, promptEasyNames);
            return `[Answer Samples${promptSuffix}]`;
          } else if ((match = url.match(api.answerStatsUrlPattern))) {
            const promptName = api.effectivePromptName(
              match.groups?.promptName
            );
            const promptSuffix = buildPromptSuffix(promptName, promptEasyNames);
            return `[Answer Stats${promptSuffix}]`;
          } else if (url.match(api.evaluationsUrlPattern)) {
            return "[Evaluation]";
          } else {
            return "[Remote]";
          }
        case "video":
          return `${message}`;
        default:
          return "[Preview not available]";
      }
    }
  }

  function buildPreview(value: PromptEasyNamesEntry): ReactNode {
    if (value.promptDef.messages != null) {
      const messageItem = value.promptDef.messages[0];
      if (messageItem == null) {
        return "[Empty]";
      } else {
        return buildMessagePreview(
          messageItem.message,
          messageItem.contentType
        );
      }
    } else {
      return buildMessagePreview(
        value.promptDef.message,
        value.promptDef.contentType
      );
    }
  }

  const data = props.data as PromptOptionData;
  return (
    <components.Option {...props}>
      <div className={styles.optionLabelKey}>{data.label}</div>
      <div className={styles.optionLabelPreview}>
        {buildPreview(data.value as PromptEasyNamesEntry)}
      </div>
    </components.Option>
  );
}

function RemoteOption(props: OptionProps) {
  const data = props.data as RemoteOptionData;
  return (
    <components.Option {...props}>
      <div className={styles.optionLabelKey}>{data.label}</div>
      <div className={styles.optionLabelPreview}>{data.preview}</div>
    </components.Option>
  );
}

function Option(props: OptionProps) {
  const data = props.data as OptionData;
  switch (data.type) {
    case "prompt":
      return <PromptOption {...props}>{props.children}</PromptOption>;
    case "remote":
      return <RemoteOption {...props}>{props.children}</RemoteOption>;
    default:
      return <components.Option {...props} />;
  }
}

const selectStyles: SelectStyles = {
  container: (provided) => ({
    ...provided,
    fontSize: "85%",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 24,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
    padding: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontSize: "90%",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
    marginTop: 4,
    marginBottom: 4,
  }),
  menu: (provided) => ({
    ...provided,
    width: 300,
    zIndex: 3,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 30,
  }),
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  }),
};

export function getKeyDisplayName(initialPromptKey: string): string {
  const match = initialPromptKey.match(REMOTE_FLOW_PATTERN);
  if (match != null) {
    return `#${match[1]}`;
  } else if (initialPromptKey.startsWith(".")) {
    return initialPromptKey.substring(1);
  } else {
    return initialPromptKey;
  }
}

export interface PromptKeySelectProps {
  // TODO: Also use this option to exclude the prompt from options
  promptIndex: number;
  initialPromptKey?: string | null;
  onChange?: ((selectedKey: string | null | undefined) => void) | null;
  autoFocus?: boolean;
  onBlur?: () => void;
  isClearable?: boolean;
  showBelowOption?: boolean | null;
  showRemoteOptions?: boolean | null;
  maxMenuHeight?: number;
}

export default function PromptKeySelect(
  props: PromptKeySelectProps
): ReactElement | null {
  const promptEasyNames = useSelector(selectPromptEasyNames);

  const initialValue: OptionData | null = useMemo(() => {
    if (promptEasyNames == null) return null;
    if (props.initialPromptKey == null) return null;
    const initialEntry = promptEasyNames.entries.find(
      (entry) => props.initialPromptKey === `.${entry.name}`
    );
    if (initialEntry != null) {
      return {
        type: "prompt",
        value: initialEntry,
        label: initialEntry.easy,
      };
    } else {
      return {
        type: "remote",
        value: props.initialPromptKey,
        label: getKeyDisplayName(props.initialPromptKey),
      };
    }
  }, [promptEasyNames, props.initialPromptKey]);

  const promptOptions: {
    value: PromptEasyNamesEntry;
    label: string;
  }[] = useMemo(() => {
    if (promptEasyNames == null) return [];
    const entries = promptEasyNames.entries;
    const options = entries.map((entry) => {
      return { type: "prompt", value: entry, label: `${entry.easy}` };
    });
    if (props.showBelowOption && props.promptIndex < entries.length - 1) {
      const entry = entries[props.promptIndex + 1];
      options.unshift({ type: "prompt", value: entry, label: "Below" });
    }
    return options;
  }, [promptEasyNames, props.showBelowOption, props.promptIndex]);

  if (promptEasyNames == null) return null;

  return (
    <AsyncSelect
      styles={selectStyles}
      components={{
        LoadingIndicator,
        Menu,
        Option,
      }}
      menuPlacement="auto"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      isClearable={props.isClearable}
      autoFocus={props.autoFocus}
      menuIsOpen={props.autoFocus}
      maxMenuHeight={props.maxMenuHeight}
      value={initialValue}
      defaultOptions={promptOptions}
      loadOptions={async (inputValue) => {
        if (props.showRemoteOptions && inputValue.startsWith("#")) {
          const response = await api.listFlows({
            all: true,
            ownership: "own",
            q: inputValue.substring(1),
          });
          return response.flows.map((flow) => {
            return {
              type: "remote",
              value: `${REMOTE_FLOW_PREFIX}?${CHAT_ID_ARG}=${flow.id}.start`,
              label: `#${flow.id}`,
              preview: flow.name,
            };
          });
        } else {
          return promptOptions.filter((o) =>
            o.label.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
      }}
      placeholder="---"
      onChange={(selectedOption) => {
        if (props.onChange != null) {
          let selectedKey;
          if (selectedOption != null) {
            const typedSelectedOption = selectedOption as {
              value: string | PromptEasyNamesEntry;
            };
            if (typeof typedSelectedOption.value === "string") {
              selectedKey = typedSelectedOption.value;
            } else {
              selectedKey = `.${typedSelectedOption.value.name}`;
            }
          }
          props.onChange(selectedKey);
        }
      }}
      onBlur={props.onBlur}
      // All props below this line are custom props and have no special meaning
      // for react-select.
      showRemoteOptions={props.showRemoteOptions ?? undefined}
    />
  );
}
