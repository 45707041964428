import { OrdChatFlowDefinition } from "@xflr6/chatbot";
import { AltMessageItem } from "@xflr6/chatbot-engine";
import { v4 } from "uuid";

import { WithKey } from "./types";

/**
 * Adds a prompt-level quick response list, iff it not already present.
 */
export function initPromptQuickResponses(
  promptIndex: number,
  flowDef: OrdChatFlowDefinition
): void {
  const { definition: promptDef } = flowDef.promptsArray[promptIndex];
  if (!promptDef.quickResponses?.length) {
    const message = { message: "" };
    (message as WithKey<AltMessageItem>).__key = v4();
    promptDef.quickResponses = [message];
  }
}
