import {
  Chat,
  ChatFlow,
  ChatFlowDefinition,
  ChatView,
  createBaseCssVariablesStream,
  FlowId,
  getItemDuration,
  OrdChatFlowDefinition,
  PromptKey,
} from "@xflr6/chatbot";
import {
  appendVersionsToRemoteNextKeys,
  getMatchingRemotePromptNames,
  setAnswerSamplesBuilder,
  setAnswerStatsBuilder,
  setMeetingBuilder,
} from "@xflr6/chatbot_customizations";
import * as api from "@xflr6/chatbot-api";
import { ChatJson } from "@xflr6/chatbot-engine";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  ErrorLayout,
  SelectItemLayout,
} from "../../components/LoadingLayout";
import MyTippy from "../../components/MyTippy";
import { selectEffectiveResponseFlowSettings } from "../../featuresCommon/selectors";
import ChatViewNav from "../../featuresCommon/stats/ChatViewNav";
import EvaluationMessageBase, {
  EvaluationMessageProps,
} from "../../featuresCommon/stats/EvaluationMessage";
import RecdHistoryItemView from "../../featuresCommon/stats/RecdHistoryItemView";
import SentHistoryItemView from "../../featuresCommon/stats/SentHistoryItemView";
import {
  FIFTEEN_MINUTES_IN_MS,
  getChatTimeSpentInWords,
} from "../../featuresCommon/stats/utils";
import { CHAT_ID_ARG, VERSION_ARG } from "../../featuresCommon/utils";
import { applySettingsToBaseCss } from "../../utils/settings";
import styles from "./ChatHistoryView.module.css";
import {
  removeFromResponses,
  selectResponseAdminInputLocations,
  selectResponseState,
  selectStatsOwnership,
  updateResponseEvaluation,
  updateResponseHistory,
} from "./flowStatsSlice";

// We create a custom `EvaluationMessage` component in order to be able to use
// `dispatch`.
function EvaluationMessage(
  props: Omit<EvaluationMessageProps, "onUpdateEvaluation">
): ReactElement {
  const dispatch = useDispatch();

  return (
    <EvaluationMessageBase
      {...props}
      onUpdateEvaluation={async (evaluation, decrementPending) => {
        await api.updateEvaluation(
          props.flowId,
          props.identifier,
          props.historyItem.promptKeyStr,
          evaluation
        );
        dispatch(
          updateResponseEvaluation({
            flowId: props.flowId,
            identifier: props.identifier,
            promptKeyStr: props.historyItem.promptKeyStr,
            evaluation,
            decrementPending,
          })
        );
      }}
    />
  );
}

class ResponseFlow extends ChatFlow {
  async getDefinition(): Promise<OrdChatFlowDefinition> {
    const chatId = parseInt((this.id.args[CHAT_ID_ARG] ?? [])[0], 10);
    const version = parseInt((this.id.args[VERSION_ARG] ?? [])[0], 10);
    if (isNaN(chatId) || isNaN(version)) {
      throw new Error("Valid flow id and/or version not found in arguments");
    } else {
      return (await api.getPublishedFlow(chatId, version, this.chat.language))
        .definition;
    }
  }

  async onDefinitionAvailable(definition: ChatFlowDefinition): Promise<void> {
    await appendVersionsToRemoteNextKeys(definition);
  }

  async onDefinitionProcessed(definition: ChatFlowDefinition) {
    getMatchingRemotePromptNames(definition, api.evaluationsUrlPattern).forEach(
      (name) =>
        this.setHistoryRecdComponentBuilder(
          name,
          (historyItem, positionInfo, promptInput) => {
            const responseChat = this.chat as ResponseChat;
            return (
              <EvaluationMessage
                historyItem={historyItem}
                positionInfo={positionInfo}
                promptInput={promptInput}
                flowId={responseChat.flowId}
                identifier={responseChat.identifier}
                initialEvaluation={
                  responseChat.evaluations[historyItem.promptKeyStr]
                }
              />
            );
          }
        )
    );

    setAnswerSamplesBuilder(this, definition, api.answerSamplesUrlPattern);
    setAnswerStatsBuilder(this, definition, api.answerStatsUrlPattern);
    setMeetingBuilder(this, definition);
  }
}

class ResponseChat extends Chat {
  readonly flowId: number;
  readonly identifier: string;
  readonly evaluations: Record<string, string>;

  constructor(
    name: string,
    response: api.ChatResponse,
    onAdminHistoryUpdate: (
      id: number,
      identifier: string,
      history: ChatJson
    ) => void
  ) {
    let shouldUpdateAdminHistory = false;
    super(name, {
      runMode: "admin",
      answerClickDelayMs: 0,
      defaultMinNextPromptDelayMs: 0,
      language: response.history?.language,
      context: {
        userId: response.identifier,
        args: {
          originId: [response.id.toString()],
        },
      },
      onHistoryChanged: (history) => {
        const lastItem = history[history.length - 1];
        if (lastItem != null) {
          if (lastItem.direction === "sent" && lastItem.answerExternally) {
            shouldUpdateAdminHistory = true;
          } else if (
            lastItem.direction === "received" &&
            lastItem.promptAnswerType !== "auto" &&
            shouldUpdateAdminHistory
          ) {
            onAdminHistoryUpdate(this.flowId, this.identifier, this.toJson());
            shouldUpdateAdminHistory = false;
          }
        }
      },
    });

    this.flowId = response.id;
    this.identifier = response.identifier;
    this.evaluations = response.evaluations;

    if (response.history != null) {
      this.loadFromJson(response.history);
    } else {
      this.setPrompt(PromptKey.fromString(".start"));
    }
  }

  handleGetFlow(flowId: FlowId): ChatFlow {
    return new ResponseFlow(flowId, this);
  }
}

function ResponseViewNav(): ReactElement | null {
  const responseAdminInputLocations = useSelector(
    selectResponseAdminInputLocations
  );

  return responseAdminInputLocations.length > 0 ? (
    <div className={styles.responseViewNav}>
      <ChatViewNav locations={responseAdminInputLocations} />
    </div>
  ) : null;
}

export default function ChatHistoryView(): ReactElement {
  const dispatch = useDispatch();
  const responseState = useSelector(selectResponseState);
  const ownership = useSelector(selectStatsOwnership);

  const { addToast } = useToasts();

  const [chat, setChat] = useState<ResponseChat | null>(null);
  const [isDestroying, setIsDestroying] = useState(false);

  const onAdminHistoryUpdate = useCallback(
    async (flowId, identifier, newHistory) => {
      await api.updateManagedChatResponseHistory(
        flowId,
        identifier,
        newHistory
      );
      dispatch(updateResponseHistory({ data: newHistory, index: 0 }));
    },
    [dispatch]
  );

  useEffect(() => {
    let chat: ResponseChat;
    if (responseState.response != null) {
      chat = new ResponseChat(
        "chat",
        responseState.response,
        onAdminHistoryUpdate
      );
      setChat(chat);
    }

    return function cleanup() {
      chat?.dispose();
      setChat(null);
    };
  }, [responseState.response, onAdminHistoryUpdate]);

  // TODO Remove outlierThreshold hard-coding
  function buildHeader(): ReactElement {
    const response = responseState.response;
    return (
      <div className={styles.header}>
        <MyTippy content="Time spent">
          <div className={styles.timeSpent}>
            <MdTimer />
            &nbsp;
            {response == null
              ? "--"
              : getChatTimeSpentInWords(
                  response.history?.history,
                  FIFTEEN_MINUTES_IN_MS
                )}
          </div>
        </MyTippy>
        {ownership === "own" && (
          <FilledButton
            className={styles.destroyButton}
            disabled={response == null || isDestroying}
            onClick={async () => {
              if (response == null) return;
              if (window.confirm("This is irreversible. Are you sure?")) {
                const { id: flowId, identifier } = response;
                try {
                  setIsDestroying(true);
                  await api.destroyChatResponse(flowId, identifier);
                  dispatch(removeFromResponses({ flowId, identifier }));
                  setIsDestroying(false);
                } catch (error) {
                  setIsDestroying(false);
                  addToast("Failed to delete chat.", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }
            }}
          >
            <FaRegTrashAlt />
            &nbsp;Delete
          </FilledButton>
        )}
      </div>
    );
  }

  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  const settings = useSelector(selectEffectiveResponseFlowSettings);
  useEffect(() => {
    applySettingsToBaseCss($baseCssVariables.current, settings);
  }, [settings]);

  function buildChatView(): ReactElement {
    if (responseState.loading === "pending") {
      return <LoadingLayout />;
    } else if (responseState.loading === "rejected") {
      return <ErrorLayout message={responseState.loadError} />;
    } else if (responseState.response == null) {
      return <SelectItemLayout message="Select an item to view" />;
    } else if (chat == null) {
      return <LoadingLayout />;
    } else {
      const response = responseState.response;
      const historyItems = response.history?.history;
      const millisecondsSpentPerItem =
        historyItems != null
          ? historyItems.map((item, index) =>
              getItemDuration(historyItems, index)
            )
          : null;

      return (
        <ChatView
          chat={chat}
          showScore
          disableKeyboardAnswer
          skipInitialAnimations
          renderHistoryItem={(renderProps) => {
            return renderProps.historyItem.direction === "sent" ? (
              <SentHistoryItemView
                {...renderProps}
                flowId={response.id}
                onUpdateHistory={async (index, data) => {
                  await api.updateChatResponseHistory(
                    response.id,
                    response.identifier,
                    index,
                    data
                  );
                  dispatch(updateResponseHistory({ index, data }));
                }}
              />
            ) : (
              <RecdHistoryItemView
                {...renderProps}
                millisecondsSpent={
                  millisecondsSpentPerItem != null &&
                  renderProps.positionInfo.index != null
                    ? millisecondsSpentPerItem[renderProps.positionInfo.index]
                    : null
                }
                outlierThreshold={FIFTEEN_MINUTES_IN_MS}
                outlierBehaviour="clip"
                historyLength={response.history?.history.length ?? null}
              />
            );
          }}
          $baseCssVariables={$baseCssVariables.current}
          avatarSrc={settings?.avatarUrl}
        />
      );
    }
  }

  return (
    <div className={styles.root}>
      {buildHeader()}
      <div className={styles.responseView}>
        <ResponseViewNav />
        {buildChatView()}
      </div>
    </div>
  );
}
