import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
} from "../../components/LoadingLayout";
import FlowAndSeqFilterBar, {
  BLANK_FILTER,
  filterEquals,
  FlowAndSeqFilter,
} from "../../featuresCommon/FlowAndSeqFilterBar";
import FlowAndSeqGetStarted from "../../featuresCommon/FlowAndSeqGetStarted";
import styles from "../flowList/FlowListView.module.css";
import FlowSequenceItem from "./FlowSequenceItem";
import {
  clearFlowSequences,
  loadFlowSequences,
  selectFlowSequenceList,
} from "./flowSequenceListSlice";

const LS_FLOW_SEQUENCE_FILTER_KEY = "flowSequenceFilter";

function getFilterFromLocalStorage(): FlowAndSeqFilter {
  const value = localStorage.getItem(LS_FLOW_SEQUENCE_FILTER_KEY);
  return value == null
    ? { ownership: null, searchTerm: "" }
    : JSON.parse(value);
}

function setFilterInLocalStorage(value: FlowAndSeqFilter): void {
  localStorage.setItem(LS_FLOW_SEQUENCE_FILTER_KEY, JSON.stringify(value));
}

export interface FlowSequenceListViewProps {
  className?: string;
  renderItem?: ((flow: api.FlowSequenceStub) => ReactElement) | null;
}

export default function FlowSequenceListView(
  props: FlowSequenceListViewProps
): ReactElement | null {
  const dispatch = useDispatch();

  const flowSequenceList = useSelector(selectFlowSequenceList);

  useEffect(() => {
    dispatch(loadFlowSequences(getFilterFromLocalStorage()));

    return function cleanup() {
      dispatch(clearFlowSequences());
    };
  }, [dispatch]);

  function buildResults(): ReactNode {
    if (flowSequenceList.loading === "pending") {
      return <LoadingLayout />;
    }
    if (flowSequenceList.loading === "rejected") {
      return <ErrorLayout message={flowSequenceList.error} />;
    }
    if (flowSequenceList.data == null) return null;
    if (flowSequenceList.data.flowSequences.length === 0) {
      return (
        <NoResultsLayout
          message={
            filterEquals(flowSequenceList.filter, BLANK_FILTER)
              ? "You have no sequences yet"
              : filterEquals(flowSequenceList.filter, {
                  ...BLANK_FILTER,
                  ownership: "shared",
                })
              ? "Nothing shared with you yet"
              : "There are no matching results"
          }
          footer={
            filterEquals(flowSequenceList.filter, BLANK_FILTER) && (
              <FlowAndSeqGetStarted />
            )
          }
        />
      );
    }
    return flowSequenceList.data.flowSequences.map(
      (flowSequence) =>
        props.renderItem?.(flowSequence) ?? (
          <FlowSequenceItem key={flowSequence.id} flowSequence={flowSequence} />
        )
    );
  }

  return (
    <div className={classNames(styles.root, props.className)}>
      <FlowAndSeqFilterBar
        value={flowSequenceList.filter}
        onChange={(value) => {
          setFilterInLocalStorage(value);
          dispatch(loadFlowSequences(value));
        }}
      />
      <div className={classNames(styles.results)}>{buildResults()}</div>
    </div>
  );
}
