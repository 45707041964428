import React, { ReactElement, ReactNode, useEffect } from "react";
import { BsChatQuoteFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteProps } from "react-router-dom";

import AuthForm from "./AuthForm";
import { loadDetails, selectAuth } from "./authSlice";
import styles from "./PrivateRoute.module.css";

export interface PrivateRouteProps extends RouteProps {
  children: ReactNode;
}

export default function PrivateRoute({
  children,
  ...restProps
}: PrivateRouteProps): ReactElement {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  useEffect(() => {
    if (auth.currentUser) {
      dispatch(loadDetails());
    }
  }, [dispatch, auth.currentUser]);

  return (
    <Route
      {...restProps}
      render={() =>
        auth.currentUser != null ? (
          children
        ) : (
          <div className={styles.root}>
            <div className={styles.logo}>
              <BsChatQuoteFill className={styles.logo_icon} />
              <div className={styles.logo_name}>DialogForm</div>
            </div>
            <div className={styles.authForm}>
              <AuthForm />
            </div>
          </div>
        )
      }
    />
  );
}
