import React, { ReactElement } from "react";
import { IoLogoYoutube } from "react-icons/io";

import buttonStyles from "../components/buttons/Button.module.css";
import FilledButton from "../components/buttons/FilledButton";
import { DOCS_URL } from "../utils/constants";
import styles from "./FlowAndSeqGetStarted.module.css";

export default function FlowAndSeqGetStarted(): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.message}>
        Watch a series of short videos to start creating content on DialogForm
      </div>
      <a
        href={`${DOCS_URL}/tutorial`}
        className={buttonStyles.hasButton}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FilledButton isFullWidth>
          <IoLogoYoutube size={18} />
          &nbsp;Video Tutorials
        </FilledButton>
      </a>
    </div>
  );
}
