import {
  AnswerDefinition,
  assignTranslation,
  getTranslation,
} from "@xflr6/chatbot";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Draggable } from "react-beautiful-dnd";
import { DiAsterisk } from "react-icons/di";
import { FaRegTrashAlt, FaReply, FaTrophy } from "react-icons/fa";
import { GrDrag } from "react-icons/gr";
import { MdMoreVert } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import FlatButton from "../../../../components/buttons/FlatButton";
import Menu from "../../../../components/menus/Menu";
import MenuItem from "../../../../components/menus/MenuItem";
import Popover from "../../../../components/Popover";
import useDelayedToggle from "../../../../utils/useDelayedToggle";
import MessageEditor, {
  MessageEditorCtrl,
} from "../../../slateMessageEditor/MessageEditor";
import MessageStatic from "../../../slateMessageEditor/MessageStatic";
import { isEmptyValue } from "../../../slateMessageEditor/utils";
import {
  insertAnswer,
  removeAnswer,
  selectFlowId,
  updateAnswerFields,
} from "../../flowEditorSlice";
import NextKeyEditor from "../NextKeyEditor";
import { WithKey } from "../operations/types";
import TranslationEditor from "../TranslationEditor";
import styles from "./AnswerEditor.module.css";
import { useAnswersEditor } from "./AnswersEditor";
import InsertAnswerButton from "./InsertAnswerButton";
import QuickResponseEditor, {
  QuickResponseEditorCtrl,
} from "./QuickResponseEditor";
import ScoreEditor from "./ScoreEditor";

export interface AnswerEditorCtrl {
  key: string;
  index: number;
  canFocus: boolean;
  focus: () => void;
  quickResponse: () => QuickResponseEditorCtrl | undefined;
}

export interface AnswerEditorProps {
  promptName: string;
  promptIndex: number;
  definition: AnswerDefinition;
  index: number;
  isLast: boolean;
  addDisabled?: boolean | null;
  messageDisabled?: boolean | null;
  scoreDisabled?: boolean | null;
  scoreLaterDisabled?: boolean | null;
  nextKeyHidden?: boolean | null;
  quickResponseDisabled?: boolean | null;
  quickResponseAltMessagesMode?: boolean | null;
  control?: (control: AnswerEditorCtrl) => void;
  deregisterControl?: (controlKey: string) => void;
}

function AnswerEditor({
  promptIndex,
  definition,
  index,
  isLast,
  addDisabled,
  messageDisabled,
  scoreDisabled,
  scoreLaterDisabled,
  nextKeyHidden,
  quickResponseDisabled,
  quickResponseAltMessagesMode,
  control,
  deregisterControl,
}: AnswerEditorProps): ReactElement {
  const dispatch = useDispatch();

  const flowId = useSelector(selectFlowId);

  const [isHovering, setIsHovering] = useState(false);

  const [isMessageEmpty, setIsMessageEmpty] = useState(
    definition.message == null || definition.message === ""
  );

  function insertAnswerHandler(index: number): void {
    dispatch(
      insertAnswer({
        promptIndexOrName: promptIndex,
        definition: null,
        index,
      })
    );
    // Give time for answer to be inserted, then focus on it
    setTimeout(() => answersEditor.items[index]?.focus(), 0);
  }

  function removeAnswerHandler(): void {
    dispatch(
      removeAnswer({
        promptIndexOrName: promptIndex,
        index,
      })
    );
  }

  function updateAnswer(fields: Partial<AnswerDefinition>) {
    dispatch(
      updateAnswerFields({
        promptIndexOrName: promptIndex,
        answerIndex: index,
        fields,
      })
    );
  }

  function addQuickResponseHandler(): void {
    updateAnswer({
      quickResponse: {
        message: "",
      },
    });
    setTimeout(() => quickRespEditorControl.current?.focus(), 0);
  }

  function setMessage(message: string) {
    updateAnswer({ message });
  }

  function setNextKey(key: string | null) {
    updateAnswer({ nextKey: key });
  }

  function setScore(value: number | null) {
    updateAnswer({ score: value });
  }

  function setScoreLater(value: boolean | null) {
    updateAnswer({ scoreLater: value });
  }

  function updateTranslation(
    language: string,
    message: string,
    confirmOverwrite?: boolean
  ) {
    const currTranslation = getTranslation(definition.tMessage, language);
    if (
      !confirmOverwrite ||
      isEmpty(currTranslation) ||
      window.confirm("Overwrite translation?")
    ) {
      updateAnswer({
        tMessage: assignTranslation(definition.tMessage, language, message),
      });
    }
  }

  const editorControl = useRef<MessageEditorCtrl>();

  const registerEditorControl = useCallback(
    (c) => (editorControl.current = c),
    []
  );

  const quickRespEditorControl = useRef<QuickResponseEditorCtrl>();

  const registerQuickRespEditorControl = useCallback(
    (c) => (quickRespEditorControl.current = c),
    []
  );

  const __key = (definition as WithKey<AnswerDefinition>).__key;

  const hasQuickResponse = !!definition.quickResponse;
  useEffect(() => {
    control?.({
      key: __key,
      index,
      canFocus: !messageDisabled,
      focus: () => editorControl.current?.focus(),
      quickResponse: () => quickRespEditorControl.current,
    });
  }, [control, __key, index, messageDisabled, hasQuickResponse]);

  useEffect(() => {
    return function cleanup() {
      deregisterControl?.(__key);
    };
  }, [deregisterControl, __key]);

  const effectiveNextKeyHidden =
    nextKeyHidden || definition.quickResponse?.repeatAnswers;

  const answersEditor = useAnswersEditor();

  const {
    value: isActionMenuOpen,
    setTrueWithDelay: openActionMenuWithDelay,
    setTrueImmediate: openActionMenu,
    setFalseWithDelay: closeActionMenuWithDelay,
    setFalseImmediate: closeActionMenu_,
    toggleImmediate: toggleActionMenu,
  } = useDelayedToggle(false, {});
  const closeActionMenu = useCallback(() => {
    closeActionMenu_();
    setIsHovering(false);
  }, [closeActionMenu_]);

  const showMenuItemScore = !scoreDisabled && definition.score == null;
  const showMenuItemQuickResponse =
    !quickResponseDisabled && definition.quickResponse == null;
  const showMenuItemDelete = !addDisabled;
  const showActionMenu =
    showMenuItemScore || showMenuItemQuickResponse || showMenuItemDelete;

  return (
    <Draggable draggableId={__key} index={index}>
      {(provided) => {
        return (
          <div
            className={classNames(styles.root, {
              [styles.root__hovering]: isHovering,
            })}
            {...provided.draggableProps}
            ref={provided.innerRef}
            onPointerOver={() => setIsHovering(true)}
            onPointerLeave={() => setIsHovering(false)}
          >
            {!addDisabled && isHovering && index === 0 && (
              <InsertAnswerButton
                isAbove
                onClick={() => insertAnswerHandler(index)}
              />
            )}
            <div className={styles.answer}>
              <div className={styles.leftColumn}>
                <div
                  className={styles.dragHandle}
                  {...provided.dragHandleProps}
                >
                  <GrDrag size={14} />
                </div>
                <div className={styles.nextKeyWrapper}>
                  <div
                    className={classNames({
                      [styles.nextKey__hidden]: effectiveNextKeyHidden,
                    })}
                  >
                    <NextKeyEditor
                      promptIndex={promptIndex}
                      initialNextKey={definition.nextKey}
                      onChange={(key) => setNextKey(key ?? null)}
                    />
                  </div>
                  <div
                    className={classNames(styles.noNextKey, {
                      [styles.noNextKey__hidden]: !effectiveNextKeyHidden,
                    })}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.topBar}>
                  {!scoreDisabled && definition.score != null && (
                    <ScoreEditor
                      initialScore={definition.score}
                      initialScoreLater={definition.scoreLater}
                      scoreLaterDisabled={scoreLaterDisabled}
                      onChangeScore={(v) => setScore(v ?? null)}
                      onChangeScoreLater={(v) => setScoreLater(v ?? null)}
                    />
                  )}
                </div>
                <div className={styles.message}>
                  <div className={styles.message_left}>
                    {messageDisabled ? (
                      <MessageStatic value={`${definition.message}`} />
                    ) : (
                      <>
                        <MessageEditor
                          value={`${definition.message}`}
                          placeholder="Answer..."
                          uploadPathPrefix={`flows/${flowId}/`}
                          onChangeDebounced={(value) => {
                            if (value !== definition.message) {
                              setMessage(value);
                            }
                          }}
                          onChangeRaw={(value) => {
                            setIsMessageEmpty(isEmptyValue(value));
                          }}
                          onKeyDown={(event) => {
                            if (event.ctrlKey || event.metaKey) {
                              if (event.key === "Enter") {
                                insertAnswerHandler(index + 1);
                                return true;
                              } else if (event.key === "Delete") {
                                const prevIndex = Math.max(index - 1, 0);
                                removeAnswerHandler();
                                setTimeout(() => {
                                  answersEditor.items[prevIndex]?.focus();
                                }, 0);
                                return true;
                              } else if (event.key === "/") {
                                toggleActionMenu();
                                return true;
                              }
                            }
                            return false;
                          }}
                          control={registerEditorControl}
                        />
                        {!messageDisabled && definition.message !== "*" && (
                          <TranslationEditor
                            onClickPaste={(language) => {
                              updateTranslation(
                                language,
                                `${definition.message}`,
                                true
                              );
                              // Returning `true` forces a remount. Required
                              // because Slate (and hence MessageEditor) does
                              // not re-render automatically when the `value`
                              // prop passed into it is modified from outside.
                              return true;
                            }}
                          >
                            {(language) => (
                              <MessageEditor
                                value={`${
                                  getTranslation(
                                    definition.tMessage,
                                    language
                                  ) ?? ""
                                }`}
                                placeholder=""
                                onChangeDebounced={(message) =>
                                  updateTranslation(language, message)
                                }
                              />
                            )}
                          </TranslationEditor>
                        )}
                      </>
                    )}
                  </div>
                  <div className={styles.message_right}>
                    {showActionMenu && (
                      <Popover
                        positions={["bottom", "top"]}
                        align="end"
                        isOpen={isActionMenuOpen}
                        onClickOutside={closeActionMenu}
                        containerClassName={styles.actionMenu}
                        content={
                          <Menu
                            autoClose
                            closeOnEsc
                            onRequestClose={closeActionMenu}
                            onPointerEnter={openActionMenu}
                            onPointerLeave={closeActionMenu}
                          >
                            {showMenuItemScore && (
                              <MenuItem
                                onClick={() => {
                                  setScore(100);
                                }}
                              >
                                <FaTrophy />
                                &nbsp;Add Score
                              </MenuItem>
                            )}
                            {showMenuItemQuickResponse && (
                              <MenuItem onClick={addQuickResponseHandler}>
                                <FaReply />
                                &nbsp;Add Quick Resp.
                              </MenuItem>
                            )}
                            {showMenuItemDelete && (
                              <MenuItem
                                autoClose={false}
                                onClick={removeAnswerHandler}
                              >
                                <FaRegTrashAlt />
                                &nbsp;Delete
                              </MenuItem>
                            )}
                          </Menu>
                        }
                      >
                        <FlatButton
                          className={styles.actionMenuButton}
                          onPointerEnter={openActionMenuWithDelay}
                          onPointerLeave={closeActionMenuWithDelay}
                        >
                          <MdMoreVert size={18} />
                        </FlatButton>
                      </Popover>
                    )}
                  </div>
                </div>
                <div className={styles.bottomBar}>
                  {isLast && isMessageEmpty && (
                    <FlatButton
                      className={styles.makeCatchAll}
                      onClick={() => {
                        editorControl.current?.setMessage("*");
                      }}
                    >
                      <DiAsterisk size={16} />
                      <div className={styles.makeCatchAll_label}>
                        Accept any answer
                      </div>
                    </FlatButton>
                  )}
                </div>
                {!quickResponseDisabled && definition.quickResponse != null && (
                  <QuickResponseEditor
                    promptIndex={promptIndex}
                    answerIndex={index}
                    quickResponse={definition.quickResponse}
                    altMessagesMode={quickResponseAltMessagesMode}
                    control={registerQuickRespEditorControl}
                    onActionMenuClosed={() => setIsHovering(false)}
                  />
                )}
              </div>
            </div>
            {!addDisabled && isHovering && (
              <InsertAnswerButton
                onClick={() => insertAnswerHandler(index + 1)}
              />
            )}
          </div>
        );
      }}
    </Draggable>
  );
}

export default memo(AnswerEditor);
