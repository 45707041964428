import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  AnswerDefinition,
  JsonObject,
  OrdChatFlowDefinition,
  PromptDefinition,
  PromptMessageItem,
} from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import { DateTime } from "luxon";

import { AsyncStatus, RootState } from "../../app/store";
import { ErrorData, getSerializableError } from "../../utils/error";
import Worker from "../../worker";
import { MaxScoreResult } from "../../worker/worker";
import * as ops from "./edit/operations";
import { MCFlowEditorError, MCNullFlowError } from "./errors";

// TODO Should we convert all actions to accept only prompt names?
// Can relying on index lead to potentially incorrect operations?

export type EditorConvertToMessage = {
  promptIndexOrName: number | string;
};
export type EditorConvertToMessages = {
  promptIndexOrName: number | string;
};
export type EditorInsertAnswer = {
  promptIndexOrName: number | string;
  definition: AnswerDefinition | null;
  index: number | null;
};
export type EditorInsertMessageItem = {
  promptIndexOrName: number | string;
  messageItem: PromptMessageItem;
  index: number | null;
};
export type EditorInsertPrompt = {
  definition: PromptDefinition | null;
  index: number | null;
};
export type EditorMoveAnswer = {
  promptIndexOrName: number | string;
  fromIndex: number;
  toIndex: number;
};
export type EditorMoveMessageItem = {
  promptIndexOrName: number | string;
  fromIndex: number;
  toIndex: number;
};
export type EditorMovePrompt = {
  fromIndex: number;
  toIndex: number;
};
export type EditorRemoveAnswer = {
  promptIndexOrName: number | string;
  index: number;
};
export type EditorRemoveMessageItem = {
  promptIndexOrName: number | string;
  index: number;
};
export type EditorRemovePrompt = {
  indexOrName: number | string;
};
export type EditorPreviewPrompt = {
  indexOrName: number | string;
};
export type EditorUpdateAnswerFields = {
  promptIndexOrName: number | string;
  answerIndex: number;
  fields: Partial<AnswerDefinition>;
};
export type EditorUpdateMessageItemFields = {
  promptIndexOrName: number | string;
  messageIndex: number;
  fields: Partial<
    Pick<
      PromptMessageItem,
      "message" | "tMessage" | "repeatAnswers" | "minNextPromptDelayMs"
    >
  >;
};
export type EditorUpdatePromptCustom = {
  promptIndexOrName: number | string;
  data: Partial<JsonObject>;
};
export type EditorUpdatePromptFields = {
  promptIndexOrName: number | string;
  fields: Partial<Omit<PromptDefinition, "answers" | "messages">>;
};
export type EditorInitPromptQuickResponses = {
  promptIndexOrName: number | string;
};

export interface EditorState {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  flow: api.Flow | null;
  updating: AsyncStatus;
  lastUpdateAction: (keyof Omit<api.FlowUpdateFields, "definition">)[] | null;
  updateError: ErrorData | null;
  definitionLastEditedAt: number | null;
  definitionUpdating: AsyncStatus;
  definitionUpdateError: ErrorData | null;
  definitionLastUpdatedAt: number | null;
  publishing: AsyncStatus;
  publishError: ErrorData | null;
  computingMaxScore: AsyncStatus;
  computeMaxScoreError: ErrorData | null;
  shouldComputeMaxScore: boolean;
  maxScore: number | null;
  maxScoreCalcTooCostly: boolean;
  previewPromptIndex: number;
  currentLanguage: string | null;
}

// Call in each reducer that edits the flow
function markAsEdited(state: EditorState) {
  state.definitionLastEditedAt = DateTime.local().toMillis();
}

function resolvePromptIndex(
  flowDef: OrdChatFlowDefinition,
  promptIndexOrName: number | string
): number {
  if (typeof promptIndexOrName === "number") {
    return promptIndexOrName;
  }
  const index = flowDef.promptsArray.findIndex(
    ({ name }) => name === promptIndexOrName
  );
  if (index === -1) {
    throw new MCFlowEditorError(
      `Prompt with name '${promptIndexOrName}' not found`
    );
  }
  return index;
}

export const loadFlow = createAsyncThunk(
  "flowEditor/loadFlowStatus",
  async (
    {
      id,
      version,
    }: {
      id: number;
      version?: number;
    },
    thunkApi
  ) => {
    try {
      const flow = await (version == null
        ? api.getFlow(id)
        : api.getPublishedFlow(id, version, null, true));
      // In case any answer definitions don't have keys, provide them.
      // This app only works with flows that possess such answer keys.
      ops.hydrateAnswerKeys(flow.definition);
      // Same as above with message item keys
      ops.hydrateMessageItemKeys(flow.definition);
      // Same as above with alt message item keys
      ops.hydrateAltMessageItemKeys(flow.definition);
      return flow;
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

/**
 * Used for updating everything except the flow definition to server.
 * For updating definition, see `updateFlowDefinition`.
 */
export const updateFlow = createAsyncThunk(
  "flowEditor/updateFlowStatus",
  async (fields: Omit<api.FlowUpdateFields, "definition">, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (!selectIsFlowReadonly(rootState)) {
      const editor = rootState.flowEditor as EditorState;
      if (editor.flow != null) {
        const id = editor.flow.id;
        try {
          await api.updateFlow(id, fields);
          return { id, fields };
        } catch (error) {
          return thunkApi.rejectWithValue({
            error: getSerializableError(error),
            id,
          });
        }
      }
    }
  }
);

/**
 * Used for updating the current locally stored definition to server.
 * For updating all other flow fields, see `updateFlow`.
 */
export const updateFlowDefinition = createAsyncThunk(
  "flowEditor/updateFlowDefinitionStatus",
  async (payload: void, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (!selectIsFlowReadonly(rootState)) {
      const lastUpdatedAt = DateTime.local().toMillis();
      const editor = rootState.flowEditor as EditorState;
      if (editor.flow != null) {
        const { id, definition } = editor.flow;
        try {
          await api.updateFlow(id, { definition });
          return { id, lastUpdatedAt };
        } catch (error) {
          return thunkApi.rejectWithValue({
            error: getSerializableError(error),
            id,
          });
        }
      }
    }
  }
);

export const publishFlow = createAsyncThunk(
  "flowEditor/publishFlowStatus",
  async (payload: void, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (!selectIsFlowReadonly(rootState)) {
      const { flow } = rootState.flowEditor as EditorState;
      if (flow != null) {
        const id = flow.id;
        try {
          await api.publishFlow(id);
          return id;
        } catch (error) {
          return thunkApi.rejectWithValue({
            error: getSerializableError(error),
            id,
          });
        }
      }
    }
  }
);

const worker = new Worker();
let maxScoreCalcIteration = 0;
export const computeFlowMaxScore = createAsyncThunk(
  "flowEditor/computeFlowMaxScoreStatus",
  async (pathLimit: number | undefined, thunkApi) => {
    const iteration = ++maxScoreCalcIteration;
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flow } = rootState.flowEditor;
      if (flow?.definition != null) {
        const result: MaxScoreResult = await worker.computeChatFlowMaxScore(
          flow.definition,
          "start",
          pathLimit
        );
        return { ...result, iteration };
      }
    } catch (error) {
      return thunkApi.rejectWithValue({
        error: error.message ?? "Error",
        iteration,
      });
    }
  }
);

function ensureFlowExists(flow: EditorState["flow"]): asserts flow is api.Flow {
  if (flow == null) throw new MCNullFlowError();
}

export const slice = createSlice({
  name: "flowEditor",
  initialState: {
    loading: "idle",
    loadError: null,
    flow: null,
    updating: "idle",
    lastUpdateAction: null,
    updateError: null,
    definitionLastEditedAt: null,
    definitionUpdating: "fulfilled",
    definitionUpdateError: null,
    definitionLastUpdatedAt: null,
    publishing: "idle",
    publishError: null,
    computingMaxScore: "idle",
    computeMaxScoreError: null,
    shouldComputeMaxScore: false,
    maxScore: null,
    maxScoreCalcTooCostly: false,
    previewPromptIndex: 0,
    currentLanguage: null,
  } as EditorState,
  reducers: {
    clearFlow: (state: EditorState) => {
      state.flow = null;
      state.definitionLastEditedAt = null;
      state.definitionLastUpdatedAt = null;
      state.previewPromptIndex = 0;
    },
    convertToMessage: (
      state: EditorState,
      action: PayloadAction<EditorConvertToMessage>
    ) => {
      ensureFlowExists(state.flow);
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        action.payload.promptIndexOrName
      );
      const lostItemsCount = ops.convertToMessage(
        promptIndex,
        state.flow.definition
      );
      markAsEdited(state);
      if (lostItemsCount > 0) {
        state.shouldComputeMaxScore = true;
      }
    },
    convertToMessages: (
      state: EditorState,
      action: PayloadAction<EditorConvertToMessages>
    ) => {
      ensureFlowExists(state.flow);
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        action.payload.promptIndexOrName
      );
      ops.convertToMessages(promptIndex, state.flow.definition);
      markAsEdited(state);
    },
    initPromptQuickResponses: (
      state: EditorState,
      action: PayloadAction<EditorInitPromptQuickResponses>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.initPromptQuickResponses(promptIndex, state.flow.definition);
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    insertAnswer: (
      state: EditorState,
      action: PayloadAction<EditorInsertAnswer>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, definition, index } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      const inserted = ops.insertAnswer(
        promptIndex,
        definition,
        index,
        state.flow.definition
      );
      if (inserted.score != null) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    insertMessageItem: (
      state: EditorState,
      action: PayloadAction<EditorInsertMessageItem>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, messageItem, index } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.insertMessageItem(
        promptIndex,
        messageItem,
        index,
        state.flow.definition
      );
      state.shouldComputeMaxScore = true;
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    insertPrompt: (
      state: EditorState,
      action: PayloadAction<EditorInsertPrompt>
    ) => {
      ensureFlowExists(state.flow);
      const { definition, index } = action.payload;
      const inserted = ops.insertPrompt(
        definition,
        index,
        state.flow.definition
      );
      if (ops.promptHasAnswersWithScore(inserted.definition)) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex =
        index ?? state.flow.definition.promptsArray.length - 1;
    },
    moveAnswer: (
      state: EditorState,
      action: PayloadAction<EditorMoveAnswer>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, fromIndex, toIndex } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.moveAnswer(promptIndex, fromIndex, toIndex, state.flow.definition);
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    moveMessageItem: (
      state: EditorState,
      action: PayloadAction<EditorMoveMessageItem>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, fromIndex, toIndex } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.moveMessageItem(
        promptIndex,
        fromIndex,
        toIndex,
        state.flow.definition
      );
      // Since we are only reordering, it may seem like maxScore will always
      // remain the same, but this is not the case. If a message item that does
      // not repeat answers is moved to the last position in the messages array
      // then answers will still be shown for it (because for the last message,
      // answers are shown no matter what). So this may increment the number of
      // times the answers are shown, leading to a possible change in maxScore!
      state.shouldComputeMaxScore = true;

      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    movePrompt: (
      state: EditorState,
      action: PayloadAction<EditorMovePrompt>
    ) => {
      ensureFlowExists(state.flow);
      const { fromIndex, toIndex } = action.payload;
      ops.movePrompt(fromIndex, toIndex, state.flow.definition);
      markAsEdited(state);
      state.previewPromptIndex = toIndex;
    },
    previewPrompt: (
      state: EditorState,
      action: PayloadAction<EditorPreviewPrompt>
    ) => {
      ensureFlowExists(state.flow);
      state.previewPromptIndex = resolvePromptIndex(
        state.flow.definition,
        action.payload.indexOrName
      );
    },
    removeAnswer: (
      state: EditorState,
      action: PayloadAction<EditorRemoveAnswer>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, index } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      const removed = ops.removeAnswer(
        promptIndex,
        index,
        state.flow.definition
      );
      if (removed.score != null || removed.nextKey != null) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    removeMessageItem: (
      state: EditorState,
      action: PayloadAction<EditorRemoveMessageItem>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, index } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.removeMessageItem(promptIndex, index, state.flow.definition);
      state.shouldComputeMaxScore = true;
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    removePrompt: (
      state: EditorState,
      action: PayloadAction<EditorRemovePrompt>
    ) => {
      ensureFlowExists(state.flow);
      const index = resolvePromptIndex(
        state.flow.definition,
        action.payload.indexOrName
      );
      const removed = ops.removePrompt(index, state.flow.definition, false);
      if (ops.promptHasAnswersWithScore(removed.definition)) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex = Math.min(
        index,
        state.flow.definition.promptsArray.length - 1
      );
    },
    setCurrentLanguage: (
      state: EditorState,
      action: PayloadAction<string | null>
    ) => {
      state.currentLanguage = action.payload;
    },
    updateAnswerFields: (
      state: EditorState,
      action: PayloadAction<EditorUpdateAnswerFields>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, answerIndex, fields } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.updateAnswerFields(
        promptIndex,
        answerIndex,
        fields,
        state.flow.definition
      );
      if (
        "score" in fields ||
        "nextKey" in fields ||
        ("quickResponse" in fields &&
          (fields.quickResponse == null ||
            "repeatAnswers" in fields.quickResponse))
      ) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    updateMessageItemFields: (
      state: EditorState,
      action: PayloadAction<EditorUpdateMessageItemFields>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, messageIndex, fields } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.updateMessageItemFields(
        promptIndex,
        messageIndex,
        fields,
        state.flow.definition
      );
      if ("repeatAnswers" in fields) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    updatePromptCustom: (
      state: EditorState,
      action: PayloadAction<EditorUpdatePromptCustom>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, data } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.updatePromptCustom(promptIndex, data, state.flow.definition);
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
    updatePromptFields: (
      state: EditorState,
      action: PayloadAction<EditorUpdatePromptFields>
    ) => {
      ensureFlowExists(state.flow);
      const { promptIndexOrName, fields } = action.payload;
      const promptIndex = resolvePromptIndex(
        state.flow.definition,
        promptIndexOrName
      );
      ops.updatePromptFields(promptIndex, fields, state.flow.definition);
      if (
        "acceptsMultipleAnswers" in fields ||
        "maxSuperScore" in fields ||
        "scoreStrategy" in fields ||
        "nextKey" in fields
      ) {
        state.shouldComputeMaxScore = true;
      }
      markAsEdited(state);
      state.previewPromptIndex = promptIndex;
    },
  },
  extraReducers: {
    // *** loadFlow ***
    [`${loadFlow.pending}`]: (state: EditorState) => {
      state.loading = "pending";
      state.loadError = null;
      state.flow = null;
      state.definitionLastEditedAt = null;
      state.definitionLastUpdatedAt = null;
      state.previewPromptIndex = 0;
    },
    [`${loadFlow.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<api.Flow>
    ) => {
      state.loading = "fulfilled";
      state.flow = action.payload;
      state.shouldComputeMaxScore = true;
      state.computeMaxScoreError = null;
      state.maxScore = null;
      state.maxScoreCalcTooCostly = false;
      state.currentLanguage = null;
    },
    [`${loadFlow.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.loading = "rejected";
      state.loadError = action.payload;
    },
    // *** updateFlow ***
    [`${updateFlow.pending}`]: (state: EditorState, action) => {
      state.updating = "pending";
      state.lastUpdateAction = Object.keys(
        action.meta.arg
      ) as EditorState["lastUpdateAction"];
      state.updateError = null;
    },
    [`${updateFlow.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<{
        id: number;
        fields: Omit<api.FlowUpdateFields, "definition">;
      }>
    ) => {
      const { id, fields } = action.payload;
      if (state.flow?.id === id) {
        state.updating = "fulfilled";
        state.flow = { ...state.flow, ...fields };
      }
    },
    [`${updateFlow.rejected}`]: (
      state: EditorState,
      action: PayloadAction<{ error: ErrorData; id: number }>
    ) => {
      if (state.flow?.id === action.payload.id) {
        state.updating = "rejected";
        state.updateError = action.payload.error;
      }
    },
    // *** updateFlowDefinition ***
    [`${updateFlowDefinition.pending}`]: (state: EditorState) => {
      state.definitionUpdating = "pending";
      state.definitionUpdateError = null;
    },
    [`${updateFlowDefinition.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<{ id: number; lastUpdatedAt: number }>
    ) => {
      const { id, lastUpdatedAt } = action.payload;
      if (state.flow?.id === id) {
        state.definitionUpdating = "fulfilled";
        state.definitionLastUpdatedAt = lastUpdatedAt;
        state.flow.isDirty = true;
      }
    },
    [`${updateFlowDefinition.rejected}`]: (
      state: EditorState,
      action: PayloadAction<{ error: ErrorData; id: number }>
    ) => {
      if (state.flow?.id === action.payload.id) {
        state.definitionUpdating = "rejected";
        state.definitionUpdateError = action.payload.error;
      }
    },
    // *** publishFlow ***
    [`${publishFlow.pending}`]: (state: EditorState) => {
      state.publishing = "pending";
      state.publishError = null;
    },
    [`${publishFlow.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<number>
    ) => {
      if (state.flow?.id === action.payload) {
        state.publishing = "fulfilled";
        state.flow.isDirty = false;
      }
    },
    [`${publishFlow.rejected}`]: (
      state: EditorState,
      action: PayloadAction<{ error: ErrorData; id: number }>
    ) => {
      if (state.flow?.id === action.payload.id) {
        state.publishing = "rejected";
        state.publishError = action.payload.error;
      }
    },
    // *** computeFlowMaxScore ***
    [`${computeFlowMaxScore.pending}`]: (state: EditorState) => {
      state.shouldComputeMaxScore = false;
      state.computingMaxScore = "pending";
      state.computeMaxScoreError = null;
      state.maxScore = null;
      state.maxScoreCalcTooCostly = false;
    },
    [`${computeFlowMaxScore.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<MaxScoreResult & { iteration: number }>
    ) => {
      if (action.payload != null) {
        const { score, pathLimitReached, iteration } = action.payload;
        if (iteration === maxScoreCalcIteration) {
          state.computingMaxScore = "fulfilled";
          state.maxScore = score;
          state.maxScoreCalcTooCostly = pathLimitReached;
        }
      }
    },
    [`${computeFlowMaxScore.rejected}`]: (
      state: EditorState,
      action: PayloadAction<{ error: ErrorData; iteration: number }>
    ) => {
      const { error, iteration } = action.payload;
      if (iteration === maxScoreCalcIteration) {
        state.computingMaxScore = "rejected";
        state.computeMaxScoreError = error;
      }
    },
  },
});

export const {
  convertToMessage,
  convertToMessages,
  clearFlow,
  initPromptQuickResponses,
  insertAnswer,
  insertMessageItem,
  insertPrompt,
  moveAnswer,
  moveMessageItem,
  movePrompt,
  removeAnswer,
  removeMessageItem,
  removePrompt,
  previewPrompt,
  setCurrentLanguage,
  updateAnswerFields,
  updateMessageItemFields,
  updatePromptCustom,
  updatePromptFields,
} = slice.actions;

export const selectEditor = (state: RootState): EditorState => state.flowEditor;

export const selectLoading = (
  state: RootState
): [AsyncStatus, ErrorData | null] => [
  state.flowEditor.loading,
  state.flowEditor.loadError,
];

export const selectHasUnsavedChanges = createSelector(
  selectEditor,
  (editor) => {
    const { definitionLastEditedAt, definitionLastUpdatedAt } = editor;
    return (
      definitionLastEditedAt != null &&
      (definitionLastUpdatedAt == null ||
        definitionLastEditedAt > definitionLastUpdatedAt)
    );
  }
);

export const selectIsFlowLoaded = (state: RootState): boolean =>
  state.flowEditor.flow != null;

export const selectUpdating = (
  state: RootState
): [AsyncStatus, EditorState["lastUpdateAction"], ErrorData | null] => [
  state.flowEditor.updating,
  state.flowEditor.lastUpdateAction,
  state.flowEditor.updateError,
];

export const selectFlowId = (state: RootState): number | null =>
  state.flowEditor.flow?.id ?? null;

export const selectFlowUid = (state: RootState): string | null =>
  state.flowEditor.flow?.uid ?? null;

export const selectFlowName = (state: RootState): string | null =>
  state.flowEditor.flow?.name ?? null;

export const selectFlowTName = (
  state: RootState
): Record<string, string> | null => state.flowEditor.flow?.tName ?? null;

export const selectFlowLanguage = (state: RootState): string | null =>
  state.flowEditor.flow?.language ?? null;

export const selectFlowLanguages = (state: RootState): string[] | null =>
  state.flowEditor.flow?.languages ?? null;

export const selectFlowDefinition = (
  state: RootState
): OrdChatFlowDefinition | null => state.flowEditor.flow?.definition ?? null;

export const selectFlowDirty = (state: RootState): boolean | null =>
  state.flowEditor.flow?.isDirty ?? null;

export const selectFlowVersion = (state: RootState): number | null =>
  state.flowEditor.flow?.version ?? null;

export const selectIsFlowReadonly = (state: RootState): boolean =>
  state.flowEditor.flow?.version != null;

export const selectFlowFlowSequence = (
  state: RootState
): api.FlowSequenceStub | null => state.flowEditor.flow?.flowSequence ?? null;

export const selectFlowSettings = (
  state: RootState
): api.SettingsPatch | null => state.flowEditor.flow?.settings ?? null;

export const selectFlowUserSettings = (
  state: RootState
): api.SettingsPatch | null => state.flowEditor.flow?.userSettings ?? null;

export const selectFlowOwnership = (state: RootState): api.Ownership | null =>
  state.flowEditor.flow?.ownership ?? null;

export const selectFlowOwner = (state: RootState): api.OwnerDetails | null =>
  state.flowEditor.flow?.user ?? null;

export const selectMaxScoreState = (
  state: RootState
): {
  computing: AsyncStatus;
  shouldCompute: boolean;
  maxScore: number | null;
  calcTooCostly: boolean;
} => ({
  computing: state.flowEditor.computingMaxScore,
  shouldCompute: state.flowEditor.shouldComputeMaxScore,
  maxScore: state.flowEditor.maxScore,
  calcTooCostly: state.flowEditor.maxScoreCalcTooCostly,
});

export const selectPreviewPromptIndex = (state: RootState): number =>
  state.flowEditor.previewPromptIndex;

export const selectCurrentLanguage = (state: RootState): string | null =>
  state.flowEditor.currentLanguage;

export default slice.reducer;
