import React, { ReactElement, useEffect, useState } from "react";
import { BsChatQuoteFill } from "react-icons/bs";
import { FaHandshake, FaUser } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FilledButton from "../../components/buttons/FilledButton";
import MenuItemLink from "../../components/menus/MenuItemLink";
import MenuSection from "../../components/menus/MenuSection";
import MyTippy from "../../components/MyTippy";
import Tabs from "../../components/Tabs";
import TopBar from "../../components/TopBar";
import { selectAuth } from "../../features/auth/authSlice";
import ProfileButton from "../../features/auth/ProfileButton";
import FlowItem from "../../features/flowList/FlowItem";
import FlowListView from "../../features/flowList/FlowListView";
import FlowSequenceItem from "../../features/flowSequenceList/FlowSequenceItem";
import FlowSequenceListView from "../../features/flowSequenceList/FlowSequenceListView";
import CreateFlowButton from "./CreateFlowButton";
import CreateFlowSequenceButton from "./CreateFlowSequenceButton";
import styles from "./HomePage.module.css";

const FLOWS = "Flows";
const FLOW_SEQUENCES = "Sequences";
const TABS = [FLOWS, FLOW_SEQUENCES];

export default function HomePage(): ReactElement {
  const history = useHistory();
  const { currentUser } = useSelector(selectAuth);

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("homeTab") ?? TABS[0]
  );

  useEffect(() => {
    localStorage.setItem("homeTab", activeTab);
  }, [activeTab]);

  const topBarActions: ReactElement[] = [];
  if (activeTab === FLOWS) {
    topBarActions.push(<CreateFlowButton key="flow" />);
  } else if (activeTab === FLOW_SEQUENCES) {
    topBarActions.push(<CreateFlowSequenceButton key="seq" />);
  }
  topBarActions.push(
    <ProfileButton
      key="login"
      renderMenuItems={() => (
        <MenuSection>
          <MenuItemLink to="/user_activity" autoClose={false}>
            <FaUser />
            &nbsp;User&nbsp;Activity
          </MenuItemLink>
          <MenuItemLink to={`/integrations`} autoClose={false}>
            <FaHandshake />
            &nbsp;Integrations
          </MenuItemLink>
        </MenuSection>
      )}
    />
  );

  return (
    <div className={styles.root}>
      <TopBar
        leading={<BsChatQuoteFill className={styles.logo} />}
        title="DialogForm"
        center={
          <Tabs
            isInTopBar
            className={styles.tabs}
            tabs={TABS}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        }
        actions={topBarActions}
      />
      <div className={styles.body}>
        {activeTab === FLOWS ? (
          <FlowListView
            className={styles.list}
            renderItem={(flow) => (
              <FlowItem
                key={flow.id}
                flow={flow}
                sharedBy={
                  currentUser?.email === flow.user.email ? null : flow.user
                }
                renderAction={() => (
                  <MyTippy content="View stats">
                    <FilledButton
                      className={styles.itemAction}
                      onClick={() => history.push(`/flows/${flow.id}/stats`)}
                    >
                      <IoAnalyticsOutline size={14} />
                    </FilledButton>
                  </MyTippy>
                )}
              />
            )}
          />
        ) : activeTab === FLOW_SEQUENCES ? (
          <FlowSequenceListView
            className={styles.list}
            renderItem={(seq) => (
              <FlowSequenceItem
                key={seq.id}
                flowSequence={seq}
                sharedBy={
                  currentUser?.email === seq.user.email ? null : seq.user
                }
                renderAction={() => (
                  <MyTippy content="View stats">
                    <FilledButton
                      className={styles.itemAction}
                      onClick={() => history.push(`/sequences/${seq.id}/stats`)}
                    >
                      <IoAnalyticsOutline size={14} />
                    </FilledButton>
                  </MyTippy>
                )}
              />
            )}
          />
        ) : null}
      </div>
    </div>
  );
}
