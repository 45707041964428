import React, { ReactElement } from "react";
import { RenderElementProps, useFocused, useSelected } from "slate-react";

import { ImageProps } from ".";
import styles from "./ImageEditor.module.css";
import ImageSizeInput from "./ImageSizeInput";
import Thumbnail from "./Thumbnail";

export default function ImageEditor({
  attributes,
  children,
  element,
}: RenderElementProps): ReactElement {
  const focused = useFocused();
  const selected = useSelected();

  const imageProps = element.imageProps as ImageProps;

  return (
    <div {...attributes}>
      <div contentEditable={false} className={styles.root}>
        <Thumbnail
          src={imageProps.src}
          alt={imageProps.alt}
          title={imageProps.title}
          isHighlighted={focused && selected}
        />
        <div>
          <ImageSizeInput dimension="width" element={element} />
          <ImageSizeInput dimension="height" element={element} />
        </div>
      </div>
      {children}
    </div>
  );
}
