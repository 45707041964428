import classNames from "classnames";
import React, { ReactElement } from "react";

import styles from "./Button.module.css";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFullWidth?: boolean | null;
  isActive?: boolean | null;
  isLoading?: boolean | null;
  // If true, the button gives no visual indication of hover, focus or active.
  // Note that this does not disable the button. It merely gives the appearance
  // of not being clickable.
  isStatic?: boolean | null;
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    isFullWidth,
    isActive,
    isLoading,
    isStatic,
    ...restProps
  }: ButtonProps,
  ref
): ReactElement {
  return (
    <button
      ref={ref}
      className={classNames(styles.root, className, {
        [styles.isFullWidth]: isFullWidth,
        [styles.isActive]: isActive,
        [styles.isLoading]: isLoading,
        [styles.isStatic]: isStatic,
      })}
      {...restProps}
    />
  );
});
