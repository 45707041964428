import * as api from "@xflr6/chatbot-api";
import { nullifyIfBlank } from "@xflr6/utils";
import React, { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";

import WithDirtyDot from "../../components/WithDirtyDot";
import styles from "./FlowItem.module.css";

export interface FlowItemProps {
  flow: api.FlowStub;
  sharedBy?: api.OwnerDetails | null;
  renderAction?: (() => ReactNode) | null;
}

export default function FlowItem(props: FlowItemProps): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <div className={styles.info_title}>
          <WithDirtyDot isDirty={props.flow.isDirty}>
            <Link to={`/flows/${props.flow.id}/edit`}>{props.flow.name}</Link>
          </WithDirtyDot>
        </div>
        {props.sharedBy && (
          <div className={styles.info_owner}>
            <span>Shared by</span>&nbsp;
            <span>
              {nullifyIfBlank(props.sharedBy.name) ?? props.sharedBy.email}
            </span>
          </div>
        )}
      </div>
      <div className={styles.action}>{props.renderAction?.()}</div>
    </div>
  );
}
