import * as api from "@xflr6/chatbot-api";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useAppDispatch } from "../../../app/store";
import FilledButton from "../../../components/buttons/FilledButton";
import FieldError from "../../../components/forms/FieldError";
import formStyles from "../../../components/forms/formStyles.module.css";
import Input from "../../../components/forms/Input";
import { DOCS_USER_GUIDE_URL } from "../../../utils/constants";
import { IntegrationConfig } from "../IntegrationConfigs";
import {
  addIntegration,
  selectIntegrations,
  updateIntegration,
} from "../integrationsSlice";
import styles from "./CloudinaryConfig.module.css";

const DocumentationLink = () => (
  <a
    href={`${DOCS_USER_GUIDE_URL}/managing-media-with-cloudinary`}
    target="_blank"
    rel="noopener noreferrer"
  >
    See documentation
  </a>
);

function AddForm(): ReactElement {
  const dispatch = useAppDispatch();
  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const { register, handleSubmit, errors } = useForm<{
    cloudName: string;
    apiKey: string;
  }>();

  return (
    <>
      <p>
        Integrate with your{" "}
        <a
          href="https://cloudinary.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cloudinary
        </a>{" "}
        account to manage your DialogForm media assets.
      </p>
      <p>
        <DocumentationLink />
      </p>
      <form
        onSubmit={handleSubmit(({ cloudName, apiKey }) => {
          dispatch(
            addIntegration({
              type: "cloudinary",
              config: {
                cloudName,
                apiKey,
              },
            })
          );
        })}
      >
        <div className={formStyles.field}>
          <label className={formStyles.label}>Cloud Name</label>
          <Input
            name="cloudName"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register({ required: "Required" })}
          />
          {errors?.cloudName && (
            <FieldError error={errors.cloudName.message ?? "Invalid value"} />
          )}
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>API Key</label>
          <Input
            name="apiKey"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register({ required: "Required" })}
          />
          {errors?.apiKey && (
            <FieldError error={errors.apiKey.message ?? "Invalid value"} />
          )}
        </div>
        <div className={formStyles.actions}>
          <FilledButton
            disabled={updating === "pending"}
            isLoading={
              updating === "pending" &&
              equals(lastUpdateAction, ["cloudinary", "add"])
            }
          >
            Add
          </FilledButton>
        </div>
      </form>
    </>
  );
}

function EditForm({
  integration,
}: {
  integration: api.Integration;
}): ReactElement {
  const dispatch = useDispatch();
  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const { register, handleSubmit, errors } = useForm<{
    apiKey: string;
  }>({
    defaultValues: {
      apiKey: integration.config.apiKey as string,
    },
  });

  return (
    <>
      <p>
        You are integrated with{" "}
        <a
          href="https://cloudinary.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cloudinary
        </a>
        , and can use it to manage your DialogForm media assets.
      </p>
      <p>
        <DocumentationLink />
      </p>
      <form
        onSubmit={handleSubmit(async ({ apiKey }) => {
          await dispatch(
            updateIntegration({
              type: "cloudinary",
              config: {
                apiKey,
              },
            })
          );
          window.location.reload();
        })}
      >
        <div className={formStyles.field}>
          <label className={formStyles.label}>Cloud Name</label>
          <Input
            name="cloudName"
            styleVariant="outlined"
            className={formStyles.input}
            value={integration.config.cloudName as string}
            disabled
          />
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>API Key</label>
          <Input
            name="apiKey"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register({ required: "Required" })}
          />
          {errors?.apiKey && (
            <FieldError error={errors.apiKey.message ?? "Invalid value"} />
          )}
        </div>
        <div className={formStyles.actions}>
          <FilledButton
            disabled={updating === "pending"}
            isLoading={
              updating === "pending" &&
              equals(lastUpdateAction, ["cloudinary", "update"])
            }
          >
            Update
          </FilledButton>
        </div>
        <div className={styles.refreshHint}>
          After updating or removing the integration, the page will refresh
          automatically in order to pick up the change.
        </div>
      </form>
    </>
  );
}

export default function CloudinaryConfig(): ReactElement {
  return (
    <IntegrationConfig
      type="cloudinary"
      renderAddForm={() => <AddForm />}
      renderEditForm={(integration) => <EditForm integration={integration} />}
      confirmRemoveMessage={
        "You will no longer be able to use Cloudinary to manage videos." +
        "\nVideos already added to your DialogForm content will continue to work." +
        " Do you want to go ahead?"
      }
      onIntegrationRemoved={() => {
        window.location.reload();
      }}
    />
  );
}
