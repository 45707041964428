import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { FaUser, FaUserSecret } from "react-icons/fa";

import styles from "./IdOrigin.module.css";

export function IdOrigin({
  origin,
}: {
  origin: api.IdentifierOrigin;
}): ReactElement {
  return (
    <span className={classNames(styles.root, styles[origin])}>
      {origin === "user" && <FaUser size={12} />}
      {origin === "system" && <FaUserSecret size={12} />}
    </span>
  );
}
