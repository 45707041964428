import classNames from "classnames";
import React, { ReactElement } from "react";

import Button, { ButtonProps } from "./Button";
import styles from "./FilledButton.module.css";

export type FilledButtonProps = ButtonProps;

export default React.forwardRef<HTMLButtonElement, FilledButtonProps>(
  function FilledButton(
    { className, ...restProps }: FilledButtonProps,
    ref
  ): ReactElement {
    return (
      <Button
        ref={ref}
        className={classNames(styles.root, className)}
        {...restProps}
      />
    );
  }
);
