import classNames from "classnames";
import React, { ReactElement, ReactNode, useMemo } from "react";
import ReactModal from "react-modal";

import styles from "./Modal.module.css";

export interface ModalProps extends ReactModal.Props {
  title?: string | null;
  titleAction?: ReactNode | null;
  disableClose?: boolean | null;
  noBodySpacing?: boolean | null;
  children: ReactNode;
}

/**
 * A drop-in `ReactModal` replacement with improvements.
 * // TODO DRY up by keeping only one `Modal` component
 * @constructor
 */
export default function Modal({
  title,
  titleAction,
  disableClose,
  noBodySpacing,
  onRequestClose,
  className,
  overlayClassName,
  children,
  ...restProps
}: ModalProps): ReactElement {
  const onRequestClose_ = useMemo(
    () => (disableClose ? undefined : onRequestClose),
    [disableClose, onRequestClose]
  );

  return (
    <ReactModal
      closeTimeoutMS={200}
      className={{
        ...{
          base: styles.content,
          afterOpen: styles.contentAfterOpen,
          beforeClose: styles.contentBeforeClose,
        },
        ...(typeof className === "string"
          ? { base: classNames(styles.content, className) }
          : className),
      }}
      overlayClassName={{
        ...{
          base: styles.overlay,
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overlayBeforeClose,
        },
        ...(typeof overlayClassName === "string"
          ? { base: classNames(styles.overlay, overlayClassName) }
          : overlayClassName),
      }}
      {...restProps}
      onRequestClose={onRequestClose_}
    >
      <>
        <div className={styles.header}>
          {title && <div className={styles.header_title}>{title}</div>}
          {titleAction && (
            <div className={styles.header_titleAction}>{titleAction}</div>
          )}
          <div className={styles.header_close} onClick={onRequestClose}>
            &#x2716;
          </div>
        </div>
        <div
          className={classNames(styles.body, {
            [styles.body__noSpacing]: noBodySpacing,
          })}
        >
          {children}
        </div>
      </>
    </ReactModal>
  );
}
