import { omit } from "ramda";
import React, { ReactElement } from "react";

import Modal, { ModalProps } from "./Modal";

export default function ModalPage({
  style,
  children,
  ...restModalProps
}: ModalProps): ReactElement {
  return (
    <Modal
      {...restModalProps}
      style={{
        ...omit(["content"], style),
        content: {
          width: "100%",
          height: "100%",
          maxWidth: "100%",
          borderRadius: 0,
          ...style?.content,
        },
      }}
    >
      {children}
    </Modal>
  );
}
