import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import BackButton from "../../components/buttons/BackButton";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import {
  selectStatsFlowSequenceName,
  selectStatsOwner,
} from "../../features/flowSequenceStats/flowSequenceStatsSlice";
import FlowSequenceStatsView from "../../features/flowSequenceStats/FlowSequenceStatsView";
import RefreshButton from "../../features/flowSequenceStats/RefreshButton";
import SharedByBadge from "../../featuresCommon/SharedByBadge";
import {
  FlowSequenceTopBarTab,
  FlowSequenceTopBarTabs,
} from "../FlowSequenceTopBarTabs";
import styles from "./FlowSequenceStatsPage.module.css";

export default function FlowSequenceStatsPage(): ReactElement {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);

  const flowSequenceName = useSelector(selectStatsFlowSequenceName);
  const owner = useSelector(selectStatsOwner);

  return (
    <div className={styles.root}>
      <TopBar
        leading={<BackButton />}
        title={
          <div className={styles.title}>
            {/* &nbsp; for spacing parity with NameEditor */}
            <div className={styles.title_name}>{flowSequenceName}&nbsp;</div>
            {owner && <SharedByBadge user={owner} />}
          </div>
        }
        center={
          <FlowSequenceTopBarTabs
            flowSequenceId={id}
            activeTab={FlowSequenceTopBarTab.Stats}
          />
        }
        actions={[
          <RefreshButton key="refresh" flowSequenceId={id} />,
          <ProfileButton key="login" />,
        ]}
      />
      <div className={styles.stats}>
        <FlowSequenceStatsView flowSequenceId={id} />
      </div>
    </div>
  );
}
