import { PromptMessageItem } from "@xflr6/chatbot";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import { moveMessageItem } from "../flowEditorSlice";
import { MessageItemEditor, MessageItemEditorCtrl } from "./MessageItemEditor";
import { WithKey } from "./operations/types";
import { usePromptEditorState } from "./PromptEditor";

export interface MessagesEditorContextProps {
  items: MessageItemEditorCtrl[];
}

export const MessagesEditorContext = React.createContext<
  MessagesEditorContextProps | undefined
>(undefined);

export function useMessagesEditor(): MessagesEditorContextProps {
  const context = React.useContext(MessagesEditorContext);
  if (context === undefined) {
    throw new Error(
      "useMessagesEditor can only be used within a MessagesEditor"
    );
  }
  return context;
}

export interface MessagesEditorCtrl {
  items: MessageItemEditorCtrl[];
}

export default function MessagesEditor({
  control,
}: {
  control?: (control?: MessagesEditorCtrl) => void;
}): ReactElement | null {
  const dispatch = useDispatch();
  const prompt = usePromptEditorState();

  function handleDragEnd(result: DropResult) {
    const { destination, source } = result;
    if (destination == null) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    dispatch(
      moveMessageItem({
        promptIndexOrName: prompt.index,
        fromIndex: source.index,
        toIndex: destination.index,
      })
    );
  }

  const itemControls = useRef<MessageItemEditorCtrl[]>([]);

  const registerControl = useCallback(
    (c) => (itemControls.current[c.index] = c),
    []
  );

  const deregisterControl = useCallback((key) => {
    const index = itemControls.current.findIndex((c) => c.key === key);
    if (index !== -1) {
      itemControls.current.splice(index, 1);
    }
  }, []);

  useEffect(() => {
    control?.({
      items: itemControls.current,
    });
  }, [control]);

  const messages = prompt.definition.messages;
  if (messages == null) {
    return null;
  }
  return (
    <MessagesEditorContext.Provider value={{ items: itemControls.current }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`${prompt.name}.messages`}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {messages.map((messageItem, index) => (
                <MessageItemEditor
                  key={(messageItem as WithKey<PromptMessageItem>).__key}
                  promptIndex={prompt.index}
                  messageItem={messageItem}
                  index={index}
                  isLast={index === messages.length - 1}
                  promptHasAnswers={prompt.definition.answers != null}
                  control={registerControl}
                  deregisterControl={deregisterControl}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </MessagesEditorContext.Provider>
  );
}
