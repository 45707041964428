import React, { ReactElement, useState } from "react";
import { MdContentPaste } from "react-icons/md";

import FlatButton from "../components/buttons/FlatButton";
import MyTippy from "../components/MyTippy";
import TranslateIcon from "./TranslateIcon";
import styles from "./TranslationEditor.module.css";

export interface TranslationEditorProps {
  language?: string | null;
  /**
   * Called when paste button is clicked.
   * If this prop is not provided, no paste button is shown.
   * Return true to force the translation editor to remount.
   */
  onClickPaste?: (language: string) => boolean;
  children: (language: string) => JSX.Element;
}

export default function TranslationEditor({
  language,
  onClickPaste,
  children,
}: TranslationEditorProps): ReactElement | null {
  const [key, setKey] = useState(1);

  if (language == null) return null;

  return (
    <div className={styles.root} key={key}>
      <div className={styles.label}>
        <TranslateIcon size={11} language={language} />
        &nbsp;Translation
        {onClickPaste && (
          <MyTippy
            content="Paste original message"
            placement="right"
            delay={[300, 50]}
          >
            <FlatButton
              onClick={() => {
                const remount = onClickPaste(language);
                if (remount) {
                  setKey((key) => key + 1);
                }
              }}
            >
              <MdContentPaste size={10} />
            </FlatButton>
          </MyTippy>
        )}
      </div>
      <div className={styles.editor}>{children(language)}</div>
    </div>
  );
}
