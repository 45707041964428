import "tippy.js/themes/light-border.css";

import Tippy, { TippyProps } from "@tippyjs/react";
import React, { ReactElement } from "react";

export interface MyTippyProps extends TippyProps {
  noDelay?: boolean;
}

export default function MyTippy({
  noDelay,
  ...tippyProps
}: MyTippyProps): ReactElement {
  const defaultProps: Partial<TippyProps> = {
    ...(noDelay ? {} : { delay: [300, 50] }),
    theme: "light-border",
  };

  return <Tippy {...defaultProps} {...tippyProps} />;
}
