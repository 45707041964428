import classNames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { FaSpinner } from "react-icons/fa";

import { ReactComponent as ErrorIcon } from "../assets/errorIcon.svg";
import { ReactComponent as NoResultsIcon } from "../assets/noResultsIcon.svg";
import { ReactComponent as SelectItemIcon } from "../assets/selectItemIcon.svg";
import { getBestEffortErrorMessage } from "../utils/error";
import styles from "./LoadingLayout.module.css";

export interface LoadingLayoutProps {
  className?: string;
  header?: ReactNode | null;
  message?: string | ReactNode | null;
  footer?: ReactNode | null;
}

/**
 * To show either a 'Loading' indicator, or load error/success type messages.
 * There are specific `XXXLayout` components defined below for common use cases.
 * Check those out before using this component, as they might fit your use case.
 * @constructor
 */
export default function LoadingLayout({
  className,
  header,
  message,
  footer,
}: LoadingLayoutProps): ReactElement {
  return (
    <div className={classNames(styles.root, className)}>
      {header}
      {typeof message === "string" ? (
        <div>{message}</div>
      ) : (
        message ?? <FaSpinner className={styles.spinner} />
      )}
      {footer}
    </div>
  );
}

/**
 * Wrap any "icon" in this and supply as the `header` to `LoadingLayout`.
 * Theoretically the icon can be any component, including an SVG component.
 * The CSS height on its root element will be suitably set, so it must allow for
 * that.
 * @constructor
 */
export function LoadingLayoutHeaderIcon({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const child = React.Children.only(children);
  return React.cloneElement(child, {
    className: styles.headerIcon,
  });
}

export function ErrorLayout({
  message,
  ...restProps
}: Omit<LoadingLayoutProps, "header">): ReactElement {
  return (
    <LoadingLayout
      header={
        <LoadingLayoutHeaderIcon>
          <ErrorIcon />
        </LoadingLayoutHeaderIcon>
      }
      message={getBestEffortErrorMessage(message)}
      {...restProps}
    />
  );
}

export function NoResultsLayout(
  props: Omit<LoadingLayoutProps, "header">
): ReactElement {
  return (
    <LoadingLayout
      header={
        <LoadingLayoutHeaderIcon>
          <NoResultsIcon />
        </LoadingLayoutHeaderIcon>
      }
      {...props}
    />
  );
}

export function SelectItemLayout(
  props: Omit<LoadingLayoutProps, "header">
): ReactElement {
  return (
    <LoadingLayout
      header={
        <LoadingLayoutHeaderIcon>
          <SelectItemIcon />
        </LoadingLayoutHeaderIcon>
      }
      {...props}
    />
  );
}
