import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  load as loadFromLocalStorage,
  save as saveToLocalStorage,
} from "redux-localstorage-simple";

import authReducer from "../features/auth/authSlice";
import flowEditorReducer from "../features/flowEditor/flowEditorSlice";
import flowEditorUiReducer from "../features/flowEditor/flowEditorUiSlice";
import flowListReducer from "../features/flowList/flowListSlice";
import flowSequenceEditorReducer from "../features/flowSequenceEditor/flowSequenceEditorSlice";
import flowSequenceEditorUiReducer from "../features/flowSequenceEditor/flowSequenceEditorUiSlice";
import flowSequenceListReducer from "../features/flowSequenceList/flowSequenceListSlice";
import flowSequenceStatsReducer from "../features/flowSequenceStats/flowSequenceStatsSlice";
import flowStatsReducer from "../features/flowStats/flowStatsSlice";
import identifiersReducer from "../features/identifiers/identifiersSlice";
import integrationsReducer from "../features/integrations/integrationsSlice";
import thinkificDataReducer from "../features/integrations/thinkific/thinkificDataSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  flowEditor: flowEditorReducer,
  flowEditorUi: flowEditorUiReducer,
  flowList: flowListReducer,
  flowSequenceEditor: flowSequenceEditorReducer,
  flowSequenceEditorUi: flowSequenceEditorUiReducer,
  flowSequenceList: flowSequenceListReducer,
  flowSequenceStats: flowSequenceStatsReducer,
  flowStats: flowStatsReducer,
  identifiers: identifiersReducer,
  integrations: integrationsReducer,
  thinkificData: thinkificDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AsyncStatus = "idle" | "pending" | "fulfilled" | "rejected";

const PERSISTED_STATES = ["flowEditorUi", "flowSequenceEditorUi"];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      saveToLocalStorage({ states: PERSISTED_STATES }),
    ]),
  preloadedState: loadFromLocalStorage({ states: PERSISTED_STATES }),
});

export type AppDispatch = typeof store.dispatch;

export default store;

/**
 * TypeScript friendly version of RTK's `useDispatch`.
 * Use this when you want to await the result of dispatching an async thunk.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
