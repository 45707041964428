import { AnswerDefinition, OrdChatFlowDefinition } from "@xflr6/chatbot";
import { v4 } from "uuid";

import { WithKey } from "./types";

/**
 * Sets uuid keys on answer definitions that don't have one.
 * Keys are needed to be able to correctly render answer editors and more.
 */
export function hydrateAnswerKeys(flowDef: OrdChatFlowDefinition): void {
  flowDef.promptsArray.forEach(({ definition }) => {
    if (Array.isArray(definition.answers)) {
      definition.answers.forEach((answerDef) => {
        const keyedAnswerDef = answerDef as WithKey<AnswerDefinition>;
        if (keyedAnswerDef.__key == null) {
          keyedAnswerDef.__key = v4();
        }
      });
    }
  });
}
