import axios from "axios";

import { buildAuthHeader } from "../auth";
import { baseUrl } from "../utils";
import { CloudinaryData } from "./cloudinary";
import { LtiData } from "./lti";
import { ThinkificData } from "./thinkific";
import { WebhookData } from "./webhook";

export const INTEGRATION_TYPES = [
  "thinkific",
  "webhook",
  "lti",
  "cloudinary",
] as const;

export type IntegrationType = typeof INTEGRATION_TYPES[number];

export const INTEGRATION_TYPE_TITLES: Record<IntegrationType, string> = {
  thinkific: "Thinkific",
  webhook: "Webhook",
  lti: "LTI Advantage",
  cloudinary: "Cloudinary",
} as const;

export type IntegrationTypeData<T extends IntegrationType> =
  | (T extends "thinkific" ? ThinkificData : null)
  | (T extends "webhook" ? WebhookData : null)
  | (T extends "lti" ? LtiData : null)
  | (T extends "cloudinary" ? CloudinaryData : null)
  | null;

export interface Integration {
  type: IntegrationType;
  config: Record<string, unknown>;
}

export async function getIntegrations(): Promise<Integration[]> {
  const response = await axios.get(`${baseUrl}/integrations`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data.integrations;
}

/**
 * Some integrations return no response data, because what is saved in the
 * backend is exactly the `config` sent. Others return data, because what is
 * saved in the backend might differ from the `config` sent. Hence, the dual
 * return type.
 */
export async function addIntegration(
  type: IntegrationType,
  config: Record<string, unknown>
): Promise<Integration | null> {
  const response = await axios.post(
    `${baseUrl}/integrations/${type}`,
    { config },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data == null || response.data === "" ? null : response.data;
}

export async function updateIntegration(
  type: IntegrationType,
  config: Record<string, unknown>
): Promise<Integration | null> {
  const response = await axios.put(
    `${baseUrl}/integrations/${type}`,
    { config },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data == null || response.data === "" ? null : response.data;
}

export async function deleteIntegration(
  type: IntegrationType,
  index?: number
): Promise<Integration | null> {
  const response = await axios.delete(
    `${baseUrl}/integrations/${type}${index != null ? `/${index}` : ""}`,
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data == null || response.data === "" ? null : response.data;
}

export async function getIntegrationData<T extends IntegrationType>(
  type: T
): Promise<IntegrationTypeData<T>> {
  const response = await axios.get(`${baseUrl}/integrations/${type}/data`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}

export async function updateIntegrationData<T extends IntegrationType>({
  type,
  data,
}: {
  type: T;
  data: IntegrationTypeData<T>;
}): Promise<void> {
  await axios.post(
    `${baseUrl}/integrations/${type}/data`,
    { data },
    {
      headers: { ...buildAuthHeader() },
    }
  );
}

export async function refreshIntegrationData(
  type: IntegrationType,
  params?: Record<string, string | number>
): Promise<void> {
  await axios.get(`${baseUrl}/integrations/${type}/data/refresh`, {
    headers: { ...buildAuthHeader() },
    params,
  });
}

export function getIntegrationTypeTitle(type: IntegrationType): string {
  return INTEGRATION_TYPE_TITLES[type] ?? type;
}

export async function integrateThinkificApp(
  code: string,
  subdomain: string
): Promise<void> {
  await axios.post(
    `${baseUrl}/integrations/thinkific-app`,
    { code, subdomain },
    {
      headers: { ...buildAuthHeader() },
    }
  );
}

export * from "./lti";
export * from "./thinkific";
export * from "./webhook";
