import {
  Chat,
  ChatFlow,
  ChatFlowDefinition,
  ChatOptions,
  ChatView,
  createBaseCssVariablesStream,
  FlowId,
  MessageView,
  OrdChatFlowDefinition,
  PromptKey,
} from "@xflr6/chatbot";
import { getMatchingRemotePromptNames } from "@xflr6/chatbot_customizations";
import * as api from "@xflr6/chatbot-api";
import { merge } from "lodash";
import { clone } from "ramda";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FiInfo } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
import { distinctUntilChanged, map } from "rxjs/operators";

import FlatButton from "../../../../components/buttons/FlatButton";
import LoadingLayout from "../../../../components/LoadingLayout";
import MyTippy from "../../../../components/MyTippy";
import { selectEffectiveFlowSettings } from "../../../../featuresCommon/selectors";
import { applySettingsToBaseCss } from "../../../../utils/settings";
import { computeChatFlowMaxScore } from "../../../../worker/worker";
import { selectEditorUi } from "../../flowEditorUiSlice";
import styles from "./FlowPreview.module.css";
import { PromptTemplate } from "./templates";

export interface FlowPreviewProps {
  promptTemplate: PromptTemplate;
  onHasAnswered?: ((value: boolean) => void) | null;
}

export default function FlowPreview({
  promptTemplate,
  onHasAnswered,
}: FlowPreviewProps): ReactElement {
  const [hasAnswered, setHasAnswered] = useState(false);
  const [flowPreviewKey, setFlowPreviewKey] = useState(0);

  const effectiveOnHasAnswered = useCallback(
    (value) => {
      setHasAnswered(value);
      onHasAnswered?.(value);
    },
    [onHasAnswered]
  );

  function restartPreview() {
    setFlowPreviewKey((n) => n + 1);
  }

  return (
    <div className={styles.root}>
      <FlowPreviewInner
        key={flowPreviewKey}
        promptTemplate={promptTemplate}
        onHasAnswered={effectiveOnHasAnswered}
      />
      <div className={styles.floatingMenu}>
        {hasAnswered && (
          <MyTippy content="Restart preview" placement="left">
            <FlatButton
              className={styles.floatingMenu_item}
              onClick={restartPreview}
            >
              <MdRefresh size={20} />
            </FlatButton>
          </MyTippy>
        )}
      </div>
    </div>
  );
}

function FlowPreviewInner({
  promptTemplate,
  onHasAnswered,
}: FlowPreviewProps): ReactElement {
  const editorUi = useSelector(selectEditorUi);
  const settings = useSelector(selectEffectiveFlowSettings);

  const [chatViewProps, setChatViewProps] = useState<{
    key: string;
    chat: PreviewChat | null;
  }>({ key: new Date().toISOString(), chat: null });

  const flowDef: OrdChatFlowDefinition = useMemo(() => {
    const def = {
      promptsArray: [
        {
          name: "start",
          definition: {
            ...clone(promptTemplate.definition),
            nextKey: ".end",
          },
        },
        {
          name: "end",
          definition: { message: "End of template", contentType: "event" },
        },
      ],
    };
    return {
      ...def,
      maxScore: computeChatFlowMaxScore(def, "start").score,
    };
  }, [promptTemplate.definition]);

  useEffect(() => {
    const chat = new PreviewChat("chat", flowDef, {
      defaultMinNextPromptDelayMs: settings?.chatDefaultDelayMs ?? 1000,
    });
    chat.isAutoAnswerEnabled = editorUi.isChatAutoAnswerEnabled;
    const subs = chat.state
      .pipe(
        map((state) => !!state?.hasAnswered),
        distinctUntilChanged()
      )
      .subscribe((v) => onHasAnswered?.(v));
    chat.setPrompt(PromptKey.fromString(`flow.start`));
    setChatViewProps({ key: new Date().toISOString(), chat: chat });

    return function cleanup() {
      subs.unsubscribe();
      chat.dispose();
    };
  }, [flowDef, settings, editorUi.isChatAutoAnswerEnabled, onHasAnswered]);

  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  useEffect(() => {
    applySettingsToBaseCss($baseCssVariables.current, settings);
  }, [settings]);

  return chatViewProps.chat == null ? (
    <LoadingLayout className={styles.loading} />
  ) : (
    <div className={styles.innerRoot}>
      <ChatView
        key={chatViewProps.key}
        chat={chatViewProps.chat}
        showScore
        skipInitialAnimations
        disableAutoFocus
        disableKeyboardAnswer
        $baseCssVariables={$baseCssVariables.current}
        avatarSrc={settings?.avatarUrl}
      />
    </div>
  );
}

class PreviewChat extends Chat {
  private readonly _flowDef: OrdChatFlowDefinition;

  constructor(
    name: string,
    flowDef: OrdChatFlowDefinition,
    options?: ChatOptions
  ) {
    super(
      name,
      merge(
        {
          runMode: "preview",
          answerClickDelayMs: 0,
          defaultMinNextPromptDelayMs: 200,
          confirmDestructiveAlteration: async () => true,
        },
        options
      )
    );
    this._flowDef = flowDef;
  }

  get flowDef() {
    return this._flowDef;
  }

  handleGetFlow(flowId: FlowId): ChatFlow {
    return new PreviewFlow(flowId, this);
  }
}

function Placeholder({ children }: { children: ReactNode }) {
  return (
    <div className={styles.placeholder}>
      <FiInfo className={styles.placeholder_icon} size={20} />
      <div className={styles.placeholder_body}>{children}</div>
    </div>
  );
}

class PreviewFlow extends ChatFlow {
  private readonly _flowDef: OrdChatFlowDefinition;

  constructor(id: FlowId, chat: PreviewChat) {
    super(id, chat);
    this._flowDef = chat.flowDef;
  }

  async getDefinition(): Promise<OrdChatFlowDefinition> {
    return this._flowDef;
  }

  async onDefinitionProcessed(definition: ChatFlowDefinition) {
    getMatchingRemotePromptNames(
      definition,
      api.answerSamplesUrlPattern
    ).forEach((name) => {
      this.setHistoryRecdComponentBuilder(
        name,
        (historyItem, positionInfo, promptInput, skipAnimation) => (
          <MessageView
            direction={historyItem.direction}
            positionInfo={positionInfo}
            skipAnimation={skipAnimation}
          >
            <Placeholder>
              Here, your users will see a sampling of other users&apos; answers
            </Placeholder>
          </MessageView>
        )
      );
    });

    getMatchingRemotePromptNames(definition, api.answerStatsUrlPattern).forEach(
      (name) => {
        this.setHistoryRecdComponentBuilder(
          name,
          (historyItem, positionInfo, promptInput, skipAnimation) => (
            <MessageView
              direction={historyItem.direction}
              positionInfo={positionInfo}
              skipAnimation={skipAnimation}
            >
              <Placeholder>
                Here, your users will see how many times each answer was chosen
                by other users
              </Placeholder>
            </MessageView>
          )
        );
      }
    );
  }
}
