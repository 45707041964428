import * as api from "@xflr6/chatbot-api";
import { isBlank } from "@xflr6/utils";
import React, { ReactElement, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { selectAuth } from "../auth/authSlice";

export default function Intercom(): ReactElement {
  const Intercom = useIntercom();

  const { currentUser } = useSelector(selectAuth);

  const hasBooted = useRef(false);

  useEffect(() => {
    if (currentUser != null) {
      if (!hasBooted.current) {
        const userId = api.getUserId(currentUser);
        Intercom.boot({
          userId,
          email: currentUser.email,
          ...(!isBlank(currentUser.name) ? { name: currentUser.name } : {}),
        });
        hasBooted.current = true;

        api
          .getUserDetails()
          .then((userDetails) => {
            Intercom.update({ userId, createdAt: userDetails.createdAt });
          })
          .catch((e) => {
            console.error(
              `Failed to update Intercom with additional user details: ${e}`
            );
          });
      } else {
        Intercom.update({
          ...(!isBlank(currentUser.name) ? { name: currentUser.name } : {}),
        });
      }
    } else {
      Intercom.shutdown();
      hasBooted.current = false;
    }
  }, [currentUser, Intercom]);

  return <></>;
}
