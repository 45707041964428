import classNames from "classnames";
import React, { ReactElement } from "react";
import { FaPlus } from "react-icons/fa";

import FlatButton from "../../../../components/buttons/FlatButton";
import styles from "./InsertAnswerButton.module.css";

export interface InsertAnswerButtonProps {
  isAbove?: boolean;
  onClick?: VoidFunction;
}

export default function InsertAnswerButton(
  props: InsertAnswerButtonProps
): ReactElement {
  return (
    <div className={styles.root}>
      <FlatButton
        className={classNames(styles.button, {
          [styles.button__isAbove]: props.isAbove,
        })}
        onClick={props.onClick}
      >
        <FaPlus size={14} />
      </FlatButton>
    </div>
  );
}
