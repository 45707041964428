import {
  createDefaultSelectors,
  createDefaultThunks,
  createSlice,
} from "../integrationDataSliceUtils";

const type = "thinkific";

const thunks = createDefaultThunks(type);

const slice = createSlice({
  type,
  thunks,
  initialState: {},
  reducers: {},
});

const selectors = createDefaultSelectors(type);

export const { loadData, updateData } = thunks;
export const { clearData, markDataAsEdited } = slice.actions;
export const { selectHasUnsavedChanges, selectData } = selectors;
export default slice.reducer;
