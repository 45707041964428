import {
  getMeetingVendor,
  MeetingMessageShape,
} from "@xflr6/chatbot_customizations";
import React, { ReactElement, useEffect, useRef } from "react";
import { MdMeetingRoom } from "react-icons/md";

import Input from "../../../../components/forms/Input";
import { MessageEditorProps } from "../MessageEditor";
import styles from "./MeetingMessage.module.css";

function addWherebyMetadata(url: string): string {
  const prefix = url.includes("?") ? "&" : "?";
  return `${url}${prefix}metadata={{userId|urlEnc}}`;
}

function removeWherebyMetadata(url: string): string {
  return url.replace(/[&?]?metadata=\{\{userId\|urlEnc\}\}/, "");
}

export default function MeetingMessage({
  message: rawMessage,
  onChange,
  onKeyDown,
  control,
}: MessageEditorProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    control?.({ focus: () => inputRef.current?.focus() });
  }, [control]);

  const message = (rawMessage as unknown) as MeetingMessageShape;
  const meetingVendor = getMeetingVendor(message.url);

  let urlForInput = message.url;
  if (meetingVendor === "whereby") {
    urlForInput = removeWherebyMetadata(urlForInput);
  }

  return (
    <Input
      ref={inputRef}
      styleVariant="minimal"
      className={styles.root}
      type="text"
      value={urlForInput}
      title={urlForInput}
      placeholder="Enter participant link..."
      onBlurValue={(value) => {
        let url = value;
        const meetingVendor = getMeetingVendor(url);
        if (meetingVendor === "whereby") {
          url = addWherebyMetadata(url);
        }
        if (url !== message.url) {
          onChange({ url });
        }
      }}
      blurOnEnterOrEsc
      onKeyDown={(event) => {
        if (onKeyDown?.(event)) {
          event.preventDefault();
        }
      }}
      leading={<MdMeetingRoom size={16} />}
    />
  );
}
