import classNames from "classnames";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import innerText from "react-innertext";

import FlatButton from "../buttons/FlatButton";
import { useMenu } from "./Menu";
import styles from "./MenuItem.module.css";

export interface MenuItemCtrl {
  setIsSelected: (value: boolean) => void;
  click: VoidFunction;
}

export interface MenuItemProps {
  onClick: VoidFunction;
  disabled?: boolean;
  autoClose?: boolean;
  children: ReactNode;
}

export default function MenuItem({
  onClick,
  disabled,
  autoClose,
  children,
}: MenuItemProps): ReactElement {
  const [isSelected, setIsSelected] = useState(false);

  const { autoClose: autoCloseMenu, closeMenu, registerItem } = useMenu();

  const effectiveOnClick = useCallback(() => {
    onClick();
    if (autoClose || (autoCloseMenu && autoClose !== false)) {
      closeMenu?.();
    }
  }, [onClick, autoClose, autoCloseMenu, closeMenu]);

  useEffect(() => {
    registerItem(innerText(children), {
      setIsSelected,
      click: effectiveOnClick,
    });
  }, [registerItem, children, effectiveOnClick]);

  return (
    <FlatButton
      isFullWidth
      className={classNames(styles.root, {
        [styles.root__selected]: isSelected,
      })}
      disabled={disabled}
      onClick={effectiveOnClick}
    >
      {children}
    </FlatButton>
  );
}
