import React, { ReactElement, ReactNode } from "react";
import { useToasts } from "react-toast-notifications";
import { Element, Node, Text } from "slate";

import { ImageStatic } from "./imagesPlugin";
import { useMessageProvider } from "./MessageProvider";
import styles from "./MessageStatic.module.css";
import { deserializeMarkdown } from "./utils";
import { VariableStatic } from "./variablesPlugin";

export interface MessageStaticProps {
  value: string;
  variablesEnabled?: boolean | null;
  editingUnsupportedWarning?: string | null;
}

/**
 * Renders a static message.
 * @constructor
 */
export default function MessageStatic({
  value,
  variablesEnabled,
  editingUnsupportedWarning,
}: MessageStaticProps): ReactElement {
  const { addToast, toastStack } = useToasts();
  const { transformBeforeDeserialize } = useMessageProvider();

  function buildImageLine(elem: Element): ReactNode {
    return <ImageStatic element={elem} />;
  }

  function buildDefaultLine(elem: Element): ReactNode {
    return elem.children
      .map((n, index) => {
        if (variablesEnabled && Element.isElement(n) && n.type === "variable") {
          return <VariableStatic key={index} />;
        } else if (Text.isText(n)) {
          // &#65279; is a character that takes up zero space.
          // We render it to prevent the span's inner HTML from potentially
          // becoming blank and thus causing the span to collapse to 0 size.
          return <span key={index}>{n.text as string}&#65279;</span>;
        } else {
          // This case should not happen, but we leave it for completeness
          return null;
        }
      })
      .filter((c) => c != null);
  }

  return (
    <div className={styles.root}>
      {editingUnsupportedWarning != null && (
        <div
          className={styles.unsupportedOverlay}
          onClick={() => {
            if (toastStack[0]?.content !== editingUnsupportedWarning) {
              addToast(editingUnsupportedWarning, {
                appearance: "warning",
                autoDismiss: true,
              });
            }
          }}
        />
      )}
      <div>
        {deserializeMarkdown(
          transformBeforeDeserialize(value),
          !!variablesEnabled
        ).map((node: Node, index) => {
          let lineComponent;
          if (Element.isElement(node)) {
            if (node.type === "image") {
              lineComponent = buildImageLine(node);
            } else {
              lineComponent = buildDefaultLine(node);
            }
          } else {
            lineComponent = null;
          }
          return <div key={index}>{lineComponent}</div>;
        })}
      </div>
    </div>
  );
}
