import React, { ReactElement, useState } from "react";
import { BsChatQuoteFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { ReactComponent as SuccessIcon } from "../../assets/successIcon.svg";
import buttonStyles from "../../components/buttons/Button.module.css";
import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  LoadingLayoutHeaderIcon,
} from "../../components/LoadingLayout";
import TopBar from "../../components/TopBar";
import ResetPasswordForm from "../../features/auth/ResetPasswordForm";
import styles from "./ResetPasswordPage.module.css";

export default function ResetPasswordPage(): ReactElement {
  const { token } = useParams<{ token: string }>();

  const [isSuccess, setIsSuccess] = useState(false);

  return isSuccess ? (
    <LoadingLayout
      header={
        <LoadingLayoutHeaderIcon>
          <SuccessIcon />
        </LoadingLayoutHeaderIcon>
      }
      message="Your password has been reset!"
      footer={
        <Link to={`/`} className={buttonStyles.hasButton}>
          <FilledButton>
            <FaHome />
            &nbsp;Home
          </FilledButton>
        </Link>
      }
    />
  ) : (
    <div className={styles.root}>
      <TopBar
        leading={<BsChatQuoteFill className={styles.logo} />}
        title="DialogForm"
      />
      <div className={styles.body}>
        <div className={styles.form}>
          <ResetPasswordForm
            token={token}
            onSuccess={() => setIsSuccess(true)}
          />
        </div>
      </div>
    </div>
  );
}
