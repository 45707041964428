import React, { PropsWithChildren, ReactElement } from "react";
import { BsChatQuoteFill } from "react-icons/bs";

import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import styles from "./PageContents.module.css";

export default function PageContents(
  props: PropsWithChildren<unknown>
): ReactElement {
  return (
    <div className={styles.root}>
      <TopBar
        leading={<BsChatQuoteFill className={styles.logo} />}
        title="DialogForm App"
        actions={[<ProfileButton key="login" />]}
      />
      {props.children}
    </div>
  );
}
