import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatJson } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import { equals } from "ramda";

import { AsyncStatus, RootState } from "../../app/store";
import { ChatViewNavLocation } from "../../featuresCommon/stats/ChatViewNav";
import {
  computeAdminInputLocations,
  FIFTEEN_MINUTES_IN_MS,
} from "../../featuresCommon/stats/utils";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface FlowStatsFilter {
  fromDate: string | null;
  tillDate: string | null;
}

export interface FlowStatsState {
  filter: FlowStatsFilter;
  reqId: string | null;
  loading: AsyncStatus;
  loadError: ErrorData | null;
  stats: api.FlowStats | null;
  responsesFilter: api.ResponsesFilter;
  responsesReqId: string | null;
  responsesLoading: AsyncStatus;
  responsesLoadError: ErrorData | null;
  responsesData: api.ChatResponses | null;
  responseIndex: number | null;
  responseReqId: string | null;
  responseLoading: AsyncStatus;
  responseLoadError: ErrorData | null;
  response: api.ChatResponse | null;
}

function resetData(state: FlowStatsState, loading: AsyncStatus) {
  state.reqId = null;
  state.loading = loading;
  state.loadError = null;
  state.stats = null;

  state.responsesReqId = null;
  state.responsesLoading = "idle";
  state.responsesLoadError = null;
  state.responsesData = null;

  state.responseIndex = null;
  state.responseReqId = null;
  state.responseLoading = "idle";
  state.responseLoadError = null;
  state.response = null;
}

export const loadStats = createAsyncThunk(
  "flowStats/loadStatus",
  async (
    {
      flowId,
      filter,
    }: { flowId: number; filter?: FlowStatsFilter; force?: boolean },
    thunkApi
  ) => {
    const { flowStats } = thunkApi.getState() as { flowStats: FlowStatsState };
    const effectiveFilter = filter ?? flowStats.filter;
    try {
      return await api.getFlowStats(
        flowId,
        effectiveFilter.fromDate,
        effectiveFilter.tillDate,
        FIFTEEN_MINUTES_IN_MS
      );
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  },
  {
    condition({ flowId, filter, force }, thunkApi): boolean | undefined {
      const { flowStats } = thunkApi.getState() as {
        flowStats: FlowStatsState;
      };
      if (
        !force &&
        flowStats.loading !== "idle" &&
        flowId === flowStats.stats?.id &&
        (!filter || equals(filter, flowStats.filter))
      ) {
        return false;
      }
    },
  }
);

export const loadResponses = createAsyncThunk(
  "flowStats/loadResponsesStatus",
  async (filter: api.ResponsesFilter, thunkApi) => {
    const { flowStats } = thunkApi.getState() as { flowStats: FlowStatsState };
    if (flowStats.stats != null) {
      const flowId = flowStats.stats.id;
      try {
        return await api.getChatResponses(flowId, {
          ...flowStats.filter,
          filter,
        });
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadStats"))
      );
    }
  }
);

export const loadMoreResponses = createAsyncThunk(
  "flowStats/loadMoreResponsesStatus",
  async (payload: void, thunkApi) => {
    const { flowStats } = thunkApi.getState() as { flowStats: FlowStatsState };
    if (flowStats.stats != null) {
      const flowId = flowStats.stats.id;
      const log = flowStats.responsesData?.responseLog;
      const sinceId = log != null ? log[log.length - 1].id : null;
      try {
        return await api.getChatResponses(flowId, {
          ...flowStats.filter,
          filter: flowStats.responsesFilter,
          sinceId,
        });
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadStats"))
      );
    }
  },
  {
    condition(payload, thunkApi) {
      const { flowStats } = thunkApi.getState() as {
        flowStats: FlowStatsState;
      };
      return flowStats.responsesLoading !== "pending";
    },
  }
);

export const loadResponse = createAsyncThunk(
  "flowStats/loadResponseStatus",
  async (index: number, thunkApi) => {
    const { flowStats } = thunkApi.getState() as { flowStats: FlowStatsState };
    if (flowStats.stats != null && flowStats.responsesData != null) {
      const flowId = flowStats.stats.id;
      const identifier = flowStats.responsesData.responseLog[index].identifier;
      try {
        return await api.getChatResponse(flowId, identifier);
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadResponses"))
      );
    }
  }
);

export const slice = createSlice({
  name: "flowStats",
  initialState: {
    filter: {
      fromDate: null,
      tillDate: null,
    },
    reqId: null,
    loading: "idle",
    loadError: null,
    stats: null,
    responsesFilter: {},
    responsesReqId: null,
    responsesLoading: "idle",
    responsesLoadError: null,
    responsesData: null,
    responseIndex: null,
    responseReqId: null,
    responseLoading: "idle",
    responseLoadError: null,
    response: null,
  } as FlowStatsState,
  reducers: {
    primeFilters: (
      state: FlowStatsState,
      action: PayloadAction<
        Partial<{
          filter: FlowStatsFilter;
          responsesFilter: api.ResponsesFilter;
        }>
      >
    ) => {
      const { filter, responsesFilter } = action.payload;
      let dirty = false;
      if (filter && !equals(filter, state.filter)) {
        state.filter = filter;
        dirty = true;
      }
      if (responsesFilter && !equals(responsesFilter, state.responsesFilter)) {
        state.responsesFilter = responsesFilter;
        dirty = true;
      }
      if (dirty) {
        resetData(state, "idle");
      }
    },
    removeFromResponses: (
      state: FlowStatsState,
      action: PayloadAction<{ flowId: number; identifier: string }>
    ) => {
      if (
        state.stats != null &&
        action.payload.flowId === state.stats.id &&
        state.responsesData != null
      ) {
        if (
          state.response != null &&
          state.response.id === action.payload.flowId &&
          state.response.identifier === action.payload.identifier
        ) {
          state.response = null;
          state.responseIndex = null;
        }

        const index = state.responsesData.responseLog.findIndex(
          (r) => r.identifier === action.payload.identifier
        );
        if (index !== -1) {
          state.responsesData.responseLog.splice(index, 1);
          state.responsesData.totalRecords--;
        }
      }
    },
    updateFlowName: (state: FlowStatsState, action: PayloadAction<string>) => {
      if (state.stats != null) {
        state.stats.name = action.payload;
      }
    },
    updateResponseHistory: (
      state: FlowStatsState,
      action: PayloadAction<{
        index: number;
        data: api.ChatResponseHistoryItemPatch | ChatJson;
      }>
    ) => {
      if (state.response != null) {
        let historyItems = state.response.history?.history;
        const { data } = action.payload;

        if (historyItems != null) {
          if ("history" in data) {
            historyItems = data.history;
            state.response.history = data;
          } else {
            const { index, data } = action.payload;

            if ("comment" in data) {
              historyItems[index].c = data.comment;
            }
            if ("superScore" in data) {
              historyItems[index].ss = data.superScore;
            }
          }

          const adminLocations = computeAdminInputLocations(
            historyItems,
            state.response.evaluations
          );

          const newFeedbackPending = adminLocations.filter(
            (location) =>
              location.category === "feedback" && location.actionPending
          ).length;
          const newScoreLaterPending = adminLocations.filter(
            (location) =>
              location.category === "scoreLater" && location.actionPending
          ).length;
          const newAnswerExternallyPending = adminLocations.filter(
            (location) =>
              location.category === "answerExternally" && location.actionPending
          ).length;
          if (state.responsesData != null && state.responseIndex != null) {
            const response =
              state.responsesData.responseLog[state.responseIndex];
            response.feedbackPending = newFeedbackPending;
            response.scoreLaterPending = newScoreLaterPending;
            response.externalAnswerPending = newAnswerExternallyPending;

            if ("history" in data) {
              response.completed = data.completed ?? false;
            }
          }
        }
      }
    },
    updateResponseEvaluation: (
      state: FlowStatsState,
      action: PayloadAction<{
        flowId: number;
        identifier: string;
        promptKeyStr: string;
        evaluation: string;
        decrementPending?: boolean;
      }>
    ) => {
      if (
        state.response != null &&
        action.payload.flowId === state.response.id
      ) {
        state.response.evaluations[action.payload.promptKeyStr] =
          action.payload.evaluation;
      }

      if (
        action.payload.decrementPending &&
        state.stats != null &&
        action.payload.flowId === state.stats.id &&
        state.responsesData != null
      ) {
        const responseStub = state.responsesData.responseLog.find(
          (r) => r.identifier === action.payload.identifier
        );
        if (responseStub != null) {
          responseStub.evaluationPending -= 1;
        }
      }
    },
    updateResponseSettings: (
      state: FlowStatsState,
      action: PayloadAction<api.SettingsPatch>
    ) => {
      if (state.response != null) {
        state.response.settings = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // ** loadStats **
    builder.addCase(loadStats.pending, (state, action) => {
      resetData(state, "pending");
      state.reqId = action.meta.requestId;
      const filter = action.meta.arg.filter;
      if (filter != null) {
        state.filter = filter;
      }
    });
    builder.addCase(loadStats.fulfilled, (state, action) => {
      if (action.meta.requestId === state.reqId) {
        state.loading = "fulfilled";
        state.stats = action.payload;
      }
    });
    builder.addCase(loadStats.rejected, (state, action) => {
      if (action.meta.requestId === state.reqId) {
        state.loading = "rejected";
        state.loadError = action.payload;
      }
    });

    // ** loadResponses **
    builder.addCase(loadResponses.pending, (state, action) => {
      state.responsesReqId = action.meta.requestId;
      state.responsesFilter = action.meta.arg;
      state.responsesLoading = "pending";
      state.responsesLoadError = null;
      state.responsesData = null;
    });
    builder.addCase(loadResponses.fulfilled, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "fulfilled";
        state.responsesData = action.payload;
        state.responseIndex = null;
        state.responseReqId = null;
        state.responseLoading = "idle";
        state.responseLoadError = null;
        state.response = null;
      }
    });
    builder.addCase(loadResponses.rejected, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "rejected";
        state.responsesLoadError = action.payload;
      }
    });

    // ** loadMoreResponses **
    builder.addCase(loadMoreResponses.pending, (state, action) => {
      state.responsesReqId = action.meta.requestId;
      state.responsesLoading = "pending";
      state.responsesLoadError = null;
    });
    builder.addCase(loadMoreResponses.fulfilled, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "fulfilled";
        state.responsesData =
          state.responsesData == null
            ? action.payload
            : {
                ...action.payload,
                totalRecords: state.responsesData.totalRecords,
                responseLog: state.responsesData.responseLog.concat(
                  action.payload.responseLog
                ),
              };
      }
    });
    builder.addCase(loadMoreResponses.rejected, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "rejected";
        state.responsesLoadError = action.payload;
      }
    });

    // ** loadResponse **
    builder.addCase(loadResponse.pending, (state, action) => {
      state.responseReqId = action.meta.requestId;
      state.responseIndex = action.meta.arg;
      state.responseLoading = "pending";
      state.responseLoadError = null;
      state.response = null;
    });
    builder.addCase(loadResponse.fulfilled, (state, action) => {
      if (
        state.stats != null &&
        state.responsesData != null &&
        action.meta.requestId === state.responseReqId
      ) {
        const flowId = state.stats.id;
        if (
          action.payload.id === flowId &&
          state.responseIndex != null &&
          action.payload.identifier ===
            state.responsesData.responseLog[state.responseIndex].identifier
        ) {
          state.responseLoading = "fulfilled";
          state.response = action.payload;
        }
      }
    });
    builder.addCase(loadResponse.rejected, (state, action) => {
      if (action.meta.requestId === state.responseReqId) {
        state.responseLoading = "rejected";
        state.responseLoadError = action.payload;
      }
    });
  },
});

export const {
  primeFilters,
  removeFromResponses,
  updateFlowName,
  updateResponseHistory,
  updateResponseEvaluation,
  updateResponseSettings,
} = slice.actions;

export const selectFilter = (state: RootState): FlowStatsFilter =>
  state.flowStats.filter;

export const selectStatsState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  stats: api.FlowStats | null;
} => ({
  loading: state.flowStats.loading,
  loadError: state.flowStats.loadError,
  stats: state.flowStats.stats,
});

export const selectStatsFlowName = (state: RootState): string | null =>
  state.flowStats.stats?.name ?? null;

export const selectStatsOwnership = (state: RootState): api.Ownership | null =>
  state.flowStats.stats?.ownership ?? null;

export const selectStatsOwner = (state: RootState): api.OwnerDetails | null =>
  state.flowStats.stats?.user ?? null;

export const selectResponsesFilter = (state: RootState): api.ResponsesFilter =>
  state.flowStats.responsesFilter;

export const selectResponsesState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  responsesData: api.ChatResponses | null;
} => ({
  loading: state.flowStats.responsesLoading,
  loadError: state.flowStats.responsesLoadError,
  responsesData: state.flowStats.responsesData,
});

export const selectResponseIndex = (state: RootState): number | null =>
  state.flowStats.responseIndex;

export const selectResponseState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  response: api.ChatResponse | null;
} => ({
  loading: state.flowStats.responseLoading,
  loadError: state.flowStats.responseLoadError,
  response: state.flowStats.response,
});

export const selectResponseFlowSettings = (
  state: RootState
): api.SettingsPatch | null => state.flowStats.response?.settings ?? null;

export const selectResponseFlowUserSettings = (
  state: RootState
): api.SettingsPatch | null => state.flowStats.response?.userSettings ?? null;

export const selectResponseAdminInputLocations = (
  state: RootState
): ChatViewNavLocation[] => {
  const response = state.flowStats.response;
  if (response != null && response.history != null) {
    return computeAdminInputLocations(
      response.history.history,
      response.evaluations
    );
  } else {
    return [];
  }
};

export default slice.reducer;
