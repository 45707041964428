import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";

import MyTippy from "../../../components/MyTippy";
import styles from "./Nodes.module.css";
import { BOX_HEIGHT, BOX_WIDTH, DOT_SIZE, RenderTree } from "./renderTree";

function renderAvgTime(millisecondsSum: number, count: number) {
  return `${(millisecondsSum / (1000 * count)).toFixed(2)}s`;
}

function Measurement(props: { label: string; value: string | number }) {
  return (
    <div className={styles.measurement}>
      {props.label}: {props.value}
    </div>
  );
}

function PromptDetails({ value }: { value: Omit<api.PromptNode, "answers"> }) {
  return (
    <div className={styles.promptDetails}>
      <div className={styles.promptDetails_messages}>
        {value.messages.map((message, index) => (
          <div key={index} className={styles.promptDetails_message}>
            {`${message}`}
          </div>
        ))}
      </div>
      <div className={styles.promptDetails_measurements}>
        <Measurement label="Reached" value={value.total ?? 0} />
        <Measurement label="Dropped" value={value.dropped ?? 0} />
        <Measurement
          label="Avg. time"
          value={renderAvgTime(value.totalTime ?? 0, value.total ?? 1)}
        />
        <Measurement label="Reached (completed)" value={value.completed ?? 0} />
      </div>
    </div>
  );
}

function AnswerDetailsItem({ value }: { value: api.AnswerData }) {
  return (
    <div className={styles.answerDetailsItem}>
      <div
        className={styles.answerDetailsItem_message}
      >{`${value.message}`}</div>
      <div className={styles.answerDetailsItem_measurements}>
        <Measurement label="Total" value={value.total ?? 0} />
        <Measurement label="Total (completed)" value={value.completed ?? 0} />
      </div>
    </div>
  );
}

const Node = ({ renderTree }: { renderTree: RenderTree<api.PromptNode> }) => (
  <MyTippy interactive content={<PromptDetails value={renderTree.value} />}>
    <div
      className={styles.node}
      style={{ ...renderTree.position, width: BOX_WIDTH, height: BOX_HEIGHT }}
    >
      <label className={styles.message}>
        {renderTree.value.messages.join(", ")}
      </label>
      <div className={styles.tags}>
        <span className={styles.tag_total}>{renderTree.value.total ?? 0}</span>
        <span className={styles.tag_dropped}>
          {renderTree.value.dropped ?? 0}
        </span>
      </div>
    </div>
  </MyTippy>
);

function NodesHelper({
  renderTree,
}: {
  renderTree: RenderTree<api.PromptNode>;
}): ReactElement {
  return (
    <>
      <Node renderTree={renderTree} />
      {renderTree.children &&
        renderTree.children.map(({ value, child, position }) => (
          <React.Fragment key={child.value.messages.join(", ")?.toString()}>
            <MyTippy
              interactive
              content={
                <div className={styles.answerDetails}>
                  <div className={styles.answerDetails_title}>Answers</div>
                  {Object.keys(value.answers).map((answerKey) => (
                    <AnswerDetailsItem
                      key={value.answers[answerKey].message?.toString()}
                      value={value.answers[answerKey]}
                    />
                  ))}
                </div>
              }
            >
              <div
                className={styles.dot}
                style={{ ...position, width: DOT_SIZE, height: DOT_SIZE }}
              >
                {" "}
              </div>
            </MyTippy>
            <NodesHelper
              key={child.value.messages.join(", ")?.toString()}
              renderTree={child}
            />
          </React.Fragment>
        ))}
    </>
  );
}

export default function Nodes({
  renderTree,
}: {
  renderTree: RenderTree<api.PromptNode>;
}): ReactElement {
  return <NodesHelper renderTree={renderTree} />;
}
