import * as api from "@xflr6/chatbot-api";
import { not } from "ramda";
import React, {
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaHome, FaIntercom } from "react-icons/fa";
import { IoMdHelpBuoy } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useIntercom } from "react-use-intercom";

import Avatar from "../../components/Avatar";
import FlatButton from "../../components/buttons/FlatButton";
import HorizontalSeparator from "../../components/HorizontalSeparator";
import MenuItem from "../../components/menus/MenuItem";
import MenuItemLink from "../../components/menus/MenuItemLink";
import MenuSection from "../../components/menus/MenuSection";
import Modal from "../../components/Modal";
import TopBarMenu from "../../components/TopBarMenu";
import { SettingsFormController } from "../../featuresCommon/SettingsForm";
import { DOCS_URL } from "../../utils/constants";
import { logout, selectAuth } from "./authSlice";
import CurrentPlan from "./CurrentPlan";
import styles from "./ProfileButton.module.css";
import ProfileEditForm, { ProfileEditFormController } from "./ProfileEditForm";
import SettingsForm from "./SettingsForm";

function ChangePasswordButton({ email }: { email: string }) {
  const { addToast } = useToasts();

  return (
    <FlatButton
      className={styles.changePasswordButton}
      onClick={async () => {
        if (
          window.confirm(
            `You will receive an email at ${email}` +
              " with instructions for updating your password." +
              "\n\nIf you ignore the email then your password, if any," +
              " will remain as it is." +
              " Do you want to go ahead?"
          )
        ) {
          try {
            await api.requestPasswordResetLink(email);
            addToast("You should receive an email soon.", {
              appearance: "success",
              autoDismiss: true,
            });
          } catch (error) {
            addToast("Failed to send password change email.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      }}
    >
      Update password
    </FlatButton>
  );
}

function IntercomChatMenuItem() {
  const Intercom = useIntercom();

  return (
    <MenuItem onClick={Intercom.show}>
      <FaIntercom />
      &nbsp;Ask Us Anything
    </MenuItem>
  );
}

function HomeButton() {
  const location = useLocation();

  if (location.pathname === "/") {
    return null;
  }

  return (
    <MenuItemLink to="/" autoClose={false}>
      <FaHome />
      &nbsp;Home
    </MenuItemLink>
  );
}

export default function ProfileButton({
  renderMenuItems,
}: {
  renderMenuItems?: (closeMenu: () => void) => ReactNode;
}): ReactElement | null {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth).currentUser;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const closeMenu = useMemo(
    () => () => {
      setIsMenuOpen(false);
    },
    [setIsMenuOpen]
  );

  const editFormController = useRef<ProfileEditFormController>();
  const settingsFormController = useRef<SettingsFormController>();

  if (user == null) return null;

  return (
    <>
      <Modal
        title="Account"
        ariaHideApp={false}
        isOpen={isFormOpen}
        style={{
          content: {
            width: "600px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        onRequestClose={() => {
          if (
            (!editFormController.current?.isDirty() &&
              !settingsFormController.current?.isDirty()) ||
            window.confirm(
              "You have unsaved changes. Discard them and close account editor?"
            )
          ) {
            setIsFormOpen(false);
          }
        }}
      >
        <ProfileEditForm controller={(c) => (editFormController.current = c)} />
        <HorizontalSeparator />
        <div className={styles.sectionTitle}>Content Settings</div>
        <SettingsForm
          controller={(c) => (settingsFormController.current = c)}
        />
        <HorizontalSeparator />
        <ChangePasswordButton email={user.email} />
        <HorizontalSeparator />
        <CurrentPlan />
      </Modal>
      <TopBarMenu
        isOpen={isMenuOpen}
        autoClose
        closeOnEsc
        onRequestClose={() => setIsMenuOpen(false)}
        trigger={
          <FlatButton onClick={() => setIsMenuOpen(not)}>
            <Avatar
              initial={user.name?.[0] ?? user.email[0]}
              stringForColor={user.email}
            />
          </FlatButton>
        }
      >
        <MenuItem
          onClick={() => {
            setIsFormOpen(true);
          }}
        >
          <div className={styles.profile}>
            {user.name != null && (
              <div className={styles.profile_name}>{user.name}</div>
            )}
            <div className={styles.profile_email}>{user.email}</div>
          </div>
        </MenuItem>
        {renderMenuItems?.(closeMenu)}
        <MenuSection>
          <MenuItem
            onClick={() =>
              window.open(DOCS_URL, "_blank", "noopener, noreferrer")
            }
          >
            <IoMdHelpBuoy />
            &nbsp;Help
          </MenuItem>
          <IntercomChatMenuItem />
          <HomeButton />
          <MenuItem autoClose={false} onClick={() => dispatch(logout())}>
            <IoLogOutOutline />
            &nbsp;Logout
          </MenuItem>
        </MenuSection>
      </TopBarMenu>
    </>
  );
}
