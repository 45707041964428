import * as api from "@xflr6/chatbot-api";
import { equals } from "ramda";
import React, { ReactElement, useEffect, useState } from "react";
import { IoCheckmarkDone } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ErrorIcon } from "../../../assets/errorIcon.svg";
import FlatButton from "../../../components/buttons/FlatButton";
import formStyles from "../../../components/forms/formStyles.module.css";
import Input from "../../../components/forms/Input";
import HelpTip from "../../../components/HelpTip";
import { DOCS_USER_GUIDE_URL } from "../../../utils/constants";
import { IntegrationConfig } from "../IntegrationConfigs";
import { selectIntegrations, updateIntegration } from "../integrationsSlice";
import styles from "./ThinkificConfig.module.css";
import ThinkificDataView from "./ThinkificDataView";
import { getIsPointagramAddedOn } from "./utils";

const DOCS_THINKIFIC_PAGE_URL = `${DOCS_USER_GUIDE_URL}/using-dialogform-with-thinkific`;
const DIALOGFORM_APP_URL = "https://apps.thinkific.com/apps/dialogform";
const DIALOGFORM_APP_INSTALL = `${window.location.origin}/thinkific-app/install?subdomain=`;

const ThinkificLink = () => (
  <a href={DOCS_THINKIFIC_PAGE_URL} target="_blank" rel="noopener noreferrer">
    Thinkific
  </a>
);

const DocumentationLink = () => (
  <a href={DOCS_THINKIFIC_PAGE_URL} target="_blank" rel="noopener noreferrer">
    See documentation
  </a>
);

const ReauthenticateThinkific = ({ subDomain }: { subDomain?: string }) => (
  <div className={styles.thinkificAuthExpired}>
    <ErrorIcon className={styles.errorIcon} />
    {subDomain ? (
      <div>
        Authentication has expired, please re-authenticate by clicking{" "}
        <a
          href={`${DIALOGFORM_APP_INSTALL}${subDomain}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>
      </div>
    ) : (
      <div>
        Authentication has expired, please re-authenticate by{" "}
        <a href={DIALOGFORM_APP_URL} target="_blank" rel="noopener noreferrer">
          installing the app
        </a>{" "}
        again. You will not lose any data by doing so.
      </div>
    )}
  </div>
);

// Not using for now.
// This contains the Pointagram add-on functionality. We are temporarily
// disabling Pointagram integration until we either test it thoroughly or
// decide to drop it permanently.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function EditForm(props: { integration: api.Integration }): ReactElement {
  const dispatch = useDispatch();
  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const [pointagramApiKey, setPointagramApiKey] = useState<string>("");
  const [pointagramApiUser, setPointagramApiUser] = useState<string>("");
  const [isPointagramAddedOn, setIsPointagramAddedOn] = useState<boolean>(
    false
  );

  useEffect(() => {
    setPointagramApiKey(
      (props.integration.config["pointagramApiKey"] as string) ?? ""
    );
    setPointagramApiUser(
      (props.integration.config["pointagramApiUser"] as string) ?? ""
    );
    setIsPointagramAddedOn(
      getIsPointagramAddedOn(props.integration.config as Record<string, string>)
    );
  }, [props.integration.config]);

  return (
    <>
      <p>
        You are integrated with <ThinkificLink />, and are eligible to use
        DialogForm&apos;s Thinkific-specific enhancements.
      </p>
      <p>
        <DocumentationLink />
      </p>
      <form className={styles.pointagram}>
        <div className={formStyles.field}>
          {isPointagramAddedOn ? (
            <div className={styles.pointagram_title}>
              <IoCheckmarkDone size={20} />
              Pointagram added-on
            </div>
          ) : (
            "Add-on Pointagram (Optional)"
          )}
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>Pointagram API Key</label>
          <Input
            styleVariant="outlined"
            className={formStyles.input}
            value={pointagramApiKey}
            onChange={(event) => setPointagramApiKey(event.target.value)}
          />
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>Pointagram API User</label>
          <Input
            styleVariant="outlined"
            className={formStyles.input}
            value={pointagramApiUser}
            onChange={(event) => setPointagramApiUser(event.target.value)}
          />
        </div>
        <div className={formStyles.actions}>
          <FlatButton
            disabled={updating === "pending"}
            isLoading={
              updating === "pending" &&
              equals(lastUpdateAction, ["thinkific", "update"])
            }
            onClick={() => {
              dispatch(
                updateIntegration({
                  type: "thinkific",
                  config: {
                    pointagramApiKey,
                    pointagramApiUser,
                  },
                })
              );
            }}
          >
            Update
          </FlatButton>
          {isPointagramAddedOn && (
            <FlatButton
              disabled={updating === "pending"}
              isLoading={
                updating === "pending" &&
                equals(lastUpdateAction, ["thinkific", "update"])
              }
              onClick={() => {
                dispatch(
                  updateIntegration({
                    type: "thinkific",
                    config: {
                      ...props.integration.config,
                      pointagramApiKey: "",
                      pointagramApiUser: "",
                    },
                  })
                );
              }}
            >
              Remove
            </FlatButton>
          )}
        </div>
      </form>
    </>
  );
}

export default function ThinkificConfig(): ReactElement {
  return (
    <IntegrationConfig
      type="thinkific"
      renderAddForm={() => (
        <>
          <p>
            Integrate with your <ThinkificLink /> school to include DialogForm
            content in your online courses.
          </p>
          <p>
            <DocumentationLink />
          </p>
        </>
      )}
      renderEditForm={(integration) => (
        <>
          <p>
            You are integrated with <ThinkificLink />, and are eligible to use
            DialogForm&apos;s Thinkific-specific enhancements.
          </p>
          <p>
            <DocumentationLink />
          </p>
          {integration.config.invalid ? (
            <ReauthenticateThinkific
              subDomain={integration.config.subDomain as string | undefined}
            />
          ) : (
            <ThinkificDataView />
          )}
        </>
      )}
      renderRemoveButton={() => (
        <HelpTip
          label="Remove Integration"
          tooltipContent={
            <div>
              Remove via your Thinkific dashboard.
              <br />
              <a
                href={`${DOCS_THINKIFIC_PAGE_URL}#remove-dialogform-from-your-thinkific-account`}
                target="_blank"
                rel="noopener noreferrer"
              >
                See instructions
              </a>
            </div>
          }
          tooltipOptions={{ interactive: true }}
        />
      )}
      confirmRemoveMessage={
        "You will lose any data associated with this integration." +
        " Do you want to go ahead?"
      }
    />
  );
}
