import React, { MouseEventHandler, ReactElement } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

import Avatar from "../components/Avatar";
import FlatButton from "../components/buttons/FlatButton";
import { selectAuth } from "../features/auth/authSlice";
import styles from "./AvatarInput.module.css";
import { useImageInput } from "./ImageInputProvider";

export default function AvatarInput({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (publicUrl: string) => void;
}): ReactElement | null {
  const getImage = useImageInput();
  const user = useSelector(selectAuth).currentUser;

  const handleEditAvatar: MouseEventHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const imageData = await getImage({
      uploadPathPrefix: "profileImages/",
      initialCropVal: {
        unit: "%",
        width: 70,
      },
      aspectRatio: 1,
      acceptExternalSources: false,
    });
    onChange(imageData.url);
  };

  if (user == null) return null;

  return (
    <div className={styles.root}>
      <FlatButton onClick={handleEditAvatar}>
        <Avatar
          src={value}
          initial={user.name?.[0] ?? user.email[0]}
          stringForColor={user.email}
          size={5 * 16}
        />
        <div className={styles.editIcon}>
          <FaPencilAlt size={20} />
        </div>
      </FlatButton>
    </div>
  );
}
