import Picker, { IEmojiData } from "emoji-picker-react";
import React, { ReactElement, useMemo, useState } from "react";

import Modal from "./Modal";

export interface EmojiPickerProps {
  title?: string;
  isOpen: boolean;
  onSelect: (data: IEmojiData) => void;
  onRequestClose?: () => void;
}

/**
 * Usage:
 *
 * Method 1 (controlled):
 * <EmojiPicker
 *   // Manually provide properties
 * />
 *
 * Method 2 (uncontrolled):
 * function SomeComponent() {
 *   const { getEmoji, pickerProps } = useEmojiPicker();
 *   return(
 *     <>
 *       // Other Stuff you want to render, such as:
 *       <button onClick={async () => {
 *         const emoji = await getEmoji();
 *         console.log(emoji);
 *       }}>Pick Emoji</button>
 *
 *       // Render an EmojiPicker and pass it props
 *       <EmojiPicker {...pickerProps} />
 *     </>
 *   );
 * }
 *
 * @constructor
 */
export function EmojiPicker({
  title = "Emoji Picker",
  isOpen,
  onSelect,
  onRequestClose,
}: EmojiPickerProps): ReactElement {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      style={{
        content: {
          maxHeight: "calc(100% - var(--spacingDouble))",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
    >
      <Picker onEmojiClick={(event, data) => onSelect(data)} />
    </Modal>
  );
}

export function useEmojiPicker(): {
  getEmoji: () => Promise<IEmojiData | null>;
  pickerProps: Pick<EmojiPickerProps, "isOpen" | "onSelect" | "onRequestClose">;
} {
  const [isOpen, setIsOpen] = useState(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: (data: IEmojiData | null) => void;
  }>();

  const getEmoji = useMemo(
    () => () => {
      setIsOpen(true);
      return new Promise<IEmojiData | null>((resolve) => {
        awaitingPromiseRef.current = { resolve };
      });
    },
    []
  );

  const onSelect = useMemo(
    () => (data: IEmojiData) => {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve(data);
      }
      setIsOpen(false);
    },
    []
  );

  const onRequestClose = useMemo(
    () => () => {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve(null);
      }
      setIsOpen(false);
    },
    []
  );

  const pickerProps = useMemo(
    () => ({
      isOpen,
      onSelect,
      onRequestClose,
    }),
    [isOpen, onSelect, onRequestClose]
  );

  return { getEmoji, pickerProps };
}
