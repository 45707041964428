import classNames from "classnames";
import React, { PropsWithChildren, ReactElement, ReactNode } from "react";

import styles from "./Checkbox.module.css";

export function CheckboxLabel({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  return <div>{children}</div>;
}

export function CheckboxLabelTitle({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  return <div className={styles.label_title}>{children}</div>;
}

export function CheckboxLabelSubtitle({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  return <div className={styles.label_subtitle}>{children}</div>;
}

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  label: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
}

export default React.forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      className,
      label,
      labelClassName,
      inputClassName,
      ...restProps
    }: CheckboxProps,
    ref
  ): ReactElement {
    return (
      <label className={classNames(styles.root, className)}>
        <input
          ref={ref}
          type="checkbox"
          className={classNames(styles.input, inputClassName)}
          {...restProps}
        />
        <div className={classNames(styles.label, labelClassName)}>{label}</div>
      </label>
    );
  }
);
