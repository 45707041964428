import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";

import FilledButton from "../../../components/buttons/FilledButton";
import HelpTip from "../../../components/HelpTip";
import LoadingLayout from "../../../components/LoadingLayout";
import MyTippy from "../../../components/MyTippy";
import { SelectStyles } from "../../../utils/reactSelect";
import {
  clearFlows,
  loadFlows,
  selectFlowList,
} from "../../flowList/flowListSlice";
import {
  clearFlowSequences,
  loadFlowSequences,
  selectFlowSequenceList,
} from "../../flowSequenceList/flowSequenceListSlice";
import { clearData, loadData, selectData } from "./thinkificDataSlice";
import styles from "./ThinkificDataView.module.css";

const selectStyles: SelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 30,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 4,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    marginTop: 4,
    marginBottom: 4,
  }),
};

function ItemUI({
  content,
  flows,
  flowSequences,
}: {
  content: api.Content;
  flows: api.FlowStub[] | null;
  flowSequences: api.FlowSequenceStub[] | null;
}) {
  const history = useHistory();

  const type = content.flowId ? "flows" : "sequences";
  const id = content.flowId ?? content.flowSequenceId;
  const name =
    (type === "flows" ? flows : flowSequences)?.find((f) => f.id === id)
      ?.name ?? null;

  return (
    <div className={styles.flowItem}>
      {((content.flowId != null || content.flowSequenceId != null) && (
        <>
          <div className={styles.itemName}>{name}</div>
          <div className={styles.actions}>
            <MyTippy content="Edit">
              <FilledButton
                className={styles.action}
                onClick={() => history.push(`/${type}/${id}/edit`)}
              >
                <FaPencilAlt size={14} />
              </FilledButton>
            </MyTippy>
            <MyTippy content="View stats">
              <FilledButton
                className={styles.action}
                onClick={() => history.push(`/${type}/${id}/stats`)}
              >
                <IoAnalyticsOutline size={14} />
              </FilledButton>
            </MyTippy>
          </div>
        </>
      )) || (
        <HelpTip
          label="Not Synced"
          tooltipContent={
            <div>
              This is either not a dialogform lesson or is not synced with our
              data yet. It will be synced once someone uses it.
            </div>
          }
          tooltipOptions={{ interactive: true }}
        />
      )}
    </div>
  );
}

export default function ThinkificDataView(): ReactElement | null {
  const dispatch = useDispatch();

  const { loading, error, integrationData } = useSelector(selectData);
  const { data: flowListData } = useSelector(selectFlowList);
  const flows = flowListData?.flows ?? null;
  const { data: flowSequenceListData } = useSelector(selectFlowSequenceList);
  const flowSequences = flowSequenceListData?.flowSequences ?? null;

  const [isRetryingFetch, setIsRetryingFetch] = useState<boolean>(false);
  const fetchDataTimeout = useRef<NodeJS.Timeout>();

  function clearFetchDataTimeout() {
    if (fetchDataTimeout.current != null) {
      clearTimeout(fetchDataTimeout.current);
      fetchDataTimeout.current = undefined;
    }
  }

  const [selectedCourse, setSelectedCourse] = useState<api.Course | null>(null);

  useEffect(() => {
    dispatch(loadData());
    dispatch(loadFlows({ ownership: null, searchTerm: "" }));
    dispatch(loadFlowSequences({ ownership: null, searchTerm: "" }));

    return function cleanup() {
      dispatch(clearData());
      dispatch(clearFlows());
      dispatch(clearFlowSequences());
    };
  }, [dispatch]);

  useEffect(() => {
    if (integrationData == null) return;
    if (["idle", "pending"].includes(integrationData.status)) {
      clearFetchDataTimeout();
      setIsRetryingFetch(true);
      fetchDataTimeout.current = setTimeout(() => {
        clearFetchDataTimeout();
        dispatch(loadData());
      }, 1000);
    } else {
      clearFetchDataTimeout();
      setIsRetryingFetch(false);
    }

    return function cleanup() {
      clearFetchDataTimeout();
      setIsRetryingFetch(false);
    };
  }, [integrationData, dispatch]);

  if (isRetryingFetch) {
    return <LoadingLayout message="Preparing data. Please wait..." />;
  }

  if (loading === "pending") {
    return <LoadingLayout />;
  }
  if (loading === "rejected") {
    return <LoadingLayout message={error} />;
  }

  if (integrationData == null || isEmpty(integrationData)) return null;

  if (integrationData.status === "pending") {
    return <LoadingLayout message="Preparing data. Please wait..." />;
  }
  if (integrationData.status === "rejected") {
    return <LoadingLayout message="Failed to prepare data" />;
  }

  const courseOptions = integrationData.courses.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Select
          name="courses"
          styles={selectStyles}
          options={courseOptions}
          placeholder="Select course..."
          onChange={(selectedOption) => {
            const courseId = (selectedOption as { value: number }).value;
            setSelectedCourse(
              integrationData.courses.find((c) => c.id === courseId) ?? null
            );
          }}
        />
      </div>
      {selectedCourse == null ? (
        <LoadingLayout message="Select a course to view" />
      ) : (
        <div className={styles.body}>
          <div className={styles.courseTree}>
            {selectedCourse.chapters.map((chapter, chapterIndex) => (
              <div className={styles.chapter} key={chapter.id}>
                <div className={styles.chapter_id}>ID: {chapter.id}</div>
                <div className={styles.chapter_name}>
                  Chapter {chapterIndex + 1} - {chapter.name}
                </div>
                <div className={styles.contentList}>
                  {chapter.contents.map((content, contentIndex) => (
                    <div
                      className={classNames(styles.content, {
                        [styles.content__relevant]:
                          content.contentable_type === "Iframe",
                      })}
                      key={content.id}
                    >
                      <div className={styles.content_id}>ID: {content.id}</div>
                      <div className={styles.content_name}>
                        Content {contentIndex + 1} - {content.name}
                      </div>
                      {content.contentable_type === "Iframe" && (
                        <ItemUI
                          content={content}
                          flows={flows}
                          flowSequences={flowSequences}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
