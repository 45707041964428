import React, { ReactElement, ReactNode } from "react";

import styles from "./HelpTip.module.css";
import MyTippy, { MyTippyProps } from "./MyTippy";

export default function HelpTip({
  label,
  tooltipContent,
  tooltipOptions,
}: {
  label: string;
  tooltipContent: ReactNode;
  tooltipOptions?: Omit<MyTippyProps, "content">;
}): ReactElement {
  return (
    <MyTippy content={tooltipContent} placement="bottom" {...tooltipOptions}>
      <div className={styles.root}>{label}</div>
    </MyTippy>
  );
}
