import { AnswerDefinition, PromptDefinition } from "@xflr6/chatbot";
import { any } from "ramda";

export function shouldEnablePromptQuickResponses(
  promptDef: PromptDefinition
): boolean {
  const answers = promptDef.answers;
  return (
    Array.isArray(answers) &&
    any(
      (answer: AnswerDefinition) =>
        answer.quickResponse?.repeatAnswers ?? false,
      answers
    )
  );
}
