import React, { ReactElement, useState } from "react";

import FlatButton from "../../components/buttons/FlatButton";
import MessageStatic from "../../features/slateMessageEditor/MessageStatic";
import MessageFooter from "./MessageFooter";
import styles from "./Notes.module.css";

export default function Notes({
  notes,
  direction,
}: {
  notes: string;
  direction: "received" | "sent";
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MessageFooter
      direction={direction}
      body={
        isOpen && (
          <div className={styles.viewer}>
            <MessageStatic value={notes} />
          </div>
        )
      }
      actions={
        <FlatButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "Close Notes" : "View Notes"}
        </FlatButton>
      }
    />
  );
}
