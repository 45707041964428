import React, { ReactElement, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import MessageEditor, {
  MessageEditorCtrl,
} from "../../../slateMessageEditor/MessageEditor";
import MessageStatic from "../../../slateMessageEditor/MessageStatic";
import { selectFlowId } from "../../flowEditorSlice";
import { MessageEditorProps } from "../MessageEditor";
import { usePromptEditorState } from "../PromptEditor";
import styles from "./MarkdownMessage.module.css";

export default function MarkdownMessage({
  message,
  placeholder,
  onChange,
  onKeyDown,
  control,
}: MessageEditorProps): ReactElement {
  const prompt = usePromptEditorState();
  const flowId = useSelector(selectFlowId);

  const editorControl = useRef<MessageEditorCtrl>();
  const isFocusPending = useRef(false);

  useEffect(() => {
    control?.({
      focus: () => {
        if (editorControl.current) {
          editorControl.current.focus();
        } else {
          isFocusPending.current = true;
        }
      },
    });
  }, [control]);

  useEffect(() => {
    if (isFocusPending.current) {
      editorControl.current?.focus();
      isFocusPending.current = false;
    }
  }, [prompt.didEnterView]);

  return (
    <div className={styles.root}>
      {prompt.didEnterView ? (
        <MessageEditor
          value={`${message}`}
          placeholder={placeholder ?? "Enter message..."}
          uploadPathPrefix={`flows/${flowId}/`}
          variablesEnabled
          onChangeDebounced={(value) => {
            if (value !== message) {
              onChange(value);
            }
          }}
          onKeyDown={onKeyDown}
          control={(c) => (editorControl.current = c)}
        />
      ) : (
        <MessageStatic value={`${message}`} variablesEnabled />
      )}
    </div>
  );
}
