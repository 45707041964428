import React, { ReactElement } from "react";

import styles from "./HorizontalSeparator.module.css";

export default function HorizontalSeparator({
  text,
}: {
  text?: string;
}): ReactElement {
  return (
    <div className={styles.root}>
      {text != null && <span className={styles.text}>{text}</span>}
    </div>
  );
}
