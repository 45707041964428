import React, { ReactElement } from "react";

import Menu, { MenuProps } from "./menus/Menu";
import Popover from "./Popover";
import styles from "./TopBarMenu.module.css";

export default function TopBarMenu({
  isOpen,
  autoClose,
  closeOnEsc,
  onRequestClose,
  trigger,
  children: menuChildren,
}: {
  isOpen: boolean;
  trigger: ReactElement;
  children: MenuProps["children"];
} & Pick<
  MenuProps,
  "autoClose" | "closeOnEsc" | "onRequestClose"
>): ReactElement {
  return (
    <Popover
      key="more"
      positions={["bottom"]}
      align="end"
      reposition={false}
      isOpen={isOpen}
      onClickOutside={onRequestClose}
      containerClassName={styles.popoverContainer}
      content={
        <Menu
          autoClose={autoClose}
          closeOnEsc={closeOnEsc}
          onRequestClose={onRequestClose}
          className={styles.menu}
        >
          {menuChildren}
        </Menu>
      }
    >
      {trigger}
    </Popover>
  );
}
