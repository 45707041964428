import { LANGUAGES as CHATBOT_LANGUAGES } from "@xflr6/chatbot-engine";
import { pick } from "lodash";

export const DOCS_URL = "https://docs.dialogform.in";

export const DOCS_USER_GUIDE_URL = `${DOCS_URL}/user-guide`;

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+$/;

/**
 * Requires password to have a lowercase character, an uppercase character,
 * a number and a special character.
 */
export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;

/** Taken from validatorjs */
export const URL_PATTERN = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i;

/**
 * Languages supported by DialogForm. Must be a subset of those supported by
 * chatbot.
 */
export const LANGUAGES: Record<string, string> = pick(
  CHATBOT_LANGUAGES,
  "en",
  "hi"
);
