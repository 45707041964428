import {
  AnswerQuickResponse,
  assignTranslation,
  getTranslation,
  JsonPresent,
} from "@xflr6/chatbot";
import { isBlank } from "@xflr6/utils";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { FaRegTrashAlt, FaReplyAll } from "react-icons/fa";
import { MdMoreVert } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import FlatButton from "../../../../components/buttons/FlatButton";
import Menu from "../../../../components/menus/Menu";
import MenuItem from "../../../../components/menus/MenuItem";
import MyTippy from "../../../../components/MyTippy";
import Popover from "../../../../components/Popover";
import useDelayedToggle from "../../../../utils/useDelayedToggle";
import MessageEditor, {
  MessageEditorCtrl,
} from "../../../slateMessageEditor/MessageEditor";
import {
  initPromptQuickResponses,
  selectFlowId,
  updateAnswerFields,
} from "../../flowEditorSlice";
import { usePromptEditorState } from "../PromptEditor";
import TranslationEditor from "../TranslationEditor";
import styles from "./QuickResponseEditor.module.css";

export interface QuickResponseEditorCtrl {
  canFocus: boolean;
  focus: () => void;
}

export interface QuickResponseEditorProps {
  promptIndex: number;
  answerIndex: number;
  quickResponse: AnswerQuickResponse;
  altMessagesMode?: boolean | null;
  control?: (control?: QuickResponseEditorCtrl) => void;
  onActionMenuClosed?: VoidFunction;
}

export default function QuickResponseEditor({
  promptIndex,
  answerIndex,
  quickResponse,
  altMessagesMode,
  control,
  onActionMenuClosed,
}: QuickResponseEditorProps): ReactElement {
  const dispatch = useDispatch();

  const flowId = useSelector(selectFlowId);

  const {
    value: isActionMenuOpen,
    setTrueWithDelay: openActionMenuWithDelay,
    setTrueImmediate: openActionMenu,
    setFalseWithDelay: closeActionMenuWithDelay,
    setFalseImmediate: closeActionMenu_,
    toggleImmediate: toggleActionMenu,
  } = useDelayedToggle(false, {});
  const closeActionMenu = useCallback(() => {
    closeActionMenu_();
    onActionMenuClosed?.();
  }, [closeActionMenu_, onActionMenuClosed]);

  function toggleRepeatAnswersHandler(): void {
    dispatch(
      updateAnswerFields({
        promptIndexOrName: promptIndex,
        answerIndex,
        fields: {
          quickResponse: {
            ...quickResponse,
            repeatAnswers: !quickResponse.repeatAnswers,
          },
        },
      })
    );
  }

  function removeQuickResponseHandler(): void {
    // Need to call manually
    onActionMenuClosed?.();

    dispatch(
      updateAnswerFields({
        promptIndexOrName: promptIndex,
        answerIndex,
        fields: {
          quickResponse: null,
        },
      })
    );
  }

  function updateQuickResponseFields(fields: Partial<AnswerQuickResponse>) {
    dispatch(
      updateAnswerFields({
        promptIndexOrName: promptIndex,
        answerIndex,
        fields: { quickResponse: { ...quickResponse, ...fields } },
      })
    );
  }

  function updateTranslation(
    language: string,
    message: JsonPresent,
    confirmOverwrite?: boolean
  ) {
    const currTranslation = getTranslation(quickResponse.tMessage, language);
    if (
      !confirmOverwrite ||
      isEmpty(currTranslation) ||
      window.confirm("Overwrite translation?")
    ) {
      updateQuickResponseFields({
        tMessage: assignTranslation(quickResponse.tMessage, language, message),
      });
    }
  }

  const editorControl = useRef<MessageEditorCtrl>();

  const registerEditorControl = useCallback(
    (c) => (editorControl.current = c),
    []
  );

  useEffect(() => {
    control?.({
      canFocus: !!!altMessagesMode,
      focus: () => editorControl.current?.focus(),
    });

    return function cleanup() {
      control?.();
    };
  }, [control, altMessagesMode]);

  const prompt = usePromptEditorState();

  return (
    <div className={styles.root}>
      <div className={styles.quickResponseEditor}>
        <div className={styles.dash}>-</div>
        <div className={styles.leftColumn}>
          <MyTippy
            content={
              <ul className={styles.tipContent}>
                <li>
                  <span className={styles.tipContent_label}>FWD</span>: Insert
                  quick response and move ahead
                </li>
                <li>
                  <span className={styles.tipContent_label}>REP</span>: Insert
                  quick response and repeat answers
                </li>
              </ul>
            }
          >
            <FlatButton
              className={classNames(styles.repeatAnswers, {
                [styles.repeatAnswers__on]: quickResponse.repeatAnswers,
              })}
              onClick={toggleRepeatAnswersHandler}
            >
              {quickResponse.repeatAnswers ? "rep" : "fwd"}
            </FlatButton>
          </MyTippy>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.message}>
            <div className={styles.message_left}>
              {altMessagesMode ? (
                <div className={styles.message_placeholder}>Using list</div>
              ) : (
                <>
                  <MessageEditor
                    value={`${quickResponse.message}`}
                    placeholder="Message..."
                    uploadPathPrefix={`flows/${flowId}/`}
                    onChangeDebounced={(value) => {
                      if (value !== quickResponse.message) {
                        updateQuickResponseFields({ message: value });
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.ctrlKey || event.metaKey) {
                        if (event.key === "/") {
                          toggleActionMenu();
                          return true;
                        }
                      }
                      return false;
                    }}
                    control={registerEditorControl}
                  />
                  <TranslationEditor
                    onClickPaste={(language) => {
                      updateTranslation(language, quickResponse.message, true);
                      // Returning `true` forces a remount. Required
                      // because Slate (and hence MessageEditor) does
                      // not re-render automatically when the `value`
                      // prop passed into it is modified from outside.
                      return true;
                    }}
                  >
                    {(language) => (
                      <MessageEditor
                        value={`${
                          getTranslation(quickResponse.tMessage, language) ?? ""
                        }`}
                        placeholder=""
                        onChangeDebounced={(message) =>
                          updateTranslation(language, message)
                        }
                      />
                    )}
                  </TranslationEditor>
                </>
              )}
            </div>
            <div className={styles.message_right}>
              <Popover
                positions={["bottom", "top"]}
                align="end"
                reposition
                isOpen={isActionMenuOpen}
                onClickOutside={closeActionMenu}
                containerClassName={styles.actionMenu}
                content={
                  <Menu
                    autoClose
                    closeOnEsc
                    onRequestClose={closeActionMenu}
                    onPointerEnter={openActionMenu}
                    onPointerLeave={closeActionMenu}
                  >
                    <MenuItem
                      autoClose={false}
                      onClick={removeQuickResponseHandler}
                    >
                      <FaRegTrashAlt />
                      &nbsp;Delete
                    </MenuItem>
                  </Menu>
                }
              >
                <FlatButton
                  className={styles.actionMenuButton}
                  onPointerEnter={openActionMenuWithDelay}
                  onPointerLeave={closeActionMenuWithDelay}
                >
                  <MdMoreVert size={18} />
                </FlatButton>
              </Popover>
            </div>
          </div>
          <div className={styles.bottomBar}>
            {!altMessagesMode &&
              quickResponse.repeatAnswers &&
              isBlank(`${quickResponse.message}`) && (
                <FlatButton
                  className={styles.useListButton}
                  onClick={() => {
                    dispatch(
                      initPromptQuickResponses({
                        promptIndexOrName: promptIndex,
                      })
                    );
                    setTimeout(
                      () => prompt.control.altMessages()?.items[0].focus(),
                      0
                    );
                  }}
                >
                  <FaReplyAll size={14} />
                  <div className={styles.useListButton_label}>Use list</div>
                </FlatButton>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
