import { PromptMessageItem } from "@xflr6/chatbot";
import classNames from "classnames";
import React, { ReactElement, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";

import SplitButton from "../../../components/buttons/SplitButton";
import { insertMessageItem } from "../flowEditorSlice";
import styles from "./InsertMessageItemButton.module.css";
import { useMessagesEditor } from "./MessagesEditor";
import { getSeedMessage } from "./promptConfigEditors/ContentType";

const LS_INSERT_MESSAGE_ITEM_KEY = "insertMessageItem";

export interface InsertMessageItemButtonProps {
  promptIndex: number;
  index: number;
  isAbove?: boolean;
}

export default function InsertMessageItemButton(
  props: InsertMessageItemButtonProps
): ReactElement {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem(LS_INSERT_MESSAGE_ITEM_KEY)
  );

  const messagesEditor = useMessagesEditor();

  function insertMessageItemHandler(messageItem: PromptMessageItem) {
    dispatch(
      insertMessageItem({
        promptIndexOrName: props.promptIndex,
        messageItem,
        index: props.index,
      })
    );
    setTimeout(() => messagesEditor.items[props.index]?.focus(), 0);
  }

  return (
    <div className={styles.root}>
      <SplitButton
        className={classNames(styles.button, {
          [styles.button__isAbove]: props.isAbove,
        })}
        partClassName={styles.buttonPart}
        options={[
          { value: null, label: "Markdown" },
          { value: "video", label: "Video" },
          { value: "remote", label: "API" },
          { value: "meeting", label: "Meeting " },
        ]}
        renderSelectedOption={(option) => (
          <div className={styles.selectedOption}>
            <FaPlus size={12} />
            <div className={styles.optionLabel}>{option.label}</div>
          </div>
        )}
        selectedValue={selectedValue}
        onClick={(value) => {
          setSelectedValue(value);
          if (value != null) {
            localStorage.setItem(LS_INSERT_MESSAGE_ITEM_KEY, value);
          } else {
            localStorage.removeItem(LS_INSERT_MESSAGE_ITEM_KEY);
          }
          insertMessageItemHandler({
            message: getSeedMessage(value),
            contentType: value,
            repeatAnswers: true,
          });
        }}
      />
    </div>
  );
}
