import React, { ReactElement } from "react";

import { ReactComponent as IntegrationIcon } from "../../assets/integrationIcon.svg";
import LoadingLayout, {
  LoadingLayoutHeaderIcon,
} from "../../components/LoadingLayout";
import PageContents from "./PageContents";

export default function MainPage(): ReactElement {
  return (
    <PageContents>
      <LoadingLayout
        header={
          <LoadingLayoutHeaderIcon>
            <IntegrationIcon />
          </LoadingLayoutHeaderIcon>
        }
        message="Integrates DialogForm with Thinkific"
      />
    </PageContents>
  );
}
