import {
  getTranslation,
  JsonPresent,
  PromptDefinition,
  Translations,
} from "@xflr6/chatbot";
import { isEmpty } from "lodash";
import React, { KeyboardEvent, ReactElement } from "react";

import MarkdownMessage from "./messageEditors/MarkdownMessage";
import MeetingMessage from "./messageEditors/MeetingMessage";
import RemoteMessage from "./messageEditors/remoteMessage";
import VideoMessage from "./messageEditors/VideoMessage";
import TranslationEditor from "./TranslationEditor";

export interface MessageEditorCtrl {
  focus: () => void;
}
export interface MessageEditorProps {
  message: PromptDefinition["message"];
  placeholder?: string | null;
  onChange: (message: PromptDefinition["message"]) => void;
  onKeyDown?: ((event: KeyboardEvent) => boolean) | null;
  control?: (control: MessageEditorCtrl) => void;
}

export interface MessageEditorPropsWithI18n extends MessageEditorProps {
  translations?: Translations | null;
  onTranslationChange?: (lang: string, message: JsonPresent | null) => void;
}

export default function MessageEditor({
  contentType,
  translations,
  onTranslationChange,
  ...props
}: MessageEditorPropsWithI18n & {
  contentType?: string | null;
}): ReactElement {
  switch (contentType) {
    case "meeting":
      return <MeetingMessage {...props} />;
    case "remote":
      return (
        <RemoteMessage
          {...props}
          translations={translations}
          onTranslationChange={onTranslationChange}
        />
      );
    case "video":
      return (
        <div>
          <VideoMessage {...props} />
          <TranslationEditor>
            {(language) => (
              <VideoMessage
                message={getTranslation(translations, language) ?? ""}
                placeholder=""
                onChange={(v) => onTranslationChange?.(language, v ?? null)}
              />
            )}
          </TranslationEditor>
        </div>
      );
    default:
      return (
        <div>
          <MarkdownMessage {...props} />
          <TranslationEditor
            onClickPaste={
              onTranslationChange
                ? (language) => {
                    const currTranslation = getTranslation(
                      translations,
                      language
                    );
                    if (
                      isEmpty(currTranslation) ||
                      window.confirm("Overwrite translation?")
                    ) {
                      onTranslationChange(language, props.message ?? null);
                      // Returning `true` forces a remount. Required
                      // because Slate (and hence MessageEditor) does
                      // not re-render automatically when the `value`
                      // prop passed into it is modified from outside.
                      return true;
                    }
                    return false;
                  }
                : undefined
            }
          >
            {(language) => (
              <MarkdownMessage
                message={getTranslation(translations, language) ?? ""}
                placeholder=""
                onChange={(v) => onTranslationChange?.(language, v ?? null)}
              />
            )}
          </TranslationEditor>
        </div>
      );
  }
}
