import { PromptDefinition } from "@xflr6/chatbot";

export function promptHasAnswersWithScore(
  definition: PromptDefinition
): boolean {
  return (
    Array.isArray(definition.answers) &&
    definition.answers.some((answer) => answer.score != null)
  );
}
