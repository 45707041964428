import * as api from "@xflr6/chatbot-api";
import { equals } from "ramda";
import React, { ReactElement, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../../components/buttons/FilledButton";
import FlatButton from "../../../components/buttons/FlatButton";
import Modal from "../../../components/Modal";
import { DOCS_USER_GUIDE_URL } from "../../../utils/constants";
import { IntegrationConfig } from "../IntegrationConfigs";
import { deleteIntegration, selectIntegrations } from "../integrationsSlice";
import AddPlatformButton, { getAuthMethodOption } from "./AddPlatformButton";
import styles from "./LtiConfig.module.css";

const DocumentationLink = () => (
  <a
    href={`${DOCS_USER_GUIDE_URL}/using-dialogform-as-an-lti-tool`}
    target="_blank"
    rel="noopener noreferrer"
  >
    See documentation
  </a>
);

function PlatformDetailsModal({
  platform,
  onRequestClose,
}: {
  platform: api.PlatformInfo;
  onRequestClose: () => void;
}) {
  return (
    <Modal
      title={platform.config.name}
      style={{
        content: {
          width: "600px",
          maxWidth: "calc(100% - var(--spacingDouble))",
          maxHeight: "calc(100% - var(--spacingDouble))",
        },
      }}
      ariaHideApp={false}
      isOpen
      onRequestClose={onRequestClose}
    >
      <div className={styles.platformDetails}>
        <label className={styles.infoLabel}>URL</label>
        <div className={styles.infoValue}>{platform.config.url}</div>
        <label className={styles.infoLabel}>Client ID</label>
        <div className={styles.infoValue}>{platform.config.clientId}</div>
        <label className={styles.infoLabel}>Authentication URL</label>
        <div className={styles.infoValue}>{platform.config.authUrl}</div>
        <label className={styles.infoLabel}>Authentication method</label>
        <div className={styles.infoValue}>
          {getAuthMethodOption(platform.config.authConfigMethod)?.label}
        </div>
        <label className={styles.infoLabel}>Authentication key</label>
        <div className={styles.infoValue}>{platform.config.authConfigKey}</div>
        <label className={styles.infoLabel}>Access token URL</label>
        <div className={styles.infoValue}>{platform.config.accessTokenUrl}</div>
      </div>
    </Modal>
  );
}

function EditForm({
  integration,
}: {
  integration: api.Integration;
}): ReactElement {
  const dispatch = useDispatch();

  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const platforms = integration.config.platforms as api.PlatformInfo[];

  const [detailsIndex, setDetailsIndex] = useState(-1);

  return (
    <>
      {detailsIndex > -1 && (
        <PlatformDetailsModal
          platform={platforms[detailsIndex]}
          onRequestClose={() => setDetailsIndex(-1)}
        />
      )}
      <div className={styles.items}>
        {platforms.map((platform, index) => (
          <div key={platform.config.clientId} className={styles.item}>
            <div className={styles.item_info}>
              <div className={styles.item_info_title}>
                {platform.config.name}
                <FlatButton
                  className={styles.item_info_title_button}
                  onClick={() => setDetailsIndex(index)}
                >
                  <MdOutlineInfo />
                </FlatButton>
              </div>
              <div className={styles.item_info_grid}>
                <label className={styles.infoLabel}>URL</label>
                <div className={styles.infoValue}>{platform.config.url}</div>
                <label className={styles.infoLabel}>Client ID</label>
                <div className={styles.infoValue}>
                  {platform.config.clientId}
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <FilledButton
                onClick={() => {
                  if (index !== null) {
                    const confirmMessage =
                      "Any DialogForm content you are using on this" +
                      " platform will stop working." +
                      " Do you want to go ahead?";

                    if (window.confirm(confirmMessage)) {
                      dispatch(deleteIntegration({ type: "lti", index }));
                    }
                  }
                }}
                disabled={updating === "pending"}
                isLoading={
                  updating === "pending" &&
                  equals(lastUpdateAction, ["lti", "delete"])
                }
              >
                <FaRegTrashAlt size={14} />
              </FilledButton>
            </div>
          </div>
        ))}
      </div>
      <AddPlatformButton />
    </>
  );
}

export default function LtiConfig(): ReactElement {
  return (
    <IntegrationConfig
      type="lti"
      renderAddForm={() => (
        <>
          <p>
            Integrate with your LTI Advantage compatible platforms (such as
            Moodle or Canvas) to include DialogForm content in your online
            courses.
          </p>
          <p>
            <DocumentationLink />
          </p>
          <AddPlatformButton />
        </>
      )}
      renderEditForm={(integration) => <EditForm integration={integration} />}
      renderRemoveButton={() => null}
    />
  );
}
