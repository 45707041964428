import axios from "axios";
import jwtDecode from "jwt-decode";

import { Settings, SettingsPatch } from "./sharedTypes";
import { baseUrl } from "./utils";

const LS_CURRENT_USER_KEY = "currentUser";

export interface User {
  email: string;
  name: string;
  jwt: string;
}

export interface UserDetails {
  id: number;
  email: string;
  name: string;
  createdAt: string;
  settings: SettingsPatch;
  defaultSettings: Settings;
}

export interface Plan {
  id: number;
  code: string;
  name: string;
  description: string;
  durationUnit: string;
  durationCount: string;
  priceInPaisa: number;
  chatLimit: number;
}

export interface CurrentPlan {
  plan: Plan;
  usage: number;
}

export type UserUpdateAttrs = Partial<Pick<UserDetails, "name" | "settings">>;

export function getUserId(user: User): string {
  return jwtDecode<{ userId: string }>(user.jwt).userId;
}

export function getCurrentUserFromLocalStorage(): User | null {
  const user = localStorage.getItem(LS_CURRENT_USER_KEY);
  return user == null ? null : JSON.parse(user);
}

export function setCurrentUserInLocalStorage(user: User | null): void {
  if (user == null) {
    localStorage.removeItem(LS_CURRENT_USER_KEY);
  } else {
    localStorage.setItem(LS_CURRENT_USER_KEY, JSON.stringify(user));
  }
}

export function buildAuthHeader(): { Authorization?: string } {
  const user = getCurrentUserFromLocalStorage();
  return user == null ? {} : { Authorization: `Bearer ${user.jwt}` };
}

export async function signUpWithEmailAndPassword(
  email: string,
  password: string,
  name?: string | null
): Promise<User> {
  const response = await axios.post(`${baseUrl}/auth/signup`, {
    email,
    password,
    ...(name != null ? { name } : {}),
  });
  return response.data;
}

export async function loginWithEmailAndPassword(
  email: string,
  password: string
): Promise<User> {
  const response = await axios.post(`${baseUrl}/auth/login`, {
    email,
    password,
  });
  return response.data;
}

export async function requestPasswordResetLink(email: string): Promise<void> {
  const response = await axios.post(`${baseUrl}/auth/forgot_password`, {
    email,
  });
  return response.data;
}

export async function resetPassword(
  token: string,
  newPassword: string
): Promise<void> {
  const response = await axios.post(`${baseUrl}/auth/reset_password`, {
    token,
    newPassword,
  });
  return response.data;
}

export async function loginWithGoogle(idToken: string): Promise<User> {
  const response = await axios.post(`${baseUrl}/auth/google`, {
    idToken,
  });
  return response.data;
}

export async function updateProfile(attrs: UserUpdateAttrs): Promise<void> {
  const response = await axios.put(`${baseUrl}/auth/profile`, attrs, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}

export async function getCurrentPlan(): Promise<CurrentPlan> {
  const response = await axios.get(`${baseUrl}/auth/plan`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}

export async function getUserDetails(): Promise<UserDetails> {
  const response = await axios.get(`${baseUrl}/auth`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}
