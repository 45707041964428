import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";

import AccessManagerBase from "../../featuresCommon/AccessManager";

export default function AccessManager({
  flowId: id,
}: {
  flowId: number;
}): ReactElement | null {
  return (
    <AccessManagerBase
      load={() => api.getFlowSharedWith(id)}
      share={(email) => api.shareFlow(id, email)}
      unshare={(email) => api.unshareFlow(id, email)}
    />
  );
}
