import { OrdChatFlowDefinition, PromptMessageItem } from "@xflr6/chatbot";

import { MCFlowEditorError } from "../../errors";

/**
 * Removes a message from a prompt's messages in the chat flow definition.
 * @param promptIndex
 * @param index
 * @param flowDef
 * @returns the message item removed
 */
export function removeMessageItem(
  promptIndex: number,
  index: number,
  flowDef: OrdChatFlowDefinition
): PromptMessageItem {
  const { definition: promptDef } = flowDef.promptsArray[promptIndex];
  const messages = promptDef.messages;
  if (messages == null) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has no \`messages\` array present`
    );
  } else if (messages.length < 2) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has at most 1 message, cannot remove`
    );
  }
  const targetMessage = messages[index];
  messages.splice(index, 1);

  return targetMessage;
}
