import React, { ReactElement } from "react";

import styles from "./FixedLoader.module.css";
import LoadingLayout from "./LoadingLayout";

export default function FixedLoader({
  message,
}: {
  message?: string | null;
}): ReactElement {
  return (
    <div className={styles.root}>
      <LoadingLayout footer={<div>{message}</div>} />
    </div>
  );
}
