import React, { ReactElement } from "react";

import Input from "../../../../components/forms/Input";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";
import styles from "./MinNextPromptDelay.module.css";

export default function MinNextPromptDelay({
  placeholder,
}: {
  placeholder?: number | null;
}): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  return (
    <div className={styles.root}>
      <Input
        styleVariant="outlined"
        className={styles.input}
        type="number"
        value={prompt.definition.minNextPromptDelayMs ?? ""}
        placeholder={placeholder ? `${placeholder}` : undefined}
        onBlurValue={(value) => {
          let newDelay: number | null = parseInt(value, 10);
          if (isNaN(newDelay)) newDelay = null;
          if (newDelay !== prompt.definition.minNextPromptDelayMs) {
            updateFields({ minNextPromptDelayMs: newDelay });
          }
        }}
        blurOnEnterOrEsc
      />
      <div className={styles.units}>milliseconds</div>
    </div>
  );
}
