import {
  getImageIntrinsicAspectRatio,
  getScaledImageHeight,
  getScaledImageWidth,
} from "@xflr6/chatbot";
import React, { ReactElement, useContext } from "react";
import { Element, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";

import Input from "../../../components/forms/Input";
import { MessageEditorContext } from "../MessageEditor";
import { ImageProps } from ".";
import styles from "./ImageSizeInput.module.css";

export interface ImageSizeInputProps {
  dimension: "width" | "height";
  element: Element;
}

export default function ImageSizeInput(
  props: ImageSizeInputProps
): ReactElement {
  const editor = useSlateStatic();

  const messageEditorContext = useContext(MessageEditorContext);

  const imageProps = props.element.imageProps as ImageProps | null | undefined;

  function getValue(): string {
    return `${imageProps?.[props.dimension] ?? ""}`;
  }

  function getPlaceholderValue(): string {
    if (
      imageProps?.intrinsicWidth != null &&
      imageProps?.intrinsicHeight != null
    ) {
      const aspect = getImageIntrinsicAspectRatio(
        imageProps.intrinsicWidth,
        imageProps.intrinsicHeight
      );
      if (aspect != null) {
        if (props.dimension === "width" && imageProps.height != null) {
          return `${Math.round(
            getScaledImageWidth(imageProps.height, aspect)
          )}`;
        } else if (props.dimension === "height" && imageProps.width != null) {
          return `${Math.round(
            getScaledImageHeight(imageProps.width, aspect)
          )}`;
        }
      }
    }
    return "auto";
  }

  return (
    <div className={styles.root}>
      <Input
        styleVariant="outlined"
        className={styles.input}
        type="number"
        placeholder={getPlaceholderValue()}
        value={getValue()}
        onFocus={() => messageEditorContext.setIsInAtomicEdit(true)}
        blurOnEnterOrEsc
        onBlur={() => messageEditorContext.setIsInAtomicEdit(false)}
        onBlurValue={(value) => {
          const valueAsNumber = parseInt(value, 10);
          Transforms.setNodes(
            editor,
            {
              imageProps: {
                ...imageProps,
                [`${props.dimension}`]: isNaN(valueAsNumber)
                  ? undefined
                  : valueAsNumber,
              },
            },
            { at: ReactEditor.findPath(editor, props.element) }
          );
        }}
        leading={
          <div style={{ fontWeight: "bold" }}>
            {props.dimension[0].toUpperCase()}
          </div>
        }
      />
    </div>
  );
}
