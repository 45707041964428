import axios from "axios";
import classNames from "classnames";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { BsChatQuoteFill } from "react-icons/bs";
import useScript from "react-script-hook";

import FilledButton from "../../components/buttons/FilledButton";
import formStyles from "../../components/forms/formStyles.module.css";
import Modal from "../../components/Modal";
import Tabs from "../../components/Tabs";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import {
  buildSettings as buildFlowSettings,
  EmbedConfig as FlowEmbedConfig,
} from "../../features/flowEditor/EmbedForm";
import FlowItem from "../../features/flowList/FlowItem";
import FlowListView from "../../features/flowList/FlowListView";
import {
  buildSettings as buildFlowSeqSettings,
  EmbedConfig as FlowSeqEmbedConfig,
} from "../../features/flowSequenceEditor/EmbedForm";
import FlowSequenceItem from "../../features/flowSequenceList/FlowSequenceItem";
import FlowSequenceListView from "../../features/flowSequenceList/FlowSequenceListView";
import useUrlSearchParams from "../../utils/useUrlSearchParams";
import styles from "./LtiDeepLinkPage.module.css";

const FLOWS = "Flows";
const FLOW_SEQUENCES = "Sequences";
const TABS = [FLOWS, FLOW_SEQUENCES];

function SettingsModal({
  resource,
  onOk,
  onCancel,
}: {
  resource: Resource;
  onOk: (settings: string | null) => void;
  onCancel: () => void;
}) {
  const { control: flowControl } = useForm();
  const { control: flowSeqControl } = useForm();

  return (
    <Modal
      title={resource.name}
      style={{
        content: {
          width: "450px",
          maxWidth: "calc(100% - var(--spacingDouble))",
          maxHeight: "calc(100% - var(--spacingDouble))",
        },
      }}
      ariaHideApp={false}
      isOpen
      onRequestClose={() => onCancel()}
    >
      {resource.type === "chat" ? (
        <FlowEmbedConfig
          formControl={flowControl}
          excludedFields={new Set(["height", "width", "identifier"])}
        />
      ) : (
        <FlowSeqEmbedConfig
          formControl={flowSeqControl}
          excludedFields={new Set(["height", "width", "identifier"])}
        />
      )}
      <div className={classNames(formStyles.actions, styles.formActions)}>
        <FilledButton
          onClick={() => {
            const settings =
              resource.type === "chat"
                ? buildFlowSettings(flowControl.getValues())
                : buildFlowSeqSettings(flowSeqControl.getValues());
            onOk(settings);
          }}
        >
          Proceed
        </FilledButton>
        <FilledButton onClick={() => onCancel()}>Cancel</FilledButton>
      </div>
    </Modal>
  );
}

interface Resource {
  name: string;
  id: number;
  type: "chat" | "chat_sequence";
  settings?: string;
}

export default function LtiDeepLinkPage(): ReactElement {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("ltiDeepLinkTab") ?? TABS[0]
  );

  useEffect(() => {
    localStorage.setItem("ltiDeepLinkTab", activeTab);
  }, [activeTab]);

  const [jQueryLoading, jQueryLoadError] = useScript({
    src: "https://code.jquery.com/jquery-3.6.0.min.js",
    checkForExisting: true,
    async: true,
    defer: true,
  });

  const $ = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const $ = (window as any).jQuery;
    if (!$) {
      throw new Error("jQuery not present");
    }
    return $;
  };

  const [selectedResource, setSelectedResource] = useState<Resource>();

  const urlSearchParams = useUrlSearchParams();
  const submit = useMemo(
    () => async (resource: Resource) => {
      const ltik = urlSearchParams.get("ltik");
      const form = await axios.post(
        `${process.env.REACT_APP_LTI_DEEPLINK_URL}`,
        resource,
        {
          withCredentials: true,
          headers: { Authorization: "Bearer " + ltik },
        }
      );
      $()("body").append(form.data);
    },
    [urlSearchParams]
  );

  if (jQueryLoading) {
    return <div>Loading...</div>;
  }
  if (jQueryLoadError) {
    return <div>Load error</div>;
  }

  return (
    <>
      {selectedResource != null && (
        <SettingsModal
          resource={selectedResource}
          onOk={(settings) => {
            submit({ ...selectedResource, ...(settings ? { settings } : {}) });
          }}
          onCancel={() => setSelectedResource(undefined)}
        />
      )}
      <div className={styles.root}>
        <TopBar
          leading={<BsChatQuoteFill className={styles.logo} />}
          title="DialogForm"
          center={
            <Tabs
              isInTopBar
              className={styles.tabs}
              tabs={TABS}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
          actions={[<ProfileButton key="login" />]}
        />
        <div className={styles.body}>
          {activeTab === FLOWS ? (
            <FlowListView
              className={styles.list}
              renderItem={(flow) => (
                <FlowItem
                  key={flow.id}
                  flow={flow}
                  renderAction={() => (
                    <FilledButton
                      className={styles.itemAction}
                      onClick={() =>
                        setSelectedResource({
                          name: flow.name,
                          id: flow.id,
                          type: "chat",
                        })
                      }
                    >
                      Select
                    </FilledButton>
                  )}
                />
              )}
            />
          ) : activeTab === FLOW_SEQUENCES ? (
            <FlowSequenceListView
              className={styles.list}
              renderItem={(seq) => (
                <FlowSequenceItem
                  key={seq.id}
                  flowSequence={seq}
                  renderAction={() => (
                    <FilledButton
                      className={styles.itemAction}
                      onClick={() =>
                        setSelectedResource({
                          name: seq.name,
                          id: seq.id,
                          type: "chat_sequence",
                        })
                      }
                    >
                      Select
                    </FilledButton>
                  )}
                />
              )}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
