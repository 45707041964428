import { OrdChatFlowDefinition, PromptMessageItem } from "@xflr6/chatbot";
import { clone } from "ramda";
import { v4 } from "uuid";

import { MCFlowEditorError } from "../../errors";
import { WithKey } from "./types";

/**
 * Inserts a message into a prompt's messages in the chat flow definition.
 * @param promptIndex
 * @param messageItem
 * @param index - If null, the message is added at the very end of the messages
 *   array in the prompt definition.
 * @param flowDef
 */
export function insertMessageItem(
  promptIndex: number,
  messageItem: PromptMessageItem,
  index: number | null,
  flowDef: OrdChatFlowDefinition
): void {
  const { definition: promptDef } = flowDef.promptsArray[promptIndex];
  if (promptDef.messages == null) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has no \`messages\` array present`
    );
  }

  const effectiveMessageItem = clone(messageItem);
  (effectiveMessageItem as WithKey<PromptMessageItem>).__key = v4();
  if (index == null) {
    promptDef.messages.push(effectiveMessageItem);
  } else if (index > promptDef.messages.length) {
    throw new MCFlowEditorError(
      `Message index ${index} out of bounds for prompt at index ${promptIndex}`
    );
  } else {
    promptDef.messages.splice(index, 0, effectiveMessageItem);
  }
}
