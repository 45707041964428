import React, { ReactElement, ReactNode } from "react";

import styles from "./ButtonGroup.module.css";

export interface ButtonGroupProps {
  children: ReactNode;
}

export default function ButtonGroup(props: ButtonGroupProps): ReactElement {
  return <div className={styles.root}>{props.children}</div>;
}
