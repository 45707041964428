import { createSelector } from "@reduxjs/toolkit";
import type { Ownership, SettingsPatch } from "@xflr6/chatbot-api";

import { AuthState, selectAuth } from "../../features/auth/authSlice";
import {
  selectFlowOwnership,
  selectFlowSettings,
  selectFlowUserSettings,
} from "../../features/flowEditor/flowEditorSlice";
import {
  selectFlowSequenceOwnership,
  selectFlowSequenceSettings,
  selectFlowSequenceUserSettings,
} from "../../features/flowSequenceEditor/flowSequenceEditorSlice";
import {
  selectResponseFlowSequenceSettings,
  selectResponseFlowSequenceUserSettings,
  selectStatsOwnership as selectSequenceStatsOwnership,
} from "../../features/flowSequenceStats/flowSequenceStatsSlice";
import {
  selectResponseFlowSettings,
  selectResponseFlowUserSettings,
  selectStatsOwnership,
} from "../../features/flowStats/flowStatsSlice";

const effectiveUserSettings = (
  auth: AuthState,
  userSettings: SettingsPatch | null,
  ownership: Ownership | null
): SettingsPatch | null => {
  return ownership === "own" ? auth.settings : userSettings;
};

const mergeSettings = (
  settings: SettingsPatch | null,
  auth: AuthState,
  userSettings: SettingsPatch | null,
  ownership: Ownership | null
) => {
  if (auth.defaultSettings == null) return null;
  return {
    ...auth.defaultSettings,
    ...effectiveUserSettings(auth, userSettings, ownership),
    ...settings,
  };
};

export const selectEffectiveFlowUserSettings = createSelector(
  [selectAuth, selectFlowUserSettings, selectFlowOwnership],
  effectiveUserSettings
);

export const selectEffectiveFlowSequenceUserSettings = createSelector(
  [selectAuth, selectFlowSequenceUserSettings, selectFlowSequenceOwnership],
  effectiveUserSettings
);

export const selectEffectiveFlowSettings = createSelector(
  [selectFlowSettings, selectAuth, selectFlowUserSettings, selectFlowOwnership],
  mergeSettings
);

export const selectEffectiveFlowSequenceSettings = createSelector(
  [
    selectFlowSequenceSettings,
    selectAuth,
    selectFlowSequenceUserSettings,
    selectFlowSequenceOwnership,
  ],
  mergeSettings
);

export const selectEffectiveResponseFlowSettings = createSelector(
  [
    selectResponseFlowSettings,
    selectAuth,
    selectResponseFlowUserSettings,
    selectStatsOwnership,
  ],
  mergeSettings
);

export const selectEffectiveResponseFlowSequenceSettings = createSelector(
  [
    selectResponseFlowSequenceSettings,
    selectAuth,
    selectResponseFlowSequenceUserSettings,
    selectSequenceStatsOwnership,
  ],
  mergeSettings
);
