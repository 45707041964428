import { useFeatureFlag } from "configcat-react";
import { omit, without } from "lodash";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select, { components, MenuProps, OptionProps } from "react-select";

import { useAppDispatch } from "../../../app/store";
import FilledButton from "../../../components/buttons/FilledButton";
import FlatButton from "../../../components/buttons/FlatButton";
import Modal, { ModalProps } from "../../../components/Modal";
import TranslateIcon from "../../../featuresCommon/TranslateIcon";
import { LANGUAGES } from "../../../utils/constants";
import {
  selectCurrentLanguage,
  selectFlowLanguage,
  selectFlowLanguages,
  setCurrentLanguage,
  updateFlow,
} from "../flowEditorSlice";
import styles from "./LanguageSelect.module.css";

type Option = { value: string | null; label: string };

function AddLanguageModal({
  isOpen,
  onRequestClose,
}: Pick<ModalProps, "isOpen" | "onRequestClose">) {
  const dispatch = useAppDispatch();
  const language = useSelector(selectFlowLanguage);
  const languages = useSelector(selectFlowLanguages);

  if (language == null || languages == null) return null;

  const remainingLanguages = Object.keys(
    omit(LANGUAGES, language, ...languages)
  );

  return (
    <Modal
      title="Add Language"
      style={{
        content: {
          width: "480px",
          maxWidth: "calc(100% - var(--spacingDouble))",
          maxHeight: "calc(100% - var(--spacingDouble))",
        },
      }}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      noBodySpacing
    >
      {remainingLanguages.length ? (
        remainingLanguages.map((language) => (
          <div key={language} className={styles.languageItem}>
            {LANGUAGES[language]}
            <FilledButton
              onClick={async (event) => {
                await dispatch(
                  updateFlow({ languages: [...languages, language] })
                );
                dispatch(setCurrentLanguage(language));
                onRequestClose?.(event);
              }}
            >
              Add
            </FilledButton>
          </div>
        ))
      ) : (
        <div className={styles.noLanguagesHint}>
          No more languages available
        </div>
      )}
    </Modal>
  );
}

function Menu(props: MenuProps<Option, false>) {
  return (
    <components.Menu {...props}>
      <>
        {props.children}
        {!props.selectProps.isReadonly && (
          <div className={styles.menuBottomBar}>
            <FlatButton
              isFullWidth
              onClick={() => {
                props.selectProps.onMenuActionClick?.();
              }}
            >
              <FaPlus size={14} />
              &nbsp;Add language
            </FlatButton>
          </div>
        )}
      </>
    </components.Menu>
  );
}

function Option(props: OptionProps<Option, false>) {
  return (
    <components.Option {...props}>
      <div className={styles.option}>
        {props.children}
        {!props.selectProps.isReadonly && props.data.value != null && (
          <FlatButton
            onClick={() => {
              props.selectProps.onOptionActionClick?.(props.data);
            }}
          >
            <FaRegTrashAlt size={14} />
          </FlatButton>
        )}
      </div>
    </components.Option>
  );
}

export default function LanguageSelect({
  isReadonly,
}: {
  isReadonly?: boolean;
}): ReactElement | null {
  const dispatch = useAppDispatch();
  const language = useSelector(selectFlowLanguage);
  const languages = useSelector(selectFlowLanguages);
  const currentLanguage = useSelector(selectCurrentLanguage);

  useEffect(() => {
    if (currentLanguage && !languages?.includes(currentLanguage)) {
      dispatch(setCurrentLanguage(null));
    }
  }, [currentLanguage, languages, dispatch]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const options = useMemo(
    () => [
      { value: null, label: LANGUAGES[language ?? ""] ?? "Default" },
      ...(languages ?? []).map((code) => ({
        value: code,
        label: LANGUAGES[code],
      })),
    ],
    [language, languages]
  );

  const selectedOption = useMemo(
    () => options.find((o) => o.value === currentLanguage),
    [options, currentLanguage]
  );

  const { value: isI18nEnabled } = useFeatureFlag("isI18nEnabled", false);
  const shouldShowSelect = isI18nEnabled || languages?.length;
  if (!shouldShowSelect) return null;

  return (
    <div className={styles.root}>
      <AddLanguageModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
      <TranslateIcon language={currentLanguage} />
      <Select
        styles={{
          container: (styles) => ({
            ...styles,
            marginLeft: "var(--spacingHalf)",
          }),
          control: (styles) => ({
            ...styles,
            minWidth: 200,
            minHeight: 24,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 4,
            paddingRight: 4,
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            marginTop: 4,
            marginBottom: 4,
          }),
          input: (styles) => ({
            ...styles,
            margin: 0,
            padding: 0,
          }),
        }}
        components={{
          Menu,
          Option,
        }}
        options={options}
        value={selectedOption}
        onChange={(selected) => {
          dispatch(setCurrentLanguage(selected?.value ?? null));
        }}
        // All props below this line are custom props and have no special
        // meaning for react-select.
        isReadonly={isReadonly}
        onMenuActionClick={() => setIsModalOpen(true)}
        onOptionActionClick={(option) => {
          if (option.value != null) {
            dispatch(
              updateFlow({ languages: without(languages, option.value) })
            );
          }
        }}
        getOptionLabel={(option) =>
          option.value == null ? `${option.label} (Default)` : option.label
        }
      />
    </div>
  );
}
