import { OrdChatFlowDefinition } from "@xflr6/chatbot";
import axios from "axios";

import { buildAuthHeader } from "../auth";
import { FlowSequenceStub } from "../flowSequences";
import {
  OwnerDetails,
  Ownership,
  OwnershipFilter,
  SettingsPatch,
  UserAccess,
} from "../sharedTypes";
import { baseUrl } from "../utils";

export interface Flow {
  id: number;
  uid: string;
  name: string;
  tName: Record<string, string>;
  language: string;
  languages: string[];
  definition: OrdChatFlowDefinition;
  flowSequence?: FlowSequenceStub | null;
  settings: SettingsPatch;
  // Flows endpoint only
  isDirty?: boolean;
  ownership: Ownership;
  user: OwnerDetails;
  userSettings: SettingsPatch;
  // Managed flows endpoint only
  version?: number;
  publishedAt?: string;
}

export interface FlowStub {
  id: number;
  name: string;
  tName?: Record<string, string>;
  isDirty: boolean;
  user: OwnerDetails;
}

export interface Flows {
  flows: FlowStub[];
  page: number;
  totalPages: number;
  totalRecords: number;
}

export async function listFlows(options: {
  page?: number;
  all?: boolean;
  ownership?: OwnershipFilter;
  q?: string;
}): Promise<Flows> {
  const response = await axios.get(`${baseUrl}/flows`, {
    headers: { ...buildAuthHeader() },
    params: {
      ...(options.page ? { page: options.page.toString() } : {}),
      ...(options.all ? { all: "1" } : {}),
      ...(options.ownership ? { ownership: options.ownership } : {}),
      ...(options.q?.trim() ? { q: options.q } : {}),
    },
  });
  return response.data;
}

export async function getFlow(id: number): Promise<Flow> {
  const response = await axios.get(`${baseUrl}/flows/${id}`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}

export async function createFlow(
  name: string,
  definition: OrdChatFlowDefinition,
  language = "en"
): Promise<number> {
  const response = await axios.post(
    `${baseUrl}/flows`,
    {
      name,
      language,
      definition,
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function cloneFlow(id: number): Promise<number> {
  const response = await axios.post(
    `${baseUrl}/flows/${id}/clone`,
    {},
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export type FlowUpdateFields = Partial<
  Pick<Flow, "name" | "tName" | "definition" | "languages" | "settings">
>;

export async function updateFlow(
  id: number,
  fields: FlowUpdateFields
): Promise<void> {
  await axios.put(`${baseUrl}/flows/${id}`, fields, {
    headers: { ...buildAuthHeader() },
  });
}

export async function publishFlow(id: number): Promise<void> {
  await axios.post(`${baseUrl}/flows/${id}/publish`, null, {
    headers: { ...buildAuthHeader() },
  });
}

export async function promoteFlowVersion(
  id: number,
  version: number
): Promise<void> {
  await axios.post(
    `${baseUrl}/flows/${id}/published/${version}/promote`,
    null,
    {
      headers: { ...buildAuthHeader() },
    }
  );
}

export async function getFlowSharedWith(id: number): Promise<UserAccess[]> {
  const response = await axios.get(`${baseUrl}/flows/${id}/sharedWith`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}

/**
 * Returns the name of the user added.
 */
export async function shareFlow(
  id: number,
  userEmail: string
): Promise<string> {
  const response = await axios.post(
    `${baseUrl}/flows/${id}/share`,
    { with: userEmail },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function unshareFlow(
  id: number,
  userEmail: string
): Promise<void> {
  await axios.post(
    `${baseUrl}/flows/${id}/unshare`,
    { with: userEmail },
    {
      headers: { ...buildAuthHeader() },
    }
  );
}

export async function getPublishedFlow(
  id: number | string,
  version?: number | string | null,
  language?: string | null,
  keepTranslations?: boolean | null
): Promise<Flow> {
  const response = await axios.get(
    `${baseUrl}/flows/${id}/published/${version ?? ""}`,
    {
      params: {
        ...(language ? { language } : {}),
        ...(keepTranslations ? { keepTranslations: "true" } : {}),
      },
    }
  );
  return response.data;
}

export async function getFlowIdFromUid(uid: string): Promise<number> {
  const response = await axios.get(`${baseUrl}/flows/uid/${uid}`);
  return response.data;
}

export async function getLatestFlowVersion(flowId: number): Promise<number> {
  const response = await axios.get(`${baseUrl}/flows/${flowId}/latestVersion`);
  return response.data;
}

export * from "./history";
export * from "./stats";
