import classNames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";

import styles from "./WithDirtyDot.module.css";

export function DirtyDot(): ReactElement {
  return <div className={styles.standaloneDirtyDot} />;
}

export interface WithDirtyDotProps {
  isDirty: boolean;
  className?: string;
}

export default function WithDirtyDot(
  props: PropsWithChildren<WithDirtyDotProps>
): ReactElement {
  return (
    <span
      className={classNames(props.className, styles.root, {
        [styles.dirty]: props.isDirty,
      })}
    >
      {props.children}
    </span>
  );
}
