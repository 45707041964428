import "./App.css";

import { ConfigCatProvider, createConsoleLogger } from "configcat-react";
import React, { ReactElement } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { IntercomProvider } from "react-use-intercom";

import store from "./app/store";
import PrivateRoute from "./features/auth/PrivateRoute";
import SetConfigCatUserComponent from "./features/auth/SetConfigCatUserComponent";
import { CloudinaryProvider } from "./features/integrations/cloudinary/CloudinaryProvider";
import Intercom from "./features/intercom/Intercom";
import MessageProvider from "./features/slateMessageEditor/MessageProvider";
import Smartlook from "./features/smartlook/Smartlook";
import { ImageInputProvider } from "./featuresCommon/ImageInputProvider";
import FlowEditPage from "./pages/flowEdit/FlowEditPage";
import FlowSequenceEditPage from "./pages/flowSequenceEdit/FlowSequenceEditPage";
import FlowSequenceStatsPage from "./pages/flowSequenceStats/FlowSequenceStatsPage";
import FlowStatsPage from "./pages/flowStats/FlowStatsPage";
import FlowVersionsPage from "./pages/flowVersions/FlowVersionsPage";
import HomePage from "./pages/home/HomePage";
import IdentifiersPage from "./pages/identifiers/IdentifiersPage";
import IntegrationDataPage from "./pages/integrationData/IntegrationDataPage";
import IntegrationsPage from "./pages/integrations/IntegrationsPage";
import LtiDeepLinkPage from "./pages/ltiDeepLink/LtiDeepLinkPage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import * as thinkificApp from "./pages/thinkificApp";

export default function App(): ReactElement {
  const configCatLogger = createConsoleLogger(
    process.env.NODE_ENV === "production" ? 1 : 3
  );

  return (
    <Provider store={store}>
      <ConfigCatProvider
        sdkKey={process.env.REACT_APP_CONFIGCAT_SDK_KEY!}
        options={{ logger: configCatLogger }}
      >
        <SetConfigCatUserComponent />
        <ToastProvider>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID!}>
            <Intercom />
            <MessageProvider>
              <CloudinaryProvider>
                <ImageInputProvider>
                  <Router>
                    <div className="App">
                      {process.env.REACT_APP_SMARTLOOK_PROJECT_KEY && (
                        <Smartlook
                          projectKey={
                            process.env.REACT_APP_SMARTLOOK_PROJECT_KEY
                          }
                        />
                      )}
                      <Switch>
                        <PrivateRoute path="/" exact>
                          <HomePage />
                        </PrivateRoute>
                        <PrivateRoute path="/flows/:id/edit">
                          <FlowEditPage />
                        </PrivateRoute>
                        <PrivateRoute path="/flows/:id/stats">
                          <FlowStatsPage />
                        </PrivateRoute>
                        <PrivateRoute path="/flows/:id/versions/:version?">
                          <FlowVersionsPage />
                        </PrivateRoute>
                        <PrivateRoute path="/sequences/:id/edit">
                          <FlowSequenceEditPage />
                        </PrivateRoute>
                        <PrivateRoute path="/sequences/:id/stats">
                          <FlowSequenceStatsPage />
                        </PrivateRoute>
                        <PrivateRoute path="/user_activity">
                          <IdentifiersPage />
                        </PrivateRoute>
                        <Route path="/reset_password/:token">
                          <ResetPasswordPage />
                        </Route>
                        <PrivateRoute path="/integrations" exact>
                          <IntegrationsPage />
                        </PrivateRoute>
                        <PrivateRoute path="/integrations/:type">
                          <IntegrationDataPage />
                        </PrivateRoute>
                        <Route path="/thinkific-app/install">
                          <thinkificApp.InstallPage />
                        </Route>
                        <PrivateRoute path="/thinkific-app/callback">
                          <thinkificApp.CallbackPage />
                        </PrivateRoute>
                        <Route path="/thinkific-app">
                          <thinkificApp.MainPage />
                        </Route>
                        <PrivateRoute path="/lti_deeplink">
                          <LtiDeepLinkPage />
                        </PrivateRoute>
                      </Switch>
                    </div>
                  </Router>
                </ImageInputProvider>
              </CloudinaryProvider>
            </MessageProvider>
          </IntercomProvider>
        </ToastProvider>
      </ConfigCatProvider>
    </Provider>
  );
}
