import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import Tabs from "../components/Tabs";

export enum FlowSequenceTopBarTab {
  Build = "Build",
  Stats = "Stats",
}

export function FlowSequenceTopBarTabs(props: {
  flowSequenceId: number;
  activeTab: FlowSequenceTopBarTab;
}): ReactElement {
  const history = useHistory();

  return (
    <Tabs
      isInTopBar
      tabs={Object.keys(FlowSequenceTopBarTab)}
      activeTab={props.activeTab}
      setActiveTab={(tab) => {
        switch (tab) {
          case FlowSequenceTopBarTab.Build:
            history.replace(`/sequences/${props.flowSequenceId}/edit`);
            break;
          case FlowSequenceTopBarTab.Stats:
            history.replace(`/sequences/${props.flowSequenceId}/stats`);
        }
      }}
    />
  );
}
