import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FaUsers, FaWrench } from "react-icons/fa";
import { MdShare } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useAppDispatch } from "../../app/store";
import BackButton from "../../components/buttons/BackButton";
import MenuItem from "../../components/menus/MenuItem";
import MenuSection from "../../components/menus/MenuSection";
import Modal from "../../components/Modal";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import AccessManager from "../../features/flowSequenceEditor/AccessManager";
import EmbedForm, {
  EmbedFormController,
} from "../../features/flowSequenceEditor/EmbedForm";
import FlowSequenceEditor from "../../features/flowSequenceEditor/FlowSequenceEditor";
import {
  selectFlowSequenceName,
  selectFlowSequenceOwner,
  selectFlowSequenceOwnership,
  selectUpdating,
} from "../../features/flowSequenceEditor/flowSequenceEditorSlice";
import NamesEditor from "../../features/flowSequenceEditor/NamesEditor";
import PublishButton from "../../features/flowSequenceEditor/PublishButton";
import SettingsForm from "../../features/flowSequenceEditor/SettingsForm";
import { updateFlowSequenceName } from "../../features/flowSequenceStats/flowSequenceStatsSlice";
import { loadIntegrations } from "../../features/integrations/integrationsSlice";
import { SettingsFormController } from "../../featuresCommon/SettingsForm";
import SharedByBadge from "../../featuresCommon/SharedByBadge";
import {
  FlowSequenceTopBarTab,
  FlowSequenceTopBarTabs,
} from "../FlowSequenceTopBarTabs";
import styles from "./FlowSequenceEditPage.module.css";

export default function FlowSequenceEditPage(): ReactElement {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);

  const dispatch = useAppDispatch();

  const [updating] = useSelector(selectUpdating);
  const name = useSelector(selectFlowSequenceName);
  const ownership = useSelector(selectFlowSequenceOwnership);
  const owner = useSelector(selectFlowSequenceOwner);

  const { addToast } = useToasts();

  const [isEmbedFormOpen, setIsEmbedFormOpen] = useState(false);
  const [isAccessMgrOpen, setIsAccessMgrOpen] = useState(false);
  const [isSettingsFormOpen, setIsSettingsFormOpen] = useState(false);

  useEffect(() => {
    dispatch(loadIntegrations({ noReload: true }));
  }, [dispatch]);

  const embedFormController = useRef<EmbedFormController>();
  const settingsFormController = useRef<SettingsFormController>();

  return (
    <div className={styles.root}>
      <Modal
        title="Embed/Share"
        style={{
          content: {
            width: "700px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isEmbedFormOpen}
        onRequestClose={() => {
          embedFormController.current?.save();
          setIsEmbedFormOpen(false);
        }}
      >
        <EmbedForm control={(c) => (embedFormController.current = c)} />
      </Modal>
      <Modal
        title="Manage Access"
        style={{
          content: {
            width: "700px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isAccessMgrOpen}
        onRequestClose={() => setIsAccessMgrOpen(false)}
      >
        <AccessManager flowSequenceId={id} />
      </Modal>
      <Modal
        title={`Settings: ${name}`}
        ariaHideApp={false}
        isOpen={isSettingsFormOpen}
        style={{
          content: {
            width: "600px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        disableClose={updating === "pending"}
        onRequestClose={() => {
          if (
            !settingsFormController.current?.isDirty() ||
            window.confirm(
              "You have unsaved changes. Discard them and close settings editor?"
            )
          ) {
            setIsSettingsFormOpen(false);
          }
        }}
      >
        <SettingsForm
          onSubmit={() => {
            setTimeout(() => setIsSettingsFormOpen(false), 0);
          }}
          onError={() => {
            addToast("Failed to update settings.", {
              appearance: "error",
              autoDismiss: true,
            });
          }}
          controller={(c) => (settingsFormController.current = c)}
        />
      </Modal>
      <TopBar
        leading={<BackButton />}
        title={
          <div className={styles.title}>
            {/* Replaced NameEditor by NamesEditor, which is i18n enabled */}
            <NamesEditor
              onUpdate={(name) => dispatch(updateFlowSequenceName(name))}
            />
            {owner && <SharedByBadge user={owner} />}
          </div>
        }
        center={
          <FlowSequenceTopBarTabs
            flowSequenceId={id}
            activeTab={FlowSequenceTopBarTab.Build}
          />
        }
        actions={[
          <PublishButton key="publish" />,
          <ProfileButton
            key="login"
            renderMenuItems={() => (
              <MenuSection title="Sequence">
                <MenuItem
                  onClick={() => {
                    setIsEmbedFormOpen(true);
                  }}
                >
                  <MdShare size={16} />
                  &nbsp;Embed/Share
                </MenuItem>
                {ownership === "own" && (
                  <MenuItem
                    onClick={() => {
                      setIsAccessMgrOpen(true);
                    }}
                  >
                    <FaUsers size={16} />
                    &nbsp;Manage&nbsp;Access
                  </MenuItem>
                )}
                {
                  <MenuItem
                    onClick={() => {
                      setIsSettingsFormOpen(true);
                    }}
                  >
                    <FaWrench size={16} />
                    &nbsp;Settings
                  </MenuItem>
                }
              </MenuSection>
            )}
          />,
        ]}
      />
      <div className={styles.flowSequence}>
        <FlowSequenceEditor flowSequenceId={id} />
      </div>
    </div>
  );
}
