import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";

import { AsyncStatus, RootState } from "../../app/store";
import {
  BLANK_FILTER,
  FlowAndSeqFilter,
} from "../../featuresCommon/FlowAndSeqFilterBar";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface FlowSequenceListState {
  loading: AsyncStatus;
  filter: FlowAndSeqFilter;
  data: api.FlowSequences | null;
  error: ErrorData | null;
}

export const loadFlowSequences = createAsyncThunk(
  "flowSequenceList/loadFlowSequencesStatus",
  async (filter: FlowAndSeqFilter, thunkApi) => {
    try {
      const { ownership, searchTerm } = filter;
      const result = await api.listFlowSequences({
        all: true,
        ...(ownership != null ? { ownership } : {}),
        ...(searchTerm.length > 0 ? { q: searchTerm } : {}),
      });
      return { flowSequences: result.flowSequences };
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const slice = createSlice({
  name: "flowSequenceList",
  initialState: {
    loading: "idle",
    filter: BLANK_FILTER,
    data: null,
    error: null,
  } as FlowSequenceListState,
  reducers: {
    clearFlowSequences: (state: FlowSequenceListState) => {
      state.loading = "idle";
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: {
    [`${loadFlowSequences.pending}`]: (
      state: FlowSequenceListState,
      action
    ) => {
      state.loading = "pending";
      state.filter = action.meta.arg;
      state.data = null;
    },
    [`${loadFlowSequences.fulfilled}`]: (
      state: FlowSequenceListState,
      action: PayloadAction<api.FlowSequences>
    ) => {
      state.loading = "fulfilled";
      state.data = action.payload;
      state.error = null;
    },
    [`${loadFlowSequences.rejected}`]: (
      state: FlowSequenceListState,
      action: PayloadAction<ErrorData>
    ) => {
      state.loading = "rejected";
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { clearFlowSequences } = slice.actions;

export const selectFlowSequenceList = (
  state: RootState
): FlowSequenceListState => state.flowSequenceList;

export default slice.reducer;
