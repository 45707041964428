import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useState } from "react";
import { useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";

import { AsyncStatus } from "../../app/store";
import { ReactComponent as IntegratedIcon } from "../../assets/integratedIcon.svg";
import { ReactComponent as IntegrationIcon } from "../../assets/integrationIcon.svg";
import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  ErrorLayout,
  LoadingLayoutHeaderIcon,
} from "../../components/LoadingLayout";
import {
  getBestEffortErrorMessage,
  getSerializableError,
} from "../../utils/error";
import PageContents from "./PageContents";
import { getState } from "./utils";

export default function CallbackPage(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get("code");
  const subdomain = query.get("subdomain");
  const state = query.get("state");

  const [requestState] = useState(getState());
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<AsyncStatus>("idle");

  useEffect(() => {
    setStatus("pending");
    (async () => {
      if (code == null || subdomain == null || state == null) {
        setStatus("rejected");
        setError("Invalid request");
      } else if (state !== requestState) {
        setStatus("rejected");
        setError("State mismatched");
      } else {
        try {
          await api.integrateThinkificApp(code, subdomain);
          setStatus("fulfilled");
          setTimeout(() => {
            history.push("/integrations/thinkific");
          }, 300);
        } catch (error) {
          setStatus("rejected");
          setError(getBestEffortErrorMessage(getSerializableError(error)));
        }
      }
    })();
  }, []);

  return (
    <PageContents>
      {status === "rejected" ? (
        <ErrorLayout
          message={error}
          footer={
            <FilledButton
              onClick={() => {
                history.push("/");
              }}
            >
              <FaHome />
              &nbsp;Home
            </FilledButton>
          }
        />
      ) : status === "fulfilled" ? (
        <LoadingLayout
          header={
            <LoadingLayoutHeaderIcon>
              <IntegratedIcon />
            </LoadingLayoutHeaderIcon>
          }
          message="Integrated with Thinkific"
        />
      ) : (
        <LoadingLayout
          header={
            <LoadingLayoutHeaderIcon>
              <IntegrationIcon />
            </LoadingLayoutHeaderIcon>
          }
          message="Integrating with Thinkific..."
        />
      )}
    </PageContents>
  );
}
