import React, { Children, ReactElement, ReactNode } from "react";

import MenuDivider from "./MenuDivider";

export default function MenuSection({
  title,
  children,
}: {
  title?: string | null;
  children: ReactNode;
}): ReactElement {
  let childCount = 0;
  Children.forEach(children, (c) => {
    if (c) childCount++;
  });
  return (
    <>
      {childCount > 0 && <MenuDivider title={title} />}
      {children}
    </>
  );
}
