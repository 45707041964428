import {
  HistoryItem,
  JsonPresent,
  LightboxMessage,
  LightboxMessageShape,
  MarkdownMessage,
  MessageView,
  MultiAnswerMessage,
  PositionInfo,
  ReactMarkdown,
} from "@xflr6/chatbot";
import React, { ReactElement } from "react";

import styles from "./AnswerSample.module.css";

export default function AnswerSample({
  title,
  answer,
}: {
  title: string;
  answer: string | HistoryItem;
}): ReactElement {
  function renderString(answer: string) {
    return (
      <MessageView
        direction="sent"
        positionInfo={new PositionInfo(false)}
        skipAnimation
      >
        <ReactMarkdown source={answer} />
      </MessageView>
    );
  }

  function renderHistoryItem(historyItem: HistoryItem) {
    const positionInfo = new PositionInfo(false);
    if (historyItem.multipleAnswers) {
      return (
        <MultiAnswerMessage
          direction={historyItem.direction}
          positionInfo={positionInfo}
          message={historyItem.cleanMessage as JsonPresent[]}
          inputDisplayType={historyItem.inputDisplayType}
        />
      );
    } else if (historyItem.contentType === "lightbox") {
      return (
        <LightboxMessage
          direction={historyItem.direction}
          positionInfo={positionInfo}
          message={
            (historyItem.cleanMessage as unknown) as LightboxMessageShape
          }
          skipAnimation
        />
      );
    } else {
      return (
        <MarkdownMessage
          direction={historyItem.direction}
          positionInfo={positionInfo}
          message={`${historyItem.cleanMessage}`}
          skipAnimation
        />
      );
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      {typeof answer === "string"
        ? renderString(answer)
        : renderHistoryItem(answer)}
    </div>
  );
}
