import { PromptEasyNames } from "../selectPromptEasyNames";

export const SELF_CORE_PROMPT_KEY = "{{corePromptKey|urlEnc}}";

export function buildPromptSuffix(
  promptName: string | undefined,
  promptEasyNames: PromptEasyNames | null
): string {
  if (promptName === SELF_CORE_PROMPT_KEY) {
    return " - Self";
  }
  return promptEasyNames != null && promptName != null
    ? ` - Prompt ${promptEasyNames.toEasyName(promptName)}`
    : "";
}
