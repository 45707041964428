import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../components/buttons/FilledButton";
import { loadStats, selectStatsState } from "./flowSequenceStatsSlice";

export default function RefreshButton({
  flowSequenceId,
}: {
  flowSequenceId: number;
}): ReactElement {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectStatsState);

  return (
    <FilledButton
      onClick={() => {
        dispatch(loadStats({ flowSequenceId, force: true }));
      }}
      disabled={loading === "pending"}
    >
      Refresh
    </FilledButton>
  );
}
