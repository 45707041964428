import React, { ReactElement } from "react";

import BackButton from "../../components/buttons/BackButton";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import IdentifiersView from "../../features/identifiers/IdentifiersView";
import RefreshButton from "../../features/identifiers/RefreshButton";
import styles from "./IdentifiersPage.module.css";

export default function IdentifiersPage(): ReactElement {
  return (
    <div className={styles.root}>
      <TopBar
        leading={<BackButton />}
        title="User Activity"
        actions={[
          <RefreshButton key="refresh" />,
          <ProfileButton key="login" />,
        ]}
      />
      <div className={styles.body}>
        <IdentifiersView />
      </div>
    </div>
  );
}
