import React, { ReactElement } from "react";

import styles from "./VariableStatic.module.css";

export default function VariableStatic(): ReactElement {
  return (
    <div className={styles.root}>
      <span className={styles.brackets}>{"{{"}</span>
      <div className={styles.inputWrapDummy} />
      <div className={styles.typeChangeButtonDummy} />
      <span className={styles.brackets}>{"}}"}</span>
    </div>
  );
}
