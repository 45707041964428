import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ChatJson } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import { equals } from "ramda";

import { AsyncStatus, RootState } from "../../app/store";
import { ChatViewNavLocation } from "../../featuresCommon/stats/ChatViewNav";
import {
  computeAdminInputLocations,
  FIFTEEN_MINUTES_IN_MS,
} from "../../featuresCommon/stats/utils";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface FlowSequenceStatsFilter {
  fromDate: string | null;
  tillDate: string | null;
}

export interface FlowSequenceStatsState {
  filter: FlowSequenceStatsFilter;
  reqId: string | null;
  loading: AsyncStatus;
  loadError: ErrorData | null;
  stats: api.FlowSequenceStats | null;
  selectedItemId: number | null;
  itemStatsReqId: string | null;
  itemStatsLoading: AsyncStatus;
  itemStatsLoadError: ErrorData | null;
  // TODO Load all items stats in one go, to avoid data inconsistencies
  // (between aggregate data in `stats` and data in item stats).
  itemStats: api.FlowStats | null;
  responsesReqId: string | null;
  responsesFilter: api.ResponsesFilter;
  responsesLoading: AsyncStatus;
  responsesLoadError: ErrorData | null;
  responsesData: api.ChatSequenceResponses | null;
  responseIndex: number | null;
  responseReqId: string | null;
  responseLoading: AsyncStatus;
  responseLoadError: ErrorData | null;
  response: api.ChatSequenceResponse | null;
}

function resetData(state: FlowSequenceStatsState, loading: AsyncStatus) {
  state.reqId = null;
  state.loading = loading;
  state.loadError = null;
  state.stats = null;

  state.selectedItemId = null;
  state.itemStatsReqId = null;
  state.itemStatsLoading = "idle";
  state.itemStatsLoadError = null;
  state.itemStats = null;

  state.responsesReqId = null;
  state.responsesLoading = "idle";
  state.responsesLoadError = null;
  state.responsesData = null;

  state.responseIndex = null;
  state.responseReqId = null;
  state.responseLoading = "idle";
  state.responseLoadError = null;
  state.response = null;
}

export const loadStats = createAsyncThunk(
  "flowSequenceStats/loadStatus",
  async (
    {
      flowSequenceId,
      filter,
    }: {
      flowSequenceId: number;
      filter?: FlowSequenceStatsFilter;
      force?: boolean;
    },
    thunkApi
  ) => {
    const { flowSequenceStats } = thunkApi.getState() as {
      flowSequenceStats: FlowSequenceStatsState;
    };
    const effectiveFilter = filter ?? flowSequenceStats.filter;
    try {
      return await api.getFlowSequenceStats(
        flowSequenceId,
        effectiveFilter.fromDate,
        effectiveFilter.tillDate,
        FIFTEEN_MINUTES_IN_MS
      );
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  },
  {
    condition(
      { flowSequenceId, filter, force },
      thunkApi
    ): boolean | undefined {
      const { flowSequenceStats } = thunkApi.getState() as {
        flowSequenceStats: FlowSequenceStatsState;
      };
      if (
        !force &&
        flowSequenceStats.loading !== "idle" &&
        flowSequenceId === flowSequenceStats.stats?.id &&
        (!filter || equals(filter, flowSequenceStats.filter))
      ) {
        return false;
      }
    },
  }
);

export const loadItemStats = createAsyncThunk(
  "flowSequenceStats/loadItemStatsStatus",
  async (itemId: number, thunkApi) => {
    const { flowSequenceStats } = thunkApi.getState() as {
      flowSequenceStats: FlowSequenceStatsState;
    };
    if (flowSequenceStats.stats != null) {
      const flowId = flowSequenceStats.stats.items[`${itemId}`].id;
      try {
        return await api.getFlowStats(
          flowId,
          flowSequenceStats.filter.fromDate,
          flowSequenceStats.filter.tillDate,
          FIFTEEN_MINUTES_IN_MS
        );
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadStats"))
      );
    }
  }
);

export const loadResponses = createAsyncThunk(
  "flowSequenceStats/loadResponsesStatus",
  async (filter: api.ResponsesFilter, thunkApi) => {
    const { flowSequenceStats } = thunkApi.getState() as {
      flowSequenceStats: FlowSequenceStatsState;
    };
    if (flowSequenceStats.stats != null) {
      const flowSequenceId = flowSequenceStats.stats.id;
      try {
        return await api.getChatSequenceResponses(flowSequenceId, {
          ...flowSequenceStats.filter,
          filter,
        });
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadStats"))
      );
    }
  }
);

export const loadMoreResponses = createAsyncThunk(
  "flowSequenceStats/loadMoreResponsesStatus",
  async (payload: void, thunkApi) => {
    const { flowSequenceStats } = thunkApi.getState() as {
      flowSequenceStats: FlowSequenceStatsState;
    };
    if (flowSequenceStats.stats != null) {
      const flowSequenceId = flowSequenceStats.stats.id;
      const log = flowSequenceStats.responsesData?.responseLog;
      const sinceId = log != null ? log[log.length - 1].id : null;
      try {
        return await api.getChatSequenceResponses(flowSequenceId, {
          ...flowSequenceStats.filter,
          filter: flowSequenceStats.responsesFilter,
          sinceId,
        });
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadStats"))
      );
    }
  },
  {
    condition(payload, thunkApi) {
      const { flowSequenceStats } = thunkApi.getState() as {
        flowSequenceStats: FlowSequenceStatsState;
      };
      return flowSequenceStats.responsesLoading !== "pending";
    },
  }
);

export const loadResponse = createAsyncThunk(
  "flowSequenceStats/loadResponseStatus",
  async (index: number, thunkApi) => {
    const { flowSequenceStats } = thunkApi.getState() as {
      flowSequenceStats: FlowSequenceStatsState;
    };
    if (
      flowSequenceStats.stats != null &&
      flowSequenceStats.responsesData != null
    ) {
      const flowSequenceId = flowSequenceStats.stats.id;
      const identifier =
        flowSequenceStats.responsesData.responseLog[index].identifier;
      try {
        return await api.getChatSequenceResponse(flowSequenceId, identifier);
      } catch (error) {
        return thunkApi.rejectWithValue(getSerializableError(error));
      }
    } else {
      return thunkApi.rejectWithValue(
        getSerializableError(new Error("Called before loadResponses"))
      );
    }
  }
);

export const slice = createSlice({
  name: "flowSequenceStats",
  initialState: {
    filter: {
      fromDate: null,
      tillDate: null,
    },
    reqId: null,
    loading: "idle",
    loadError: null,
    stats: null,
    selectedItemId: null,
    itemStatsReqId: null,
    itemStatsLoading: "idle",
    itemStatsLoadError: null,
    itemStats: null,
    responsesReqId: null,
    responsesFilter: {},
    responsesLoading: "idle",
    responsesLoadError: null,
    responsesData: null,
    responseIndex: null,
    responseReqId: null,
    responseItemIndex: null,
    responseLoading: "idle",
    responseLoadError: null,
    response: null,
  } as FlowSequenceStatsState,
  reducers: {
    primeFilters: (
      state: FlowSequenceStatsState,
      action: PayloadAction<
        Partial<{
          filter: FlowSequenceStatsFilter;
          responsesFilter: api.ResponsesFilter;
        }>
      >
    ) => {
      const { filter, responsesFilter } = action.payload;
      let dirty = false;
      if (filter && !equals(filter, state.filter)) {
        state.filter = filter;
        dirty = true;
      }
      if (responsesFilter && !equals(responsesFilter, state.responsesFilter)) {
        state.responsesFilter = responsesFilter;
        dirty = true;
      }
      if (dirty) {
        resetData(state, "idle");
      }
    },
    removeFromResponses: (
      state: FlowSequenceStatsState,
      action: PayloadAction<{ flowSequenceId: number; identifier: string }>
    ) => {
      if (
        state.stats != null &&
        action.payload.flowSequenceId === state.stats.id &&
        state.responsesData != null
      ) {
        if (
          state.response != null &&
          state.response.id === action.payload.flowSequenceId &&
          state.response.identifier === action.payload.identifier
        ) {
          state.response = null;
          state.responseIndex = null;
        }

        const index = state.responsesData.responseLog.findIndex(
          (r) => r.identifier === action.payload.identifier
        );
        if (index !== -1) {
          state.responsesData.responseLog.splice(index, 1);
          state.responsesData.totalRecords--;
        }
      }
    },
    updateFlowSequenceName: (
      state: FlowSequenceStatsState,
      action: PayloadAction<string>
    ) => {
      if (state.stats != null) {
        state.stats.name = action.payload;
      }
    },
    updateResponseHistory: (
      state: FlowSequenceStatsState,
      action: PayloadAction<{
        chatIndex: number;
        historyItemIndex: number;
        data: api.ChatResponseHistoryItemPatch | ChatJson;
      }>
    ) => {
      if (state.response != null) {
        const { chatIndex, historyItemIndex, data } = action.payload;
        let historyItems =
          state.response.history?.histories[chatIndex]?.history;
        if (historyItems != null) {
          if ("history" in data && state.response.history) {
            state.response.history.histories[chatIndex] = data;
            historyItems = data.history;
          }
          if ("comment" in data) {
            historyItems[historyItemIndex].c = data.comment;
          }
          if ("superScore" in data) {
            historyItems[historyItemIndex].ss = data.superScore;
          }

          const adminLocations = computeAdminInputLocations(
            historyItems,
            state.response.evaluations[chatIndex]
          );
          const newFeedbackPending = adminLocations.filter(
            (location) =>
              location.category === "feedback" && location.actionPending
          ).length;
          const newScoreLaterPending = adminLocations.filter(
            (location) =>
              location.category === "scoreLater" && location.actionPending
          ).length;
          const newExternalAnswerPending = adminLocations.filter(
            (location) =>
              location.category === "answerExternally" && location.actionPending
          ).length;
          if (state.responsesData != null && state.responseIndex != null) {
            const response =
              state.responsesData.responseLog[state.responseIndex];
            const responseItem = response.items[chatIndex];
            const oldFeedbackPending = responseItem.feedbackPending;
            const oldScoreLaterPending = responseItem.scoreLaterPending;
            const oldExternalAnswerPending = responseItem.externalAnswerPending;
            responseItem.feedbackPending = newFeedbackPending;
            responseItem.scoreLaterPending = newScoreLaterPending;
            response.feedbackPending =
              response.feedbackPending -
              oldFeedbackPending +
              newFeedbackPending;
            response.scoreLaterPending =
              response.scoreLaterPending -
              oldScoreLaterPending +
              newScoreLaterPending;
            response.externalAnswerPending =
              response.externalAnswerPending -
              oldExternalAnswerPending +
              newExternalAnswerPending;
          }
        }
      }
    },
    updateResponseEvaluation: (
      state: FlowSequenceStatsState,
      action: PayloadAction<{
        chatIndex: number;
        flowSequenceId: number;
        identifier: string;
        promptKeyStr: string;
        evaluation: string;
        decrementPending?: boolean;
      }>
    ) => {
      if (
        state.response != null &&
        action.payload.flowSequenceId === state.response.id
      ) {
        state.response.evaluations[action.payload.chatIndex][
          action.payload.promptKeyStr
        ] = action.payload.evaluation;
      }

      if (
        action.payload.decrementPending &&
        state.stats != null &&
        action.payload.flowSequenceId === state.stats.id &&
        state.responsesData != null
      ) {
        const responseStub = state.responsesData.responseLog.find(
          (r) => r.identifier === action.payload.identifier
        );
        if (responseStub != null) {
          responseStub.evaluationPending -= 1;
          responseStub.items[action.payload.chatIndex].evaluationPending -= 1;
        }
      }
    },
    updateResponseSettings: (
      state: FlowSequenceStatsState,
      action: PayloadAction<api.SettingsPatch>
    ) => {
      if (state.response != null) {
        state.response.settings = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // ** loadStats **
    builder.addCase(loadStats.pending, (state, action) => {
      resetData(state, "pending");
      state.reqId = action.meta.requestId;
      const filter = action.meta.arg.filter;
      if (filter != null) {
        state.filter = filter;
      }
    });
    builder.addCase(loadStats.fulfilled, (state, action) => {
      if (action.meta.requestId === state.reqId) {
        state.loading = "fulfilled";
        state.stats = action.payload;
      }
    });
    builder.addCase(loadStats.rejected, (state, action) => {
      if (action.meta.requestId === state.reqId) {
        state.loading = "rejected";
        state.loadError = action.payload;
      }
    });

    // ** loadItemStats **
    builder.addCase(loadItemStats.pending, (state, action) => {
      state.itemStatsReqId = action.meta.requestId;
      state.selectedItemId = action.meta.arg;
      state.itemStatsLoading = "pending";
      state.itemStatsLoadError = null;
      state.itemStats = null;
    });
    builder.addCase(loadItemStats.fulfilled, (state, action) => {
      if (action.meta.requestId === state.itemStatsReqId) {
        state.itemStatsLoading = "fulfilled";
        state.itemStats = action.payload;
      }
    });
    builder.addCase(loadItemStats.rejected, (state, action) => {
      if (action.meta.requestId === state.itemStatsReqId) {
        state.itemStatsLoading = "rejected";
        state.itemStatsLoadError = action.payload;
      }
    });

    // ** loadResponses **
    builder.addCase(loadResponses.pending, (state, action) => {
      state.responsesReqId = action.meta.requestId;
      state.responsesFilter = action.meta.arg;
      state.responsesLoading = "pending";
      state.responsesLoadError = null;
      state.responsesData = null;
    });
    builder.addCase(loadResponses.fulfilled, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "fulfilled";
        state.responsesData = action.payload;
        state.responseIndex = null;
        state.responseReqId = null;
        state.responseLoading = "idle";
        state.responseLoadError = null;
        state.response = null;
      }
    });
    builder.addCase(loadResponses.rejected, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "rejected";
        state.responsesLoadError = action.payload;
      }
    });

    // ** loadMoreResponses **
    builder.addCase(loadMoreResponses.pending, (state, action) => {
      state.responsesReqId = action.meta.requestId;
      state.responsesLoading = "pending";
      state.responsesLoadError = null;
    });
    builder.addCase(loadMoreResponses.fulfilled, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "fulfilled";
        state.responsesData =
          state.responsesData == null
            ? action.payload
            : {
                ...action.payload,
                totalRecords: state.responsesData.totalRecords,
                responseLog: state.responsesData.responseLog.concat(
                  action.payload.responseLog
                ),
              };
      }
    });
    builder.addCase(loadMoreResponses.rejected, (state, action) => {
      if (action.meta.requestId === state.responsesReqId) {
        state.responsesLoading = "rejected";
        state.responsesLoadError = action.payload;
      }
    });

    // ** loadResponse **
    builder.addCase(loadResponse.pending, (state, action) => {
      state.responseReqId = action.meta.requestId;
      state.responseIndex = action.meta.arg;
      state.responseLoading = "pending";
      state.responseLoadError = null;
      state.response = null;
    });
    builder.addCase(loadResponse.fulfilled, (state, action) => {
      if (
        state.stats != null &&
        state.responsesData != null &&
        action.meta.requestId === state.responseReqId
      ) {
        const flowId = state.stats.id;
        if (
          action.payload.id === flowId &&
          state.responseIndex != null &&
          action.payload.identifier ===
            state.responsesData.responseLog[state.responseIndex].identifier
        ) {
          state.responseLoading = "fulfilled";
          state.response = action.payload;
        }
      }
    });
    builder.addCase(loadResponse.rejected, (state, action) => {
      if (action.meta.requestId === state.responseReqId) {
        state.responseLoading = "rejected";
        state.responseLoadError = action.payload;
      }
    });
  },
});

export const {
  primeFilters,
  removeFromResponses,
  updateFlowSequenceName,
  updateResponseEvaluation,
  updateResponseHistory,
  updateResponseSettings,
} = slice.actions;

export const selectFilter = (state: RootState): FlowSequenceStatsFilter =>
  state.flowSequenceStats.filter;

export const selectStatsState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  stats: api.FlowSequenceStats | null;
} => ({
  loading: state.flowSequenceStats.loading,
  loadError: state.flowSequenceStats.loadError,
  stats: state.flowSequenceStats.stats,
});

export const selectStatsFlowSequenceName = (state: RootState): string | null =>
  state.flowSequenceStats.stats?.name ?? null;

export const selectStatsOwnership = (state: RootState): api.Ownership | null =>
  state.flowSequenceStats.stats?.ownership ?? null;

export const selectStatsOwner = (state: RootState): api.OwnerDetails | null =>
  state.flowSequenceStats.stats?.user ?? null;

export const selectSelectedItem = (state: RootState): number | null =>
  state.flowSequenceStats.selectedItemId;

export const selectItemStatsState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  itemStats: api.FlowStats | null;
} => {
  return {
    loading: state.flowSequenceStats.itemStatsLoading,
    loadError: state.flowSequenceStats.itemStatsLoadError,
    itemStats: state.flowSequenceStats.itemStats,
  };
};

export const selectResponsesFilter = (state: RootState): api.ResponsesFilter =>
  state.flowSequenceStats.responsesFilter;

export const selectResponsesState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  responsesData: api.ChatSequenceResponses | null;
} => ({
  loading: state.flowSequenceStats.responsesLoading,
  loadError: state.flowSequenceStats.responsesLoadError,
  responsesData: state.flowSequenceStats.responsesData,
});

export const selectResponseIndex = (state: RootState): number | null =>
  state.flowSequenceStats.responseIndex;

export const selectResponseAtIndex = createSelector(
  (state: RootState) => state.flowSequenceStats.responsesData,
  selectResponseIndex,
  (responsesData, responseIndex) =>
    responsesData != null && responseIndex != null
      ? responsesData.responseLog[responseIndex]
      : null
);

export const selectResponseState = (
  state: RootState
): {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  response: api.ChatSequenceResponse | null;
} => ({
  loading: state.flowSequenceStats.responseLoading,
  loadError: state.flowSequenceStats.responseLoadError,
  response: state.flowSequenceStats.response,
});

export const selectResponseFlowSequenceSettings = (
  state: RootState
): api.SettingsPatch | null =>
  state.flowSequenceStats.response?.settings ?? null;

export const selectResponseFlowSequenceUserSettings = (
  state: RootState
): api.SettingsPatch | null =>
  state.flowSequenceStats.response?.userSettings ?? null;

export const selectResponseAdminInputLocations = (
  state: RootState
): (ChatViewNavLocation[] | null)[] => {
  const response = state.flowSequenceStats.response;
  if (response != null && response.history != null) {
    return response.history.histories.map((history, index) => {
      return history != null
        ? computeAdminInputLocations(
            history.history,
            response.evaluations[index]
          )
        : null;
    });
  } else {
    return [];
  }
};

export default slice.reducer;
