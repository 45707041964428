import { HistoryItemJson, JsonPresent } from "@xflr6/chatbot";
import axios from "axios";

import { buildAuthHeader } from "../auth";
import {
  OwnerDetails,
  Ownership,
  Responses,
  ResponsesFilter,
  ResponseStub,
  Stats,
} from "../sharedTypes";
import { baseUrl } from "../utils";

export type ChatResponseStub = ResponseStub;

export type ChatResponses = Responses<ChatResponseStub>;

export interface AnswerData {
  message?: JsonPresent;
  multipleAnswers?: boolean;
  total?: number;
  completed?: number;
}

export interface AnswersEdge {
  answers: Record<string, AnswerData>;
  nextPrompt: PromptNode;
  answersDropped: number;
}

export interface PromptNode {
  pk?: string;
  messages: JsonPresent[];
  total?: number;
  totalTime?: number;
  dropped?: number;
  completed?: number;
  answers?: Record<string, AnswersEdge>;
}

export interface PromptData {
  message: JsonPresent;
  total?: number;
  answers?: Record<string, { count: number; sample: HistoryItemJson }>;
  expandedThread?: { key: string; data: PromptData }[];
}

export interface FlowStats extends Stats {
  pathAnalytics: PromptNode;
  promptAnalytics: { key: string; data: PromptData }[];
  ownership: Ownership;
  user: OwnerDetails;
}

export async function getFlowStats(
  id: number,
  fromDate?: string | null,
  tillDate?: string | null,
  outlierThreshold?: number | null
): Promise<FlowStats> {
  const response = await axios.get(`${baseUrl}/flows/${id}/stats`, {
    headers: { ...buildAuthHeader() },
    params: {
      ...(fromDate != null ? { fromDate } : {}),
      ...(tillDate != null ? { tillDate } : {}),
      ...(outlierThreshold != null ? { outlierThreshold } : {}),
    },
  });
  return response.data;
}

export async function getChatResponses(
  id: number,
  options?: {
    fromDate?: string | null;
    tillDate?: string | null;
    filter?: ResponsesFilter | null;
    sinceId?: number | null;
    all?: boolean | null;
  }
): Promise<ChatResponses> {
  const response = await axios.get(`${baseUrl}/flows/${id}/responseLogs`, {
    headers: { ...buildAuthHeader() },
    params: {
      ...(options?.fromDate != null ? { fromDate: options.fromDate } : {}),
      ...(options?.tillDate != null ? { tillDate: options.tillDate } : {}),
      ...(options?.filter?.search != null
        ? { search: options.filter.search }
        : {}),
      ...(options?.filter?.completed
        ? { completed: options.filter.completed }
        : {}),
      ...(options?.filter?.inputsPending
        ? { inputsPending: options.filter.inputsPending }
        : {}),
      ...(options?.sinceId != null ? { sinceId: options.sinceId } : {}),
      ...(options?.all ? { all: "1" } : {}),
    },
  });
  return response.data;
}

export async function getChatResponsesDownloadLink(
  id: number,
  options?: {
    fromDate?: string | null;
    tillDate?: string | null;
    filter?: ResponsesFilter | null;
    withHistories?: boolean;
  }
): Promise<string> {
  const response = await axios.get(`${baseUrl}/flows/${id}/responseLogsFile`, {
    responseType: "blob",
    headers: { ...buildAuthHeader() },
    params: {
      ...(options?.fromDate != null ? { fromDate: options.fromDate } : {}),
      ...(options?.tillDate != null ? { tillDate: options.tillDate } : {}),
      ...(options?.filter?.search != null
        ? { search: options.filter.search }
        : {}),
      ...(options?.filter?.completed
        ? { completed: options.filter.completed }
        : {}),
      ...(options?.filter?.inputsPending
        ? { inputsPending: options.filter.inputsPending }
        : {}),
      ...(options?.withHistories ? { withHistories: true } : {}),
    },
  });
  return window.URL.createObjectURL(new Blob([response.data]));
}
