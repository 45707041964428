import classNames from "classnames";
import React, { ReactElement } from "react";

import styles from "./TopBar.module.css";

export interface TopBarProps {
  className?: string | null;
  leading?: ReactElement | null;
  title: string | ReactElement;
  center?: ReactElement | null;
  actions?: (ReactElement | null)[] | null;
}

export default function TopBar(props: TopBarProps): ReactElement {
  return (
    <div className={classNames(styles.root, props.className)}>
      <div className={styles.bar}>
        <div className={styles.left}>
          {props.leading != null && (
            <div className={styles.leading}>{props.leading}</div>
          )}
          <div
            className={classNames({
              [`${styles.titleString}`]: typeof props.title === "string",
              [`${styles.titleComponent}`]: typeof props.title !== "string",
            })}
          >
            {props.title}
          </div>
        </div>
        {props.center != null && (
          <div className={styles.center}>{props.center}</div>
        )}
        {/* We need the actions div to correctly position center content */}
        {(props.actions != null || props.center != null) && (
          <div
            className={classNames(styles.actions, {
              [styles.actions__hasCenter]: props.center != null,
            })}
          >
            {props.actions}
          </div>
        )}
      </div>
      {props.center != null && (
        <div className={styles.centerMobile}>{props.center}</div>
      )}
    </div>
  );
}
