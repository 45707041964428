import { BaseCssVariablesStream } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import { mapObjIndexed } from "ramda";

export function applySettingsToBaseCss(
  $baseCssVariables: BaseCssVariablesStream,
  settings?: api.SettingsPatch | null
): void {
  $baseCssVariables.next(
    mapObjIndexed((v) => v ?? null, {
      "--primary": settings?.chatPrimaryColor,
      "--accent": settings?.chatAccentColor,
      "--borderRadius": settings?.chatBorderRadius,
      "--spacing": settings?.chatSpacing,
    })
  );
}
