import * as api from "@xflr6/chatbot-api";

export interface Point {
  left: number;
  top: number;
}

export interface RenderTree<T extends api.PromptNode | api.AnswersEdge> {
  type: string;
  value: T extends api.PromptNode
    ? Omit<api.PromptNode, "answers">
    : Omit<api.AnswersEdge, "nextPrompt">;
  position: Point;
  children?: T extends api.PromptNode ? RenderTree<api.AnswersEdge>[] : null;
  child: T extends api.PromptNode ? null : RenderTree<api.PromptNode>;
}

export const BOX_WIDTH = 200;
export const BOX_HEIGHT = 40;
export const HORIZONTAL_GAP = 100;
export const VERTICAL_GAP = 20;
export const PADDING = 20;
export const DOT_SIZE = 14;

function _toRenderTree(
  inputData: api.PromptNode,
  maxX = 0,
  offsetX = PADDING,
  offsetY: number[] = []
): [RenderTree<api.PromptNode>, number, number[]] {
  let offsetYMain = offsetY[0] ?? PADDING,
    subtreeOffsetY = offsetY.slice(1),
    childMaxX = maxX;
  const { answers, ...restOfPrompt } = inputData;
  const tree = {
    type: "prompt",
    value: restOfPrompt,
    position: { left: offsetX, top: offsetYMain },
    ...(answers != null
      ? {
          children: Object.keys(answers).map((answerKey) => {
            const { nextPrompt, ...restOfPath } = answers[answerKey];
            const position = {
              left: offsetX + BOX_WIDTH + HORIZONTAL_GAP - DOT_SIZE,
              top: (subtreeOffsetY[0] ?? PADDING) + (BOX_HEIGHT - DOT_SIZE) / 2,
            };
            let childTree;
            [childTree, childMaxX, subtreeOffsetY] = _toRenderTree(
              nextPrompt,
              childMaxX,
              offsetX + BOX_WIDTH + HORIZONTAL_GAP,
              subtreeOffsetY
            );

            return {
              type: "path",
              value: restOfPath,
              position,
              child: childTree,
            };
          }),
        }
      : {}),
    child: null,
  };
  offsetYMain += BOX_HEIGHT + VERTICAL_GAP;
  return [tree, Math.max(childMaxX, offsetX), [offsetYMain, ...subtreeOffsetY]];
}

export default function toRenderTree(
  inputData: api.PromptNode
): {
  renderTree: RenderTree<api.PromptNode>;
  maxX: number;
  maxY: number;
} {
  const [renderTree, maxX, allY] = _toRenderTree(inputData);
  return {
    renderTree,
    maxX: maxX + BOX_WIDTH + PADDING,
    maxY: Math.max(...allY) - VERTICAL_GAP + PADDING,
  };
}
