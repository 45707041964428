import { OrdChatFlowDefinition } from "@xflr6/chatbot";

import { moveElement } from "../../../../utils/array";
import { MCFlowEditorError } from "../../errors";

export function moveMessageItem(
  promptIndex: number,
  fromIndex: number,
  toIndex: number,
  flowDef: OrdChatFlowDefinition
): void {
  const promptDef = flowDef.promptsArray[promptIndex].definition;
  const messages = promptDef.messages;
  if (messages == null) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has no \`messages\` array present`
    );
  }
  moveElement(messages, fromIndex, toIndex);
}
