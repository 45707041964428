import { BuiltInTransformers, PromptDefinition } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";

export interface PromptTemplate {
  id: number;
  name: string;
  description: string;
  // Don't provide a `nextKey` in the definition, it will be ignored
  definition: PromptDefinition;
}

const templates: PromptTemplate[] = [
  {
    id: 1,
    name: "Multi-select",
    description: "Ask the user to choose one or more correct answers",
    definition: {
      message: `\
Which of these countries are members of the Commonwealth of Nations?\
`,
      answers: [
        { message: "Antigua", score: 100 },
        { message: "Ghana", score: 100 },
        { message: "Indonesia", score: 0 },
        { message: "United States of America", score: 0 },
      ],
      acceptsMultipleAnswers: true,
      forbidsAnswerAlteration: true,
    },
  },
  {
    id: 2,
    name: "Typed answer",
    description:
      "Ask the user to type out an answer instead of presenting choices",
    definition: {
      message: `\
What is the tallest mountain in the world?\
`,
      answers: [
        { message: "Mount Everest", score: 100 },
        { message: "Mt. Everest", score: 100 },
        { message: "Mt Everest", score: 100 },
        { message: "Everest", score: 100 },
        { message: "*", score: 0 },
      ],
      normalizeAnswers: [
        { name: BuiltInTransformers.lowercase },
        { name: BuiltInTransformers.trimWhitespace },
        { name: BuiltInTransformers.collapseWhitespace },
      ],
      forbidsAnswerAlteration: true,
    },
  },
  {
    id: 3,
    name: "Handwritten answer, manually graded",
    description: `\
Ask the user to upload image(s) of their handwritten answer, \
then manually award a score after you take at look. \
The user won't need to wait for your score to proceed further.\
`,
    definition: {
      message: `\
Explain how an aircraft wing moving through the air generates **lift**.

Write your answer down on paper, using diagrams if you wish. Then upload \
image(s) of your answer.\
`,
      answers: [
        { message: "*", contentType: "lightbox", score: 100, scoreLater: true },
      ],
      inputDisplayType: "AwsImageUpload",
      inputDisplayConfig: {
        pathTemplate: "user-uploads/{{user}}/{{randomString}}-{{name}}",
        uploadPath: `${process.env.REACT_APP_API_BASE}/aws/s3-sign-upload`,
      },
      forbidsAnswerAlteration: true,
    },
  },
  {
    id: 4,
    name: "Guided answer discovery - choice specific hints",
    description: `\
Guide the user towards the correct answer by giving hints based on the choices \
they select\
`,
    definition: {
      message: `\
What is the product of all integers from -50,000 to 100,000 \
(i.e. -50,000 x -49,999 x -49,998 and so on up to 100,000)?\
`,
      answers: [
        {
          message: "Infinity",
          score: 0,
          quickResponse: {
            message:
              "Can the product of a finite set of finite numbers be infinite? Try again",
            repeatAnswers: true,
          },
        },
        {
          message: "50,000",
          score: 0,
          quickResponse: {
            message:
              "Oops, did you add all the numbers up instead of multiplying them? Try again",
            repeatAnswers: true,
          },
        },
        {
          message:
            "I don't know, I'll need a calculator and lots of time to answer that",
          score: 0,
          quickResponse: {
            message: `\
Hmm, did you notice that as you multiply the numbers, you will be crossing over \
from negative numbers to positive numbers? Does that give you a hint?\
`,
            repeatAnswers: true,
          },
        },
        {
          message: "0",
          score: 100,
          quickResponse: { message: "That's correct!" },
        },
      ],
      forbidsAnswerAlteration: true,
    },
  },
  {
    id: 5,
    name: "Guided answer discovery - progressive hints",
    description: `\
Guide the user towards the correct answer by giving progressively easier hints\
`,
    definition: {
      message: `\
Which of the countries in the list was **the first one** to achieve independence?\
`,
      answers: [
        {
          message: "Indonesia",
          score: 0,
          quickResponse: { message: "", repeatAnswers: true },
        },
        {
          message: "India",
          score: 0,
          quickResponse: { message: "", repeatAnswers: true },
        },
        { message: "Peru", score: 100 },
        {
          message: "Democratic Republic of Congo",
          score: 0,
          quickResponse: { message: "", repeatAnswers: true },
        },
      ],
      quickResponses: [
        {
          message: `\
That's incorrect!

Let us help you with some additional information:
* Indonesia was colonized by the Dutch
* India was colonized by Great Britain
* Peru was colonized by Spain
* Democratic Republic of Congo was colonized by Belgium

Does this information help you come to a different conclusion? Try again!\
`,
        },
        {
          message: `\
Still incorrect. 

Consider this: Spain was caught up in European events of the Napoleonic era. \
This led to its loss of empire in Spanish America.

Now have another go!\
`,
        },
        {
          message: `\
Hmm, that's still not correct. Napoleon invaded Spain quite long ago, in 1808. \
With this information, try again!\
`,
        },
      ],
      forbidsAnswerAlteration: true,
    },
  },
  {
    id: 6,
    name: "Learning from peer answers",
    description: `\
Use peer response statistics to guide the user to the correct answer\
`,
    definition: {
      messages: [
        {
          message: `\
Which of these was the most searched word on Google in the **year 2016**?\
`,
          repeatAnswers: true,
        },
        {
          message: `\
It is easy to get biased when responding to such a question.\
`,
          contentType: null,
          repeatAnswers: false,
        },
        {
          message: `\
For example, if you personally care more about US president than newer models of \
mobile phones, you may think more people searched for Donald Trump instead of \
iPhone 7.\
`,
          contentType: null,
          repeatAnswers: false,
          minNextPromptDelayMs: 2500,
        },
        {
          message: `\
But your personal view may not represent reality! The below stats show how your \
peers responded to the above question.\
`,
          contentType: null,
          repeatAnswers: false,
        },
        {
          message: {
            url: api.buildAnswerStatsUrl(
              "{{#originId}}",
              "{{corePromptKey|urlEnc}}",
              "{{language}}"
            ),
            statusMessages: {
              pending: "Loading answer stats",
              rejected: "Error fetching answer stats",
              fulfilledNull: "Error fetching answer stats",
            },
          },
          contentType: "remote",
          repeatAnswers: false,
        },
        {
          message: `\
Do these statistics that make you rethink your choice? If they do, select a \
different option, else reselect the same option.\
`,
          contentType: null,
          repeatAnswers: false,
        },
      ],
      contentType: null,
      forbidsAnswerAlteration: true,
      answers: [
        { message: "Pokémon Go", score: 100 },
        { message: "iPhone 7", score: 0 },
        { message: "Donald Trump", score: 0 },
        { message: "Prince", score: 0 },
      ],
      scoreStrategy: {
        thread: "last",
        overall: "avg",
        lazyDisplay: true,
      },
    },
  },
];

export default templates;
