import { AnswerDefinition, PromptDefinition } from "@xflr6/chatbot";
import { any } from "ramda";

export type PromptNextKeyStatus =
  | "fullyConnected"
  | "terminal"
  | "danglingAnswers"
  | "danglingMultiSelect"
  | "unknown";

export function getPromptNextKeyStatus(
  promptDef: PromptDefinition
): PromptNextKeyStatus {
  if (promptDef.nextKey != null) {
    return "fullyConnected";
  }
  const answers = promptDef.answers;
  if (typeof answers === "string") {
    return "unknown";
  }
  if (answers == null || answers.length === 0) {
    return "terminal";
  } else if (promptDef.acceptsMultipleAnswers) {
    return "danglingMultiSelect";
  } else if (
    // Answers with "repeat" quick responses don't need to have a next key.
    //
    // Well, technically even those with "forward" quick responses don't really
    // **need** to have a next key, since the quick response itself gets turned
    // into a prompt. However, we take the view that the user probably made a
    // mistake if they didn't specify a next key for a "forward" quick response,
    // hence the exemption below is only for "repeat" quick responses.
    any(
      (a: AnswerDefinition) =>
        a.quickResponse?.repeatAnswers !== true && a.nextKey == null
    )(answers)
  ) {
    return "danglingAnswers";
  } else {
    return "fullyConnected";
  }
}
