import {
  Button,
  HistoryComponent,
  HistoryItem,
  HistoryItemJson,
  MessageView,
  PositionInfo,
  PromptInput,
  RemoteMessage,
  RemoteMessageShape,
  WithCssVariables,
} from "@xflr6/chatbot";
import React, { ReactElement, ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import AnswerSample from "./AnswerSample";
import { ReactComponent as AnswerStatsIcon } from "./answerStatsIcon.svg";
import styles from "./AnswerStatsMessage.module.css";
import Modal from "./Modal";

export default function AnswerStatsMessage({
  historyItem,
  positionInfo,
  promptInput,
  skipAnimation,
}: {
  historyItem: HistoryItem;
  positionInfo: PositionInfo;
  promptInput: PromptInput | null;
  skipAnimation: boolean | null;
}): ReactElement {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  function renderModal(children: ReactNode) {
    return (
      <Modal
        title={t("Answer Stats")}
        style={{
          content: {
            minWidth: "min(calc(100% - var(--spacing) * 2), 400px)",
            maxWidth: "min(calc(100% - var(--spacing) * 2), 600px)",
          },
        }}
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsModalOpen(false)}
        contentElement={(props, contentElement) => (
          <WithCssVariables>
            <div {...props}>{contentElement}</div>
          </WithCssVariables>
        )}
      >
        {children}
      </Modal>
    );
  }

  function renderEmptyMessage() {
    return (
      <span>
        <Trans>Sorry, no peer answers have been recorded yet!</Trans>
      </span>
    );
  }

  return (
    <HistoryComponent
      historyItem={historyItem}
      positionInfo={positionInfo}
      promptInput={promptInput}
    >
      <RemoteMessage
        direction={historyItem.direction}
        positionInfo={positionInfo}
        message={(historyItem.cleanMessage as unknown) as RemoteMessageShape}
        renderFetchedMessage={(message) => {
          let modalContents: ReactNode;

          if (
            message.body != null &&
            typeof message.body === "object" &&
            "multipleAnswers" in message.body
          ) {
            const { multipleAnswers, multipleAnswerCount } = message.body as {
              multipleAnswers: Record<string, number>;
              multipleAnswerCount: number;
            };
            const choices = Object.entries(multipleAnswers);
            modalContents =
              choices.length > 0
                ? choices
                    .sort((a, b) => b[1] - a[1])
                    .map(([choice, count]) => (
                      <AnswerSample
                        key={choice}
                        title={`${Math.round(
                          (count / multipleAnswerCount) * 100
                        )}% ${t("users chose")}`}
                        answer={choice}
                      />
                    ))
                : renderEmptyMessage();
          } else if (
            message.body != null &&
            typeof message.body === "object" &&
            "answers" in message.body
          ) {
            const { answers: answersObj } = message.body as {
              answers: Record<
                string,
                { count: number; sample: HistoryItemJson }
              >;
            };
            const answers = Object.values(answersObj);
            const totalAnswerCount = Object.values(answers).reduce(
              (acc, val) => acc + val.count,
              0
            );
            modalContents =
              answers.length > 0
                ? answers
                    .map(({ count, sample }) => ({
                      count,
                      sample: HistoryItem.fromJson(sample),
                    }))
                    .sort((a, b) => b.count - a.count)
                    .map(({ count, sample }, index) => (
                      <AnswerSample
                        key={index}
                        title={`${Math.round(
                          (count / totalAnswerCount) * 100
                        )}% ${"users responded with"}`}
                        answer={sample}
                      />
                    ))
                : renderEmptyMessage();
          } else {
            modalContents = renderEmptyMessage();
          }

          return (
            <>
              {renderModal(modalContents)}
              <MessageView
                direction={historyItem.direction}
                positionInfo={positionInfo}
                skipAnimation={skipAnimation}
              >
                <div className={styles.message}>
                  <div className={styles.icon}>
                    <AnswerStatsIcon />
                  </div>
                  <Button onClick={() => setIsModalOpen(true)}>
                    <Trans>View Answer Stats</Trans>
                  </Button>
                </div>
              </MessageView>
            </>
          );
        }}
      />
    </HistoryComponent>
  );
}
