import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";

import AccessManagerBase from "../../featuresCommon/AccessManager";

export default function AccessManager({
  flowSequenceId: id,
}: {
  flowSequenceId: number;
}): ReactElement | null {
  return (
    <AccessManagerBase
      load={() => api.getFlowSequenceSharedWith(id)}
      share={(email) => api.shareFlowSequence(id, email)}
      unshare={(email) => api.unshareFlowSequence(id, email)}
    />
  );
}
