export class MCFlowEditorError extends Error {
  readonly _underlying: unknown;

  constructor(message: string, underlying?: unknown) {
    super(message);
    this.name = "MCFlowEditorError";
    // For Jest expectation to have the correct type
    Object.setPrototypeOf(this, MCFlowEditorError.prototype);
    this._underlying = underlying;
  }

  get underlying(): unknown {
    return this._underlying;
  }
}

export class MCNullFlowError extends MCFlowEditorError {
  constructor() {
    super("Null or undefined chat flow");
    this.name = "MCNullFlowError";
    // For Jest expectation to have the correct type
    Object.setPrototypeOf(this, MCNullFlowError.prototype);
  }
}
