import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
} from "../../components/LoadingLayout";
import FlowAndSeqFilterBar, {
  BLANK_FILTER,
  filterEquals,
  FlowAndSeqFilter,
} from "../../featuresCommon/FlowAndSeqFilterBar";
import FlowAndSeqGetStarted from "../../featuresCommon/FlowAndSeqGetStarted";
import FlowItem from "./FlowItem";
import { clearFlows, loadFlows, selectFlowList } from "./flowListSlice";
import styles from "./FlowListView.module.css";

const LS_FLOW_FILTER_KEY = "flowFilter";

function getFilterFromLocalStorage(): FlowAndSeqFilter {
  const value = localStorage.getItem(LS_FLOW_FILTER_KEY);
  return value == null
    ? { ownership: null, searchTerm: "" }
    : JSON.parse(value);
}

function setFilterInLocalStorage(value: FlowAndSeqFilter): void {
  localStorage.setItem(LS_FLOW_FILTER_KEY, JSON.stringify(value));
}

export interface FlowListViewProps {
  className?: string;
  renderItem?: ((flow: api.FlowStub) => ReactElement) | null;
}

export default function FlowListView(
  props: FlowListViewProps
): ReactElement | null {
  const dispatch = useDispatch();

  const flowList = useSelector(selectFlowList);

  useEffect(() => {
    dispatch(loadFlows(getFilterFromLocalStorage()));

    return function cleanup() {
      dispatch(clearFlows());
    };
  }, [dispatch]);

  function buildResults(): ReactNode {
    if (flowList.loading === "pending") {
      return <LoadingLayout />;
    }
    if (flowList.loading === "rejected") {
      return <ErrorLayout message={flowList.error} />;
    }
    if (flowList.data == null) return null;
    if (flowList.data.flows.length === 0) {
      return (
        <NoResultsLayout
          message={
            filterEquals(flowList.filter, BLANK_FILTER)
              ? "You have no flows yet"
              : filterEquals(flowList.filter, {
                  ...BLANK_FILTER,
                  ownership: "shared",
                })
              ? "Nothing shared with you yet"
              : "There are no matching results"
          }
          footer={
            filterEquals(flowList.filter, BLANK_FILTER) && (
              <FlowAndSeqGetStarted />
            )
          }
        />
      );
    }
    return flowList.data.flows.map(
      (flow) =>
        props.renderItem?.(flow) ?? <FlowItem key={flow.id} flow={flow} />
    );
  }

  return (
    <div className={classNames(styles.root, props.className)}>
      <FlowAndSeqFilterBar
        value={flowList.filter}
        onChange={(value) => {
          setFilterInLocalStorage(value);
          dispatch(loadFlows(value));
        }}
      />
      <div className={classNames(styles.results)}>{buildResults()}</div>
    </div>
  );
}
