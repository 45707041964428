import { BuiltInTransformers, PromptDefinition } from "@xflr6/chatbot";
import React, { ReactElement } from "react";

import Checkbox from "../../../../components/forms/Checkbox";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";

function hasTransformer(
  normalizedAnswers: PromptDefinition["normalizeAnswers"],
  name: string
) {
  return !!normalizedAnswers?.find((v) => v.name === name);
}

function setTransformer(
  normalizedAnswers: PromptDefinition["normalizeAnswers"],
  name: string,
  set: boolean
) {
  if (set) {
    if (!hasTransformer(normalizedAnswers, name)) {
      return [...(normalizedAnswers ?? []), { name }];
    }
  } else {
    if (normalizedAnswers != null) {
      const i = normalizedAnswers.findIndex((v) => v.name === name);
      if (i !== -1) {
        const result = [
          ...normalizedAnswers.slice(0, i),
          ...normalizedAnswers.slice(i + 1),
        ];
        return result.length === 0 ? null : result;
      }
    }
  }
  return normalizedAnswers;
}

function setTransformers(
  normalizedAnswers: PromptDefinition["normalizeAnswers"],
  names: Record<string, boolean>
) {
  return Object.entries(names).reduce(
    (acc, [name, set]) => setTransformer(acc, name, set),
    normalizedAnswers
  );
}

export default function NormalizeAnswers(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  const { normalizeAnswers } = prompt.definition;

  return (
    <>
      <Checkbox
        label="Ignore case"
        checked={hasTransformer(
          normalizeAnswers,
          BuiltInTransformers.lowercase
        )}
        onChange={(event) => {
          updateFields({
            normalizeAnswers: setTransformer(
              normalizeAnswers,
              BuiltInTransformers.lowercase,
              event.target.checked
            ),
          });
        }}
      />
      <Checkbox
        label="Ignore extra spaces"
        checked={hasTransformer(
          normalizeAnswers,
          BuiltInTransformers.collapseWhitespace
        )}
        onChange={(event) => {
          updateFields({
            normalizeAnswers: setTransformers(normalizeAnswers, {
              [BuiltInTransformers.trimWhitespace]: event.target.checked,
              [BuiltInTransformers.collapseWhitespace]: event.target.checked,
              [BuiltInTransformers.removeWhitespace]: false,
            }),
          });
        }}
      />
      <Checkbox
        label="Ignore all spaces"
        checked={hasTransformer(
          normalizeAnswers,
          BuiltInTransformers.removeWhitespace
        )}
        onChange={(event) => {
          updateFields({
            normalizeAnswers: setTransformers(normalizeAnswers, {
              [BuiltInTransformers.trimWhitespace]: event.target.checked,
              [BuiltInTransformers.removeWhitespace]: event.target.checked,
              [BuiltInTransformers.collapseWhitespace]: false,
            }),
          });
        }}
      />
    </>
  );
}
