import React from "react";
import {
  Popover as PopoverBase,
  PopoverProps,
  PopoverState,
} from "react-tiny-popover";

/**
 * Drop-in replacement for react-tiny-popover that hides the popver instead of
 * having it float on screen even when the anchor element goes off the screen.
 */
const Popover: React.FC<PopoverProps & { preserveOnNudge?: boolean }> = ({
  preserveOnNudge,
  content,
  ...restProps
}) => {
  return (
    <PopoverBase
      {...restProps}
      content={
        preserveOnNudge
          ? content
          : React.isValidElement(content)
          ? (state) => (isNudged(state) ? <div /> : content)
          : typeof content === "function"
          ? (state) => (isNudged(state) ? <div /> : content(state))
          : content
      }
    />
  );
};

const isNudged = (state: PopoverState) => state.nudgedTop || state.nudgedLeft;

export default Popover;
