import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";

import { AsyncStatus } from "../app/store";
import FilledButton from "../components/buttons/FilledButton";
import FlatButton from "../components/buttons/FlatButton";
import FieldError from "../components/forms/FieldError";
import formStyles from "../components/forms/formStyles.module.css";
import Input from "../components/forms/Input";
import {
  ErrorData,
  getSerializableError,
  getValidationErrorMessage,
} from "../utils/error";
import styles from "./AccessManager.module.css";

export default function AccessManager({
  load,
  share,
  unshare,
}: {
  load: () => Promise<api.UserAccess[]>;
  share: (email: string) => Promise<string>;
  unshare: (email: string) => Promise<void>;
}): ReactElement {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState<AsyncStatus>("idle");
  const [users, setUsers] = useState<api.UserAccess[]>([]);
  const [sharing, setSharing] = useState<AsyncStatus>("idle");
  const [shareError, setShareError] = useState<ErrorData | null>(null);
  const [unsharing, setUnsharing] = useState<AsyncStatus>("idle");
  const [unshareEmail, setUnshareEmail] = useState<string>();

  useEffect(() => {
    setLoading("pending");
    setUsers([]);
    load()
      .then((users) => {
        setLoading("fulfilled");
        setUsers(users);
      })
      .catch(() => {
        setLoading("rejected");
      });
  }, [load]);

  const buttonsDisabled = sharing === "pending" || unsharing === "pending";

  function renderUsers() {
    if (loading === "pending") {
      return <div className={styles.users_status}>Fetching...</div>;
    } else if (loading === "rejected") {
      return <div className={styles.users_status}>Failed to fetch users</div>;
    } else if (users.length === 0) {
      return <div className={styles.users_status}>Not shared with anyone</div>;
    } else {
      return users.map(({ email }) => (
        <div key={email} className={styles.user}>
          <div className={styles.user_details}>
            <div className={styles.user_email}>{email}</div>
            <div className={styles.user_role}>Collaborator</div>
          </div>
          <div className={styles.user_actions}>
            <FlatButton
              disabled={buttonsDisabled}
              onClick={async () => {
                setUnsharing("pending");
                setUnshareEmail(email);
                try {
                  await unshare(email);
                  setUnsharing("fulfilled");
                  const index = users.findIndex((u) => u.email === email);
                  if (index >= 0) {
                    setUsers([
                      ...users.slice(0, index),
                      ...users.slice(index + 1),
                    ]);
                  }
                } catch (error) {
                  setUnsharing("rejected");
                  addToast(`Failed to remove access for ${email}`, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                } finally {
                  setUnshareEmail(undefined);
                }
              }}
            >
              {unsharing === "pending" && unshareEmail === email
                ? "Removing..."
                : "Remove"}
            </FlatButton>
          </div>
        </div>
      ));
    }
  }

  const emailInputRef = useRef<HTMLInputElement>(null);

  async function addAccess() {
    if (emailInputRef.current != null) {
      setSharing("pending");
      setShareError(null);
      try {
        const email = emailInputRef.current.value;
        const name = await share(email);
        setSharing("fulfilled");
        setUsers([...users, { email, name }]);
        emailInputRef.current.select();
      } catch (error) {
        setSharing("rejected");
        setShareError(getSerializableError(error));
      }
    }
  }

  return (
    <div className={styles.root}>
      <div className={formStyles.field}>
        <div className={styles.inputBar}>
          <Input
            type="email"
            styleVariant="outlined"
            className={styles.inputBar_input}
            placeholder="Enter email address"
            autoFocus
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                addAccess();
              }
            }}
            ref={emailInputRef}
          />
          <FilledButton disabled={buttonsDisabled} onClick={addAccess}>
            {sharing === "pending" ? (
              "Adding..."
            ) : (
              <>
                <FaPlus size={14} />
                &nbsp;Add
              </>
            )}
          </FilledButton>
        </div>
        {shareError != null && (
          <FieldError
            error={
              getValidationErrorMessage(shareError, "with") ??
              "Failed to add access for this email"
            }
          />
        )}
      </div>
      <div className={styles.users}>{renderUsers()}</div>
    </div>
  );
}
