import classNames from "classnames";
import { prop } from "ramda";
import React, { ReactElement, ReactNode, useState } from "react";
import { FaCaretDown } from "react-icons/fa";

import Menu from "../menus/Menu";
import MenuItem from "../menus/MenuItem";
import Popover from "../Popover";
import ButtonGroup from "./ButtonGroup";
import FilledButton from "./FilledButton";
import styles from "./SplitButton.module.css";

export interface SplitButtonOption {
  value: string | null;
  label: ReactNode;
}

export interface SplitButtonProps {
  className?: string;
  partClassName?: string;
  icon?: ReactElement | null;
  options: SplitButtonOption[];
  renderMenuOption?: ((option: SplitButtonOption) => ReactNode) | null;
  renderSelectedOption?: ((option: SplitButtonOption) => ReactNode) | null;
  selectedValue?: string | null;
  onClick: (value: string | null) => void;
}

export default function SplitButton(props: SplitButtonProps): ReactElement {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function renderMenuOption(option: SplitButtonOption): ReactNode {
    return (props.renderMenuOption ?? prop("label"))(option);
  }

  function renderSelectedOption(option: SplitButtonOption): ReactNode {
    return (props.renderSelectedOption ?? prop("label"))(option);
  }

  const selectedOption =
    props.options.find(({ value }) => value === props.selectedValue) ??
    props.options[0];

  return (
    <div className={classNames(styles.root, props.className)}>
      <ButtonGroup>
        <FilledButton
          className={props.partClassName}
          onClick={() => {
            props.onClick(selectedOption.value);
          }}
        >
          {renderSelectedOption(selectedOption)}
        </FilledButton>
        <Popover
          positions={["top", "bottom"]}
          reposition
          isOpen={isMenuOpen}
          onClickOutside={() => setIsMenuOpen(false)}
          containerClassName={styles.menu}
          content={
            <Menu
              autoClose
              closeOnEsc
              onRequestClose={() => setIsMenuOpen(false)}
            >
              {props.options.map((option, index) =>
                option.value !== selectedOption.value ? (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      props.onClick(option.value);
                    }}
                  >
                    {renderMenuOption(option)}
                  </MenuItem>
                ) : null
              )}
            </Menu>
          }
        >
          <FilledButton
            className={classNames(styles.dropdownPart, props.partClassName)}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {props.icon ?? <FaCaretDown size={16} />}
          </FilledButton>
        </Popover>
      </ButtonGroup>
    </div>
  );
}
