import * as api from "@xflr6/chatbot-api";
import { isBlank } from "@xflr6/utils";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import MyTippy from "../components/MyTippy";
import { selectAuth } from "../features/auth/authSlice";
import styles from "./SharedByBadge.module.css";

export default function SharedByBadge({
  user,
}: {
  user: api.OwnerDetails;
}): ReactElement | null {
  const { currentUser } = useSelector(selectAuth);

  if (!currentUser || currentUser.email === user.email) {
    return null;
  }

  return (
    <MyTippy
      interactive
      content={
        <div>
          <div className={styles.title}>Shared by:</div>
          {!isBlank(user.name) && (
            <div className={styles.name}>{user.name}</div>
          )}
          <div className={styles.email}>{user.email}</div>
        </div>
      }
    >
      <div className={styles.root}>Shared</div>
    </MyTippy>
  );
}
