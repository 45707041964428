import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/store";
import NamesEditorBase from "../../featuresCommon/NamesEditor";
import {
  selectFlowSequenceDirty,
  selectFlowSequenceLanguage,
  selectFlowSequenceLanguages,
  selectFlowSequenceName,
  selectFlowSequenceOwnership,
  selectFlowSequenceTName,
  updateFlowSequence,
} from "./flowSequenceEditorSlice";

export default function NamesEditor({
  onUpdate,
}: {
  onUpdate?: (name: string) => void;
}): ReactElement | null {
  const dispatch = useAppDispatch();

  const name = useSelector(selectFlowSequenceName);
  const language = useSelector(selectFlowSequenceLanguage);
  const tName = useSelector(selectFlowSequenceTName);
  const languages = useSelector(selectFlowSequenceLanguages);

  const dirty = useSelector(selectFlowSequenceDirty);
  const ownership = useSelector(selectFlowSequenceOwnership);

  if (name == null || tName == null) return null;

  return (
    <NamesEditorBase
      name={name}
      language={language}
      tName={tName}
      languages={languages}
      isEditable={ownership === "own"}
      showDirtyDot={dirty ?? false}
      onSubmit={async (name, tName, closeEditor) => {
        const result = await dispatch(updateFlowSequence({ name, tName }));
        closeEditor();
        if (result.meta.requestStatus !== "rejected") {
          onUpdate?.(name);
        }
      }}
    />
  );
}
