import { OrdChatFlowDefinition, PromptDefinition } from "@xflr6/chatbot";

import { MCFlowEditorError } from "../../errors";
import { forEachPromptRef } from "./forEachPromptRef";

/**
 * Removes a prompt from the chat flow definition.
 *
 * By default only a dry run is executed, i.e. all logic is executed but the
 * prompt is not actually removed from the definition.
 *
 * @param index
 * @param flowDef
 * @param dryRun - defaults to true
 * @returns the definition of the prompt removed along with some usage stats
 */
export function removePrompt(
  index: number,
  flowDef: OrdChatFlowDefinition,
  dryRun = true
): {
  usageCount: number;
  promptUsages: string[];
  commonAnsUsages: string[];
  definition: PromptDefinition;
} {
  const { name, definition } = flowDef.promptsArray[index];
  if (name === "start") {
    throw new MCFlowEditorError("The 'start' prompt cannot be removed");
  }

  let usageCount = 0;
  const promptUsages = new Set<string>();
  const commonAnsUsages = new Set<string>();
  forEachPromptRef(
    name,
    (referrerName, referrerLocation) => {
      usageCount++;
      switch (referrerLocation) {
        case "prompt":
          promptUsages.add(referrerName);
          break;
        case "common_ans":
          commonAnsUsages.add(referrerName);
          break;
      }
      return !dryRun;
    },
    flowDef
  );

  if (!dryRun) {
    flowDef.promptsArray.splice(index, 1);
  }

  return {
    usageCount: usageCount,
    promptUsages: Array.from(promptUsages),
    commonAnsUsages: Array.from(commonAnsUsages),
    definition,
  };
}
