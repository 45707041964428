import React, { ReactElement } from "react";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";

import FlatButton from "./FlatButton";

export default function BackButton(props: {
  disabled?: boolean;
}): ReactElement {
  const history = useHistory();

  return (
    <FlatButton onClick={() => history.goBack()} disabled={props.disabled}>
      <MdArrowBack size={22} />
    </FlatButton>
  );
}
