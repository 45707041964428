import React, { ReactElement } from "react";
import { Element } from "slate";

import { ImageProps } from ".";
import Thumbnail from "./Thumbnail";

export default function ImageStatic({
  element,
}: {
  element: Element;
}): ReactElement {
  const imageProps = element.imageProps as ImageProps;
  return (
    <Thumbnail
      src={imageProps.src}
      alt={imageProps.alt}
      title={imageProps.title}
    />
  );
}
