import { not } from "ramda";
import { useEffect, useRef, useState } from "react";

export default function useDelayedToggle(
  initialValue: boolean,
  {
    trueDelayInMs = 150,
    falseDelayInMs = 200,
  }: {
    trueDelayInMs?: number;
    falseDelayInMs?: number;
  }
): {
  value: boolean;
  setTrueWithDelay: VoidFunction;
  setTrueImmediate: VoidFunction;
  setFalseWithDelay: VoidFunction;
  setFalseImmediate: VoidFunction;
  toggleImmediate: VoidFunction;
} {
  const [value, setValue] = useState(initialValue);
  const timer = useRef<NodeJS.Timeout>();
  function clearTimer() {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }

  const setTrueImmediate = () => {
    clearTimer();
    setValue(true);
  };
  const setTrueWithDelay = () => {
    clearTimer();
    setTimeout(() => {
      if (!timer.current) {
        setValue(true);
      }
    }, trueDelayInMs);
  };
  const setFalseImmediate = () => {
    clearTimer();
    setValue(false);
  };
  const setFalseWithDelay = () => {
    clearTimer();
    timer.current = setTimeout(() => setValue(false), falseDelayInMs);
  };
  const toggleImmediate = () => setValue(not);

  useEffect(() => clearTimer, []);

  return {
    value,
    setTrueWithDelay,
    setTrueImmediate,
    setFalseWithDelay,
    setFalseImmediate,
    toggleImmediate,
  };
}
