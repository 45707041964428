import React, { ReactElement } from "react";
import { IoCheckmarkDone } from "react-icons/io5";

import styles from "./CompletedIcon.module.css";

export default function CompletedIcon({
  size,
}: {
  size?: string | number;
}): ReactElement {
  return (
    <div className={styles.root}>
      <IoCheckmarkDone size={size} />
    </div>
  );
}
