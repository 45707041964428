import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import MessageEditor from "../../../slateMessageEditor/MessageEditor";
import { selectFlowId } from "../../flowEditorSlice";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";
import styles from "./Notes.module.css";

export default function Notes(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateCustom } = usePromptConfigEditorOps();

  const flowId = useSelector(selectFlowId);

  return (
    <div className={styles.editor}>
      <MessageEditor
        value={(prompt.definition.custom?.notes ?? "") as string}
        placeholder="Feedback instructions etc..."
        uploadPathPrefix={`flows/${flowId}/`}
        onChangeDebounced={(value) => {
          updateCustom({ notes: value });
        }}
      />
    </div>
  );
}
