import React, { ReactElement } from "react";

import styles from "./MenuDivider.module.css";

function Line() {
  return <div className={styles.line} />;
}

export default function MenuDivider({
  title,
}: {
  title?: string | null;
}): ReactElement {
  return title == null ? (
    <Line />
  ) : (
    <div className={styles.row}>
      <Line />
      <div className={styles.title}>{title}</div>
      <Line />
    </div>
  );
}
