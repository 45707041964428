import classNames from "classnames";
import React, { ReactElement } from "react";
import { MdContentCopy } from "react-icons/md";
import { useToasts } from "react-toast-notifications";

import FlatButton from "../components/buttons/FlatButton";
import MyTippy from "../components/MyTippy";
import styles from "./CopyToClipboardButton.module.css";

async function copyToClipboard(text: string): Promise<void> {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    throw new Error("Clipboard functionality not available");
  }
}

export default function CopyToClipboardButton({
  className,
  label,
  text,
}: {
  className?: string;
  label: string;
  text: string;
}): ReactElement {
  const { addToast } = useToasts();

  return (
    <MyTippy content="Click to copy">
      <FlatButton
        className={classNames(styles.root, className)}
        onClick={async () => {
          try {
            await copyToClipboard(text);
            addToast("Copied to clipboard", {
              appearance: "success",
              autoDismiss: true,
            });
          } catch (e) {
            addToast("Failed to copy to clipboard", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }}
      >
        {label}&nbsp;
        <MdContentCopy size={12} />
      </FlatButton>
    </MyTippy>
  );
}
