import React, { ReactElement } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import xml from "react-syntax-highlighter/dist/esm/languages/hljs/xml";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";

SyntaxHighlighter.registerLanguage("xml", xml);

export default function XMLPreview(props: { code: string }): ReactElement {
  return (
    <SyntaxHighlighter language="xml" style={dark}>
      {props.code}
    </SyntaxHighlighter>
  );
}
