import * as api from "@xflr6/chatbot-api";
import { nullifyIfBlank } from "@xflr6/utils";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, {
  ComponentType,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaFileDownload, FaRedo } from "react-icons/fa";
import { IoPulse } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import { AsyncStatus } from "../../app/store";
import Button from "../../components/buttons/Button";
import FilledButton from "../../components/buttons/FilledButton";
import FlatButton from "../../components/buttons/FlatButton";
import Input from "../../components/forms/Input";
import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
} from "../../components/LoadingLayout";
import MyTippy from "../../components/MyTippy";
import Popover from "../../components/Popover";
import { primeFilter } from "../../features/identifiers/identifiersSlice";
import { AdminInputsIcon } from "./AdminInputsIcon";
import CompletedIcon from "./CompletedIcon";
import { IdOrigin } from "./IdOrigin";
import styles from "./ResponsesView.module.css";

function ItemListFooter<T extends api.ResponseStub>({
  responsesState,
  onLoadMoreResponses,
}: {
  responsesState: {
    loading: AsyncStatus;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadError: any;
    responsesData: api.Responses<T> | null;
  };
  onLoadMoreResponses: () => void;
}): ReactElement | null {
  if (responsesState.loading === "pending") {
    return <div className={styles.itemListFooter}>Loading...</div>;
  } else if (responsesState.loading === "rejected") {
    return (
      <div className={styles.itemListFooter}>
        <FlatButton onClick={() => onLoadMoreResponses()}>
          <FaRedo size={12} />
          &nbsp;Error, click to retry
        </FlatButton>
      </div>
    );
  } else {
    return null;
  }
}

export interface ResponsesViewProps<T extends api.ResponseStub> {
  filter: api.ResponsesFilter;
  responsesState: {
    loading: AsyncStatus;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadError: any;
    responsesData: api.Responses<T> | null;
  };
  currentResponseIndex?: number | null;
  onLoadResponses: (filter: api.ResponsesFilter) => void;
  onLoadMoreResponses: () => void;
  onLoadResponse: (index: number) => void;
  ResponseComponent: ComponentType;
  onDownloadResponses?: (
    filter: api.ResponsesFilter,
    withHistories?: boolean
  ) => Promise<void>;
}

export default function ResponsesView<T extends api.ResponseStub>({
  filter,
  responsesState,
  currentResponseIndex,
  onLoadResponses,
  onLoadMoreResponses,
  onLoadResponse,
  ResponseComponent,
  onDownloadResponses,
}: ResponsesViewProps<T>): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();

  function needsAdminInputs(response: T) {
    return (
      response.feedbackTotal > 0 ||
      response.scoreLaterTotal > 0 ||
      response.evaluationTotal > 0 ||
      response.externalAnswerTotal > 0
    );
  }

  function adminInputsPending(response: T) {
    return (
      response.feedbackPending > 0 ||
      response.scoreLaterPending > 0 ||
      response.evaluationPending > 0 ||
      response.externalAnswerPending > 0
    );
  }

  useEffect(() => {
    if (responsesState.loading === "idle") {
      onLoadResponses(filter);
    }
  }, [filter, responsesState.loading, onLoadResponses]);

  useEffect(() => {
    if (responsesState.responsesData?.responseLog.length === 1) {
      onLoadResponse(0);
    }
  }, [responsesState.responsesData, onLoadResponse]);

  function renderItemList(responses: api.Responses<T>) {
    return (
      <Virtuoso
        data={responses.responseLog}
        totalCount={responses.totalRecords}
        initialTopMostItemIndex={currentResponseIndex ?? 0}
        endReached={() => {
          if (responses.responseLog.length < responses.totalRecords) {
            onLoadMoreResponses();
          }
        }}
        itemContent={(index, response) => (
          <div
            key={response.identifier}
            className={classNames(styles.item, {
              [styles.item__selected]: index === currentResponseIndex,
              [styles.item__softDeleted]:
                filter?.inputsPending && !adminInputsPending(response),
            })}
            onClick={() => onLoadResponse(index)}
          >
            <div className={styles.item_info}>
              <div className={styles.item_info_title}>
                <IdOrigin origin={response.identifierOrigin} />
                {` ${response.identifier}`}
              </div>
              <div className={styles.item_info_subtitle}>
                {DateTime.fromISO(response.createdAt).toLocaleString(
                  DateTime.DATETIME_MED_WITH_SECONDS
                )}
              </div>
            </div>
            {response.completed && <CompletedIcon size={20} />}
            {
              <AdminInputsIcon
                className={classNames({
                  [styles.item_adminInputsIcon__hidden]: !needsAdminInputs(
                    response
                  ),
                })}
                pending={adminInputsPending(response)}
              />
            }
            <MyTippy content="See all of this user's activity">
              <FilledButton
                className={styles.item_statsIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(primeFilter({ search: response.identifier }));
                  history.push("/user_activity");
                }}
              >
                <IoPulse size={15} />
              </FilledButton>
            </MyTippy>
          </div>
        )}
        components={{
          Footer: function ItemListFooter_() {
            return (
              <ItemListFooter
                responsesState={responsesState}
                onLoadMoreResponses={onLoadMoreResponses}
              />
            );
          },
        }}
      />
    );
  }

  function renderItems(): ReactElement | null {
    if (responsesState.responsesData?.responseLog?.length) {
      return renderItemList(responsesState.responsesData);
    } else {
      if (responsesState.loading === "pending") {
        return <LoadingLayout />;
      } else if (responsesState.loading === "rejected") {
        return <ErrorLayout message={responsesState.loadError} />;
      } else if (responsesState.loading === "fulfilled") {
        return <NoResultsLayout message="There are no chats" />;
      } else {
        return null;
      }
    }
  }

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [isDownloaderOpen, setIsDownloaderOpen] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.itemsColumn}>
        <div className={styles.filter}>
          <Input
            ref={searchInputRef}
            type="search"
            styleVariant="outlined"
            className={styles.filter_searchInput}
            defaultValue={filter.search ?? ""}
            placeholder="Search"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onLoadResponses({
                  ...filter,
                  search: nullifyIfBlank(event.currentTarget.value),
                });
              }
            }}
          />
          <MyTippy content="Completed">
            <Button
              className={classNames(styles.filter_toggleButton, {
                [styles.filter_toggleButton__active]: filter.completed,
              })}
              onClick={() => {
                onLoadResponses({
                  ...filter,
                  search: nullifyIfBlank(searchInputRef.current?.value),
                  completed: !filter.completed,
                });
              }}
            >
              <CompletedIcon />
            </Button>
          </MyTippy>
          <MyTippy content="Input(s) pending" delay={[300, 0]}>
            <Button
              className={classNames(styles.filter_toggleButton, {
                [styles.filter_toggleButton__active]: filter.inputsPending,
              })}
              onClick={() => {
                onLoadResponses({
                  ...filter,
                  search: nullifyIfBlank(searchInputRef.current?.value),
                  inputsPending: !filter.inputsPending,
                });
              }}
            >
              <AdminInputsIcon pending />
            </Button>
          </MyTippy>
        </div>
        {onDownloadResponses &&
          responsesState.responsesData != null &&
          responsesState.responsesData.responseLog.length > 0 && (
            <div className={styles.topBar}>
              <Popover
                key="more"
                positions={["bottom"]}
                align="center"
                reposition={false}
                isOpen={isDownloaderOpen}
                onClickOutside={() => setIsDownloaderOpen(false)}
                containerClassName={styles.dlPopover}
                content={
                  <div className={styles.dlPopover_body}>
                    <div className={styles.dlPopover_title}>
                      Download summary
                    </div>
                    <FilledButton
                      className={styles.dlPopover_button}
                      onClick={async () => {
                        await onDownloadResponses(filter);
                      }}
                    >
                      Download&nbsp;
                      <FaFileDownload />
                    </FilledButton>
                    <div className={styles.dlPopover_title}>
                      Download with chat histories
                    </div>
                    <div className={styles.dlPopover_hint}>
                      <div>
                        Along with summary data, includes full chat histories
                      </div>
                      <div className={styles.dlPopover_hint_experimental}>
                        This is an experimental feature. It may result in a
                        large file, and potentially fail if the file size
                        becomes too big.
                      </div>
                    </div>
                    <FilledButton
                      className={styles.dlPopover_button}
                      onClick={async () => {
                        await onDownloadResponses(filter, true);
                      }}
                    >
                      Download&nbsp;
                      <FaFileDownload />
                    </FilledButton>
                  </div>
                }
              >
                <FlatButton onClick={() => setIsDownloaderOpen(true)}>
                  Download&nbsp;
                  <FaFileDownload />
                </FlatButton>
              </Popover>
            </div>
          )}
        <div className={styles.items}>{renderItems()}</div>
      </div>
      <div className={styles.chatViewColumn}>
        {responsesState.responsesData != null &&
          responsesState.responsesData.responseLog.length > 0 && (
            <ResponseComponent />
          )}
      </div>
    </div>
  );
}
