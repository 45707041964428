import classNames from "classnames";
import React, { ReactElement, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FlatButton from "../../../components/buttons/FlatButton";
import { useMarkerBar } from "../../../components/MarkerBar";
import MyTippy from "../../../components/MyTippy";
import { REMOTE_FLOW_PATTERN } from "../../../featuresCommon/utils";
import { selectPromptEasyNames } from "../selectPromptEasyNames";
import styles from "./NextKeyEditor.module.css";
import PromptKeySelect, { getKeyDisplayName } from "./PromptKeySelect";

export interface NextKeyEditorProps {
  label?: string;
  promptIndex: number;
  initialNextKey?: string | null;
  onChange?: ((selectedKey: string | null | undefined) => void) | null;
}

export default function NextKeyEditor({
  label = "To",
  promptIndex,
  initialNextKey,
  onChange,
}: NextKeyEditorProps): ReactElement | null {
  const promptEasyNames = useSelector(selectPromptEasyNames);

  const [isEditing, setIsEditing] = useState(false);

  const [shouldScroll, setShouldScroll] = useState(false);

  const history = useHistory();

  const { scrollToMarker } = useMarkerBar();

  const handleKeyDown = useMemo(() => {
    return (event: KeyboardEvent) => {
      if (event.key === "Control") {
        setShouldScroll(true);
      }
    };
  }, []);

  const handleKeyUp = useMemo(() => {
    return (event: KeyboardEvent) => {
      if (event.key === "Control") {
        setShouldScroll(false);
      }
    };
  }, []);

  if (promptEasyNames == null) return null;

  const match = initialNextKey?.match(REMOTE_FLOW_PATTERN);

  return (
    <div className={styles.root}>
      <div className={styles.label}>{label}</div>
      <div className={styles.body}>
        {isEditing ? (
          <PromptKeySelect
            promptIndex={promptIndex}
            initialPromptKey={initialNextKey}
            onChange={(key) => {
              if (onChange != null) {
                onChange(key);
              }
              setIsEditing(false);
            }}
            isClearable
            autoFocus
            showBelowOption
            showRemoteOptions
            onBlur={() => setIsEditing(false)}
          />
        ) : (
          <MyTippy
            content={match != null ? "Go to flow" : "Go to prompt"}
            visible={shouldScroll}
          >
            <FlatButton
              className={classNames(styles.static, {
                [styles.static__shouldScroll]: shouldScroll,
              })}
              onPointerEnter={(event) => {
                if (event.ctrlKey || event.metaKey) {
                  setShouldScroll(true);
                }

                document.addEventListener("keydown", handleKeyDown);
                document.addEventListener("keyup", handleKeyUp);
              }}
              onPointerLeave={() => {
                setShouldScroll(false);

                document.removeEventListener("keydown", handleKeyDown);
                document.removeEventListener("keyup", handleKeyUp);
              }}
              onClick={() => {
                if (shouldScroll) {
                  if (match != null) {
                    history.push(`/flows/${match[1]}/edit`);
                  } else if (initialNextKey?.startsWith(".")) {
                    scrollToMarker(initialNextKey.substring(1));
                  }
                } else {
                  setIsEditing(true);
                }
              }}
            >
              <div className={styles.static_key}>
                {getKeyDisplayName(
                  promptEasyNames.toEasyKey(initialNextKey ?? "---")
                )}
              </div>
            </FlatButton>
          </MyTippy>
        )}
      </div>
    </div>
  );
}
