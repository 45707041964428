import { isMessageBlank, PromptDefinition } from "@xflr6/chatbot";
import { equals } from "ramda";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

import Checkbox from "../../../../components/forms/Checkbox";
import { convertToMessage, convertToMessages } from "../../flowEditorSlice";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";
import styles from "./ContentType.module.css";

type Option = { value: string | null; label: string };

const OPTIONS: Option[] = [
  { value: null, label: "Markdown" },
  { value: "video", label: "Video" },
  { value: "meeting", label: "Meeting" },
  { value: "remote", label: "API" },
];

function getOption(value?: string | null): Option | undefined {
  return OPTIONS.find((o) => o.value === (value ?? null));
}

// Seed messages by content type
const SEED_MESSAGES: Record<string, PromptDefinition["message"]> = {
  video: "",
  meeting: {
    url: "",
  },
  remote: {
    url: "",
    statusMessages: {
      pending: null,
      rejected: null,
      fulfilledNull: null,
    },
  },
};

export function getSeedMessage(
  contentType?: string | null
): PromptDefinition["message"] {
  const seedMarkdownMessage = "";
  if (contentType == null) return seedMarkdownMessage;
  return SEED_MESSAGES[contentType] ?? seedMarkdownMessage;
}

export default function ContentType(): ReactElement {
  const dispatch = useDispatch();
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  const { message, contentType, messages } = prompt.definition;

  function updateContentType(newContentType: string | null | undefined) {
    if (equals(getSeedMessage(newContentType), getSeedMessage(contentType))) {
      updateFields({ contentType: newContentType });
    } else if (
      isMessageBlank(message, contentType) ||
      window.confirm(
        "The current message is incompatible with this type, and will be lost." +
          " Do you want to go ahead?"
      )
    ) {
      updateFields({
        contentType: newContentType ?? null,
        message: getSeedMessage(newContentType),
      });
    }
  }

  return (
    <>
      {messages == null && (
        <Select
          className={styles.select}
          options={OPTIONS}
          value={getOption(prompt.definition.contentType)}
          onChange={(selectedOption) => {
            updateContentType((selectedOption as Option | undefined)?.value);
          }}
        />
      )}
      <Checkbox
        label="Multi-step message"
        checked={messages != null}
        onChange={(event) => {
          if (event.target.checked) {
            dispatch(convertToMessages({ promptIndexOrName: prompt.name }));
          } else {
            if (
              messages == null ||
              messages.length < 2 ||
              window.confirm(
                `The message has ${messages.length} steps.` +
                  " All steps except for the first one will be lost. Are you sure?"
              )
            ) {
              dispatch(convertToMessage({ promptIndexOrName: prompt.name }));
            }
          }
        }}
      />
    </>
  );
}
