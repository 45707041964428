import {
  Chat,
  ChatFlow,
  ChatFlowDefinition,
  ChatJson,
  ChatOptions,
  ChatSeqItem,
  ChatSeqItemDefinition,
  ChatSequence,
  ChatSequenceView,
  createBaseCssVariablesStream,
  FlowId,
  getItemDuration,
  OrdChatFlowDefinition,
  PromptKey,
} from "@xflr6/chatbot";
import {
  getMatchingRemotePromptNames,
  setAnswerSamplesBuilder,
  setAnswerStatsBuilder,
  setMeetingBuilder,
} from "@xflr6/chatbot_customizations";
import * as api from "@xflr6/chatbot-api";
import { nullifyIfBlank } from "@xflr6/utils";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  ErrorLayout,
  SelectItemLayout,
} from "../../components/LoadingLayout";
import MyTippy from "../../components/MyTippy";
import { selectEffectiveResponseFlowSequenceSettings } from "../../featuresCommon/selectors";
import { AdminInputsIcon } from "../../featuresCommon/stats/AdminInputsIcon";
import ChatViewNav from "../../featuresCommon/stats/ChatViewNav";
import EvaluationMessageBase, {
  EvaluationMessageProps,
} from "../../featuresCommon/stats/EvaluationMessage";
import RecdHistoryItemView from "../../featuresCommon/stats/RecdHistoryItemView";
import SentHistoryItemView from "../../featuresCommon/stats/SentHistoryItemView";
import {
  FIFTEEN_MINUTES_IN_MS,
  getChatSeqTimeSpentInWords,
  getChatTimeSpentInWords,
} from "../../featuresCommon/stats/utils";
import { CHAT_ID_ARG, VERSION_ARG } from "../../featuresCommon/utils";
import { applySettingsToBaseCss } from "../../utils/settings";
import styles from "./ChatSequenceHistoryView.module.css";
import {
  removeFromResponses,
  selectResponseAdminInputLocations,
  selectResponseAtIndex,
  selectResponseState,
  selectStatsOwnership,
  updateResponseEvaluation,
  updateResponseHistory,
} from "./flowSequenceStatsSlice";

// We create a custom `EvaluationMessage` component in order to be able to use
// `dispatch`.
function EvaluationMessage(
  props: Omit<EvaluationMessageProps, "onUpdateEvaluation"> & {
    flowSequenceId: number;
    chatIndex: number;
  }
): ReactElement {
  const dispatch = useDispatch();

  return (
    <EvaluationMessageBase
      {...props}
      onUpdateEvaluation={async (evaluation, decrementPending) => {
        await api.updateEvaluation(
          props.flowId,
          props.identifier,
          props.historyItem.promptKeyStr,
          evaluation,
          props.flowSequenceId
        );
        dispatch(
          updateResponseEvaluation({
            chatIndex: props.chatIndex,
            flowSequenceId: props.flowSequenceId,
            identifier: props.identifier,
            promptKeyStr: props.historyItem.promptKeyStr,
            evaluation,
            decrementPending,
          })
        );
      }}
    />
  );
}

class ResponseFlow extends ChatFlow {
  async getDefinition(): Promise<OrdChatFlowDefinition> {
    const chatId = parseInt((this.id.args[CHAT_ID_ARG] ?? [])[0], 10);
    const version = parseInt((this.id.args[VERSION_ARG] ?? [])[0], 10);
    if (isNaN(chatId) || isNaN(version)) {
      throw new Error("Valid flow id and/or version not found in arguments");
    } else {
      return (await api.getPublishedFlow(chatId, version, this.chat.language))
        .definition;
    }
  }

  async onDefinitionAvailable(definition: ChatFlowDefinition) {
    getMatchingRemotePromptNames(definition, api.evaluationsUrlPattern).forEach(
      (name) =>
        this.setHistoryRecdComponentBuilder(
          name,
          (historyItem, positionInfo, promptInput) => {
            const responseChat = this.chat as ResponseChat;
            return (
              <EvaluationMessage
                historyItem={historyItem}
                positionInfo={positionInfo}
                promptInput={promptInput}
                flowId={responseChat.seqItem.flowId}
                identifier={responseChat.seqItem.sequence.identifier}
                initialEvaluation={
                  responseChat.seqItem.evaluations[historyItem.promptKeyStr]
                }
                chatIndex={responseChat.seqItem.index}
                flowSequenceId={responseChat.seqItem.sequence.flowSequenceId}
              />
            );
          }
        )
    );
  }

  async onDefinitionProcessed(definition: ChatFlowDefinition) {
    setAnswerSamplesBuilder(this, definition, api.answerSamplesUrlPattern);
    setAnswerStatsBuilder(this, definition, api.answerStatsUrlPattern);
    setMeetingBuilder(this, definition);
  }
}

class ResponseChat extends Chat {
  readonly seqItem: ResponseChatSeqItem;

  constructor(
    name: string,
    seqItem: ResponseChatSeqItem,
    options?: ChatOptions
  ) {
    super(name, options);
    this.seqItem = seqItem;
  }

  handleGetFlow(flowId: FlowId): ChatFlow {
    return new ResponseFlow(flowId, this);
  }
}

class EmptyResponseFlow extends ChatFlow {
  constructor(id: FlowId, chat: Chat) {
    super(id, chat);

    this.setPromptHandler("start", {
      // eslint-disable-next-line react/display-name
      historyRecdComponentBuilder: () => () => (
        <div className={styles.emptyChatMessage}>
          The user has not started this step yet
        </div>
      ),
      // eslint-disable-next-line react/display-name
      inputComponentBuilder: () => () => <div />,
    });
  }

  async getDefinition(): Promise<OrdChatFlowDefinition> {
    return {
      promptsArray: [
        {
          name: "start",
          definition: {
            message: "",
            // Dummy answer to prevent "You have completed this step" message
            answers: [{ message: "" }],
          },
        },
      ],
    };
  }
}

class EmptyResponseChat extends Chat {
  handleGetFlow(flowId: FlowId): ChatFlow {
    return new EmptyResponseFlow(flowId, this);
  }
}

class ResponseChatSeqItem extends ChatSeqItem {
  readonly sequence: ResponseChatSequence;

  constructor(
    definition: ChatSeqItemDefinition,
    index: number,
    sequence: ResponseChatSequence
  ) {
    super(definition, index);
    this.sequence = sequence;
  }

  get flowId() {
    return this.sequence.flowIds[this.index];
  }

  get evaluations() {
    return this.sequence.evaluations[this.index];
  }

  createChat(name: string, options?: ChatOptions): Chat {
    if (this.initialHistory != null) {
      const chat = new ResponseChat(name, this, options);
      chat.loadFromJson(this.initialHistory, this.promptKey);
      return chat;
    } else {
      // If there is no history then we show a "Not started" message. In order
      // to do this, we end up creating a "dummy" chat and custom rendering it.
      const chat = new EmptyResponseChat(name, options);
      chat.setPrompt(PromptKey.fromString("x.start"));
      return chat;
    }
  }
}

class ResponseChatSequence extends ChatSequence {
  readonly flowSequenceId: number;
  readonly identifier: string;
  readonly flowIds: number[];
  readonly evaluations: Record<string, string>[];

  constructor(
    name: string,
    response: api.ChatSequenceResponse,
    initialChatIndex: number,
    onAdminHistoryUpdate: (
      flowId: number,
      identifier: string,
      history: ChatJson,
      flowSequenceId: number,
      chatIndex: number
    ) => void
  ) {
    const lang = response.history.histories?.[0]?.language;
    let shouldUpdateAdminHistory = false;
    super(
      name,
      {
        items: response.flows.map((flow, index) => ({
          label: lang
            ? nullifyIfBlank(flow.tName[lang]) ?? flow.name
            : flow.name,
          promptKey: ".start",
          initialHistory: response.history.histories[index],
        })),
      },
      {
        language: lang,
        onHistoryChanged: (index, history) => {
          const lastItem = history[history.length - 1];
          if (lastItem != null) {
            if (lastItem.direction === "sent" && lastItem.answerExternally) {
              shouldUpdateAdminHistory = true;
            } else if (
              lastItem.direction === "received" &&
              lastItem.promptAnswerType !== "auto" &&
              shouldUpdateAdminHistory
            ) {
              onAdminHistoryUpdate(
                this.flowIds[index],
                this.identifier,
                this.chats[index].toJson(),
                this.flowSequenceId,
                index
              );
              shouldUpdateAdminHistory = false;
            }
          }
        },
        chatOptions: (index) => ({
          runMode: "admin",
          answerClickDelayMs: 0,
          defaultMinNextPromptDelayMs: 0,
          context: {
            userId: response.identifier,
            args: {
              originId: [response.flows[index].id.toString()],
            },
          },
        }),
      }
    );

    this.flowSequenceId = response.id;
    this.identifier = response.identifier;
    this.flowIds = response.flows.map(({ id }) => id);
    this.evaluations = response.evaluations;

    this.setCurrentIndex(initialChatIndex);
  }

  buildChatSeqItem(
    definition: ChatSeqItemDefinition,
    index: number
  ): ChatSeqItem {
    return new ResponseChatSeqItem(definition, index, this);
  }
}

function ItemAdminInputsIcon({
  chatIndex,
}: {
  chatIndex: number;
}): ReactElement {
  const responseStub = useSelector(selectResponseAtIndex);

  if (responseStub == null) {
    return <div className={styles.ItemAdminInputsIcon} />;
  } else {
    const item = responseStub.items[chatIndex];
    const hasAdminInputs =
      item.evaluationTotal + item.feedbackTotal + item.scoreLaterTotal > 0;
    const hasPendingAdminInputs =
      item.evaluationPending + item.feedbackPending + item.scoreLaterPending >
      0;

    return (
      <div className={styles.ItemAdminInputsIcon}>
        {hasAdminInputs && <AdminInputsIcon pending={hasPendingAdminInputs} />}
      </div>
    );
  }
}

function ResponseViewNav({
  chatIndex,
}: {
  chatIndex: number;
}): ReactElement | null {
  const responseAdminInputLocations = useSelector(
    selectResponseAdminInputLocations
  );

  const chatIndexAdminInputLocations = useMemo(
    () => responseAdminInputLocations[chatIndex],
    [responseAdminInputLocations, chatIndex]
  );

  return chatIndexAdminInputLocations?.length ? (
    <div className={styles.responseViewNav}>
      <ChatViewNav locations={chatIndexAdminInputLocations} />
    </div>
  ) : null;
}

export default function ChatSequenceHistoryView(): ReactElement {
  const dispatch = useDispatch();
  const responseState = useSelector(selectResponseState);
  const ownership = useSelector(selectStatsOwnership);

  const { addToast } = useToasts();

  const [chatSequence, setChatSequence] = useState<ResponseChatSequence | null>(
    null
  );
  const [isDestroying, setIsDestroying] = useState(false);

  const id = responseState.response?.id;
  // Storing this value in state triggers a full rerender of this component each
  // time a new item is selected in the ChatSequenceView dropdown menu. We could
  // probably push this piece of state out into a store, and then use it only in
  // the relevant components. This will prevent the rerender.
  const [chatIndex, setChatIndex] = useState<number>(0);
  useEffect(() => {
    setChatIndex(0);
  }, [id]);

  const onAdminHistoryUpdate = useCallback(
    async (
      flowId: number,
      identifier: string,
      history: ChatJson,
      flowSequenceId: number,
      chatIndex: number
    ) => {
      await api.updateManagedChatResponseHistory(
        flowId,
        identifier,
        history,
        flowSequenceId
      );
      dispatch(
        updateResponseHistory({
          data: history,
          historyItemIndex: 0,
          chatIndex,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    let chatSequence: ResponseChatSequence;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let subscription: any;
    if (responseState.response != null) {
      chatSequence = new ResponseChatSequence(
        "chatSequence",
        responseState.response,
        chatIndex,
        onAdminHistoryUpdate
      );
      setChatSequence(chatSequence);
      subscription = chatSequence.state.subscribe((s) => {
        setChatIndex(s?.currentIndex ?? 0);
      });
    }

    return function cleanup() {
      subscription?.unsubscribe();
      subscription = null;
      chatSequence?.dispose();
      setChatSequence(null);
    };
  }, [responseState.response, chatIndex, onAdminHistoryUpdate]);

  // TODO Remove outlierThreshold hard-coding
  function buildHeader(): ReactElement {
    const response = responseState.response;
    return (
      <div className={styles.header}>
        <MyTippy content="Time spent">
          <div className={styles.timeSpent}>
            <MdTimer />
            &nbsp;
            {response == null
              ? "--"
              : getChatSeqTimeSpentInWords(
                  response.history?.histories.map((h) => h?.history),
                  FIFTEEN_MINUTES_IN_MS
                )}
          </div>
        </MyTippy>
        {ownership === "own" && (
          <FilledButton
            className={styles.destroyButton}
            disabled={response == null || isDestroying}
            onClick={async () => {
              if (response == null) return;
              if (window.confirm("This is irreversible. Are you sure?")) {
                const { id: flowSequenceId, identifier } = response;
                try {
                  setIsDestroying(true);
                  await api.destroyChatSequenceResponse(
                    flowSequenceId,
                    identifier
                  );
                  dispatch(removeFromResponses({ flowSequenceId, identifier }));
                  setIsDestroying(false);
                } catch (error) {
                  setIsDestroying(false);
                  addToast("Failed to delete chat sequence.", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }
            }}
          >
            <FaRegTrashAlt />
            &nbsp;Delete
          </FilledButton>
        )}
      </div>
    );
  }

  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  const settings = useSelector(selectEffectiveResponseFlowSequenceSettings);
  useEffect(() => {
    applySettingsToBaseCss($baseCssVariables.current, settings);
  }, [settings]);

  function buildChatView(): ReactElement {
    if (responseState.loading === "pending") {
      return <LoadingLayout />;
    } else if (responseState.loading === "rejected") {
      return <ErrorLayout message={responseState.loadError} />;
    } else if (responseState.response == null) {
      return <SelectItemLayout message="Select an item to view" />;
    } else if (chatSequence == null) {
      return <LoadingLayout />;
    } else {
      const response = responseState.response;
      const historyItems = response.history?.histories[chatIndex]?.history;
      const millisecondsSpentPerItem =
        historyItems != null
          ? historyItems.map((item, index) =>
              getItemDuration(historyItems, index)
            )
          : null;

      return (
        <ChatSequenceView
          key={chatSequence.flowSequenceId}
          chatSequence={chatSequence}
          showScore
          disableKeyboardAnswer
          skipInitialAnimations
          renderHistoryItem={(renderProps, chatIndex) => {
            return renderProps.historyItem.direction === "sent" ? (
              <SentHistoryItemView
                {...renderProps}
                flowId={response.flows[chatIndex].id}
                onUpdateHistory={async (index, data) => {
                  await api.updateChatResponseHistory(
                    response.flows[chatIndex].id,
                    response.identifier,
                    index,
                    data,
                    response.id
                  );
                  dispatch(
                    updateResponseHistory({
                      chatIndex,
                      historyItemIndex: index,
                      data,
                    })
                  );
                }}
              />
            ) : (
              <RecdHistoryItemView
                {...renderProps}
                millisecondsSpent={
                  millisecondsSpentPerItem != null &&
                  renderProps.positionInfo.index != null
                    ? millisecondsSpentPerItem[renderProps.positionInfo.index]
                    : null
                }
                outlierThreshold={FIFTEEN_MINUTES_IN_MS}
                outlierBehaviour="clip"
              />
            );
          }}
          renderAfterDropdownOptionBadge={(chatIndex) => (
            <ItemAdminInputsIcon chatIndex={chatIndex} />
          )}
          renderAfterDropdownOptionTitle={(chatIndex) => {
            const historyItems = response.history.histories[chatIndex]?.history;
            return historyItems != null ? (
              <div className={styles.timeSpent}>
                {getChatTimeSpentInWords(historyItems, FIFTEEN_MINUTES_IN_MS)}
                &nbsp;
                <MdTimer />
              </div>
            ) : null;
          }}
          $baseCssVariables={$baseCssVariables.current}
          avatarSrc={settings?.avatarUrl}
        />
      );
    }
  }

  return (
    <div className={styles.root}>
      {buildHeader()}
      <div className={styles.responseView}>
        <ResponseViewNav chatIndex={chatIndex} />
        {buildChatView()}
      </div>
    </div>
  );
}
