import { unwrapResult } from "@reduxjs/toolkit";
import React, { ReactElement, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/store";
import { selectEffectiveFlowUserSettings } from "../../featuresCommon/selectors";
import SettingsFormBase, {
  SettingsFormController,
} from "../../featuresCommon/SettingsForm";
import { selectAuth } from "../auth/authSlice";
import { updateResponseSettings } from "../flowStats/flowStatsSlice";
import {
  selectFlowSettings,
  selectUpdating,
  updateFlow,
} from "./flowEditorSlice";

export default function SettingsForm({
  onSubmit,
  onError,
  onCancel,
  controller,
}: {
  onSubmit?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void;
  onCancel?: () => void;
  controller?: (c: SettingsFormController) => void;
}): ReactElement | null {
  const dispatch = useAppDispatch();
  const auth = useSelector(selectAuth);
  const settings = useSelector(selectFlowSettings);
  const userSettings = useSelector(selectEffectiveFlowUserSettings);
  const [updating] = useSelector(selectUpdating);

  const _onSubmit = useCallback(
    async (settings) => {
      unwrapResult(await dispatch(updateFlow({ settings })));
      // TODO Code smell: cross-usage of Redux slices
      dispatch(updateResponseSettings(settings));
      onSubmit?.();
    },
    [dispatch, onSubmit]
  );

  const baseSettings = useMemo(() => {
    if (auth?.defaultSettings && userSettings) {
      return { ...auth.defaultSettings, ...userSettings };
    }
    return null;
  }, [auth, userSettings]);

  if (settings == null || baseSettings == null) return null;

  return (
    <SettingsFormBase
      settings={settings}
      baseSettings={baseSettings}
      baseSettingsDesc="content-wide"
      onSubmit={_onSubmit}
      onError={onError}
      onCancel={onCancel}
      disabled={updating === "pending"}
      controller={controller}
    />
  );
}
