import classNames from "classnames";
import React, { ReactElement } from "react";

import Button from "./buttons/Button";
import styles from "./Tabs.module.css";

export interface TabsProps {
  isInTopBar?: boolean | null;
  className?: string;
  tabs: string[];
  tabClassName?: string;
  displayNames?: string[] | null;
  activeTab: string;
  setActiveTab: (tabName: string) => void;
  activeTabClassName?: string;
  trailing?: ReactElement | null;
}

export default function Tabs(props: TabsProps): ReactElement {
  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.root__isInTopBar]: props.isInTopBar,
        },
        props.className
      )}
    >
      {props.tabs.map((tabName, tabIndex) => (
        <Button
          key={tabName}
          className={classNames(styles.tab, props.tabClassName, {
            [styles.tab__isActive]: tabName === props.activeTab,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [props.activeTabClassName!]:
              props.activeTabClassName != null && tabName === props.activeTab,
          })}
          isActive={tabName === props.activeTab}
          onClick={() => props.setActiveTab(tabName)}
        >
          {props.displayNames == null ? tabName : props.displayNames[tabIndex]}
        </Button>
      ))}
      {props.trailing != null && (
        <div className={styles.trailing}>{props.trailing}</div>
      )}
    </div>
  );
}
