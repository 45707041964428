import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";

import {
  selectHasUnsavedChanges,
  selectIsFlowReadonly,
} from "./flowEditorSlice";

export default function NavAwayPrompt(): ReactElement {
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);
  const isFlowReadonly = useSelector(selectIsFlowReadonly);

  return (
    <Prompt
      when={hasUnsavedChanges && !isFlowReadonly}
      message="You have unsaved changes! Do you want to navigate away?"
    />
  );
}
