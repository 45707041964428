import React, { ReactElement } from "react";

import Checkbox from "../../../../components/forms/Checkbox";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";

export default function AllowMultiSelect(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();
  return (
    <Checkbox
      label="Allow multiple select"
      checked={prompt.definition.acceptsMultipleAnswers ?? false}
      onChange={(event) =>
        updateFields({ acceptsMultipleAnswers: event.target.checked })
      }
    />
  );
}
