import classNames from "classnames";
import React, { ReactElement, ReactNode } from "react";

import styles from "./MessageFooter.module.css";

export default function MessageFooter(props: {
  direction: "received" | "sent";
  bodyClassName?: string;
  body?: ReactNode;
  actionsClassName?: string;
  actions?: ReactNode;
}): ReactElement {
  return (
    <div className={classNames(styles.root, styles[`__${props.direction}`])}>
      {props.body && (
        <div className={classNames(styles.body, props.bodyClassName)}>
          {props.body}
        </div>
      )}
      {props.actions && (
        <div className={classNames(styles.actions, props.actionsClassName)}>
          {props.actions}
        </div>
      )}
    </div>
  );
}
