import * as api from "@xflr6/chatbot-api";
import { useConfigCatClient, User } from "configcat-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectAuth } from "./authSlice";

/**
 * Keeps ConfigCat updated with the current user.
 * Call as soon as possible within ConfigCatProvider.
 */
export default function SetConfigCatUserComponent(): null {
  const client = useConfigCatClient();
  const { currentUser } = useSelector(selectAuth);

  useEffect(() => {
    if (currentUser) {
      client.setDefaultUser(
        new User(api.getUserId(currentUser), currentUser.email)
      );
    } else {
      client.clearDefaultUser();
    }
  }, [client, currentUser]);

  return null;
}
