import {
  HistoryComponent,
  HistoryItem,
  MarkdownMessage,
  MessageView,
  PositionInfo,
  PromptInput,
  RemoteMessage,
  RemoteMessageShape,
} from "@xflr6/chatbot";
import React, { ReactElement, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Node } from "slate";

import FilledButton from "../../components/buttons/FilledButton";
import FlatButton from "../../components/buttons/FlatButton";
import MessageEditor from "../../features/slateMessageEditor/MessageEditor";
import { serializeToMarkdown } from "../../features/slateMessageEditor/utils";
import { AdminInputsIcon } from "./AdminInputsIcon";
import styles from "./EvaluationMessage.module.css";
import MessageFooter from "./MessageFooter";

export interface EvaluationMessageProps {
  historyItem: HistoryItem;
  positionInfo: PositionInfo;
  promptInput: PromptInput | null;
  flowId: number;
  identifier: string;
  initialEvaluation?: string | null;
  onUpdateEvaluation?: (
    evaluation: string,
    decrementPending?: boolean
  ) => Promise<void>;
}

export default function EvaluationMessage({
  historyItem,
  positionInfo,
  promptInput,
  flowId,
  initialEvaluation,
  onUpdateEvaluation,
}: EvaluationMessageProps): ReactElement {
  const { addToast } = useToasts();

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const rawEvaluation = useRef<Node[]>();

  function buildStatusIcon(pending: boolean) {
    return <AdminInputsIcon pending={pending} />;
  }

  function buildStatic(evaluation: string): ReactElement {
    return (
      <div className={styles.evaluation}>
        <div className={styles.evaluation_body}>
          <MarkdownMessage
            direction={historyItem.direction}
            positionInfo={positionInfo}
            message={evaluation}
            skipAnimation
          />
        </div>
        <MessageFooter
          direction="received"
          actions={
            <FlatButton onClick={() => setIsEditing(true)}>
              <div className={styles.editButton}>
                <div className={styles.editButton_icon}>
                  {buildStatusIcon(false)}
                </div>
                Edit Evaluation
              </div>
            </FlatButton>
          }
        />
      </div>
    );
  }

  function buildEditor(
    evaluation: string,
    decrementPending?: boolean
  ): ReactElement {
    return (
      <div className={styles.evaluation}>
        <div className={styles.evaluation_body}>
          <MessageView
            direction={historyItem.direction}
            positionInfo={positionInfo}
            skipAnimation
            contentClassName={styles.messageViewBubble}
          >
            <MessageEditor
              value={evaluation}
              placeholder="Enter your evaluation..."
              uploadPathPrefix={`flows/${flowId}/`}
              onChangeRaw={(value) => (rawEvaluation.current = value)}
              autoFocus
            />
          </MessageView>
        </div>
        <MessageFooter
          direction="received"
          actions={
            <>
              <FilledButton
                onClick={() => {
                  setIsEditing(false);
                  rawEvaluation.current = undefined;
                }}
                disabled={isUpdating}
              >
                Cancel
              </FilledButton>
              <FilledButton
                onClick={async () => {
                  setIsUpdating(true);
                  try {
                    const evaluation =
                      rawEvaluation.current != null
                        ? serializeToMarkdown(rawEvaluation.current)
                        : "";
                    await onUpdateEvaluation?.(evaluation, decrementPending);
                    setIsEditing(false);
                  } catch (error) {
                    addToast("Failed to save evaluation.", {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  } finally {
                    setIsUpdating(false);
                  }
                }}
                disabled={isUpdating}
              >
                {isUpdating ? "Saving..." : "Save"}
              </FilledButton>
            </>
          }
        />
      </div>
    );
  }

  function buildFulfilledNull(message: string): ReactElement {
    return (
      <div className={styles.evaluation}>
        <div className={styles.evaluation_body}>
          <MarkdownMessage
            direction={historyItem.direction}
            positionInfo={positionInfo}
            message={message}
            skipAnimation
          />
        </div>
        <MessageFooter
          direction="received"
          actions={
            <FlatButton onClick={() => setIsEditing(true)}>
              <div className={styles.editButton}>
                <div className={styles.editButton_icon}>
                  {buildStatusIcon(true)}
                </div>
                Add Evaluation
              </div>
            </FlatButton>
          }
        />
      </div>
    );
  }

  return (
    <HistoryComponent
      historyItem={historyItem}
      positionInfo={positionInfo}
      promptInput={promptInput}
    >
      {/* "Scroll-to" marker for use by ChatViewNav */}
      <div
        style={{ position: "relative", top: -100 }}
        id={`__history${positionInfo.index}_evaluation`}
      />
      <RemoteMessage
        direction={historyItem.direction}
        positionInfo={positionInfo}
        message={(historyItem.cleanMessage as unknown) as RemoteMessageShape}
        renderFetchedMessage={(message) => {
          return isEditing
            ? buildEditor(message.body as string)
            : buildStatic(message.body as string);
        }}
        renderStatusFulfilledNull={(message) => {
          return isEditing
            ? buildEditor("", true)
            : buildFulfilledNull(message);
        }}
        fetchedMessage={
          initialEvaluation != null
            ? { body: initialEvaluation }
            : rawEvaluation.current != null
            ? { body: serializeToMarkdown(rawEvaluation.current) }
            : null
        }
      />
    </HistoryComponent>
  );
}
