import { unwrapResult } from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { useAppDispatch } from "../../app/store";
import FilledButton from "../../components/buttons/FilledButton";
import formStyles from "../../components/forms/formStyles.module.css";
import Input from "../../components/forms/Input";
import { selectAuth, updateProfile } from "./authSlice";

export class ProfileEditFormController {
  constructor(readonly isDirty: () => boolean) {}
}

export default function ProfileEditForm({
  controller,
}: {
  controller?: (c: ProfileEditFormController) => void;
}): ReactElement | null {
  const dispatch = useAppDispatch();
  const { updating, currentUser } = useSelector(selectAuth);

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<api.UserUpdateAttrs>();

  useEffect(() => {
    if (controller != null) {
      controller(new ProfileEditFormController(() => isDirty));
    }
  }, [controller, isDirty]);

  const { addToast } = useToasts();

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (currentUser == null) return null;

  return (
    <form
      onSubmit={handleSubmit(async ({ name }) => {
        try {
          setIsSubmitting(true);
          unwrapResult(await dispatch(updateProfile({ name })));
          reset(getValues());
        } catch (error) {
          addToast(`Failed to update your profile.`, {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setIsSubmitting(false);
        }
      })}
    >
      <div className={formStyles.field}>
        <label>Name</label>
        <Input
          name="name"
          styleVariant="outlined"
          className={formStyles.input}
          placeholder="Your name (optional)"
          defaultValue={currentUser.name}
          ref={register()}
        />
      </div>
      <div className={formStyles.actions}>
        <FilledButton
          disabled={!isDirty || updating === "pending"}
          isLoading={isSubmitting}
        >
          Save
        </FilledButton>
      </div>
    </form>
  );
}
