import { AltMessageItem } from "@xflr6/chatbot";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { v4 } from "uuid";

import { moveElement } from "../../../../utils/array";
import { WithKey } from "../operations/types";
import { usePromptEditorState } from "../PromptEditor";
import AltMessageEditor, { AltMessageEditorCtrl } from "./AltMessageEditor";
import styles from "./AltMessagesEditor.module.css";
import InsertAltMessageButton from "./InsertAltMessageButton";

export interface AltMessagesEditorContextProps {
  items: AltMessageEditorCtrl[];
}

const AltMessagesEditorContext = React.createContext<
  AltMessagesEditorContextProps | undefined
>(undefined);

export function useAltMessagesEditor(): AltMessagesEditorContextProps {
  const context = React.useContext(AltMessagesEditorContext);
  if (context === undefined) {
    throw new Error(
      "useAltMessagesEditor must be used within an AltMessagesEditor"
    );
  }
  return context;
}

export interface AltMessagesEditorCtrl {
  items: AltMessageEditorCtrl[];
}

export interface AltMessagesEditorProps {
  messages?: AltMessageItem[] | null;
  onChange: (messages: AltMessageItem[] | null) => void;
  control?: (control?: AltMessagesEditorCtrl) => void;
}

export default function AltMessagesEditor({
  messages,
  onChange,
  control,
}: AltMessagesEditorProps): ReactElement | null {
  const prompt = usePromptEditorState();

  function handleDragEnd(result: DropResult) {
    const { destination, source } = result;
    if (destination == null) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (messages == null) return;
    const newMessages = [...messages];
    moveElement(newMessages, source.index, destination.index);

    onChange(newMessages);
  }

  function insertMessage(index: number, message: AltMessageItem) {
    const newMessages = [...(messages || [])];
    if (index > -1 && index <= newMessages.length) {
      (message as WithKey<AltMessageItem>).__key = v4();
      newMessages.splice(index, 0, message);
      onChange(newMessages);
    }
  }

  function updateMessage(index: number, message: AltMessageItem) {
    const newMessages = [...(messages || [])];
    if (index > -1 && index <= newMessages.length) {
      newMessages.splice(index, 1, message);
      onChange(newMessages);
    }
  }

  function deleteMessage(index: number) {
    const newMessages = [...(messages || [])];
    if (index > -1 && index <= newMessages.length) {
      newMessages.splice(index, 1);
      onChange(newMessages.length > 0 ? newMessages : null);
    }
  }

  const itemControls = useRef<AltMessageEditorCtrl[]>([]);

  const registerControl = useCallback(
    (c) => (itemControls.current[c.index] = c),
    []
  );

  const deregisterControl = useCallback((key) => {
    const index = itemControls.current.findIndex((c) => c.key === key);
    if (index !== -1) {
      itemControls.current.splice(index, 1);
    }
  }, []);

  useEffect(() => {
    control?.({ items: itemControls.current });

    return function cleanup() {
      control?.();
    };
  }, [control]);

  if (messages == null || messages.length === 0) {
    return (
      <div className={styles.standaloneInsertButton}>
        <InsertAltMessageButton index={0} insertMessage={insertMessage} />
      </div>
    );
  }

  return (
    <AltMessagesEditorContext.Provider value={{ items: itemControls.current }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`${prompt.name}.messages`}>
          {(provided) => (
            <div className={styles.root}>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {messages.map((alternateMessage, index) => (
                  <AltMessageEditor
                    key={(alternateMessage as WithKey<AltMessageItem>).__key}
                    promptIndex={prompt.index}
                    alternateMessage={alternateMessage}
                    index={index}
                    insertMessage={insertMessage}
                    updateMessage={updateMessage}
                    deleteMessage={deleteMessage}
                    isLast={index === messages.length - 1}
                    control={registerControl}
                    deregisterControl={deregisterControl}
                  />
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </AltMessagesEditorContext.Provider>
  );
}
