import { dateFromISO, dateToISO } from "@xflr6/utils";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DateRange } from "../../components/forms/DateRange";
import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
} from "../../components/LoadingLayout";
import Tabs from "../../components/Tabs";
import {
  FlowSequenceStatsFilter,
  loadStats,
  selectFilter,
  selectStatsState,
} from "./flowSequenceStatsSlice";
import styles from "./FlowSequenceStatsView.module.css";
import ItemAnalyticsView from "./ItemAnalyticsView";
import ResponsesView from "./ResponsesView";

export const ITEMS_TAB = "Summary";
export const RESPONSES_TAB = "Chats";
const TABS = [ITEMS_TAB, RESPONSES_TAB];

const LS_FLOW_SEQ_STATS_TAB_KEY = "flowSequenceStatsTab";

export function primeTab(tabName: string): void {
  localStorage.setItem(LS_FLOW_SEQ_STATS_TAB_KEY, tabName);
}

function FlowSequenceStatsView({
  flowSequenceId,
}: {
  flowSequenceId: number;
}): ReactElement | null {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);
  const statsState = useSelector(selectStatsState);

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem(LS_FLOW_SEQ_STATS_TAB_KEY) ?? TABS[0]
  );

  useEffect(() => {
    primeTab(activeTab);
  }, [activeTab]);

  const loadStats_ = useMemo(
    () => (filter?: FlowSequenceStatsFilter) => {
      dispatch(loadStats({ flowSequenceId, filter }));
    },
    [dispatch, flowSequenceId]
  );

  useEffect(() => {
    loadStats_();
  }, [loadStats_, dispatch]);

  function renderFilters(): ReactElement {
    return (
      <div className={styles.filters}>
        from&nbsp;
        <DateRange
          pickerProps={{
            calendarIcon: null,
          }}
          value={{
            from: dateFromISO(filter.fromDate),
            till: dateFromISO(filter.tillDate),
          }}
          onChange={(value) => {
            loadStats_({
              fromDate: dateToISO(value.from),
              tillDate: dateToISO(value.till),
            });
          }}
        />
      </div>
    );
  }

  function renderTabBody(): ReactElement | null {
    if (statsState.loading === "pending") {
      return <LoadingLayout />;
    } else if (statsState.loading === "rejected") {
      return <ErrorLayout message={statsState.loadError} />;
    } else if (statsState.stats == null) {
      return null;
    } else if (statsState.stats.total === 0) {
      return <NoResultsLayout message="There are no chats" />;
    } else if (activeTab === ITEMS_TAB) {
      return <ItemAnalyticsView flowSequenceStats={statsState.stats} />;
    } else if (activeTab === RESPONSES_TAB) {
      return <ResponsesView />;
    }
    return null;
  }

  return (
    <div className={styles.root}>
      <Tabs
        className={styles.tabs}
        tabs={TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        trailing={renderFilters()}
      />
      <section className={styles.body}>{renderTabBody()}</section>
    </div>
  );
}

export default FlowSequenceStatsView;
