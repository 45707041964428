import { createSelector } from "@reduxjs/toolkit";
import { isBlank } from "@xflr6/utils";
import React, { ReactElement, useEffect } from "react";
import { Control, Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "../../components/forms/Checkbox";
import formStyles from "../../components/forms/formStyles.module.css";
import Input from "../../components/forms/Input";
import LanguagePicker from "../../components/forms/LanguagePicker";
import HelpTip from "../../components/HelpTip";
import Tabs from "../../components/Tabs";
import XMLPreview from "../../components/XMLPreview";
import CopyToClipboardButton from "../../featuresCommon/CopyToClipboardButton";
import { LANGUAGES } from "../../utils/constants";
import {
  loadIntegrations,
  selectIsIntegratedThinkific,
} from "../integrations/integrationsSlice";
import styles from "./EmbedForm.module.css";
import {
  selectFlowSequenceLanguage,
  selectFlowSequenceLanguages,
  selectFlowSequenceUid,
} from "./flowSequenceEditorSlice";
import {
  EmbedFormData,
  EmbedMode,
  selectEditorUi,
  setEmbedMode,
  updateEmbedFormData,
} from "./flowSequenceEditorUiSlice";

export function EmbedConfig({
  formControl,
  excludedFields,
}: {
  formControl: Control;
  excludedFields?: Set<
    keyof Pick<EmbedFormData, "height" | "width" | "identifier">
  >;
}): ReactElement {
  const dispatch = useDispatch();
  const formData = useSelector(selectEditorUi).embedFormData;
  const isIntegratedThinkific = useSelector(selectIsIntegratedThinkific);
  const language = useSelector(selectFlowSequenceLanguage);
  const languages = useSelector(selectFlowSequenceLanguages);
  const register = formControl.register;

  useEffect(() => {
    dispatch(loadIntegrations({ noReload: true }));
  }, [dispatch]);

  return (
    <form>
      {excludedFields?.has("height") || (
        <div className={formStyles.field}>
          <label htmlFor="height">Height</label>
          <Input
            name="height"
            styleVariant="outlined"
            className={formStyles.input}
            value={formData.height}
            placeholder="100%"
            ref={register}
          />
        </div>
      )}
      {excludedFields?.has("width") || (
        <div className={formStyles.field}>
          <label htmlFor="width">Width</label>
          <Input
            name="width"
            styleVariant="outlined"
            className={formStyles.input}
            value={formData.width}
            placeholder="100%"
            ref={register}
          />
        </div>
      )}
      {excludedFields?.has("identifier") || (
        <div className={formStyles.field}>
          <label htmlFor="identifier">User identifier</label>
          <Input
            name="identifier"
            styleVariant="outlined"
            className={formStyles.input}
            value={formData.identifier}
            placeholder="Leave blank to auto-generate"
            ref={register}
          />
          {isIntegratedThinkific && (
            <HelpTip
              label="Embedding in Thinkific?"
              tooltipContent={
                <span>
                  Enter <b>{`{{email}}`}</b> as the identifier
                </span>
              }
            />
          )}
        </div>
      )}
      {!!languages?.length && (
        <div className={formStyles.field}>
          <label htmlFor="language">Language</label>
          <Controller
            name="language"
            control={formControl}
            defaultValue={formData.language}
            render={({ value, onChange }) => {
              return (
                <LanguagePicker
                  value={value}
                  onChange={onChange}
                  allowedLanguages={languages}
                  placeholder={
                    language && LANGUAGES[language]
                      ? `${LANGUAGES[language]} (Default)`
                      : "Default"
                  }
                  isClearable
                  isInModal
                />
              );
            }}
          />
        </div>
      )}
      <div className={formStyles.field}>
        <Checkbox
          label="Show score"
          name="showScore"
          defaultChecked={formData.showScore}
          ref={register}
        />
      </div>
      <div className={formStyles.field}>
        <Checkbox
          label="Show name"
          name="showName"
          defaultChecked={formData.showName}
          ref={register}
        />
      </div>
      <div className={formStyles.field}>
        <Checkbox
          label="Allow random access"
          name="allowRandomAccess"
          defaultChecked={formData.allowRandomAccess}
          ref={register}
        />
      </div>
      <div className={formStyles.field}>
        <Checkbox
          label="Disable auto answer"
          name="disableAutoAnswer"
          defaultChecked={formData.disableAutoAnswer}
          ref={register}
        />
      </div>
      <div className={formStyles.field}>
        <Checkbox
          label="Disable keyboard answer"
          name="disableKeyboardAnswer"
          defaultChecked={formData.disableKeyboardAnswer}
          ref={register}
        />
      </div>
    </form>
  );
}

export function buildSettings(data: Partial<EmbedFormData>): string | null {
  const settings: Record<string, unknown> = {};
  if (data.showName) settings.showName = true;
  if (data.showScore) settings.showScore = true;
  if (data.allowRandomAccess) settings.allowRandomAccess = true;
  if (data.disableAutoAnswer) settings.disableAutoAnswer = true;
  if (data.disableKeyboardAnswer) settings.disableKeyboardAnswer = true;
  if (Object.keys(settings).length > 0) {
    return btoa(JSON.stringify(settings));
  } else {
    return null;
  }
}

function buildShareUrl(
  flowSequenceUid: string,
  data: Partial<EmbedFormData>
): string {
  let url = `${process.env.REACT_APP_EMBED_BASE}/chat_sequences/${flowSequenceUid}`;
  if (!isBlank(data.identifier)) {
    url += `/${data.identifier}`;
  }
  const settings = buildSettings(data);
  if (settings != null) {
    url += "?s=" + settings;
  }
  if (!isBlank(data.language)) {
    url += `${settings ? "&" : "?"}l=${data.language}`;
  }
  return url;
}

const selectEffectiveFormData = createSelector(
  [selectEditorUi, selectFlowSequenceLanguages],
  ({ embedFormData: { language, ...formData } }, languages) => ({
    ...formData,
    language: languages?.includes(language) ? language : "",
  })
);

function EmbedCode({
  formControl,
}: {
  formControl: Control;
}): ReactElement | null {
  const dispatch = useDispatch();
  const flowSequenceUid = useSelector(selectFlowSequenceUid);
  const formData = useSelector(selectEffectiveFormData);
  const embedMode = useSelector(selectEditorUi).embedMode;

  const data: Partial<EmbedFormData> = useWatch<EmbedFormData>({
    control: formControl,
    defaultValue: formData,
  });

  if (flowSequenceUid == null) return null;

  const webComponentCode =
    `<dialog-form-sequence\n` +
    `  flow-sequence-id="${flowSequenceUid}"\n` +
    (!isBlank(data.height) ? `  height="${data.height}"\n` : "") +
    (!isBlank(data.width) ? `  width="${data.width}"\n` : "") +
    (!isBlank(data.identifier)
      ? `  user-identifier="${data.identifier}"\n`
      : "") +
    (data.showName ? `  settings-show-name\n` : "") +
    (data.showScore ? `  settings-show-score\n` : "") +
    (data.allowRandomAccess ? `  settings-allow-random-access\n` : "") +
    (data.disableAutoAnswer ? `  settings-disable-auto-answer\n` : "") +
    (data.disableKeyboardAnswer ? `  settings-disable-keyboard-answer\n` : "") +
    (!isBlank(data.language) ? `  language="${data.language}"\n` : "") +
    `></dialog-form-sequence>` +
    `

<!-- 
Include this script on your HTML page,
ideally just before the closing </body> tag.
-->
<script
  src="https://unpkg.com/@xflr6/chatbot-embed-lib"
></script>
`;

  const iframeCode =
    `<iframe\n` +
    `  height="${isBlank(data.height) ? "100%" : data.height}"\n` +
    `  width="${isBlank(data.width) ? "100%" : data.width}"\n` +
    `  src="${buildShareUrl(flowSequenceUid, data)}"\n` +
    `></iframe>`;

  const TABS: EmbedMode[] = ["iframe", "webComponent"];
  return (
    <div className={styles.embedCode}>
      <CopyToClipboardButton
        className={styles.copyToClipboardButton}
        label="Embed code"
        text={embedMode === "iframe" ? iframeCode : webComponentCode}
      />
      <Tabs
        className={styles.embedModeTabs}
        tabs={TABS}
        displayNames={["iframe", "Web Component"]}
        tabClassName={styles.embedModeTab}
        activeTab={embedMode}
        setActiveTab={(tabName) => {
          dispatch(setEmbedMode(tabName as EmbedMode));
        }}
        activeTabClassName={styles.embedModeTab__isActive}
      />
      <XMLPreview
        code={embedMode === "iframe" ? iframeCode : webComponentCode}
      />
    </div>
  );
}

function ShareLink({
  formControl,
}: {
  formControl: Control;
}): ReactElement | null {
  const flowSequenceUid = useSelector(selectFlowSequenceUid);
  const formData = useSelector(selectEffectiveFormData);

  const data: Partial<EmbedFormData> = useWatch<EmbedFormData>({
    control: formControl,
    defaultValue: formData,
  });

  if (flowSequenceUid == null) return null;

  const shareUrl = buildShareUrl(flowSequenceUid, data);

  return (
    <div className={styles.shareLink}>
      <CopyToClipboardButton
        className={styles.copyToClipboardButton}
        label="Share link"
        text={shareUrl}
      />
      {shareUrl}
    </div>
  );
}

export interface EmbedFormController {
  save: VoidFunction;
}

// We have two `EmbedForm`s - one in the flowEditor feature and the other in the
// flowSequenceEditor feature. Both are almost identical, but for the moment, we
// haven't DRYed them up because they might differ more sometime soon.
export default function EmbedForm({
  control,
}: {
  control?: (c: EmbedFormController) => void;
}): ReactElement {
  const dispatch = useDispatch();
  const { control: formControl, getValues } = useForm<EmbedFormData>();

  useEffect(() => {
    control?.({ save: () => dispatch(updateEmbedFormData(getValues())) });
  }, [dispatch, control, getValues]);

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.column}>
          <EmbedConfig formControl={formControl} />
        </div>
        <div className={styles.column}>
          <EmbedCode formControl={formControl} />
        </div>
      </div>
      <ShareLink formControl={formControl} />
    </div>
  );
}
