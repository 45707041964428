import { AltMessageItem } from "@xflr6/chatbot";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { FaPlus } from "react-icons/fa";

import FilledButton from "../../../../components/buttons/FilledButton";
import styles from "./InsertAltMessageButton.module.css";

export interface InsertAltMessageButtonProps {
  index: number;
  insertMessage: (index: number, message: AltMessageItem) => void;
  isAbove?: boolean;
}

export default function InsertAltMessageButton(
  props: InsertAltMessageButtonProps
): ReactElement {
  function insertMessageHandler() {
    props.insertMessage(props.index, {
      message: "",
    });
  }

  return (
    <div className={styles.root}>
      <FilledButton
        className={classNames(styles.button, {
          [styles.button__isAbove]: props.isAbove,
        })}
        onClick={insertMessageHandler}
      >
        <FaPlus size={12} />
        &nbsp;Response
      </FilledButton>
    </div>
  );
}
