export const CHAT_ID_ARG = "chatId";

export const VERSION_ARG = "version";

export const REMOTE_FLOW_PREFIX = "remote";

export const REMOTE_FLOW_PATTERN = new RegExp(
  `${REMOTE_FLOW_PREFIX}\\?${CHAT_ID_ARG}=(\\d+)`
);

export const REMOTE_FLOW_PATTERN_G = new RegExp(REMOTE_FLOW_PATTERN, "g");
