import { OrdChatFlowDefinition, PromptMessageItem } from "@xflr6/chatbot";
import { v4 } from "uuid";

import { WithKey } from "./types";

/**
 * Sets uuid keys on prompt message items that don't have one.
 * Keys are needed to be able to correctly render message item editors.
 */
export function hydrateMessageItemKeys(flowDef: OrdChatFlowDefinition): void {
  flowDef.promptsArray.forEach(({ definition }) => {
    if (definition.messages != null) {
      definition.messages.forEach((messageItem) => {
        const keyedMessageItem = messageItem as WithKey<PromptMessageItem>;
        if (keyedMessageItem.__key == null) {
          keyedMessageItem.__key = v4();
        }
      });
    }
  });
}
