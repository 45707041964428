import { useFeatureFlag } from "configcat-react";
import { chain, mapValues, merge, omit } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { FaPencilAlt } from "react-icons/fa";

import FilledButton from "../components/buttons/FilledButton";
import FlatButton from "../components/buttons/FlatButton";
import formStyles from "../components/forms/formStyles.module.css";
import Input from "../components/forms/Input";
import Modal from "../components/Modal";
import WithDirtyDot from "../components/WithDirtyDot";
import { LANGUAGES } from "../utils/constants";
import styles from "./NamesEditor.module.css";

interface FormController {
  isDirty: boolean;
}

/**
 * Whether or not i18n is enabled or some earlier languages have been deleted,
 * all translations in `tName` must be preserved, just like the translations in
 * the flow definitions are always preserved. This form is designed to do that.
 */
function Form({
  name,
  language,
  tName,
  languages,
  onSubmit,
  controller,
}: {
  name: string;
  language: string | null;
  tName: Record<string, string>;
  languages: string[] | null;
  onSubmit: (name: string, tName: Record<string, string>) => void;
  controller: (c: FormController) => void;
}): ReactElement {
  const { value: isI18nEnabled } = useFeatureFlag("isI18nEnabled", false);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Record<string, string>>({
    defaultValues: {
      name,
      ...merge(
        {},
        chain(languages ?? [])
          .keyBy((l) => l)
          .mapValues(() => "")
          .value(),
        mapValues(tName, (v) => v ?? "")
      ),
    },
  });

  useEffect(() => {
    controller({ isDirty });
  }, [controller, isDirty]);

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        const name = data["name"];
        const tName = omit(data, ["name"]);
        onSubmit(name, tName);
      })}
    >
      <div className={formStyles.field}>
        {isI18nEnabled ? (
          <label htmlFor="name">
            {language && LANGUAGES[language]
              ? `${LANGUAGES[language]} (Default)`
              : "Default"}
          </label>
        ) : null}
        <Input
          name="name"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register}
        />
      </div>
      {languages?.length
        ? languages.map((language) =>
            isI18nEnabled ? (
              <div className={formStyles.field} key={language}>
                <label htmlFor={language}>{LANGUAGES[language]}</label>
                <Input
                  name={language}
                  styleVariant="outlined"
                  className={formStyles.input}
                  ref={register}
                />
              </div>
            ) : (
              <input
                key={language}
                name={language}
                type="hidden"
                ref={register}
              />
            )
          )
        : null}
      {Object.keys(omit(tName, languages ?? [])).map((hiddenLang) => (
        <input
          key={hiddenLang}
          name={hiddenLang}
          type="hidden"
          ref={register}
        />
      ))}
      <div className={formStyles.actions}>
        <FilledButton>Save</FilledButton>
      </div>
    </form>
  );
}

export default function NamesEditor({
  name,
  language,
  tName,
  languages,
  isEditable,
  showDirtyDot,
  onSubmit,
}: {
  name: string;
  language: string | null;
  tName: Record<string, string>;
  languages: string[] | null;
  isEditable: boolean;
  showDirtyDot: boolean;
  onSubmit: (
    name: string,
    tName: Record<string, string>,
    closeEditor: VoidFunction // Must be called manually to close the editor
  ) => void;
}): ReactElement | null {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const closeForm = useCallback(() => setIsFormOpen(false), [setIsFormOpen]);

  const formController = useRef<FormController>();

  return (
    <>
      <Modal
        title="Edit Name"
        ariaHideApp={false}
        isOpen={isFormOpen}
        style={{
          content: {
            width: "600px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        onRequestClose={() => {
          if (
            !formController.current?.isDirty ||
            window.confirm(
              "You have unsaved changes. Discard them and close name editor?"
            )
          ) {
            closeForm();
          }
        }}
      >
        <Form
          name={name}
          language={language}
          tName={tName}
          languages={languages}
          onSubmit={(name, tName) => onSubmit(name, tName, closeForm)}
          controller={(c) => (formController.current = c)}
        />
      </Modal>

      <div className={styles.root}>
        <WithDirtyDot className={styles.dirtyDot} isDirty={showDirtyDot}>
          <div className={styles.name}>{name}</div>
        </WithDirtyDot>
        {isEditable ? (
          <FlatButton
            className={styles.editButton}
            onClick={() => setIsFormOpen(true)}
          >
            <FaPencilAlt size={16} />
          </FlatButton>
        ) : null}
      </div>
    </>
  );
}
