import { equals } from "ramda";
import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingLayout, { ErrorLayout } from "../../components/LoadingLayout";
import { CHAT_ID_ARG } from "../../featuresCommon/utils";
import MessageProvider from "../slateMessageEditor/MessageProvider";
import Editor from "./edit/Editor";
import PromptConfigEditor from "./edit/PromptConfigEditor";
import styles from "./FlowEditor.module.css";
import {
  clearFlow,
  loadFlow,
  selectFlowDefinition,
  selectLoading,
  selectPreviewPromptIndex,
} from "./flowEditorSlice";
import { selectEditorUi } from "./flowEditorUiSlice";
import NavAwayPrompt from "./NavAwayPrompt";
import FlowPreview from "./preview/FlowPreview";

export interface FlowEditorProps {
  flowId: number;
  flowVersion?: number;
}

export default function FlowEditor(
  props: FlowEditorProps
): ReactElement | null {
  const dispatch = useDispatch();

  const [loading, loadError] = useSelector(selectLoading, equals);
  const flowDef = useSelector(selectFlowDefinition);
  const previewPromptIndex = useSelector(selectPreviewPromptIndex);
  const editorUi = useSelector(selectEditorUi);

  useEffect(() => {
    dispatch(loadFlow({ id: props.flowId, version: props.flowVersion }));

    return function cleanup() {
      dispatch(clearFlow());
    };
  }, [props.flowId, props.flowVersion, dispatch]);

  if (loading === "pending") {
    return <LoadingLayout />;
  }

  if (loading === "rejected") {
    return <ErrorLayout message={loadError} />;
  }

  if (flowDef == null) return null;

  return (
    <div className={styles.root}>
      <NavAwayPrompt />
      <MessageProvider
        transformAfterSerialize={(markdown) =>
          markdown.replaceAll(
            `/uploads/flows/${props.flowId}/`,
            `/uploads/flows/{{!${CHAT_ID_ARG}}}/`
          )
        }
        transformBeforeDeserialize={(markdown) =>
          markdown.replaceAll(
            `/uploads/flows/{{!${CHAT_ID_ARG}}}/`,
            `/uploads/flows/${props.flowId}/`
          )
        }
      >
        {editorUi.isPromptConfigOpen && (
          <div className={styles.configColumn}>
            <PromptConfigEditor />
          </div>
        )}
        <div className={styles.promptsColumn}>
          <Editor flowDef={flowDef} />
        </div>
      </MessageProvider>
      <div className={styles.previewColumn}>
        <FlowPreview
          id={props.flowId}
          definition={flowDef}
          promptIndex={previewPromptIndex}
        />
      </div>
    </div>
  );
}
