import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../components/buttons/FilledButton";
import {
  loadIdentifiers,
  selectFilter,
  selectIdentifiersState,
} from "./identifiersSlice";

export default function RefreshButton(): ReactElement {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);
  const { loading } = useSelector(selectIdentifiersState);

  return (
    <FilledButton
      onClick={() => {
        dispatch(loadIdentifiers(filter));
      }}
      disabled={loading === "pending"}
    >
      Refresh
    </FilledButton>
  );
}
