import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { AsyncStatus } from "../../app/store";
import FilledButton from "../../components/buttons/FilledButton";
import FlatButton from "../../components/buttons/FlatButton";
import FieldError from "../../components/forms/FieldError";
import FormError from "../../components/forms/FormError";
import formStyles from "../../components/forms/formStyles.module.css";
import Input from "../../components/forms/Input";
import GoogleIdentity from "../../components/GoogleIdentity";
import HorizontalSeparator from "../../components/HorizontalSeparator";
import { EMAIL_PATTERN, PASSWORD_PATTERN } from "../../utils/constants";
import {
  ErrorData,
  getSerializableError,
  getStringErrorMessage,
  getValidationErrorMessage,
} from "../../utils/error";
import styles from "./AuthForm.module.css";
import {
  clearError,
  loginWithEmailAndPassword,
  loginWithGoogle,
  selectAuth,
  signUpWithEmailAndPassword,
} from "./authSlice";

function SignUpForm({
  onLoginClick,
}: {
  onLoginClick?: () => void;
}): ReactElement {
  const dispatch = useDispatch();
  const { loading, lastAuthAction, error } = useSelector(selectAuth);

  const { register, handleSubmit, errors, formState } = useForm<{
    email: string;
    password: string;
    name?: string | null;
  }>();

  return (
    <div>
      <form
        onSubmit={handleSubmit(({ email, password, name }) => {
          dispatch(signUpWithEmailAndPassword({ email, password, name }));
        })}
      >
        <FormError error={getStringErrorMessage(error)} />
        <div className={formStyles.field}>
          <label className={formStyles.label}>Email</label>
          <Input
            name="email"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register({
              required: "Required",
              pattern: {
                value: EMAIL_PATTERN,
                message: "Please provide a valid email address",
              },
            })}
          />
          {errors?.email ? (
            <FieldError error={errors.email.message ?? "Invalid value"} />
          ) : error && !formState.dirtyFields.email ? (
            <FieldError error={getValidationErrorMessage(error, "email")} />
          ) : null}
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>Password</label>
          <Input
            type="password"
            name="password"
            styleVariant="outlined"
            className={formStyles.input}
            placeholder="At least 8 characters"
            ref={register({
              required: "Required",
              pattern: {
                value: PASSWORD_PATTERN,
                message:
                  "Must have a lowercase character, an uppercase character" +
                  ", a number and a special character",
              },
            })}
          />
          {errors?.password ? (
            <FieldError error={errors.password.message ?? "Invalid value"} />
          ) : error && !formState.dirtyFields.password ? (
            <FieldError error={getValidationErrorMessage(error, "password")} />
          ) : null}
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>Name (Optional)</label>
          <Input
            name="name"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register}
          />
        </div>
        <div className={formStyles.actions}>
          <FilledButton
            isFullWidth
            disabled={loading === "pending"}
            isLoading={
              loading === "pending" &&
              lastAuthAction === "signUpWithEmailAndPassword"
            }
          >
            Sign Up
          </FilledButton>
        </div>
      </form>
      <HorizontalSeparator text="Or" />
      <GoogleIdentity
        client_id={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}
        callback={(response) => {
          dispatch(loginWithGoogle(response.credential));
        }}
        context="signup"
        buttonConfig={{ text: "signup_with" }}
      />
      <div className={styles.switchMessage}>
        Already have an account?{" "}
        <FlatButton
          className={styles.inlineButton}
          onClick={() => {
            onLoginClick?.();
            dispatch(clearError());
          }}
          disabled={loading === "pending"}
        >
          Login
        </FlatButton>
      </div>
    </div>
  );
}

function ForgotPasswordForm({
  defaultEmail,
  onCancel,
  onSuccess,
}: {
  defaultEmail?: string;
  onCancel?: () => void;
  onSuccess?: () => void;
}): ReactElement {
  const { register, handleSubmit, errors } = useForm<{ email: string }>({
    defaultValues: {
      email: defaultEmail ?? "",
    },
  });

  const [sending, setSending] = useState<AsyncStatus>("idle");
  const [sendError, setSendError] = useState<ErrorData | null>(null);

  return (
    <form
      onSubmit={handleSubmit(async ({ email }) => {
        setSending("pending");
        setSendError(null);
        try {
          await api.requestPasswordResetLink(email);
          setTimeout(() => onSuccess?.(), 0);
          setSending("fulfilled");
        } catch (error) {
          setSending("rejected");
          setSendError(getSerializableError(error));
        }
      })}
    >
      {sendError && (
        <FormError
          error={
            getValidationErrorMessage(sendError, "email") ??
            "Failed to send email"
          }
        />
      )}
      <div className={formStyles.field}>
        <label>Enter the email you signed up with below</label>
        <Input
          name="email"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register({
            required: "Required",
            pattern: {
              value: EMAIL_PATTERN,
              message: "Please provide a valid email address",
            },
          })}
        />
        {errors?.email && (
          <FieldError error={errors.email.message ?? "Invalid value"} />
        )}
      </div>
      <div className={formStyles.actions}>
        <FilledButton
          isFullWidth
          disabled={sending === "pending"}
          isLoading={sending === "pending"}
        >
          Send Reset Instructions
        </FilledButton>
      </div>
      <div className={styles.switchMessage}>
        <FlatButton
          className={styles.inlineButton}
          onClick={(event) => {
            onCancel?.();
            event.preventDefault();
          }}
          disabled={sending === "pending"}
        >
          Back to login
        </FlatButton>
      </div>
    </form>
  );
}

function LoginForm({
  onSignUpClick,
}: {
  onSignUpClick?: () => void;
}): ReactElement {
  const dispatch = useDispatch();
  const { loading, lastAuthAction, error } = useSelector(selectAuth);
  const { addToast } = useToasts();

  const { register, handleSubmit, errors, getValues } = useForm<{
    email: string;
    password: string;
  }>();

  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const emailForForgotPasswordForm = useRef<string>();

  return isForgotPassword ? (
    <ForgotPasswordForm
      defaultEmail={emailForForgotPasswordForm.current}
      onCancel={() => setIsForgotPassword(false)}
      onSuccess={() => {
        setIsForgotPassword(false);
        addToast("You should receive an email soon.", {
          appearance: "success",
          autoDismiss: true,
        });
      }}
    />
  ) : (
    <div>
      <form
        onSubmit={handleSubmit(({ email, password }) => {
          dispatch(loginWithEmailAndPassword({ email, password }));
        })}
      >
        <FormError error={getStringErrorMessage(error)} />
        <div className={formStyles.field}>
          <label className={formStyles.label}>Email</label>
          <Input
            name="email"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register({ required: "Required" })}
          />
          {errors?.email && (
            <FieldError error={errors.email.message ?? "Invalid value"} />
          )}
        </div>
        <div className={formStyles.field}>
          <label className={formStyles.label}>Password</label>
          <Input
            type="password"
            name="password"
            styleVariant="outlined"
            className={formStyles.input}
            ref={register({ required: "Required" })}
          />
          {errors?.password && (
            <FieldError error={errors.password.message ?? "Invalid value"} />
          )}
          <FlatButton
            type="button"
            className={styles.forgotPasswordButton}
            onClick={() => {
              emailForForgotPasswordForm.current = getValues("email");
              setIsForgotPassword(true);
            }}
            disabled={loading === "pending"}
          >
            Forgot password?
          </FlatButton>
        </div>
        <div className={formStyles.actions}>
          <FilledButton
            isFullWidth
            disabled={loading === "pending"}
            isLoading={
              loading === "pending" &&
              lastAuthAction === "loginWithEmailAndPassword"
            }
          >
            Login
          </FilledButton>
        </div>
      </form>
      <HorizontalSeparator text="Or" />
      <GoogleIdentity
        client_id={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}
        callback={(response) => {
          dispatch(loginWithGoogle(response.credential));
        }}
        context="signin"
        buttonConfig={{ text: "signin_with" }}
      />
      <div className={styles.switchMessage}>
        Don&apos;t have an account?{" "}
        <FlatButton
          className={styles.inlineButton}
          onClick={() => {
            onSignUpClick?.();
            dispatch(clearError());
          }}
          disabled={loading === "pending"}
        >
          Sign up
        </FlatButton>
      </div>
    </div>
  );
}

export default function AuthForm(): ReactElement {
  const [isSignUp, setIsSignUp] = useState(false);

  return isSignUp ? (
    <SignUpForm onLoginClick={() => setIsSignUp(false)} />
  ) : (
    <LoginForm onSignUpClick={() => setIsSignUp(true)} />
  );
}
