import {
  getChatDuration,
  getChatSequenceDuration,
  HistoryItemJson,
  RemoteMessageShape,
} from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import { durationInWords } from "@xflr6/utils";

import { ChatViewNavLocation } from "./ChatViewNav";

// This constant is being used in a hard-coded way
// TODO Remove outlierThreshold hard-coding
export const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;

export function getChatTimeSpentInWords(
  history?: HistoryItemJson[] | null,
  outlierThreshold?: number
): string {
  if (history == null) return "--";
  const timeSpent = getChatDuration(history, {
    outlierThreshold,
    outlierBehaviour: "clip",
  });
  return timeSpent != null ? durationInWords(timeSpent) : "Unknown";
}

export function getChatSeqTimeSpentInWords(
  histories?: (HistoryItemJson[] | null | undefined)[] | null,
  outlierThreshold?: number
): string {
  if (histories == null) return "--";
  const timeSpent = getChatSequenceDuration(histories, {
    outlierThreshold,
    outlierBehaviour: "clip",
  });
  return timeSpent != null ? durationInWords(timeSpent) : "Unknown";
}

export function computeAdminInputLocations(
  historyItems: HistoryItemJson[],
  evaluations: Record<string, string>
): ChatViewNavLocation[] {
  const result: ChatViewNavLocation[] = [];
  historyItems.forEach((item, index) => {
    if (item.d === "sent" && item.cu?.feedback) {
      result.push({
        category: "feedback",
        historyIndex: index,
        domElementId: `__history${index}_feedback`,
        actionPending: item.c == null,
      });
    }
    if (item.d === "sent" && item.sl) {
      result.push({
        category: "scoreLater",
        historyIndex: index,
        domElementId: `__history${index}_scoreLater`,
        actionPending: item.ss == null,
      });
    }
    if (
      item.d === "received" &&
      item.ct === "remote" &&
      item.m != null &&
      api.evaluationsUrlPattern.test(
        ((item.m as unknown) as RemoteMessageShape).url
      )
    ) {
      result.push({
        category: "evaluation",
        historyIndex: index,
        domElementId: `__history${index}_evaluation`,
        actionPending: evaluations[item.pk] == null,
      });
    }
    if (item.d === "received" && item.ae === true) {
      result.push({
        category: "answerExternally",
        historyIndex: index,
        domElementId: `__history${index}_answerExternally`,
        actionPending: index === historyItems.length - 1,
      });
    }
  });
  return result;
}
