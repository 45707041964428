import { equals } from "ramda";
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../../components/buttons/FilledButton";
import FieldError from "../../../components/forms/FieldError";
import formStyles from "../../../components/forms/formStyles.module.css";
import Input from "../../../components/forms/Input";
import { DOCS_USER_GUIDE_URL } from "../../../utils/constants";
import { IntegrationConfig } from "../IntegrationConfigs";
import { addIntegration, selectIntegrations } from "../integrationsSlice";

const DocumentationLink = () => (
  <a
    href={`${DOCS_USER_GUIDE_URL}/adding-a-webhook-for-notifications`}
    target="_blank"
    rel="noopener noreferrer"
  >
    See documentation
  </a>
);

export default function WebhookConfig(): ReactElement {
  const dispatch = useDispatch();
  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const { register, handleSubmit, errors } = useForm<{
    url: string;
  }>();

  return (
    <IntegrationConfig
      type="webhook"
      renderAddForm={() => (
        <>
          <p>
            Add a webhook to get notifications about events in your DialogForm
            account.
          </p>
          <p>
            <DocumentationLink />
          </p>
          <form
            onSubmit={handleSubmit(({ url }) => {
              dispatch(
                addIntegration({
                  type: "webhook",
                  config: {
                    url,
                  },
                })
              );
            })}
          >
            <div className={formStyles.field}>
              <label className={formStyles.label}>URL</label>
              <Input
                name="url"
                styleVariant="outlined"
                className={formStyles.input}
                placeholder="DialogForm will POST to this URL"
                ref={register({ required: "Required" })}
              />
              {errors?.url && (
                <FieldError error={errors.url.message ?? "Invalid value"} />
              )}
            </div>
            <div className={formStyles.actions}>
              <FilledButton
                disabled={updating === "pending"}
                isLoading={
                  updating === "pending" &&
                  equals(lastUpdateAction, ["webhook", "add"])
                }
              >
                Add
              </FilledButton>
            </div>
          </form>
        </>
      )}
      renderEditForm={(integration) => (
        <>
          <p>
            Your webhook will be called to notify you of events happening in
            your DialogForm account.
          </p>
          <p>
            <DocumentationLink />
          </p>
          <div className={formStyles.field}>
            <label className={formStyles.label}>URL</label>
            <Input
              name="url"
              styleVariant="outlined"
              className={formStyles.input}
              value={integration.config.url as string}
              disabled
            />
            {errors?.url && (
              <FieldError error={errors.url.message ?? "Invalid value"} />
            )}
          </div>
        </>
      )}
    />
  );
}
