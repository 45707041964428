import { useFeatureFlag } from "configcat-react";
import React, { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";

import FilledButton from "../../components/buttons/FilledButton";
import formStyles from "../../components/forms/formStyles.module.css";
import Input from "../../components/forms/Input";
import LanguagePicker from "../../components/forms/LanguagePicker";

export interface CreateFormData {
  name: string;
  language?: string;
}

export default function CreateForm({
  onSubmit,
  isPending,
}: {
  onSubmit: (data: CreateFormData) => void;
  isPending?: boolean;
}): ReactElement {
  const {
    control,
    register,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm<CreateFormData>();

  const { value: isI18nEnabled } = useFeatureFlag("isI18nEnabled", false);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Name</label>
        <Input
          name="name"
          styleVariant="outlined"
          className={formStyles.input}
          defaultValue="Untitled"
          autoFocus
          onFocus={(e) => {
            if (!dirtyFields.name) {
              e.target.select();
            }
          }}
          ref={register({ required: true })}
        />
      </div>
      {isI18nEnabled && (
        <div className={formStyles.field}>
          <label htmlFor="language">Language</label>
          <Controller
            name="language"
            control={control}
            defaultValue="en"
            render={({ value, onChange }) => {
              return (
                <LanguagePicker value={value} onChange={onChange} isInModal />
              );
            }}
          />
        </div>
      )}
      <div className={formStyles.actions}>
        <FilledButton disabled={isPending} isLoading={isPending}>
          Create
        </FilledButton>
      </div>
    </form>
  );
}
