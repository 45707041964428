import React, { ReactElement } from "react";

import styles from "./Avatar.module.css";

function getHueFromString(s: string) {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return Math.abs(hash % 360);
}

/**
 * Circular avatar component.
 * `src` takes precedence over `initial`.
 * `stringForColor` matters iff `src` is absent.
 * @constructor
 */
export default function Avatar({
  src,
  initial,
  stringForColor,
  size = 32,
}: {
  src?: string | null;
  initial?: string | null;
  stringForColor?: string | null;
  size?: number;
}): ReactElement {
  const hueStr = stringForColor ?? initial;
  const hue = hueStr ? getHueFromString(hueStr) : 0;
  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundColor: `hsl(${hue}, 60%, 30%)`,
      }}
      className={styles.root}
    >
      {src == null ? (
        <div
          style={{
            color: `rgba(255, 255, 255, 0.9)`,
            fontSize: Math.min(18, size / 1.5),
            lineHeight: `${Math.min(18, size / 1.5)}px`,
          }}
          className={styles.initial}
        >
          {initial}
        </div>
      ) : (
        <img
          style={{
            width: size,
            height: size,
          }}
          className={styles.imgAvatar}
          src={src}
          alt="Uploaded avatar"
        />
      )}
    </div>
  );
}
