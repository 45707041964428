import { unwrapResult } from "@reduxjs/toolkit";
import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { useAppDispatch } from "../../app/store";
import SettingsFormBase, {
  SettingsFormController,
} from "../../featuresCommon/SettingsForm";
import { updateResponseSettings as updateFlowSequenceResponseSettings } from "../flowSequenceStats/flowSequenceStatsSlice";
import { updateResponseSettings as updateFlowResponseSettings } from "../flowStats/flowStatsSlice";
import { loadDetails, selectAuth, updateProfile } from "./authSlice";
import styles from "./SettingsForm.module.css";

export default function SettingsForm({
  controller,
}: {
  controller?: (c: SettingsFormController) => void;
}): ReactElement | null {
  const dispatch = useAppDispatch();
  const { updating, detailsLoading, settings, defaultSettings } = useSelector(
    selectAuth
  );

  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(loadDetails());
  }, [dispatch]);

  if (settings != null && defaultSettings != null) {
    return (
      <SettingsFormBase
        settings={settings}
        baseSettings={defaultSettings}
        baseSettingsDesc="default"
        onSubmit={async (settings) => {
          unwrapResult(await dispatch(updateProfile({ settings })));
          // TODO Code smell: cross-usage of Redux slices
          dispatch(updateFlowResponseSettings(settings));
          dispatch(updateFlowSequenceResponseSettings(settings));
        }}
        onError={() => {
          addToast(`Failed to update settings.`, {
            appearance: "error",
            autoDismiss: true,
          });
        }}
        disabled={updating === "pending"}
        controller={controller}
      />
    );
  } else if (detailsLoading === "pending") {
    return <div className={styles.loadingStatus}>Loading settings...</div>;
  } else if (detailsLoading === "rejected") {
    return <div className={styles.loadingStatus}>Failed to fetch settings</div>;
  } else {
    return null;
  }
}
