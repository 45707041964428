import { Editor, Element, Range, Transforms } from "slate";

export interface VariableElement extends Element {
  type: "variable";
  variableName: string;
  isPrompt: boolean;
}

export const VARIABLE_REGEX = /{{(@?)([-.\w]*)}}/g;

// *** Private ***

const ENDS_WITH_VARIABLE_REGEX = new RegExp(`${VARIABLE_REGEX.source}$`);

// *** "Operations" ***

export const newVariableNode = (
  variableName: string,
  isPrompt = false
): Element => ({
  type: "variable",
  variableName: variableName ?? null,
  isPrompt,
  children: [{ text: "" }],
});

export const insertVariable = (
  editor: Editor,
  variableName: string,
  isPrompt: boolean
): void => {
  const variableNode = newVariableNode(variableName, isPrompt);
  Transforms.insertNodes(editor, variableNode);
  Transforms.move(editor);
};

export function dynamicallyInsertVariable(editor: Editor): void {
  Transforms.insertText(editor, "}");

  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);
    const before = Editor.before(editor, start, { unit: "line" });
    const beforeRange = before && Editor.range(editor, before, start);
    const beforeText = beforeRange && Editor.string(editor, beforeRange);
    const beforeMatch =
      beforeText && beforeText.match(ENDS_WITH_VARIABLE_REGEX);
    const startPoint =
      beforeMatch &&
      Editor.before(editor, start, {
        distance: beforeMatch[0].length,
      });

    if (beforeRange && beforeMatch && before && startPoint) {
      const endPoint = start;
      const replaceRange = Editor.range(editor, startPoint, endPoint);
      replaceRange && Transforms.select(editor, replaceRange);
      insertVariable(editor, beforeMatch[2], beforeMatch[1] === "@");
    }
  }
}

// *** Plugin ***

export const withVariables = (editor: Editor): Editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "variable" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "variable" ? true : isVoid(element);
  };

  return editor;
};

// *** Other exports ***

export { default as VariableEditor } from "./VariableEditor";
export { default as VariableStatic } from "./VariableStatic";
