import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";

import Nodes from "./Nodes";
import Paths from "./Paths";
import toRenderTree from "./renderTree";
import styles from "./TreeView.module.css";

export interface TreeViewProps {
  data: api.PromptNode;
}

export default function TreeView(props: TreeViewProps): ReactElement {
  const { renderTree, maxX, maxY } = toRenderTree(props.data);

  return (
    <div className={styles.root}>
      <div className={styles.container} style={{ width: maxX, height: maxY }}>
        <Nodes renderTree={renderTree} />
        <Paths renderTree={renderTree} />
      </div>
    </div>
  );
}
