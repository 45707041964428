import { clampDate, EOD, SOD } from "@xflr6/utils";
import classNames from "classnames";
import React, { ReactElement } from "react";
import DatePicker, { DatePickerProps } from "react-date-picker";

import styles from "./DateRange.module.css";

export interface DateRangeValue {
  from: Date | null;
  till: Date | null;
}

export interface DateRangeProps {
  pickerProps?: Omit<DatePickerProps, "value" | "onChange">;
  value?: DateRangeValue | null;
  onChange?: (value: DateRangeValue) => void;
}

export function DateRange(props: DateRangeProps): ReactElement {
  const { className: pickerClassName, ...restPickerProps } =
    props.pickerProps ?? {};

  return (
    <div className={styles.root}>
      <DatePicker
        {...restPickerProps}
        className={classNames(styles.picker, pickerClassName)}
        value={props.value?.from}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: any) => {
          props.onChange &&
            props.onChange({
              from: SOD(value as Date | null),
              till: clampDate(
                props.value?.till ?? null,
                "toEODof",
                value as Date | null
              ),
            });
        }}
      />
      <span>to</span>
      <DatePicker
        {...restPickerProps}
        className={classNames(styles.picker, pickerClassName)}
        value={props.value?.till}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: any) => {
          props.onChange &&
            props.onChange({
              from: clampDate(
                props.value?.from ?? null,
                "toSODof",
                value as Date | null
              ),
              till: EOD(value as Date | null),
            });
        }}
      />
    </div>
  );
}
