import * as api from "@xflr6/chatbot-api";
import { nullifyIfBlank } from "@xflr6/utils";
import classNames from "classnames";
import React, { ReactElement, useEffect, useRef } from "react";
import { FaRedo } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";

import { AsyncStatus } from "../../app/store";
import FlatButton from "../../components/buttons/FlatButton";
import Input from "../../components/forms/Input";
import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
} from "../../components/LoadingLayout";
import { IdOrigin } from "../../featuresCommon/stats/IdOrigin";
import { ErrorData } from "../../utils/error";
import {
  loadIdentifiers,
  loadMoreIdentifiers,
  loadResponses,
  selectFilter,
  selectIdentifierIndex,
  selectIdentifiersState,
} from "./identifiersSlice";
import styles from "./IdentifiersView.module.css";
import ResponsesView from "./ResponsesView";

function ItemListFooter({
  identifiersState,
  onLoadMoreIdentifiers,
}: {
  identifiersState: {
    loading: AsyncStatus;
    loadError: ErrorData | null;
    identifiers: api.Identifiers | null;
  };
  onLoadMoreIdentifiers: () => void;
}): ReactElement | null {
  if (identifiersState.loading === "pending") {
    return <div className={styles.itemListFooter}>Loading...</div>;
  } else if (identifiersState.loading === "rejected") {
    return (
      <div className={styles.itemListFooter}>
        <FlatButton onClick={() => onLoadMoreIdentifiers()}>
          <FaRedo size={12} />
          &nbsp;Error, click to retry
        </FlatButton>
      </div>
    );
  } else {
    return null;
  }
}

export default function IdentifiersView(): ReactElement {
  const dispatch = useDispatch();

  const filter = useSelector(selectFilter);
  const identifiersState = useSelector(selectIdentifiersState);
  const currentIdentifierIndex = useSelector(selectIdentifierIndex);

  useEffect(() => {
    if (identifiersState.loading === "idle") {
      dispatch(loadIdentifiers(filter));
    }
  }, [dispatch, identifiersState.loading, filter]);

  useEffect(() => {
    if (identifiersState.identifiers?.identifiers.length === 1) {
      dispatch(loadResponses(0));
    }
  }, [dispatch, identifiersState.identifiers]);

  function renderItemList(identifiers: api.Identifiers) {
    return (
      <Virtuoso
        data={identifiers.identifiers}
        totalCount={identifiers.totalRecords}
        initialTopMostItemIndex={currentIdentifierIndex ?? 0}
        endReached={() => {
          if (identifiers.identifiers.length < identifiers.totalRecords) {
            dispatch(loadMoreIdentifiers());
          }
        }}
        itemContent={(index, identifier) => (
          <div
            key={identifier.value}
            className={classNames(styles.item, {
              [styles.item__selected]: index === currentIdentifierIndex,
            })}
            onClick={() => dispatch(loadResponses(index))}
          >
            <div className={styles.item_title}>
              {identifier.origins.flatMap((origin, index) => [
                <IdOrigin key={index} origin={origin} />,
                ...(index + 1 < identifier.origins.length
                  ? [
                      <span key={`${index}>`} className={styles.originJoin}>
                        &raquo;
                      </span>,
                    ]
                  : []),
              ])}
              {` ${identifier.value}`}
            </div>
          </div>
        )}
        components={{
          Footer: function ItemListFooter_() {
            return (
              <ItemListFooter
                identifiersState={identifiersState}
                onLoadMoreIdentifiers={() => {
                  dispatch(loadMoreIdentifiers());
                }}
              />
            );
          },
        }}
      />
    );
  }

  function renderItems(): ReactElement | null {
    if (identifiersState.identifiers?.identifiers.length) {
      return renderItemList(identifiersState.identifiers);
    } else {
      if (identifiersState.loading === "pending") {
        return <LoadingLayout />;
      } else if (identifiersState.loading === "rejected") {
        return <ErrorLayout message={identifiersState.loadError} />;
      } else if (identifiersState.loading === "fulfilled") {
        return <NoResultsLayout message="There are no identifiers" />;
      } else {
        return null;
      }
    }
  }

  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.root}>
      <div className={styles.itemsColumn}>
        <div className={styles.filter}>
          <Input
            ref={searchInputRef}
            type="search"
            styleVariant="outlined"
            className={styles.filter_searchInput}
            defaultValue={filter.search ?? ""}
            placeholder="Search"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                dispatch(
                  loadIdentifiers({
                    ...filter,
                    search: nullifyIfBlank(event.currentTarget.value),
                  })
                );
              }
            }}
          />
        </div>
        {renderItems()}
      </div>
      <div className={styles.detailsColumn}>
        <ResponsesView />
      </div>
    </div>
  );
}
