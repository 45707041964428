import * as api from "@xflr6/chatbot-api";
import { range, reverse } from "ramda";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";

import BackButton from "../../components/buttons/BackButton";
import FilledButton from "../../components/buttons/FilledButton";
import FlatButton from "../../components/buttons/FlatButton";
import HelpTip from "../../components/HelpTip";
import LoadingLayout from "../../components/LoadingLayout";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import FlowEditor from "../../features/flowEditor/FlowEditor";
import {
  selectFlowFlowSequence,
  selectFlowName,
  selectFlowVersion,
} from "../../features/flowEditor/flowEditorSlice";
import { SelectStyles } from "../../utils/reactSelect";
import styles from "./FlowVersionsPage.module.css";

const selectStyles: SelectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 85,
    fontSize: "85%",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 24,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontSize: "90%",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    marginTop: 4,
    marginBottom: 4,
  }),
};

function VersionSelect({
  latestVersion,
  selectedVersion,
  onChange,
}: {
  latestVersion: number;
  selectedVersion: number;
  onChange: (selectedVersion: number) => void;
}) {
  const countdownFrom = (n: number) => reverse(range(1, n + 1));

  const buildOption = (version: number) => ({
    value: version,
    label: `v${version}`,
  });

  // We set `menuPortalTarget` to document.body, to be able to pop out of the
  // `overflow-x: hidden` of the TopBar component, within which this is being
  // rendered.
  return (
    <Select
      styles={selectStyles}
      options={countdownFrom(latestVersion).map(buildOption)}
      value={buildOption(selectedVersion)}
      onChange={(selectedOption) => {
        if (selectedOption != null) {
          onChange((selectedOption as { value: number }).value);
        }
      }}
      menuPortalTarget={document.body}
    />
  );
}

function PromoteFlowButton({ id, version }: { id: number; version: number }) {
  const history = useHistory();
  const { addToast, removeToast } = useToasts();

  const [isPromoting, setIsPromoting] = useState(false);

  const confirmMessage =
    "This content will irreversibly replace the content you are currently building." +
    " Do you want to go ahead?";

  return (
    <FilledButton
      disabled={isPromoting}
      onClick={async () => {
        if (window.confirm(confirmMessage)) {
          setIsPromoting(true);
          try {
            await api.promoteFlowVersion(id, version);
            let toastId: string;
            addToast(
              <div className={styles.promotedToast}>
                Copied to builder
                <FlatButton
                  onClick={() => {
                    removeToast(toastId);
                    history.push(`/flows/${id}/edit`);
                  }}
                >
                  Build
                </FlatButton>
              </div>,
              {
                appearance: "success",
                autoDismiss: true,
              },
              (toastId_) => {
                toastId = toastId_;
              }
            );
            setIsPromoting(false);
          } catch (error) {
            addToast("Failed to promote version.", {
              appearance: "error",
              autoDismiss: true,
            });
            setIsPromoting(false);
          }
        }
      }}
    >
      Copy to Builder
    </FilledButton>
  );
}

export default function FlowVersionsPage(): ReactElement {
  const params = useParams<{ id: string; version: string }>();
  const id = parseInt(params.id);

  const history = useHistory();

  const flowName = useSelector(selectFlowName);
  const flowVersion = useSelector(selectFlowVersion);
  const flowSequence = useSelector(selectFlowFlowSequence);

  const [latestFlowVersion, setLatestFlowVersion] = useState<number>();

  useEffect(() => {
    api
      .getLatestFlowVersion(id)
      .then((v) => setLatestFlowVersion(v))
      .catch(() => {
        setLatestFlowVersion(undefined);
      });
  }, [id]);

  const titleUI = (
    <div className={styles.title}>
      {flowSequence != null && (
        <div className={styles.title_sequence}>
          {flowSequence.name}
          &nbsp;-
        </div>
      )}
      <div className={styles.title_text}>{flowName}</div>
      {flowVersion != null && latestFlowVersion != null && (
        <div className={styles.title_versionSelect}>
          <VersionSelect
            latestVersion={latestFlowVersion}
            selectedVersion={flowVersion ?? latestFlowVersion}
            onChange={(selectedVersion) => {
              history.replace(`/flows/${id}/versions/${selectedVersion}`);
            }}
          />
        </div>
      )}
    </div>
  );

  const version = parseInt(params.version ?? latestFlowVersion, 10);

  return (
    <div className={styles.root}>
      <TopBar
        leading={<BackButton />}
        title={titleUI}
        actions={[
          <HelpTip
            key="notSaving"
            label="Not Saving"
            tooltipContent={
              "Edits made on this page are not saved." +
              " Feel free to play around."
            }
          />,
          <PromoteFlowButton key="promoteFlow" id={id} version={version} />,
          <ProfileButton key="login" />,
        ]}
      />
      <div className={styles.editor}>
        {version == null || isNaN(version) ? (
          <LoadingLayout message="Fetching versions..." />
        ) : (
          <FlowEditor flowId={id} flowVersion={version} />
        )}
      </div>
    </div>
  );
}
