import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";

import { ReactComponent as IntegratedIcon } from "../../assets/integratedIcon.svg";
import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  ErrorLayout,
  LoadingLayoutHeaderIcon,
} from "../../components/LoadingLayout";
import { loadIntegrations, selectIntegrations } from "./integrationsSlice";

export function NoViewableData({
  type,
}: {
  type: api.IntegrationType;
}): ReactElement {
  const history = useHistory();
  return (
    <LoadingLayout
      header={
        <LoadingLayoutHeaderIcon>
          <IntegratedIcon />
        </LoadingLayoutHeaderIcon>
      }
      message={`Integrated with ${api.INTEGRATION_TYPE_TITLES[type]}`}
      footer={
        <FilledButton
          onClick={() => {
            history.push("/");
          }}
        >
          <FaHome />
          &nbsp;Home
        </FilledButton>
      }
    />
  );
}

export default function IntegrationData({
  type,
}: {
  type: api.IntegrationType;
}): ReactElement | null {
  const dispatch = useDispatch();
  const { loading, integrations } = useSelector(selectIntegrations);

  useEffect(() => {
    dispatch(loadIntegrations());
  }, [dispatch]);

  if (loading === "pending") {
    return <LoadingLayout />;
  }
  if (loading === "rejected") {
    return <ErrorLayout message="Failed to load integrations" />;
  }
  if (integrations == null) {
    return null;
  }
  if (!integrations.find((i) => i.type === type)) {
    return <Redirect to="/" />;
  }
  if (api.INTEGRATION_TYPES.includes(type)) {
    switch (type) {
      // Add your individual integration data view components here
      default:
        return <NoViewableData type={type} />;
    }
  } else {
    return <Redirect to="/" />;
  }
}
