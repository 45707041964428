import axios from "axios";

import { buildAuthHeader } from "./auth";
import { IdentifierOrigin, ResponseStub } from "./sharedTypes";
import { baseUrl } from "./utils";

export interface Identifier {
  value: string;
  origins: IdentifierOrigin[];
}

export interface Identifiers {
  identifiers: Identifier[];
  totalRecords: number;
}

export interface IdentifierResponse extends ResponseStub {
  resource: {
    type: "flow" | "flowSequence";
    id: number;
    name: string;
  };
}

export interface IdentifierResponses {
  responseLog: IdentifierResponse[];
}

export interface IdentifiersFilter {
  search?: string | null;
}

export async function getIdentifiers(options?: {
  filter?: IdentifiersFilter | null;
  sinceIdentifier?: string | null;
}): Promise<Identifiers> {
  const response = await axios.get(`${baseUrl}/identifiers`, {
    headers: { ...buildAuthHeader() },
    params: {
      ...(options?.filter?.search != null
        ? { search: options.filter.search }
        : {}),
      ...(options?.sinceIdentifier != null
        ? { sinceIdentifier: options.sinceIdentifier }
        : {}),
    },
  });
  return response.data;
}

export async function getIdentifierResponses(
  identifier: string
): Promise<IdentifierResponses> {
  const response = await axios.get(
    `${baseUrl}/identifiers/${identifier}/responseLogs`,
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}
