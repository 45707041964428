import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

export type EmbedMode = "iframe" | "webComponent";

export interface EmbedFormData {
  height: string;
  width: string;
  identifier: string;
  language: string;
  showName: boolean;
  showScore: boolean;
  allowRandomAccess: boolean;
  disableAutoAnswer: boolean;
  disableKeyboardAnswer: boolean;
}

export interface EditorUiState {
  embedMode: EmbedMode;
  embedFormData: EmbedFormData;
}

const INITIAL_STATE: EditorUiState = {
  embedMode: "iframe",
  embedFormData: {
    height: "",
    width: "",
    identifier: "",
    language: "",
    showName: false,
    showScore: true,
    allowRandomAccess: false,
    disableAutoAnswer: false,
    disableKeyboardAnswer: false,
  },
};

/**
 * The slice of state is persisted to localStorage.
 * See /src/app/store.ts for implementation details.
 */
export const slice = createSlice({
  name: "flowSequenceEditorUi",
  initialState: INITIAL_STATE,
  reducers: {
    setEmbedMode: (state: EditorUiState, action: PayloadAction<EmbedMode>) => {
      state.embedMode = action.payload;
    },
    updateEmbedFormData: (
      state: EditorUiState,
      action: PayloadAction<Partial<EmbedFormData>>
    ) => {
      state.embedFormData = { ...state.embedFormData, ...action.payload };
    },
  },
});

export const { setEmbedMode, updateEmbedFormData } = slice.actions;

export const selectEditorUi = (state: RootState): EditorUiState =>
  state.flowSequenceEditorUi;

export default slice.reducer;
