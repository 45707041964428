import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import BackButton from "../../components/buttons/BackButton";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import {
  selectStatsFlowName,
  selectStatsOwner,
} from "../../features/flowStats/flowStatsSlice";
import FlowStatsView from "../../features/flowStats/FlowStatsView";
import RefreshButton from "../../features/flowStats/RefreshButton";
import SharedByBadge from "../../featuresCommon/SharedByBadge";
import { FlowTopBarTab, FlowTopBarTabs } from "../FlowTopBarTabs";
import styles from "./FlowStatsPage.module.css";

export default function FlowStatsPage(): ReactElement {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);

  const flowName = useSelector(selectStatsFlowName);
  const owner = useSelector(selectStatsOwner);

  return (
    <div className={styles.root}>
      <TopBar
        leading={<BackButton />}
        title={
          <div className={styles.title}>
            {/* &nbsp; for spacing parity with NameEditor */}
            <div className={styles.title_name}>{flowName}&nbsp;</div>
            {owner && <SharedByBadge user={owner} />}
          </div>
        }
        center={<FlowTopBarTabs flowId={id} activeTab={FlowTopBarTab.Stats} />}
        actions={[
          <RefreshButton key="refresh" flowId={id} />,
          <ProfileButton key="login" />,
        ]}
      />
      <div className={styles.stats}>
        <FlowStatsView flowId={id} />
      </div>
    </div>
  );
}
