import React, { ReactElement, ReactNode } from "react";

export interface MessageProviderContextProps {
  transformAfterSerialize: (markdown: string) => string;
  transformBeforeDeserialize: (markdown: string) => string;
}

export const MessageProviderContext = React.createContext<
  MessageProviderContextProps | undefined
>(undefined);

export function useMessageProvider(): MessageProviderContextProps {
  const context = React.useContext(MessageProviderContext);
  if (context === undefined) {
    throw new Error(
      "useMessageProvider can only be used within a MessageProvider"
    );
  }
  return context;
}

export interface MessageProviderProps
  extends Partial<MessageProviderContextProps> {
  children: ReactNode;
}

export default function MessageProvider(
  props: MessageProviderProps
): ReactElement {
  return (
    <MessageProviderContext.Provider
      value={{
        transformAfterSerialize:
          props.transformAfterSerialize ?? ((markdown) => markdown),
        transformBeforeDeserialize:
          props.transformBeforeDeserialize ?? ((markdown) => markdown),
      }}
    >
      {props.children}
    </MessageProviderContext.Provider>
  );
}
