import {
  AltMessageItem,
  assignTranslation,
  PromptMessageItem,
} from "@xflr6/chatbot";
import classNames from "classnames";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaRegTrashAlt } from "react-icons/fa";
import { GrDrag } from "react-icons/gr";
import { MdMoreVert } from "react-icons/md";

import FlatButton from "../../../../components/buttons/FlatButton";
import Menu from "../../../../components/menus/Menu";
import MenuItem from "../../../../components/menus/MenuItem";
import Popover from "../../../../components/Popover";
import useDelayedToggle from "../../../../utils/useDelayedToggle";
import MessageEditor, { MessageEditorCtrl } from "../MessageEditor";
import { WithKey } from "../operations/types";
import styles from "./AltMessageEditor.module.css";
import InsertAltMessageButton from "./InsertAltMessageButton";

export interface AltMessageEditorCtrl {
  key: string;
  index: number;
  focus: () => void;
}

export interface AltMessageEditorProps {
  promptIndex: number;
  alternateMessage: AltMessageItem;
  index: number;
  insertMessage: (index: number, message: AltMessageItem) => void;
  updateMessage: (index: number, message: AltMessageItem) => void;
  deleteMessage: (index: number) => void;
  isLast: boolean;
  control?: (control: AltMessageEditorCtrl) => void;
  deregisterControl?: (controlKey: string) => void;
}

export default function AltMessageEditor({
  alternateMessage,
  index,
  insertMessage,
  updateMessage,
  deleteMessage,
  control,
  deregisterControl,
}: AltMessageEditorProps): ReactElement {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const {
    value: isActionMenuOpen,
    setTrueWithDelay: openActionMenuWithDelay,
    setTrueImmediate: openActionMenu,
    setFalseWithDelay: closeActionMenuWithDelay,
    setFalseImmediate: closeActionMenu_,
    toggleImmediate: toggleActionMenu,
  } = useDelayedToggle(false, {});
  const closeActionMenu = useCallback(() => {
    closeActionMenu_();
    setIsHovering(false);
  }, [closeActionMenu_]);

  const editorControl = useRef<MessageEditorCtrl>();

  const registerEditorControl = useCallback(
    (c) => (editorControl.current = c),
    []
  );

  const __key = (alternateMessage as WithKey<PromptMessageItem>).__key;

  useEffect(() => {
    control?.({
      key: __key,
      index,
      focus: () => editorControl.current?.focus(),
    });
  }, [control, __key, index]);

  useEffect(() => {
    return function cleanup() {
      deregisterControl?.(__key);
    };
  }, [deregisterControl, __key]);

  return (
    <Draggable draggableId={__key} index={index}>
      {(provided) => (
        <div
          className={classNames(styles.root, {
            [styles.root__hovering]: isHovering,
          })}
          {...provided.draggableProps}
          ref={provided.innerRef}
          onPointerOver={() => setIsHovering(true)}
          onPointerLeave={() => setIsHovering(false)}
        >
          {isHovering && index === 0 && (
            <InsertAltMessageButton
              insertMessage={insertMessage}
              index={index}
              isAbove
            />
          )}
          <div className={styles.messageItem}>
            <div className={styles.dragHandle} {...provided.dragHandleProps}>
              <GrDrag size={14} />
            </div>
            <div className={styles.editor}>
              <div className={styles.editor_alt}>
                Quick response {index + 1}
                <Popover
                  positions={["bottom", "top"]}
                  align="end"
                  reposition
                  isOpen={isActionMenuOpen}
                  onClickOutside={closeActionMenu}
                  containerClassName={styles.actionMenu}
                  content={
                    <Menu
                      autoClose
                      closeOnEsc
                      onRequestClose={closeActionMenu}
                      onPointerEnter={openActionMenu}
                      onPointerLeave={closeActionMenu}
                    >
                      <MenuItem
                        autoClose={false}
                        onClick={() => {
                          deleteMessage(index);
                        }}
                      >
                        <FaRegTrashAlt />
                        &nbsp;Delete
                      </MenuItem>
                    </Menu>
                  }
                >
                  <FlatButton
                    className={styles.actionMenuButton}
                    onPointerEnter={openActionMenuWithDelay}
                    onPointerLeave={closeActionMenuWithDelay}
                  >
                    <MdMoreVert size={18} />
                  </FlatButton>
                </Popover>
              </div>
              <MessageEditor
                message={alternateMessage.message}
                onChange={(message) =>
                  updateMessage(index, {
                    ...alternateMessage,
                    message,
                  })
                }
                translations={alternateMessage.tMessage}
                onTranslationChange={(language, message) => {
                  updateMessage(index, {
                    ...alternateMessage,
                    tMessage: assignTranslation(
                      alternateMessage.tMessage,
                      language,
                      message
                    ),
                  });
                }}
                onKeyDown={(event) => {
                  if (event.ctrlKey || event.metaKey) {
                    if (event.key === "/") {
                      toggleActionMenu();
                      return true;
                    }
                  }
                  return false;
                }}
                control={registerEditorControl}
              />
            </div>
          </div>
          {isHovering && (
            <InsertAltMessageButton
              insertMessage={insertMessage}
              index={index + 1}
            />
          )}
        </div>
      )}
    </Draggable>
  );
}
