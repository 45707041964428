import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";

import styles from "./Paths.module.css";
import { BOX_HEIGHT, BOX_WIDTH, Point, RenderTree } from "./renderTree";

function Path({ from, to }: { from: Point; to: Point }): ReactElement {
  return (
    <path
      className={styles.path}
      d={`M${from.left},${from.top} C${(from.left + to.left) / 2},${from.top} ${
        (from.left + to.left) / 2
      },${to.top} ${to.left},${to.top}`}
    />
  );
}

function PathsHelper({
  renderTree,
}: {
  renderTree: RenderTree<api.PromptNode>;
}): ReactElement {
  const from: Point = {
    left: renderTree.position.left + BOX_WIDTH,
    top: renderTree.position.top + BOX_HEIGHT / 2,
  };

  return (
    <>
      {renderTree.children &&
        renderTree.children.map(({ child }) => {
          const to: Point = {
            left: child.position.left,
            top: child.position.top + BOX_HEIGHT / 2,
          };
          return (
            <React.Fragment
              key={`from-${from.left}-${from.top}-to-${to.left}-${to.top}`}
            >
              <Path from={from} to={to} />
              <PathsHelper
                key={child.value.messages?.toString()}
                renderTree={child}
              />
            </React.Fragment>
          );
        })}
    </>
  );
}

export default function Paths({
  renderTree,
}: {
  renderTree: RenderTree<api.PromptNode>;
}): ReactElement {
  return (
    <svg className={styles.paths}>
      <PathsHelper renderTree={renderTree} />
    </svg>
  );
}
