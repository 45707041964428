import {
  AnswerDefinition,
  AnswerType,
  PromptAnswerType,
  PromptDefinition,
} from "@xflr6/chatbot";
import { any } from "ramda";

function getAnswerType(answerDef: AnswerDefinition): AnswerType {
  if (answerDef.message === "*") {
    return "custom";
  } else {
    return "choice";
  }
}

export function getPromptAnswerType(
  promptDef: PromptDefinition
): PromptAnswerType | null {
  const answers = promptDef.answers;
  if (typeof answers === "string") {
    return null;
  }
  if (answers == null || answers.length === 0) {
    if (promptDef.nextKey == null) {
      return "none";
    } else {
      return "auto";
    }
  } else if (
    any((a: AnswerDefinition) => getAnswerType(a) === "custom")(answers)
  ) {
    return "custom";
  } else if (
    any((a: AnswerDefinition) => getAnswerType(a) === "choice")(answers)
  ) {
    return "choice";
  } else {
    return "auto";
  }
}
