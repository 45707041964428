import * as api from "@xflr6/chatbot-api";
import { debounce } from "lodash";
import { equals } from "ramda";
import React, { ReactElement, useMemo } from "react";
import Select from "react-select";

import Input from "../components/forms/Input";
import { SelectStyles } from "../utils/reactSelect";
import styles from "./FlowAndSeqFilterBar.module.css";

const selectStyles: SelectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 85,
    fontSize: "90%",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 30, // To match with search term input
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontSize: "90%",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    marginTop: 4,
    marginBottom: 4,
  }),
};

export interface FlowAndSeqFilter {
  ownership: api.OwnershipFilter;
  searchTerm: string;
}

export const BLANK_FILTER = { ownership: null, searchTerm: "" };

export function filterEquals(
  filter1: FlowAndSeqFilter,
  filter2: FlowAndSeqFilter
): boolean {
  return equals<FlowAndSeqFilter>(
    { ...filter1, searchTerm: filter1.searchTerm.trim() },
    { ...filter2, searchTerm: filter2.searchTerm.trim() }
  );
}

type Option = { value: api.OwnershipFilter; label: string };

const OPTIONS: Option[] = [
  { value: null, label: "All" },
  { value: "own", label: "Mine" },
  { value: "shared", label: "Shared" },
];

function getOption(value: api.OwnershipFilter) {
  return OPTIONS.find((o) => o.value === value);
}

export default function FlowAndSeqFilterBar({
  value,
  onChange,
}: {
  value: FlowAndSeqFilter;
  onChange?: (value: FlowAndSeqFilter) => void;
}): ReactElement {
  // Helper function used to debounce callback of `onChange`
  const debounced = useMemo(
    () => debounce((func: () => void) => func(), 750),
    []
  );

  return (
    <div className={styles.root}>
      <Select
        styles={selectStyles}
        className={styles.ownershipSelect}
        options={OPTIONS}
        value={getOption(value.ownership)}
        onChange={(selectedOption) => {
          if (onChange != null) {
            onChange({
              ...value,
              ownership: (selectedOption as { value: api.OwnershipFilter })
                .value,
            });
          }
        }}
      />
      <Input
        type="search"
        styleVariant="outlined"
        className={styles.searchTermInput}
        value={value.searchTerm}
        placeholder="Search"
        onChange={(event) => {
          if (onChange != null) {
            const searchTerm = event.target.value;
            debounced(() => onChange({ ...value, searchTerm }));
          }
        }}
      />
    </div>
  );
}
