import classNames from "classnames";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./Menu.module.css";
import { MenuItemCtrl } from "./MenuItem";

export interface MenuContextProps {
  autoClose: boolean;
  closeMenu?: VoidFunction;
  registerItem: (key: string, control: MenuItemCtrl) => void;
}

const MenuContext = React.createContext<MenuContextProps | undefined>(
  undefined
);

export function useMenu(): MenuContextProps {
  const context = React.useContext(MenuContext);
  if (context === undefined) {
    throw new Error("useMenu can only be used within a Menu component");
  }
  return context;
}

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  autoClose?: boolean;
  closeOnEsc?: boolean;
  onRequestClose?: VoidFunction;
}

export default function Menu({
  className,
  autoClose,
  closeOnEsc,
  onRequestClose,
  children,
  ...restProps
}: MenuProps): ReactElement {
  const controlMap = useRef(new Map<string, MenuItemCtrl>());
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const controls = () => Array.from(controlMap.current.values());

  const context = useMemo(
    () => ({
      autoClose: !!autoClose,
      closeMenu: onRequestClose,
      registerItem: (key: string, control: MenuItemCtrl) => {
        controlMap.current.set(key, control);
      },
    }),
    [autoClose, onRequestClose]
  );

  useEffect(() => {
    controls().forEach((control, index) =>
      control.setIsSelected(index === selectedIndex)
    );
  }, [selectedIndex]);

  useEffect(() => {
    function listener(event: KeyboardEvent) {
      const count = controlMap.current.size;
      if (event.key === "ArrowUp") {
        setSelectedIndex((v) => ((v ?? 0) + count - 1) % count);
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "ArrowDown") {
        setSelectedIndex((v) => ((v ?? -1) + 1) % count);
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "Enter") {
        if (selectedIndex !== undefined) {
          controls()[selectedIndex].click();
          event.preventDefault();
          event.stopPropagation();
        }
      } else if (event.key === "Escape") {
        if (closeOnEsc && onRequestClose) {
          onRequestClose();
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }

    document.body.addEventListener("keydown", listener);

    return function cleanup() {
      document.body.removeEventListener("keydown", listener);
    };
  }, [closeOnEsc, onRequestClose, selectedIndex]);

  return (
    <MenuContext.Provider value={context}>
      <div className={classNames(styles.root, className)} {...restProps}>
        {children}
      </div>
    </MenuContext.Provider>
  );
}
