import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";

import { AsyncStatus } from "../../app/store";
import FilledButton from "../../components/buttons/FilledButton";
import FieldError from "../../components/forms/FieldError";
import FormError from "../../components/forms/FormError";
import formStyles from "../../components/forms/formStyles.module.css";
import Input from "../../components/forms/Input";
import { PASSWORD_PATTERN } from "../../utils/constants";
import {
  ErrorData,
  getSerializableError,
  getValidationErrorMessage,
} from "../../utils/error";

export default function ResetPasswordForm({
  token,
  onSuccess,
}: {
  token: string;
  onSuccess?: () => void;
}): ReactElement {
  const { register, handleSubmit, errors } = useForm<{ newPassword: string }>();

  const [sending, setSending] = useState<AsyncStatus>("idle");
  const [sendError, setSendError] = useState<ErrorData | null>(null);

  return (
    <div>
      <h1>Reset your password</h1>
      <form
        onSubmit={handleSubmit(async ({ newPassword }) => {
          setSending("pending");
          setSendError(null);
          try {
            await api.resetPassword(token, newPassword);
            onSuccess?.();
            setSending("fulfilled");
          } catch (error) {
            setSending("rejected");
            setSendError(getSerializableError(error));
          }
        })}
      >
        {sendError && (
          <FormError
            error={
              getValidationErrorMessage(sendError, "email") ??
              "Failed to reset password"
            }
          />
        )}
        <div className={formStyles.field}>
          <label>Enter a new password</label>
          <Input
            type="password"
            name="newPassword"
            styleVariant="outlined"
            className={formStyles.input}
            placeholder="At least 8 characters"
            ref={register({
              required: "Required",
              pattern: {
                value: PASSWORD_PATTERN,
                message:
                  "Must have a lowercase character, an uppercase character" +
                  ", a number and a special character",
              },
            })}
          />
          {errors?.newPassword && (
            <FieldError error={errors.newPassword.message ?? "Invalid value"} />
          )}
        </div>
        <div className={formStyles.actions}>
          <FilledButton isFullWidth disabled={sending === "pending"}>
            Reset Password
          </FilledButton>
        </div>
      </form>
    </div>
  );
}
