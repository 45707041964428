import * as api from "@xflr6/chatbot-api";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../../components/buttons/BackButton";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import IntegrationData from "../../features/integrations/IntegrationData";
import styles from "./IntegrationDataPage.module.css";

export default function IntegrationDataPage(): ReactElement {
  const { type } = useParams<{ type: api.IntegrationType }>();

  return (
    <div className={styles.root}>
      <TopBar
        leading={<BackButton />}
        title={`Integration: ${api.getIntegrationTypeTitle(type)}`}
        actions={[<ProfileButton key="login" />]}
      />
      <div className={styles.body}>
        <IntegrationData type={type} />
      </div>
    </div>
  );
}
