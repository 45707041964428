import { OrdChatFlowDefinition, PromptMessageItem } from "@xflr6/chatbot";
import { v4 } from "uuid";

import { MCFlowEditorError } from "../../errors";
import { WithKey } from "./types";

export function convertToMessages(
  promptIndex: number,
  flowDef: OrdChatFlowDefinition
): void {
  const promptDef = flowDef.promptsArray[promptIndex].definition;
  const messages = promptDef.messages;
  if (messages != null) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} already has \`messages\` array`
    );
  }
  const firstMessageItem: PromptMessageItem = {
    message: promptDef.message,
    tMessage: promptDef.tMessage,
    contentType: promptDef.contentType,
    repeatAnswers: true,
    minNextPromptDelayMs: promptDef.minNextPromptDelayMs,
  };
  (firstMessageItem as WithKey<PromptMessageItem>).__key = v4();
  promptDef.messages = [firstMessageItem];
  promptDef.message = null;
  promptDef.tMessage = null;
  promptDef.contentType = null;
  promptDef.minNextPromptDelayMs = null;
}
