import React, { ReactElement } from "react";

import BackButton from "../../components/buttons/BackButton";
import TopBar from "../../components/TopBar";
import ProfileButton from "../../features/auth/ProfileButton";
import IntegrationConfigs from "../../features/integrations/IntegrationConfigs";
import styles from "./IntegrationsPage.module.css";

export default function IntegrationsPage(): ReactElement {
  return (
    <div className={styles.root}>
      <TopBar
        leading={<BackButton />}
        title={`Integrations`}
        actions={[<ProfileButton key="login" />]}
      />
      <div className={styles.body}>
        <IntegrationConfigs />
      </div>
    </div>
  );
}
