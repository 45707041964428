import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../components/buttons/FilledButton";
import { DirtyDot } from "../../components/WithDirtyDot";
import {
  publishFlowSequence,
  selectFlowSequenceDirty,
  selectFlowSequenceOwnership,
  selectPublishing,
  selectUpdating,
} from "./flowSequenceEditorSlice";
import styles from "./PublishButton.module.css";

export default function PublishButton(): ReactElement | null {
  const dispatch = useDispatch();

  const ownership = useSelector(selectFlowSequenceOwnership);
  const dirty = useSelector(selectFlowSequenceDirty) ?? false;
  const [updating] = useSelector(selectUpdating);
  const [publishing] = useSelector(selectPublishing);

  if (ownership === "own" && dirty) {
    return (
      <FilledButton
        className={styles.root}
        onClick={() => dispatch(publishFlowSequence())}
        disabled={updating === "pending" || publishing === "pending"}
        isLoading={publishing === "pending"}
      >
        <DirtyDot />
        &nbsp;Publish
      </FilledButton>
    );
  } else {
    return null;
  }
}
