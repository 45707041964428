import { AnswerDefinition, OrdChatFlowDefinition } from "@xflr6/chatbot";

import { MCFlowEditorError } from "../../errors";

/**
 * Removes an answer from a prompt in the chat flow definition.
 * @param promptIndex
 * @param index
 * @param flowDef
 * @returns the definition of the answer removed
 */
export function removeAnswer(
  promptIndex: number,
  index: number,
  flowDef: OrdChatFlowDefinition
): AnswerDefinition {
  const { definition: promptDef } = flowDef.promptsArray[promptIndex];
  const answers = promptDef.answers;
  if (!Array.isArray(answers)) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has non-array answers: ${answers}`
    );
  }
  const targetAnswer = answers[index];
  answers.splice(index, 1);
  // Empty array is not a valid value, so if this occurs we replace by null
  if (answers.length === 0) {
    promptDef.answers = null;
  }

  return targetAnswer;
}
