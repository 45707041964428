import { Editor, Transforms } from "slate";

export const withPreventEmptyEditor = (editor: Editor): Editor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    // If editor has zero children, we add a "blank" child.
    // Slate doesn't work unless at least one child exists.
    if (!editor.children.length) {
      Transforms.insertNodes(editor, {
        type: "paragraph",
        children: [{ text: "" }],
      });
      return;
    }

    normalizeNode(entry);
  };

  return editor;
};
