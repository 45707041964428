import { Editor, Element, Transforms } from "slate";

import { ImageData } from "../../../featuresCommon/ImageInput";

export interface ImageProps {
  src: string;
  alt: string;
  title?: string;
  width?: number | null;
  height?: number | null;
  intrinsicWidth?: number | null;
  intrinsicHeight?: number | null;
}

export interface ImageElement extends Element {
  type: "image";
  imageProps: ImageProps;
}

export const MARKDOWN_IMAGE = /!\[(?<alt>[^\]]*)]\((?<src>.+?)(?=[")])(?<title>".*")?\)/g;

// Note: We only support Markdown images that are in their own line, to make
// reasoning about the editor structure easier.
// This is the same regex as above, with '^' in beginning and '$' in the end,
// and without the global flag having been set!
export const MARKDOWN_IMAGE_LINE = /^!\[(?<alt>[^\]]*)]\((?<src>.+?)(?=[")])(?<title>".*")?\)$/;

/**
 * Inserts an image into the slate editor.
 */
export function insertImage(editor: Editor, imageData: ImageData): void {
  const old = editor.selection;
  Transforms.insertNodes(editor, {
    type: "image",
    imageProps: {
      src: imageData.url.replaceAll(" ", "%20"),
      alt: "Image",
      ...(imageData.dimensions
        ? {
            intrinsicWidth: imageData.dimensions.width,
            intrinsicHeight: imageData.dimensions.height,
          }
        : {}),
    },
    children: [{ text: "" }],
  });
  if (old) Transforms.removeNodes(editor, { at: old });
}

// *** Serialization/deserialization ***

export function serializeImageElement(element: ImageElement): string {
  const {
    src,
    alt,
    title,
    width: w,
    height: h,
    intrinsicWidth: iw,
    intrinsicHeight: ih,
  } = element.imageProps;
  let augmentedSrc = src;
  if (w || h || iw || ih) augmentedSrc += "?~~~~~~~~~~";
  if (w) augmentedSrc += `w=${w},`;
  if (h) augmentedSrc += `h=${h},`;
  if (iw) augmentedSrc += `iw=${iw},`;
  if (ih) augmentedSrc += `ih=${ih},`;
  if (augmentedSrc.endsWith(",")) augmentedSrc = augmentedSrc.slice(0, -1);
  if (title) augmentedSrc += ` "${title}"`;
  return `![${alt}](${augmentedSrc})`;
}

export function destructureAugmentedImageUrl(url: string): Partial<ImageProps> {
  const parts = url.split("?~~~~~~~~~~");
  const result = {} as Partial<ImageProps>;
  result.src = parts[0];
  if (parts.length > 1) {
    const augments = parts[1].split(",");
    for (let i = 0; i < augments.length; i++) {
      const augProp = augments[i];
      if (/^w=\d+$/.test(augProp)) {
        result.width = parseInt(augProp.substring(2), 10);
      } else if (/^h=\d+$/.test(augProp)) {
        result.height = parseInt(augProp.substring(2), 10);
      } else if (/^iw=\d+$/.test(augProp)) {
        result.intrinsicWidth = parseInt(augProp.substring(3), 10);
      } else if (/^ih=\d+$/.test(augProp)) {
        result.intrinsicHeight = parseInt(augProp.substring(3), 10);
      }
    }
  }
  return result;
}

// *** Plugin ***

export function withImages(editor: Editor): Editor {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
}

// *** Other exports ***

export { useImageInput } from "../../../featuresCommon/ImageInputProvider";
export { default as ImageEditor } from "./ImageEditor";
export { default as ImageStatic } from "./ImageStatic";
