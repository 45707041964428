import {
  HistoryItemView,
  HistoryItemViewProps,
  JsonObject,
} from "@xflr6/chatbot";
import { durationInWords } from "@xflr6/utils";
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import { MdTimer } from "react-icons/md";

import { AdminInputsIcon } from "./AdminInputsIcon";
import MessageFooter from "./MessageFooter";
import Notes from "./Notes";
import styles from "./RecdHistoryItemView.module.css";

export type RecdHistoryItemViewProps = HistoryItemViewProps & {
  millisecondsSpent?: number | null;
  outlierThreshold?: number | null;
  outlierBehaviour?: "zero" | "clip";
  historyLength?: number | null;
};

export default function RecdHistoryItemView(
  props: RecdHistoryItemViewProps
): ReactElement {
  const [custom, setCustom] = useState<JsonObject | null>();
  useEffect(() => {
    props
      .getPromptInput(props.historyItem.promptKey)
      .then((p) => setCustom(p.custom));
  }, [props]);

  const isTimeSpentOutlier =
    props.millisecondsSpent != null &&
    props.outlierThreshold != null &&
    props.millisecondsSpent > props.outlierThreshold;

  const adminAnswerProvided =
    props.historyLength != null &&
    props.positionInfo.index === props.historyLength - 1;

  return (
    <div className={styles.root}>
      <HistoryItemView {...props} />
      {props.millisecondsSpent != null && (
        <div
          className={classNames(styles.timeSpent, {
            [styles.__isOutlier]: isTimeSpentOutlier,
          })}
        >
          <MdTimer />
          &nbsp;
          <div className={styles.timeSpent_actual}>
            {durationInWords(props.millisecondsSpent)}
          </div>
          {isTimeSpentOutlier && (
            <div>
              &nbsp;
              {props.outlierBehaviour === "zero"
                ? durationInWords(0)
                : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  durationInWords(props.outlierThreshold!)}
            </div>
          )}
        </div>
      )}
      {props.historyItem.answerExternally && (
        <>
          <div
            style={{ position: "relative", top: -100 }}
            id={`__history${props.positionInfo.index}_answerExternally`}
          />
          <MessageFooter
            direction="received"
            body={
              <div className={styles.row}>
                <AdminInputsIcon
                  className={styles.inputsIcon}
                  pending={adminAnswerProvided}
                />
                <div>
                  {adminAnswerProvided
                    ? "Chat paused. Provide an answer to resume."
                    : "Answered by admin"}
                </div>
              </div>
            }
          />
        </>
      )}
      {custom?.notes != null && (
        <Notes direction="received" notes={custom.notes as string} />
      )}
    </div>
  );
}
