import { PromptDefinition } from "@xflr6/chatbot";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import {
  MarkerBar,
  MarkerBarProvider,
  MarkerContainer,
} from "../../../components/MarkerBar";
import { movePrompt } from "../flowEditorSlice";
import PromptEditor, { PromptEditorCtrl } from "./PromptEditor";
import styles from "./PromptsEditor.module.css";
import { PromptTemplatesProvider } from "./promptTemplates/PromptTemplatesProvider";
export interface PromptsEditorContextProps {
  items: PromptEditorCtrl[];
}

export const PromptsEditorContext = React.createContext<
  PromptsEditorContextProps | undefined
>(undefined);

export function usePromptsEditor(): PromptsEditorContextProps {
  const context = React.useContext(PromptsEditorContext);
  if (context === undefined) {
    throw new Error("usePromptsEditor can only be used within a PromptsEditor");
  }
  return context;
}

export interface PromptsEditorCtrl {
  items: PromptEditorCtrl[];
}

export interface PromptsEditorProps {
  promptsArray: { name: string; definition: PromptDefinition }[];
  control?: (control?: PromptsEditorCtrl) => void;
}

function PromptsEditor({
  promptsArray,
  control,
}: PromptsEditorProps): ReactElement | null {
  const dispatch = useDispatch();

  const itemControls = useRef<PromptEditorCtrl[]>([]);

  const registerControl = useCallback(
    (c) => (itemControls.current[c.index] = c),
    []
  );

  const deregisterControl = useCallback((key) => {
    const index = itemControls.current.findIndex((c) => c.key === key);
    if (index !== -1) {
      itemControls.current.splice(index, 1);
    }
  }, []);

  useEffect(() => {
    control?.({
      items: itemControls.current,
    });

    return function cleanup() {
      control?.();
    };
  }, [control]);

  const { addToast } = useToasts();

  const sortedPromptsArray = useMemo(() => {
    const array = promptsArray;
    const index = array.findIndex(({ name }) => name === "start");
    return index === -1
      ? array
      : [array[index], ...array.slice(0, index), ...array.slice(index + 1)];
  }, [promptsArray]);

  function handleDragEnd(result: DropResult) {
    const { destination, source } = result;
    if (destination == null) {
      return;
    }
    if (destination.index === 0 && sortedPromptsArray[0].name === "start") {
      addToast("You cannot drag anything above the start prompt", {
        appearance: "info",
        autoDismiss: true,
      });
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    dispatch(
      movePrompt({ fromIndex: source.index, toIndex: destination.index })
    );
  }

  return (
    <PromptsEditorContext.Provider value={{ items: itemControls.current }}>
      <MarkerBarProvider>
        <PromptTemplatesProvider>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className={styles.root}>
              <Droppable droppableId="prompts">
                {(provided) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={styles.prompts}
                    >
                      <MarkerContainer>
                        {sortedPromptsArray.map(
                          ({ name, definition }, index) => {
                            return (
                              <PromptEditor
                                key={name}
                                name={name}
                                definition={definition}
                                index={index}
                                disableDragDrop={name === "start"}
                                pinInsertButton={
                                  index === sortedPromptsArray.length - 1
                                }
                                control={registerControl}
                                deregisterControl={deregisterControl}
                              />
                            );
                          }
                        )}
                      </MarkerContainer>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
              <div className={styles.markerBar}>
                <MarkerBar />
              </div>
            </div>
          </DragDropContext>
        </PromptTemplatesProvider>
      </MarkerBarProvider>
    </PromptsEditorContext.Provider>
  );
}

export default memo(PromptsEditor);
