import React, { ReactElement } from "react";

import styles from "./FormError.module.css";

export interface FormErrorProps {
  error?: string | null;
}

/**
 * Usually rendered above all form fields.
 * Renders nothing if null/undefined error is given.
 * @constructor
 */
export default function FormError(props: FormErrorProps): ReactElement | null {
  return props.error != null ? (
    <div className={styles.root}>{props.error}</div>
  ) : null;
}
