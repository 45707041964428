import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  RiBracesLine,
  RiEmotionHappyLine,
  RiImageAddLine,
} from "react-icons/ri";
import { useSlate } from "slate-react";

import FlatButton from "../../components/buttons/FlatButton";
import MyTippy from "../../components/MyTippy";
import styles from "./HoveringToolbar.module.css";
import * as utils from "./utils";

export interface HoveringToolbarProps {
  editorRef: RefObject<HTMLDivElement>;
  onInsertImageClick?: () => Promise<void>;
  onInsertEmojiClick?: () => Promise<void>;
  onInsertRecallClick?: () => Promise<void>;
}

export default function HoveringToolbar(
  props: HoveringToolbarProps
): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  const [canAddImage, setCanAddImage] = useState(false);
  const [canAddEmoji, setCanAddEmoji] = useState(false);
  const [canAddRecall, setCanAddRecall] = useState(false);
  const { selection } = editor;
  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const el = ref.current;
    if (el == null) return;
    const editorEl = props.editorRef.current;
    if (editorEl == null) return;

    const canAddImage =
      utils.canAddImage(editor) && props.onInsertImageClick != null;
    setCanAddImage(canAddImage);

    const canAddEmoji =
      utils.canAddEmoji(editor) && props.onInsertEmojiClick != null;
    setCanAddEmoji(canAddEmoji);

    const canAddRecall =
      utils.canAddRecall(editor) && props.onInsertRecallClick != null;
    setCanAddRecall(canAddRecall);

    function cancelAndHide() {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
      el?.removeAttribute("style");
    }

    const shouldShowToolbar = canAddImage || canAddRecall || canAddEmoji;
    if (shouldShowToolbar) {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
      el.removeAttribute("style");
      timeoutRef.current = setTimeout(() => {
        const domSelection = window.getSelection();
        if (domSelection == null) return;
        const domRange = domSelection.getRangeAt(0);
        if (domRange.startContainer.nodeName !== "#text") return;
        const cursorRect = domRange.getBoundingClientRect();
        const editorRect = editorEl.getBoundingClientRect();
        el.style.top = `${cursorRect.top - editorRect.top - el.offsetHeight}px`;
        const width = el.offsetWidth;
        const availableWidth = editorRect.right - cursorRect.left;
        const leftOverflow = Math.min(availableWidth - width, 0);
        el.style.left = `${cursorRect.left - editorRect.left + leftOverflow}px`;
        el.style.opacity = `0.7`;
        timeoutRef.current = undefined;
      }, 400);
    } else {
      cancelAndHide();
    }

    return function cleanup() {
      cancelAndHide();
    };
  }, [editor, selection, props.editorRef]);

  return (
    <div className={styles.root} ref={ref}>
      {canAddImage && (
        <MyTippy content="Insert image">
          <FlatButton
            className={styles.button}
            onClick={props.onInsertImageClick}
            onPointerDown={(event) => event.preventDefault()}
          >
            <RiImageAddLine />
          </FlatButton>
        </MyTippy>
      )}
      {canAddEmoji && (
        <MyTippy content="Insert emoji">
          <FlatButton
            className={styles.button}
            onClick={props.onInsertEmojiClick}
            onPointerDown={(event) => event.preventDefault()}
          >
            <RiEmotionHappyLine size={15} />
          </FlatButton>
        </MyTippy>
      )}
      {canAddRecall && (
        <MyTippy content="Insert variable">
          <FlatButton
            className={styles.button}
            onClick={props.onInsertRecallClick}
            onPointerDown={(event) => event.preventDefault()}
          >
            <RiBracesLine />
          </FlatButton>
        </MyTippy>
      )}
    </div>
  );
}
