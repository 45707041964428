import { JsonObject, OrdChatFlowDefinition } from "@xflr6/chatbot";
import { equals, pickBy } from "ramda";

import { updatePromptFields } from "./updatePromptFields";

const trim = pickBy((k) => k);
const nullifyIfBlank = (data: JsonObject) => (equals(data, {}) ? null : data);

/**
 * Removes falsy keys from custom data to optimize size.
 */
export function updatePromptCustom(
  index: number,
  value: Partial<JsonObject>,
  flowDef: OrdChatFlowDefinition
): void {
  updatePromptFields(
    index,
    {
      custom: nullifyIfBlank(
        trim({
          ...flowDef.promptsArray[index].definition.custom,
          ...value,
        })
      ),
    },
    flowDef
  );
}
