export default {
  promptsArray: [
    {
      name: "start",
      definition: {
        message: "Hello World!",
        forbidsAnswerAlteration: true,
      },
    },
  ],
};
