import { OrdChatFlowDefinition } from "@xflr6/chatbot";
import axios from "axios";

import { buildAuthHeader } from "../auth";
import {
  OwnerDetails,
  Ownership,
  OwnershipFilter,
  SettingsPatch,
  UserAccess,
} from "../sharedTypes";
import { baseUrl } from "../utils";

export interface FlowSequence {
  id: number;
  uid: string;
  name: string;
  tName: Record<string, string>;
  language: string;
  languages: string[];
  isDirty: boolean;
  flows: FlowSequenceFlow[];
  detachedFlows: FlowSequenceFlow[];
  nextComponentText: string | null;
  nextButtonText: string | null;
  lastComponentText: string | null;
  lastButtonText: string | null;
  tNextComponentText: Record<string, string> | null;
  tNextButtonText: Record<string, string> | null;
  tLastComponentText: Record<string, string> | null;
  tLastButtonText: Record<string, string> | null;
  settings: SettingsPatch;
  ownership: Ownership;
  user: OwnerDetails;
  userSettings: SettingsPatch;
}

export interface FlowSequenceFlow {
  id: number;
  name: string;
  tName: Record<string, string>;
  isDirty: boolean;
  // Not present in detached flows
  version?: string | null;
}

export interface FlowSequenceStub {
  id: number;
  name: string;
  tName?: Record<string, string>;
  isDirty: boolean;
  user: OwnerDetails;
}

export interface FlowSequences {
  flowSequences: FlowSequenceStub[];
  page: number;
  totalPages: number;
  totalRecords: number;
}

export interface PublishedFlowSeqFlow {
  id: number;
  name: string;
  tName: Record<string, string>;
  version: number;
}

export interface PublishedFlowSequence {
  id: number;
  uid: string;
  name: string;
  tName: Record<string, string>;
  language: string;
  languages: string[];
  flows: PublishedFlowSeqFlow[];
  nextComponentText: string | null;
  nextButtonText: string | null;
  lastComponentText: string | null;
  lastButtonText: string | null;
  tNextComponentText: Record<string, string> | null;
  tNextButtonText: Record<string, string> | null;
  tLastComponentText: Record<string, string> | null;
  tLastButtonText: Record<string, string> | null;
  version: number;
  publishedAt: string;
  settings: SettingsPatch;
}

export async function listFlowSequences(options: {
  page?: number;
  all?: boolean;
  ownership?: OwnershipFilter;
  q?: string;
}): Promise<FlowSequences> {
  const response = await axios.get(`${baseUrl}/flow_sequences`, {
    headers: { ...buildAuthHeader() },
    params: {
      ...(options.page ? { page: options.page.toString() } : {}),
      ...(options.all ? { all: "1" } : {}),
      ...(options.ownership ? { ownership: options.ownership } : {}),
      ...(options.q?.trim() ? { q: options.q } : {}),
    },
  });
  return response.data;
}

export async function getFlowSequence(id: number): Promise<FlowSequence> {
  const response = await axios.get(`${baseUrl}/flow_sequences/${id}`, {
    headers: { ...buildAuthHeader() },
  });
  return response.data;
}

export async function createFlowSequence(
  name: string,
  language = "en",
  flows?: {
    name: string;
    definition: OrdChatFlowDefinition;
  }[]
): Promise<number> {
  const response = await axios.post(
    `${baseUrl}/flow_sequences`,
    {
      name,
      language,
      flows,
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export type FlowSequenceUpdateFields = Partial<
  Pick<
    FlowSequence,
    | "name"
    | "tName"
    | "languages"
    | "nextComponentText"
    | "nextButtonText"
    | "lastComponentText"
    | "lastButtonText"
    | "tNextComponentText"
    | "tNextButtonText"
    | "tLastComponentText"
    | "tLastButtonText"
    | "settings"
  >
>;

export async function updateFlowSequence(
  id: number,
  fields: FlowSequenceUpdateFields
): Promise<void> {
  await axios.put(`${baseUrl}/flow_sequences/${id}`, fields, {
    headers: { ...buildAuthHeader() },
  });
}

export async function addFlowToSequence(
  id: number,
  flowName: string,
  flowDefinition: OrdChatFlowDefinition
): Promise<number> {
  const response = await axios.post(
    `${baseUrl}/flow_sequences/${id}/add_flow`,
    {
      flowName,
      flowDefinition,
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function moveFlowInSequence(
  id: number,
  from: number,
  to: number
): Promise<void> {
  const response = await axios.post(
    `${baseUrl}/flow_sequences/${id}/move_flow`,
    {
      from,
      to,
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function attachFlowToSequence(
  id: number,
  flowId: number,
  at?: number | null
): Promise<string> {
  const response = await axios.post(
    `${baseUrl}/flow_sequences/${id}/insert_flow`,
    {
      flowId,
      ...(at != null ? { at } : {}),
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function detachFlowFromSequence(
  id: number,
  flowId: number
): Promise<void> {
  await axios.post(
    `${baseUrl}/flow_sequences/${id}/remove_flow`,
    {
      flowId,
    },
    {
      headers: { ...buildAuthHeader() },
    }
  );
}

export async function publishFlowSequence(id: number): Promise<void> {
  await axios.post(`${baseUrl}/flow_sequences/${id}/publish`, null, {
    headers: { ...buildAuthHeader() },
  });
}

export async function getFlowSequenceSharedWith(
  id: number
): Promise<UserAccess[]> {
  const response = await axios.get(
    `${baseUrl}/flow_sequences/${id}/sharedWith`,
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

/**
 * Returns the name of the user added.
 */
export async function shareFlowSequence(
  id: number,
  userEmail: string
): Promise<string> {
  const response = await axios.post(
    `${baseUrl}/flow_sequences/${id}/share`,
    { with: userEmail },
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function unshareFlowSequence(
  id: number,
  userEmail: string
): Promise<void> {
  await axios.post(
    `${baseUrl}/flow_sequences/${id}/unshare`,
    { with: userEmail },
    {
      headers: { ...buildAuthHeader() },
    }
  );
}

export async function getPublishedFlowSequence(
  id: number | string,
  version?: number | string | null
): Promise<PublishedFlowSequence> {
  const response = await axios.get(
    `${baseUrl}/flow_sequences/${id}/published/${version ?? ""}`
  );
  return response.data;
}

export async function getFlowSequenceIdFromUid(uid: string): Promise<number> {
  const response = await axios.get(`${baseUrl}/flow_sequences/uid/${uid}`);
  return response.data;
}

export * from "./history";
export * from "./stats";
