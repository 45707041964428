import {
  getTranslation,
  isMessageBlank,
  RemoteMessageShape,
  Translations,
} from "@xflr6/chatbot";
import { nullifyIfBlank } from "@xflr6/utils";
import React, { ReactElement, useEffect, useMemo } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import formStyles from "../../../../../components/forms/formStyles.module.css";
import Input from "../../../../../components/forms/Input";
import { selectCurrentLanguage } from "../../../flowEditorSlice";
import TranslationEditor from "../../TranslationEditor";

export interface ConfigFormData {
  url: string;
  tUrl: string;
  statusMessage_pending: string;
  tStatusMessage_pending: string;
  statusMessage_rejected: string;
  tStatusMessage_rejected: string;
  statusMessage_fulfilledNull: string;
  tStatusMessage_fulfilledNull: string;
}

export interface ConfigFormController {
  submit: VoidFunction;
}

/**
 * Config UI for "remote" prompts.
 * @param message - The prompt's message
 * @param onSubmit - Called when the form is submitted successfully
 * @param controller - Called back with a controller that can be used to
 *   manually trigger a form submit (which may or may not be successful)
 * @param urlReadonly - Whether the URL field is readonly
 * @param renderUrlBuilder - To custom render a component to build the URL
 * @constructor
 */
export default function ConfigForm({
  message,
  translations,
  onSubmit,
  controller,
  urlReadonly,
  renderUrlBuilder,
}: {
  message: RemoteMessageShape;
  translations?: Translations | null;
  onSubmit: (
    message: RemoteMessageShape,
    translation?: {
      language: string;
      data: Partial<RemoteMessageShape> | null;
    }
  ) => void;
  controller?: ((c: ConfigFormController) => void) | null;
  urlReadonly?: boolean | null;
  renderUrlBuilder?: (renderProps: ControllerRenderProps) => ReactElement;
}): ReactElement {
  const language = useSelector(selectCurrentLanguage);
  const { control, register, handleSubmit } = useForm();

  const _onSubmit = useMemo(
    () => (data: ConfigFormData) => {
      const translation = {
        url: nullifyIfBlank(data.tUrl) ?? undefined,
        statusMessages: {
          pending: nullifyIfBlank(data.tStatusMessage_pending),
          rejected: nullifyIfBlank(data.tStatusMessage_rejected),
          fulfilledNull: nullifyIfBlank(data.tStatusMessage_fulfilledNull),
        },
      };

      onSubmit(
        {
          url: data.url,
          statusMessages: {
            pending: nullifyIfBlank(data.statusMessage_pending),
            rejected: nullifyIfBlank(data.statusMessage_rejected),
            fulfilledNull: nullifyIfBlank(data.statusMessage_fulfilledNull),
          },
        },
        language != null
          ? {
              language,
              data: isMessageBlank(translation, "remote") ? null : translation,
            }
          : undefined
      );
    },
    [language, onSubmit]
  );
  const _controller = useMemo(() => {
    return { submit: handleSubmit(_onSubmit) };
  }, [handleSubmit, _onSubmit]);
  useEffect(() => {
    if (controller != null) {
      controller(_controller);
    }
  }, [controller, _controller]);

  return (
    <form>
      <div className={formStyles.field}>
        {renderUrlBuilder != null && (
          <Controller
            control={control}
            name="url"
            defaultValue={message.url}
            render={renderUrlBuilder}
          />
        )}
      </div>
      <div className={formStyles.field}>
        <label htmlFor="url">URL</label>
        <Input
          readOnly={renderUrlBuilder != null || !!urlReadonly}
          name="url"
          styleVariant="outlined"
          className={formStyles.input}
          placeholder="The URL to fetch data from"
          defaultValue={message.url}
          ref={register}
        />
      </div>
      {renderUrlBuilder == null && !urlReadonly && (
        <TranslationEditor language={language}>
          {(language) => (
            <div className={formStyles.field}>
              <Input
                name="tUrl"
                className={formStyles.input}
                noHighlight
                defaultValue={
                  getTypedTranslation(translations, language)?.url ?? ""
                }
                ref={register}
              />
            </div>
          )}
        </TranslationEditor>
      )}
      <div className={formStyles.field}>
        <label htmlFor="statusMessage_pending">
          Message while waiting for API
        </label>
        <Input
          name="statusMessage_pending"
          styleVariant="outlined"
          className={formStyles.input}
          placeholder="Leave blank to use default"
          defaultValue={message.statusMessages?.pending ?? ""}
          ref={register}
        />
      </div>
      <TranslationEditor language={language}>
        {(language) => (
          <div className={formStyles.field}>
            <Input
              name="tStatusMessage_pending"
              className={formStyles.input}
              noHighlight
              defaultValue={
                getTypedTranslation(translations, language)?.statusMessages
                  ?.pending ?? ""
              }
              ref={register}
            />
          </div>
        )}
      </TranslationEditor>
      <div className={formStyles.field}>
        <label htmlFor="statusMessage_rejected">
          Message if API returns an error
        </label>
        <Input
          name="statusMessage_rejected"
          styleVariant="outlined"
          className={formStyles.input}
          placeholder="Leave blank to use default"
          defaultValue={message.statusMessages?.rejected ?? ""}
          ref={register}
        />
      </div>
      <TranslationEditor language={language}>
        {(language) => (
          <div className={formStyles.field}>
            <Input
              name="tStatusMessage_rejected"
              className={formStyles.input}
              noHighlight
              defaultValue={
                getTypedTranslation(translations, language)?.statusMessages
                  ?.rejected ?? ""
              }
              ref={register}
            />
          </div>
        )}
      </TranslationEditor>
      <div className={formStyles.field}>
        <label htmlFor="statusMessage_fulfilledNull">
          Message if API returns no data
        </label>
        <Input
          name="statusMessage_fulfilledNull"
          styleVariant="outlined"
          className={formStyles.input}
          placeholder="Leave blank to use default"
          defaultValue={message.statusMessages?.fulfilledNull ?? ""}
          ref={register}
        />
      </div>
      <TranslationEditor language={language}>
        {(language) => (
          <div className={formStyles.field}>
            <Input
              name="tStatusMessage_fulfilledNull"
              className={formStyles.input}
              noHighlight
              defaultValue={
                getTypedTranslation(translations, language)?.statusMessages
                  ?.fulfilledNull ?? ""
              }
              ref={register}
            />
          </div>
        )}
      </TranslationEditor>
    </form>
  );
}

function getTypedTranslation(
  translations: Translations | null | undefined,
  language: string
): RemoteMessageShape | null {
  return getTranslation(translations, language) as RemoteMessageShape | null;
}
