import { isEmpty, pick } from "ramda";

// TODO Make `ErrorData` type more robust
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorData = any;

export const isServerResponseError = (
  error: unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): error is { response: any } =>
  typeof error === "object" && error != null && "response" in error;

export function getSerializableError(error: unknown): ErrorData | null {
  if (error == null) {
    return null;
  } else if (typeof error === "string") {
    return error;
  } else if (isServerResponseError(error)) {
    return pick(["data", "status", "statusText"])(error.response);
  } else {
    return "Unknown error";
  }
}

export function getStringErrorMessage(error: ErrorData): string | null {
  if (error == null) {
    return null;
  } else if (typeof error === "string") {
    return error;
  } else if (typeof error === "object" && !isEmpty(error?.data)) {
    return error.data;
  } else {
    return null;
  }
}

export function getValidationErrorMessage(
  error: ErrorData,
  field: string
): string | null {
  if (error == null) return null;
  const fieldError = error.data?.errors?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => error.param === field
  );
  return fieldError != null ? fieldError?.msg : null;
}

export function getBestEffortErrorMessage(error: ErrorData): string | null {
  if (error == null) {
    return null;
  } else if (typeof error === "string") {
    return error;
  } else if (typeof error.data === "string" && !isEmpty(error.data)) {
    return error.data;
  } else if (error.data?.errors) {
    return "Invalid data";
  } else if (error.statusText) {
    return error.statusText;
  } else if (error.message) {
    return error.message;
  } else {
    return "Unknown error";
  }
}
