import { PromptDefinition } from "@xflr6/chatbot";
import React, { ReactElement } from "react";
import { FaPlus } from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";
import { useDispatch } from "react-redux";

import SplitButton from "../../../components/buttons/SplitButton";
import { insertPrompt } from "../flowEditorSlice";
import styles from "./InsertPromptButton.module.css";
import { usePromptsEditor } from "./PromptsEditor";
import { usePromptTemplates } from "./promptTemplates/PromptTemplatesProvider";

const OPTIONS = [
  { value: "qa", label: "Add prompt" },
  { value: "imageUpload", label: "Image upload prompt" },
  { value: "selectTemplate", label: "Select a template..." },
];

export interface InsertPromptButtonProps {
  index: number;
}

export default function InsertPromptButton(
  props: InsertPromptButtonProps
): ReactElement {
  const dispatch = useDispatch();

  const promptsEditor = usePromptsEditor();

  const getPromptTemplate = usePromptTemplates();

  function insertPromptHandler(definition: PromptDefinition | null) {
    dispatch(insertPrompt({ definition, index: props.index }));
    setTimeout(() => promptsEditor.items[props.index].focus(), 0);
  }

  return (
    <div className={styles.root}>
      <SplitButton
        className={styles.button}
        icon={<MdMoreHoriz size={16} />}
        options={OPTIONS}
        renderSelectedOption={(option) => (
          <div className={styles.selectedOption}>
            <FaPlus size={12} />
            <div className={styles.optionLabel}>{option.label}</div>
          </div>
        )}
        selectedValue={OPTIONS[0].value}
        onClick={async (value) => {
          if (value === "qa") {
            insertPromptHandler(null);
          } else if (value === "imageUpload") {
            insertPromptHandler({
              message: "",
              answers: [{ message: "*", contentType: "lightbox" }],
              inputDisplayType: "AwsImageUpload",
              inputDisplayConfig: {
                pathTemplate: "user-uploads/{{user}}/{{randomString}}-{{name}}",
                uploadPath: `${process.env.REACT_APP_API_BASE}/aws/s3-sign-upload`,
              },
              forbidsAnswerAlteration: true,
            });
          } else if (value === "selectTemplate") {
            const template = await getPromptTemplate();
            if (template != null) {
              insertPromptHandler(template.definition);
            }
          }
        }}
      />
    </div>
  );
}
