import { createSelector } from "@reduxjs/toolkit";
import { OrdChatFlowDefinition, PromptDefinition } from "@xflr6/chatbot";

import { selectEditor } from "./flowEditorSlice";

export type PromptEasyNamesEntry = {
  easy: string;
  name: string;
  promptDef: PromptDefinition;
};

export class PromptEasyNames {
  private readonly _toEasy: Record<string, string> = {};
  private readonly _entries: PromptEasyNamesEntry[] = [];

  constructor(flowDef: OrdChatFlowDefinition) {
    flowDef.promptsArray.forEach(({ name, definition }, index) => {
      this._toEasy[name] = `${index}`;
      this._entries.push({
        easy: `${index}`,
        name,
        promptDef: definition,
      });
    });
  }

  get entries(): PromptEasyNamesEntry[] {
    return this._entries;
  }

  toEasyName(name: string): string {
    return this._toEasy[name];
  }

  toEasyKey(keyStr: string): string {
    if (keyStr.startsWith(".")) {
      return "." + this._toEasy[keyStr.substr(1)];
    } else {
      return keyStr;
    }
  }
}

export const selectPromptEasyNames = createSelector(selectEditor, (editor) => {
  if (editor.flow == null) return null;
  return new PromptEasyNames(editor.flow.definition);
});
