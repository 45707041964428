import * as api from "@xflr6/chatbot-api";
import { equals } from "ramda";
import React, { ReactElement, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";

import { useAppDispatch } from "../../../app/store";
import FilledButton from "../../../components/buttons/FilledButton";
import FieldError from "../../../components/forms/FieldError";
import formStyles from "../../../components/forms/formStyles.module.css";
import Input from "../../../components/forms/Input";
import Modal from "../../../components/Modal";
import { URL_PATTERN } from "../../../utils/constants";
import { SelectStyles } from "../../../utils/reactSelect";
import { addIntegration, selectIntegrations } from "../integrationsSlice";

const selectStyles: SelectStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 30,
  }),
};

type AuthMethodOption = { value: string; label: string };

const AUTH_METHOD_OPTIONS: AuthMethodOption[] = [
  { value: "JWK_KEY", label: "JWK key" },
  { value: "JWK_SET", label: "JWK keyset" },
  { value: "RSA_KEY", label: "RSA key" },
];

export function getAuthMethodOption(
  value: string
): AuthMethodOption | undefined {
  return AUTH_METHOD_OPTIONS.find((o) => o.value === value);
}

function AddPlatformForm({
  onSuccess,
  onCancel,
}: {
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const {
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    trigger,
  } = useForm<api.PlatformConfig>();

  return (
    <form
      onSubmit={handleSubmit(async (formData) => {
        await dispatch(
          addIntegration({
            type: "lti",
            config: formData,
          })
        );
        if (equals(lastUpdateAction, ["lti", "add"])) {
          onSuccess?.();
        }
      })}
    >
      <div className={formStyles.field}>
        <label className={formStyles.label}>Name</label>
        <Input
          name="name"
          styleVariant="outlined"
          className={formStyles.input}
          placeholder="Enter a nickname for this platform"
          ref={register({ required: "Required" })}
        />
        {errors?.name && (
          <FieldError error={errors.name.message ?? "Invalid value"} />
        )}
      </div>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Platform URL</label>
        <Input
          name="url"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register({
            required: "Required",
            pattern: {
              value: URL_PATTERN,
              message: "Please enter a valid URL",
            },
          })}
        />
        {errors?.url && (
          <FieldError error={errors.url.message ?? "Invalid value"} />
        )}
      </div>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Client ID</label>
        <Input
          name="clientId"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register({ required: "Required" })}
        />
        {errors?.clientId && (
          <FieldError error={errors.clientId.message ?? "Invalid value"} />
        )}
      </div>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Authentication URL</label>
        <Input
          name="authUrl"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register({
            required: "Required",
            pattern: {
              value: URL_PATTERN,
              message: "Please enter a valid URL",
            },
          })}
        />
        {errors?.authUrl && (
          <FieldError error={errors.authUrl.message ?? "Invalid value"} />
        )}
      </div>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Authentication method</label>
        <Controller
          name="authConfigMethod"
          control={control}
          defaultValue={AUTH_METHOD_OPTIONS[0].value}
          render={({ ref, value, onChange }) => {
            return (
              <Select
                ref={ref}
                styles={selectStyles}
                menuPlacement="auto"
                menuPosition="fixed"
                menuPortalTarget={document.body}
                options={AUTH_METHOD_OPTIONS}
                value={getAuthMethodOption(value)}
                onChange={(selectedOption) => {
                  onChange((selectedOption as AuthMethodOption).value);
                  if (formState.dirtyFields.authConfigKey) {
                    trigger(["authConfigKey"]);
                  }
                }}
              />
            );
          }}
        />
        {errors?.authConfigMethod && (
          <FieldError
            error={errors.authConfigMethod.message ?? "Invalid value"}
          />
        )}
      </div>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Authentication key</label>
        <Input
          name="authConfigKey"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register({
            required: "Required",
            validate: {
              jwk_set: (value) => {
                const method = getValues("authConfigMethod");
                return method !== "JWK_SET" || URL_PATTERN.test(value);
              },
              non_jwk_set: (value) => {
                const method = getValues("authConfigMethod");
                return method === "JWK_SET" || !URL_PATTERN.test(value);
              },
            },
          })}
        />
        {errors?.authConfigKey &&
          (errors.authConfigKey.type === "jwk_set" ? (
            <FieldError error="Please provide a valid URL" />
          ) : errors.authConfigKey.type === "non_jwk_set" ? (
            <FieldError error="This looks like an invalid key" />
          ) : (
            <FieldError
              error={errors.authConfigKey.message ?? "Invalid value"}
            />
          ))}
      </div>
      <div className={formStyles.field}>
        <label className={formStyles.label}>Access token URL</label>
        <Input
          name="accessTokenUrl"
          styleVariant="outlined"
          className={formStyles.input}
          ref={register({ required: "Required" })}
        />
        {errors?.accessTokenUrl && (
          <FieldError
            error={errors.accessTokenUrl.message ?? "Invalid value"}
          />
        )}
      </div>
      <div className={formStyles.actions}>
        <FilledButton
          disabled={updating === "pending"}
          isLoading={
            updating === "pending" && equals(lastUpdateAction, ["lti", "add"])
          }
        >
          Add
        </FilledButton>
        {onCancel != null && (
          <FilledButton
            disabled={updating === "pending"}
            onClick={(event) => {
              onCancel();
              event.preventDefault();
            }}
          >
            Cancel
          </FilledButton>
        )}
      </div>
    </form>
  );
}

export default function AddPlatformButton(): ReactElement {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const closeForm = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  return (
    <>
      <Modal
        title="Add Platform"
        style={{
          content: {
            width: "700px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isFormOpen}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeForm}
      >
        <AddPlatformForm onSuccess={closeForm} onCancel={closeForm} />
      </Modal>
      <FilledButton onClick={() => setIsFormOpen(true)} disabled={isFormOpen}>
        <FaPlus size={14} />
        &nbsp;Add Platform
      </FilledButton>
    </>
  );
}
