import classNames from "classnames";
import React, { ReactElement, useMemo, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { AdminInputsIcon } from "./AdminInputsIcon";
import styles from "./ChatViewNav.module.css";

export const NAV_LOCATION_CATEGORIES = {
  feedback: "Feedback",
  scoreLater: "Score",
  evaluation: "Evaluation",
  answerExternally: "Chat paused",
};

export type NavLocationCategory = keyof typeof NAV_LOCATION_CATEGORIES;

export interface ChatViewNavLocation {
  category: NavLocationCategory;
  historyIndex: number;
  domElementId: string;
  actionPending: boolean;
}

export default function ChatViewNav({
  locations,
}: {
  locations: ChatViewNavLocation[];
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const actionsPending = useMemo(
    () => locations.some(({ actionPending }) => actionPending),
    [locations]
  );
  const categoryItemCounts: Record<string, number> = {};

  return (
    <div
      className={classNames(styles.root, { [styles.__isOpen]: isOpen })}
      onPointerEnter={() => setIsOpen(true)}
      onPointerLeave={() => setIsOpen(false)}
    >
      <AdminInputsIcon className={styles.titleBadge} pending={actionsPending} />
      <div className={styles.title}>
        Admin Inputs&nbsp;{isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </div>
      {isOpen && (
        <div className={styles.locations}>
          {locations.map((location, index) => {
            return (
              <div
                key={index + 1}
                className={styles.location}
                onClick={() =>
                  document
                    .getElementById(location.domElementId)
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                <AdminInputsIcon
                  className={styles.location_icon}
                  pending={location.actionPending}
                />
                &nbsp;
                {`${
                  NAV_LOCATION_CATEGORIES[location.category]
                } #${(categoryItemCounts[location.category] =
                  (categoryItemCounts[location.category] ?? 0) + 1)}`}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
