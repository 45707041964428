import React, { ReactElement } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as IntegrationIcon } from "../../assets/integrationIcon.svg";
import LoadingLayout, {
  LoadingLayoutHeaderIcon,
} from "../../components/LoadingLayout";
import PageContents from "./PageContents";
import { getFreshState } from "./utils";

export default function InstallPage(): ReactElement {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const subdomain = query.get("subdomain");
  const callbackUrl = `${window.location.origin}/thinkific-app/callback`;
  const oAuthGrantScreenUrl =
    `https://${subdomain}.thinkific.com/oauth2/authorize` +
    `?client_id=${process.env.REACT_APP_THINKIFIC_CLIENT_KEY}` +
    `&redirect_uri=${encodeURIComponent(callbackUrl)}` +
    `&response_mode=query` +
    `&response_type=code` +
    `&state=${getFreshState()}` +
    `&scope=write:site_scripts`;

  useEffect(() => {
    if (subdomain != null) {
      window.location.replace(oAuthGrantScreenUrl);
    }
  });

  return (
    <PageContents>
      <LoadingLayout
        header={
          <LoadingLayoutHeaderIcon>
            <IntegrationIcon />
          </LoadingLayoutHeaderIcon>
        }
        message={
          <div>
            Redirecting to <a href={oAuthGrantScreenUrl}>Thinkific</a>
          </div>
        }
      />
    </PageContents>
  );
}
