import {
  Button,
  HistoryComponent,
  HistoryItem,
  HistoryItemJson,
  MessageView,
  PositionInfo,
  PromptInput,
  RemoteMessage,
  RemoteMessageShape,
  WithCssVariables,
} from "@xflr6/chatbot";
import React, { ReactElement, ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import AnswerSample from "./AnswerSample";
import styles from "./AnswerSamplesMessage.module.css";
import Modal from "./Modal";
import { ReactComponent as UserAnswersIcon } from "./userAnswersIcon.svg";

export default function AnswerSamplesMessage({
  historyItem,
  positionInfo,
  promptInput,
  skipAnimation,
}: {
  historyItem: HistoryItem;
  positionInfo: PositionInfo;
  promptInput: PromptInput | null;
  skipAnimation: boolean | null;
}): ReactElement {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  function renderModal(children: ReactNode) {
    return (
      <Modal
        title={t("Answer Samples")}
        style={{
          content: {
            minWidth: "min(calc(100% - var(--spacing) * 2), 400px)",
            maxWidth: "min(calc(100% - var(--spacing) * 2), 600px)",
          },
        }}
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsModalOpen(false)}
        contentElement={(props, contentElement) => (
          <WithCssVariables>
            <div {...props}>{contentElement}</div>
          </WithCssVariables>
        )}
      >
        {children}
      </Modal>
    );
  }

  return (
    <HistoryComponent
      historyItem={historyItem}
      positionInfo={positionInfo}
      promptInput={promptInput}
    >
      <RemoteMessage
        direction={historyItem.direction}
        positionInfo={positionInfo}
        message={(historyItem.cleanMessage as unknown) as RemoteMessageShape}
        renderFetchedMessage={(message) => {
          const historyItemJsons = message.body as HistoryItemJson[];
          return (
            <>
              {renderModal(
                historyItemJsons.length > 0 ? (
                  historyItemJsons
                    .map(HistoryItem.fromJson)
                    .map((historyItem, index) => (
                      <AnswerSample
                        key={index}
                        title={`${t("Answer")} #${index + 1}`}
                        answer={historyItem}
                      />
                    ))
                ) : (
                  <span>
                    <Trans>
                      Sorry, no peer answers have been recorded yet!
                    </Trans>
                  </span>
                )
              )}
              <MessageView
                direction={historyItem.direction}
                positionInfo={positionInfo}
                skipAnimation={skipAnimation}
              >
                <div className={styles.message}>
                  <div className={styles.icon}>
                    <UserAnswersIcon />
                  </div>
                  <Button onClick={() => setIsModalOpen(true)}>
                    <Trans>View Peer Answers</Trans>
                  </Button>
                </div>
              </MessageView>
            </>
          );
        }}
      />
    </HistoryComponent>
  );
}
