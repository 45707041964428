import { OrdChatFlowDefinition } from "@xflr6/chatbot";
import React, { memo, ReactElement } from "react";
import { useSelector } from "react-redux";

import { selectFlowFlowSequence } from "../flowEditorSlice";
import styles from "./Editor.module.css";
import LanguageSelect from "./LanguageSelect";
import PromptsEditor from "./PromptsEditor";

export interface EditorProps {
  flowDef: OrdChatFlowDefinition;
}

function Editor(props: EditorProps): ReactElement {
  const flowSequence = useSelector(selectFlowFlowSequence);

  return (
    <div className={styles.root}>
      <div className={styles.topBar}>
        <LanguageSelect isReadonly={!!flowSequence} />
      </div>
      <div className={styles.prompts}>
        <PromptsEditor promptsArray={props.flowDef.promptsArray} />
      </div>
    </div>
  );
}

export default memo(Editor);
