import { AnswerDefinition, OrdChatFlowDefinition } from "@xflr6/chatbot";
import { forEachObjIndexed } from "ramda";

/**
 * Finds all references to `promptName` in the chat flow definition, calling
 * `callback` each time a reference is found.
 * @param promptName
 * @param callBack - Called each time a reference to `promptName` is found.
 *   If you return true from the callback, the reference is deleted. This is
 *   useful when removing prompts.
 * @param flowDef
 */
export function forEachPromptRef(
  promptName: string,
  callBack: (
    referrerName: string,
    referrerType: "prompt" | "common_ans"
  ) => boolean | void,
  flowDef: OrdChatFlowDefinition
): void {
  flowDef.promptsArray.forEach(({ name, definition }) => {
    if (definition.nextKey === `.${promptName}`) {
      if (callBack(name, "prompt")) definition.nextKey = null;
    }

    if (Array.isArray(definition.answers)) {
      definition.answers.forEach((answerDef) => {
        if (answerDef.nextKey === `.${promptName}`) {
          if (callBack(name, "prompt")) answerDef.nextKey = null;
        }
      });
    }
  });

  if (flowDef.answers != null) {
    forEachObjIndexed((answers: AnswerDefinition[], name: string) => {
      answers.forEach((answerDef) => {
        if (answerDef.nextKey === `.${promptName}`) {
          if (callBack(name, "common_ans")) answerDef.nextKey = null;
        }
      });
    }, flowDef.answers);
  }
}
