import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import FilledButton from "../../components/buttons/FilledButton";
import Modal from "../../components/Modal";
import CreateForm from "./CreateForm";

export default function CreateFlowSequenceButton(): ReactElement {
  const history = useHistory();
  const { addToast } = useToasts();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  return (
    <>
      <Modal
        title="New Sequence"
        ariaHideApp={false}
        isOpen={isFormOpen}
        style={{
          content: {
            width: "400px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        onRequestClose={() => {
          setIsFormOpen(false);
        }}
      >
        <CreateForm
          onSubmit={async ({ name, language }) => {
            setIsCreating(true);
            try {
              const id = await api.createFlowSequence(name, language);
              setIsCreating(false);
              setIsFormOpen(false);
              history.push(`/sequences/${id}/edit`);
            } catch (error) {
              addToast("Failed to create flow sequence.", {
                appearance: "error",
                autoDismiss: true,
              });
              setIsCreating(false);
            }
          }}
          isPending={isCreating}
        />
      </Modal>
      <FilledButton
        disabled={isCreating}
        isLoading={isCreating}
        onClick={() => {
          setIsFormOpen(true);
        }}
      >
        <FaPlus size={14} />
        &nbsp;New Sequence
      </FilledButton>
    </>
  );
}
