import classNames from "classnames";
import React, { ReactElement } from "react";
import { MdTranslate } from "react-icons/md";

import styles from "./TranslateIcon.module.css";

export default function TranslateIcon({
  size = 18,
  language,
}: {
  size?: string | number;
  language?: string | null;
}): ReactElement {
  return (
    <MdTranslate
      size={size}
      className={classNames(styles.root, {
        [styles.root__nonDefault]: language != null,
      })}
    />
  );
}
