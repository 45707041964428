import { FlowSequenceStats } from "@xflr6/chatbot-api";
import { durationInWords } from "@xflr6/utils";
import classNames from "classnames";
import React, { ReactElement, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
  SelectItemLayout,
} from "../../components/LoadingLayout";
import BigPictureStats from "../../featuresCommon/stats/BigPictureStats";
import PromptAnalyticsView from "../../featuresCommon/stats/PromptAnalyticsView";
import {
  loadItemStats,
  selectItemStatsState,
  selectSelectedItem,
} from "./flowSequenceStatsSlice";
import styles from "./ItemAnalyticsView.module.css";

export interface ItemAnalyticsViewProps {
  flowSequenceStats: FlowSequenceStats;
}

export default function ItemAnalyticsView({
  flowSequenceStats,
}: ItemAnalyticsViewProps): ReactElement {
  const dispatch = useDispatch();
  const selectedItem = useSelector(selectSelectedItem);
  const itemStatsState = useSelector(selectItemStatsState);

  const items = useMemo(() => {
    const items = Object.values(flowSequenceStats.items);
    return [
      ...items.filter((i) => i.attempted > 0),
      ...items.filter((i) => i.attempted === 0),
    ];
  }, [flowSequenceStats]);

  function renderFlowStats() {
    if (itemStatsState.itemStats != null) {
      return <PromptAnalyticsView flowStats={itemStatsState.itemStats} />;
    } else {
      if (itemStatsState.loading === "pending") {
        return <LoadingLayout />;
      } else if (itemStatsState.loading === "rejected") {
        return <ErrorLayout message={itemStatsState.loadError} />;
      } else if (itemStatsState.loading === "fulfilled") {
        return <NoResultsLayout message="There are no flows" />;
      } else {
        return <SelectItemLayout message="Select a flow to view" />;
      }
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.bigPicture}>
        <BigPictureStats stats={flowSequenceStats} />
      </div>
      <div className={styles.analytics}>
        <div className={styles.itemsColumn}>
          {Object.values(items).map((flowStats) => {
            return flowStats.attempted > 0 ? (
              <div
                key={flowStats.id}
                className={classNames(styles.item, {
                  [styles.item__selected]: flowStats.id === selectedItem,
                })}
                onClick={() => {
                  dispatch(loadItemStats(flowStats.id));
                }}
              >
                <div className={styles.item_name}>{flowStats.name}</div>
                <div className={styles.item_bigPicture}>
                  <div className={styles.name}>Started</div>
                  <div className={styles.value}>{flowStats.attempted}</div>
                  <div className={styles.divider} />
                  <div className={styles.name}>Completed</div>
                  <div className={styles.value}>{flowStats.totalCompleted}</div>
                  <div className={styles.name}>Avg. time</div>
                  <div className={styles.value}>
                    {durationInWords(flowStats.totalTime)}
                  </div>
                  <div className={styles.name}>Avg. time</div>
                  <div className={styles.value}>
                    {durationInWords(flowStats.totalCompletedTime)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classNames(styles.item, styles.item__notStarted)}>
                <div className={styles.item_name}>{flowStats.name}</div>
                <div className={styles.item_message}>
                  No one has started this flow yet
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.itemStatsColumn}>{renderFlowStats()}</div>
      </div>
    </div>
  );
}
