import * as api from "@xflr6/chatbot-api";
import { nullifyIfBlank } from "@xflr6/utils";
import React, { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";

import WithDirtyDot from "../../components/WithDirtyDot";
import styles from "./FlowSequenceItem.module.css";

export interface FlowSequenceItemProps {
  flowSequence: api.FlowSequenceStub;
  sharedBy?: api.OwnerDetails | null;
  renderAction?: (() => ReactNode) | null;
}

export default function FlowSequenceItem(
  props: FlowSequenceItemProps
): ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <div className={styles.info_title}>
          <WithDirtyDot isDirty={props.flowSequence.isDirty}>
            <Link to={`sequences/${props.flowSequence.id}/edit`}>
              {props.flowSequence.name}
            </Link>
          </WithDirtyDot>
        </div>
        {props.sharedBy && (
          <div className={styles.info_owner}>
            <span>Shared by</span>&nbsp;
            <span>
              {nullifyIfBlank(props.sharedBy.name) ?? props.sharedBy.email}
            </span>
          </div>
        )}
      </div>
      <div className={styles.actions}>{props.renderAction?.()}</div>
    </div>
  );
}
