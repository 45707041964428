import { useEffect, useRef } from "react";

/**
 * A `useEffect` that only triggers on updates, not on initial mount.
 */
const useUpdateEffect: typeof useEffect = (effect, deps?): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
    // It's ok to disable eslint here, because this is just to get around a
    // compiler warning. It will never actually cause unexpected behaviour.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
