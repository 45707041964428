import { AnswerDefinition, PromptDefinition } from "@xflr6/chatbot";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../app/store";
import FlatButton from "../../../components/buttons/FlatButton";
import { selectEffectiveFlowSettings } from "../../../featuresCommon/selectors";
import { getNonNullChildCount } from "../../../utils/react";
import {
  EditorUpdatePromptCustom,
  EditorUpdatePromptFields,
  updatePromptCustom,
  updatePromptFields,
} from "../flowEditorSlice";
import { setIsPromptConfigOpen } from "../flowEditorUiSlice";
import { selectPreviewPrompt } from "../selectPreviewPrompt";
import { selectPromptEasyNames } from "../selectPromptEasyNames";
import * as ops from "./operations";
import styles from "./PromptConfigEditor.module.css";
import AllowMultiSelect from "./promptConfigEditors/AllowMultiSelect";
import AnswerExternally from "./promptConfigEditors/AnswerExternally";
import ChoiceInputDisplayType from "./promptConfigEditors/ChoiceInputDisplayType";
import ContentType from "./promptConfigEditors/ContentType";
import CustomInputDisplayType from "./promptConfigEditors/CustomInputDisplayType";
import ForbidAnswerChange from "./promptConfigEditors/ForbidAnswerChange";
import MinNextPromptDelay from "./promptConfigEditors/MinNextPromptDelay";
import NormalizeAnswers from "./promptConfigEditors/NormalizeAnswers";
import Notes from "./promptConfigEditors/Notes";
import RequireFeedback from "./promptConfigEditors/RequireFeedback";
import ScoreStrategyView from "./promptConfigEditors/ScoreStrategyView";
import ShuffleChoices from "./promptConfigEditors/ShuffleChoices";

function ConfigItem({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}): ReactElement {
  return (
    <div className={styles.ConfigItem}>
      {title && <div className={styles.ConfigItem_title}>{title}</div>}
      {children}
    </div>
  );
}

function ConfigGroup({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}): ReactElement | null {
  const nonNullChildCount = getNonNullChildCount(children);
  if (nonNullChildCount === 0) return null;

  return (
    <div className={styles.ConfigGroup}>
      {title && <div className={styles.ConfigGroup_title}>{title}</div>}
      {children}
    </div>
  );
}

export interface PromptConfigEditorOpsContextProps {
  updateCustom: (data: EditorUpdatePromptCustom["data"]) => void;
  updateFields: (fields: EditorUpdatePromptFields["fields"]) => void;
}

export interface PromptConfigEditorStateContextProps {
  name: string;
  definition: PromptDefinition;
}

export const PromptConfigEditorOpsContext = React.createContext<
  PromptConfigEditorOpsContextProps | undefined
>(undefined);

export const PromptConfigEditorStateContext = React.createContext<
  PromptConfigEditorStateContextProps | undefined
>(undefined);

export function usePromptConfigEditorOps(): PromptConfigEditorOpsContextProps {
  const context = React.useContext(PromptConfigEditorOpsContext);
  if (context === undefined) {
    throw new Error(
      "usePromptConfigEditorOps can only be used within a PromptConfigEditor"
    );
  }
  return context;
}

export function usePromptConfigEditorState(): PromptConfigEditorStateContextProps {
  const context = React.useContext(PromptConfigEditorStateContext);
  if (context === undefined) {
    throw new Error(
      "usePromptConfigEditorState can only be used within a PromptConfigEditor"
    );
  }
  return context;
}

export default function PromptConfigEditor(): ReactElement | null {
  const dispatch = useDispatch();

  const prompt = useSelector(selectPreviewPrompt);
  const settings = useSelector(selectEffectiveFlowSettings);
  const [answerExternallyEnabled, setAnswerExternallyEnabled] = useState(false);

  const updateCustom = useCallback(
    (data: EditorUpdatePromptCustom["data"]) => {
      if (prompt == null) throw Error("Prompt not present");
      dispatch(
        updatePromptCustom({
          promptIndexOrName: prompt.name,
          data,
        })
      );
    },
    [dispatch, prompt]
  );

  const updateFields = useCallback(
    (fields: EditorUpdatePromptFields["fields"]) => {
      if (prompt == null) throw Error("Prompt not present");
      dispatch(
        updatePromptFields({
          promptIndexOrName: prompt.name,
          fields,
        })
      );
    },
    [dispatch, prompt]
  );

  useEffect(() => {
    if (prompt == null) return;
    const isMultiStepPrompt = prompt.definition.messages != null;
    if (
      isMultiStepPrompt ||
      ops.promptHasQuickResponses(prompt.definition) ||
      ["auto", "none"].includes(
        ops.getPromptAnswerType(prompt.definition) ?? ""
      )
    ) {
      if (prompt?.definition.answerExternally) {
        updateFields({ answerExternally: false });
      }
      setAnswerExternallyEnabled(false);
    } else {
      setAnswerExternallyEnabled(true);
    }
  }, [prompt, updateFields]);

  const easyName = useSelector((state: RootState) => {
    const prompt = selectPreviewPrompt(state);
    const promptEasyNames = selectPromptEasyNames(state);
    return prompt && promptEasyNames?.toEasyName(prompt.name);
  });

  function buildHeader(): ReactElement {
    return (
      <div className={styles.header}>
        <div className={styles.header_title}>Settings: Prompt {easyName}</div>
        <FlatButton
          className={styles.header_close}
          onClick={() => dispatch(setIsPromptConfigOpen(false))}
        >
          <FaTimes size={14} />
        </FlatButton>
      </div>
    );
  }

  if (prompt == null) return null;

  const answerType = ops.getPromptAnswerType(prompt.definition);
  return (
    <PromptConfigEditorStateContext.Provider
      value={{ name: prompt.name, definition: prompt.definition }}
    >
      <PromptConfigEditorOpsContext.Provider
        value={{ updateCustom, updateFields }}
      >
        <div className={styles.root}>
          {buildHeader()}
          <ConfigGroup title="Message Type">
            <ConfigItem>
              <ContentType />
            </ConfigItem>
          </ConfigGroup>

          <ConfigGroup title="Input Settings">
            {answerType === "choice" && (
              <ConfigItem title="Choice variant">
                <ChoiceInputDisplayType />
              </ConfigItem>
            )}
            {answerType === "custom" &&
              prompt.definition.inputDisplayType !== "AwsImageUpload" && (
                <ConfigItem title="Input type">
                  <CustomInputDisplayType />
                </ConfigItem>
              )}
            {answerType === "choice" && (
              <ConfigItem>
                <ShuffleChoices />
              </ConfigItem>
            )}
            {answerType === "choice" && (
              <ConfigItem>
                <AllowMultiSelect />
              </ConfigItem>
            )}
            {answerType === "custom" &&
              Array.isArray(prompt.definition.answers) &&
              prompt.definition.answers.length > 1 && (
                <ConfigItem title="Answer matching">
                  <NormalizeAnswers />
                </ConfigItem>
              )}
            {((answerType === "choice" &&
              (prompt.definition.answers as AnswerDefinition[]).length > 1) ||
              answerType === "custom") && (
              <ConfigItem>
                <ForbidAnswerChange />
              </ConfigItem>
            )}
          </ConfigGroup>

          <ConfigGroup title="Score Strategy">
            <ConfigItem>
              <ScoreStrategyView />
            </ConfigItem>
          </ConfigGroup>

          <ConfigGroup title="Other Settings">
            {/* TODO Implement track analytics */}
            {/*<Item><TrackAnalytics /></Item> */}
            {answerType === "auto" && prompt.definition.messages == null && (
              <ConfigItem title="Pause after this prompt">
                <MinNextPromptDelay
                  placeholder={settings?.chatDefaultDelayMs}
                />
              </ConfigItem>
            )}
          </ConfigGroup>

          <ConfigGroup title="Admin Settings">
            {answerExternallyEnabled && (
              <ConfigItem>
                <AnswerExternally />
              </ConfigItem>
            )}
            {(answerType === "choice" || answerType === "custom") &&
              !prompt.definition.answerExternally && (
                <ConfigItem>
                  <RequireFeedback />
                </ConfigItem>
              )}
            <ConfigItem title="Note to the teacher">
              <Notes />
            </ConfigItem>
          </ConfigGroup>
        </div>
      </PromptConfigEditorOpsContext.Provider>
    </PromptConfigEditorStateContext.Provider>
  );
}
