import React, { ReactElement, useMemo } from "react";
import Select from "react-select";

import { LANGUAGES } from "../../utils/constants";
import { SelectStyles } from "../../utils/reactSelect";

type Option = { value: string; label: string };

const options: Option[] = Object.keys(LANGUAGES).map((code) => ({
  value: code,
  label: LANGUAGES[code],
}));

export default function LanguagePicker({
  value,
  onChange,
  allowedLanguages,
  placeholder,
  isMulti,
  isClearable,
  isInModal,
}: {
  value: string; // Comma separated language codes
  onChange: (value: string) => void;
  allowedLanguages?: string[]; // Array of language code
  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  isInModal?: boolean; // Pass `true` if you are rendering in a modal
}): ReactElement {
  const effectiveOptions = useMemo(() => {
    return allowedLanguages
      ? allowedLanguages
          .map((code) => ({ value: code, label: LANGUAGES[code] }))
          .filter((option) => option.label != null)
      : options;
  }, [allowedLanguages]);

  const selectedOptions = useMemo(() => {
    const codes = value.split(",");
    return effectiveOptions.filter((o) => codes.includes(o.value));
  }, [value, effectiveOptions]);

  const selectStyles: SelectStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 30,
    }),
  };

  return (
    <Select
      options={effectiveOptions}
      isMulti={isMulti}
      isClearable={isClearable}
      value={selectedOptions}
      onChange={(newValue) => {
        if (isMulti) {
          const effectiveValue = newValue as Option[];
          return onChange(
            effectiveValue.map((option) => option.value).join(",")
          );
        } else {
          const effectiveValue = newValue as Option | null;
          return onChange(effectiveValue ? effectiveValue.value : "");
        }
      }}
      placeholder={placeholder}
      {...(isInModal
        ? {
            styles: selectStyles,
            menuPlacement: "auto",
            manuPosition: "fixed",
            menuPortalTarget: document.body,
          }
        : {})}
    />
  );
}
