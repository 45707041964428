import {
  isMessageBlank,
  JsonPresent,
  RemoteMessageShape,
} from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import FlatButton from "../../../../../components/buttons/FlatButton";
import Modal from "../../../../../components/Modal";
import { SelectStyles } from "../../../../../utils/reactSelect";
import { selectPromptEasyNames } from "../../../selectPromptEasyNames";
import { MessageEditorPropsWithI18n } from "../../MessageEditor";
import { usePromptEditorState } from "../../PromptEditor";
import PromptKeySelect from "../../PromptKeySelect";
import { buildPromptSuffix, SELF_CORE_PROMPT_KEY } from "../../utils";
import ConfigForm, { ConfigFormController } from "./ConfigForm";
import styles from "./RemoteMessage.module.css";

type ApiOption = {
  value: string;
  label: string;
  urlPattern: RegExp | null;
};

const apiOptions: ApiOption[] = [
  {
    value: "answerSamples",
    label: "Answer Samples",
    urlPattern: api.answerSamplesUrlPattern,
  },
  {
    value: "answerStats",
    label: "Answer Stats",
    urlPattern: api.answerStatsUrlPattern,
  },
  {
    value: "evaluation",
    label: "Evaluation",
    urlPattern: api.evaluationsUrlPattern,
  },
  { value: "custom", label: "Custom", urlPattern: null }, // Keep as last option
];

const getOption = (url: string) =>
  apiOptions.find((o) => o.urlPattern?.test(url)) ??
  apiOptions[apiOptions.length - 1];

const Z_INDEX = 2;
const selectStyles: SelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 30,
    zIndex: Z_INDEX,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 4,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    marginTop: 4,
    marginBottom: 4,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: Z_INDEX + 1,
  }),
};

export default function RemoteMessage({
  message: rawMessage,
  onChange,
  onKeyDown,
  translations,
  onTranslationChange,
  control,
}: MessageEditorPropsWithI18n): ReactElement {
  const prompt = usePromptEditorState();

  const promptEasyNames = useSelector(selectPromptEasyNames);
  const easyName = promptEasyNames?.toEasyName(prompt.name);

  const formController = useRef<ConfigFormController>();
  const [isConfigFormOpen, setIsConfigFormOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null);

  useEffect(() => {
    control?.({ focus: () => selectRef.current?.focus() });
  }, [control]);

  const message = (rawMessage as unknown) as RemoteMessageShape;
  const currentOption = getOption(message.url);

  return (
    <>
      <Modal
        title={`API Config: Prompt ${easyName}`}
        ariaHideApp={false}
        isOpen={isConfigFormOpen}
        style={{
          content: {
            width: "600px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        onRequestClose={() => formController.current?.submit()}
      >
        <ConfigForm
          message={message}
          translations={translations}
          onSubmit={(message, translation) => {
            onChange((message as unknown) as JsonPresent);
            if (translation != null) {
              onTranslationChange?.(
                translation.language,
                translation.data as JsonPresent | null
              );
            }
            // setTimeout is used to close the modal only after this function
            // executes. Otherwise, the react-hook-form inside the modal gets
            // unmounted before it has the time to wrap up properly.
            setTimeout(() => setIsConfigFormOpen(false), 0);
          }}
          controller={(c) => {
            formController.current = c;
          }}
          urlReadonly={currentOption.value !== "custom"}
          renderUrlBuilder={
            currentOption.value === "answerSamples"
              ? ({ onChange, value }) => {
                  const [, , promptName] = value.match(
                    api.answerSamplesUrlPattern
                  ) as string[];
                  let effectiveName = api.effectivePromptName(promptName);
                  if (effectiveName === SELF_CORE_PROMPT_KEY) {
                    effectiveName = "Self";
                  }
                  return (
                    <>
                      <label>Prompt to display answers for</label>
                      <PromptKeySelect
                        initialPromptKey={`.${effectiveName}`}
                        promptIndex={0}
                        onChange={(key) => {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          const effectiveKey = key!.substring(1);
                          onChange(
                            api.buildAnswerSamplesUrl(
                              "{{#originId}}",
                              effectiveKey === prompt.name
                                ? SELF_CORE_PROMPT_KEY
                                : `{{promptKey(${effectiveKey})|urlEnc}}`,
                              "{{language}}"
                            )
                          );
                        }}
                        maxMenuHeight={200} // Set to prevent modal scrolling
                      />
                    </>
                  );
                }
              : currentOption.value === "answerStats"
              ? ({ onChange, value }) => {
                  const [, , promptName] = value.match(
                    api.answerStatsUrlPattern
                  ) as string[];
                  let effectiveName = api.effectivePromptName(promptName);
                  if (effectiveName === SELF_CORE_PROMPT_KEY) {
                    effectiveName = "Self";
                  }
                  return (
                    <>
                      <label>Prompt to display stats for</label>
                      <PromptKeySelect
                        initialPromptKey={`.${effectiveName}`}
                        promptIndex={0}
                        onChange={(key) => {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          const effectiveKey = key!.substring(1);
                          onChange(
                            api.buildAnswerStatsUrl(
                              "{{#originId}}",
                              effectiveKey === prompt.name
                                ? SELF_CORE_PROMPT_KEY
                                : `{{promptKey(${effectiveKey})|urlEnc}}`,
                              "{{language}}"
                            )
                          );
                        }}
                        maxMenuHeight={200} // Set to prevent modal scrolling
                      />
                    </>
                  );
                }
              : undefined
          }
        />
      </Modal>
      <div className={styles.root}>
        <div className={styles.apiSelect}>
          <div className={styles.apiSelect_label}>Display</div>
          <div className={styles.apiSelect_select}>
            <Select
              ref={selectRef}
              styles={selectStyles}
              options={apiOptions}
              value={currentOption}
              formatOptionLabel={(option, labelMeta) => {
                const typedOption = option as ApiOption;
                if (labelMeta.context === "value") {
                  const url = message.url;
                  if (typedOption.value === "answerSamples") {
                    const match = url.match(api.answerSamplesUrlPattern);
                    const promptName = api.effectivePromptName(
                      match?.groups?.promptName
                    );
                    const promptSuffix = buildPromptSuffix(
                      promptName,
                      promptEasyNames
                    );
                    return `${typedOption.label}${promptSuffix}`;
                  } else if (typedOption.value === "answerStats") {
                    const match = url.match(api.answerStatsUrlPattern);
                    const promptName = api.effectivePromptName(
                      match?.groups?.promptName
                    );
                    const promptSuffix = buildPromptSuffix(
                      promptName,
                      promptEasyNames
                    );
                    return `${typedOption.label}${promptSuffix}`;
                  } else {
                    return typedOption.label;
                  }
                } else {
                  return typedOption.label;
                }
              }}
              onChange={(selectedOption) => {
                const typedSelectedOption = selectedOption as ApiOption;
                if (
                  typedSelectedOption.value !== currentOption?.value &&
                  (isMessageBlank(
                    (message as unknown) as JsonPresent,
                    "remote"
                  ) ||
                    window.confirm(
                      "This will clear any existing API configuration." +
                        " Do you want to go ahead?"
                    ))
                ) {
                  switch (typedSelectedOption.value) {
                    case "answerSamples":
                      onChange({
                        url: api.buildAnswerSamplesUrl(
                          "{{#originId}}",
                          prompt.definition.messages != null
                            ? "{{corePromptKey|urlEnc}}"
                            : "{{promptKey(start)|urlEnc}}",
                          "{{language}}"
                        ),
                        statusMessages: {
                          pending: "Loading answer samples",
                          rejected: "Error fetching answer samples",
                          fulfilledNull: "Error fetching answer samples",
                        },
                      } as JsonPresent);
                      break;
                    case "answerStats":
                      onChange({
                        url: api.buildAnswerStatsUrl(
                          "{{#originId}}",
                          prompt.definition.messages != null
                            ? "{{corePromptKey|urlEnc}}"
                            : "{{promptKey(start)|urlEnc}}",
                          "{{language}}"
                        ),
                        statusMessages: {
                          pending: "Loading answer stats",
                          rejected: "Error fetching answer stats",
                          fulfilledNull: "Error fetching answer stats",
                        },
                      } as JsonPresent);
                      break;
                    case "evaluation":
                      onChange({
                        url: api.buildEvaluationsUrl(
                          "{{#originId}}",
                          "{{userId}}",
                          "{{promptKey|urlEnc}}"
                        ),
                        statusMessages: {
                          pending: "Loading evaluation details",
                          rejected: "Error fetching evaluation",
                          fulfilledNull:
                            "Your evaluation will be available here when ready",
                        },
                      } as JsonPresent);
                      break;
                    case "custom":
                    default:
                      onChange({
                        url: "",
                      });
                  }
                }
              }}
              onKeyDown={(event) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (onKeyDown?.(event as any)) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            />
          </div>
        </div>
        <div className={styles.configureButton}>
          <FlatButton onClick={() => setIsConfigFormOpen(true)}>
            Configure
          </FlatButton>
        </div>
      </div>
    </>
  );
}
