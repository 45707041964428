import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import Tabs from "../components/Tabs";

export enum FlowTopBarTab {
  Build = "Build",
  Stats = "Stats",
}

export function FlowTopBarTabs(props: {
  flowId: number;
  activeTab: FlowTopBarTab;
}): ReactElement {
  const history = useHistory();

  return (
    <Tabs
      isInTopBar
      tabs={Object.keys(FlowTopBarTab)}
      activeTab={props.activeTab}
      setActiveTab={(tab) => {
        switch (tab) {
          case FlowTopBarTab.Build:
            history.replace(`/flows/${props.flowId}/edit`);
            break;
          case FlowTopBarTab.Stats:
            history.replace(`/flows/${props.flowId}/stats`);
        }
      }}
    />
  );
}
