import { OrdChatFlowDefinition, PromptDefinition } from "@xflr6/chatbot";

export function updatePromptFields(
  index: number,
  fields: Partial<PromptDefinition>,
  flowDef: OrdChatFlowDefinition
): void {
  flowDef.promptsArray[index].definition = {
    ...flowDef.promptsArray[index].definition,
    ...fields,
  };
}
