import {
  AnyChatFlowDefinition,
  getChatFlowMaxScore,
} from "@xflr6/chatbot-engine";

export interface MaxScoreResult {
  score: number;
  pathLimitReached: boolean;
}

export function computeChatFlowMaxScore(
  flowDefinition: AnyChatFlowDefinition,
  fromPromptName: string,
  pathLimit?: number
): MaxScoreResult {
  let pathLimitReached = false;
  const score = getChatFlowMaxScore(
    flowDefinition,
    fromPromptName,
    pathLimit,
    () => {
      pathLimitReached = true;
    }
  );
  return {
    score,
    pathLimitReached,
  };
}
