import classNames from "classnames";
import React, { ImgHTMLAttributes, ReactElement } from "react";

import styles from "./Thumbnail.module.css";

export interface ThumbnailProps extends ImgHTMLAttributes<HTMLImageElement> {
  isHighlighted?: boolean | null;
}

export default function Thumbnail({
  isHighlighted,
  ...restProps
}: ThumbnailProps): ReactElement {
  return (
    <div
      className={classNames(styles.root, {
        [`${styles.root__isHighlighted}`]: isHighlighted,
      })}
    >
      <img className={styles.img} alt="Thumbnail" {...restProps} />
    </div>
  );
}
