import React, { ReactElement, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { useAppDispatch } from "../../app/store";
import FlatButton from "../../components/buttons/FlatButton";
import newFlowTemplate from "../../utils/newFlowTemplate";
import { addFlow, selectEditor } from "./flowSequenceEditorSlice";

export default function AddFlowButton({
  showConfirmDialog = false,
}: {
  showConfirmDialog?: boolean;
}): ReactElement {
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const editor = useSelector(selectEditor);

  const [isCreatingFlow, setIsCreatingFlow] = useState<boolean>(false);

  async function createFlow() {
    try {
      setIsCreatingFlow(true);
      await dispatch(
        addFlow({
          name: "Untitled",
          definition: newFlowTemplate,
        })
      );
      if (editor.updating === "rejected") {
        addToast("Failed to add a chat flow.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } finally {
      setIsCreatingFlow(false);
    }
  }

  return (
    <FlatButton
      disabled={
        editor.loading === "pending" ||
        editor.updating === "pending" ||
        editor.publishing === "pending"
      }
      isLoading={isCreatingFlow}
      onClick={async () => {
        if (
          !showConfirmDialog ||
          window.confirm("Add a new flow to the sequence?")
        ) {
          await createFlow();
        }
      }}
    >
      <FaPlus size={14} />
      &nbsp;Add Flow
    </FlatButton>
  );
}
