// Use this to create individual integration data slices.
//
// Usage:
//
// const type = "thinkific"; // Any api.IntegrationType
//
// // Some default thunks.
// // You can manually create more as per your needs.
// const thunks = createDefaultThunks(type);
//
// // The slice (your own stuff, plus some defaults).
// const slice = createSlice({
//   type,
//   initialState: {}, // DataState<"thinkific"> or derived type
//   reducers: {}, // Any additional reducers, as per your needs
//   thunks,
//   buildExtraReducers: (builder) => { // Additional, as per needs
//     // builder.addCase(......);
//   }
// });
//
// // Default selectors.
// // You can manually create more as per your needs.
// const selectors = createDefaultSelectors(type);
//
// export const { loadData, updateData } = thunks;
// export const { selectHasUnsavedChanges, selectData } = selectors;
// // Export your own actions if any, along with the default ones
// export const { clearData, markDataAsEdited } = slice.actions;
// export default slice.reducer;

import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSlice as reduxCreateSlice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";
import { DateTime } from "luxon";

import { AsyncStatus, RootState } from "../../app/store";
import { ErrorData, getSerializableError } from "../../utils/error";

interface DefaultThunks<T extends api.IntegrationType> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  loadData: AsyncThunk<api.IntegrationTypeData<T>, void, {}>;
  updateData: AsyncThunk<
    { data: api.IntegrationTypeData<T>; lastUpdatedAt: number },
    api.IntegrationTypeData<T>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >;
}

interface DefaultSelectors<T extends api.IntegrationType> {
  selectData: (state: RootState) => DataState<T>;
  selectHasUnsavedChanges: (state: RootState) => boolean;
}

export interface DataState<T extends api.IntegrationType> {
  loading: AsyncStatus;
  updating: AsyncStatus;
  error: ErrorData | null;
  lastUpdatedAt: number | null;
  lastEditedAt: number | null;
  integrationData: api.IntegrationTypeData<T> | null;
}

const sliceName = (type: api.IntegrationType) => `${type}Data`;

export function createDefaultThunks<T extends api.IntegrationType>(
  type: T
): DefaultThunks<T> {
  return {
    loadData: createAsyncThunk(
      `integrations/${type}/loadDataStatus`,
      async (payload: void, thunkApi) => {
        try {
          return await api.getIntegrationData(type);
        } catch (error) {
          return thunkApi.rejectWithValue(getSerializableError(error));
        }
      }
    ),
    updateData: createAsyncThunk(
      `integrations/${type}/updateDataStatus`,
      async (payload: api.IntegrationTypeData<T>, thunkApi) => {
        try {
          const lastUpdatedAt = DateTime.local().toMillis();
          await api.updateIntegrationData({ type, data: payload });
          return { data: payload, lastUpdatedAt };
        } catch (error) {
          return thunkApi.rejectWithValue(getSerializableError(error));
        }
      }
    ),
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createSlice<
  T extends api.IntegrationType,
  D extends DataState<T>,
  Reducers extends SliceCaseReducers<D>
>({
  type,
  thunks,
  initialState,
  reducers,
  extraReducers,
}: {
  type: T;
  thunks: DefaultThunks<T>;
  initialState: Partial<D>;
  reducers: ValidateSliceCaseReducers<D, Reducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<D>) => void;
}) {
  return reduxCreateSlice({
    name: sliceName(type),
    initialState: {
      loading: "idle",
      updating: "idle",
      error: null,
      lastUpdatedAt: null,
      lastEditedAt: null,
      integrationData: null,
      ...initialState,
    } as D,
    reducers: {
      clearData: (state: D) => {
        state.loading = "idle";
        state.integrationData = null;
        state.error = null;
      },
      markDataAsEdited: (state: D) => {
        state.lastEditedAt = DateTime.local().toMillis();
      },
      ...reducers,
    },
    extraReducers: (builder) => {
      const { loadData, updateData } = thunks;
      builder.addCase(loadData.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      });
      builder.addCase(loadData.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        (state as DataState<T>).integrationData = action.payload;
      });
      builder.addCase(loadData.rejected, (state, action) => {
        state.loading = "rejected";
        (state as DataState<T>).integrationData = null;
        state.error = action.payload as ErrorData;
      });
      // *** integrations/${type}/updateData ***
      builder.addCase(updateData.pending, (state) => {
        state.updating = "pending";
        state.error = null;
      });
      builder.addCase(updateData.fulfilled, (state, action) => {
        state.updating = "fulfilled";
        (state as DataState<T>).integrationData = action.payload.data;
        state.lastUpdatedAt = action.payload.lastUpdatedAt;
        state.error = null;
      });
      builder.addCase(updateData.rejected, (state, action) => {
        state.updating = "rejected";
        state.error = action.payload as ErrorData;
      });
      if (extraReducers != null) {
        extraReducers(builder);
      }
    },
  });
}

export function createDefaultSelectors<T extends api.IntegrationType>(
  type: T
): DefaultSelectors<T> {
  return {
    selectData: (state: RootState): DataState<T> => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (state as any)[sliceName(type)] as DataState<T>;
    },
    selectHasUnsavedChanges: (state: RootState): boolean => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataState = (state as any)[sliceName(type)] as DataState<T>;
      const { lastUpdatedAt, lastEditedAt } = dataState;
      return (
        lastEditedAt != null &&
        (lastUpdatedAt == null || lastEditedAt > lastUpdatedAt)
      );
    },
  };
}
