import classNames from "classnames";
import React, { ReactElement, useState } from "react";

import FilledButton from "../../../../components/buttons/FilledButton";
import FlowPreview from "./FlowPreview";
import styles from "./PromptTemplates.module.css";
import templates, { PromptTemplate } from "./templates";

export default function PromptTemplates({
  className,
  style,
  onSelect,
}: {
  className?: string;
  style?: React.CSSProperties;
  onSelect: (template: PromptTemplate) => void;
}): ReactElement {
  const [selectedTemplate, setSelectedTemplate] = useState<PromptTemplate>(
    templates[0]
  );

  return (
    <div className={classNames(styles.root, className)} style={style}>
      <div className={styles.items}>
        {templates.map((template) => (
          <div
            key={template.id}
            className={classNames(styles.item, {
              [styles.item__selected]: template === selectedTemplate,
            })}
            onClick={() => {
              setSelectedTemplate(template);
            }}
          >
            <div className={styles.item_body}>
              <div className={styles.item_name}>{template.name}</div>
              <div className={styles.item_description}>
                {template.description}
              </div>
            </div>
            <div className={styles.item_useButton}>
              <FilledButton
                onClick={() => {
                  onSelect(template);
                }}
              >
                Use
              </FilledButton>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.preview}>
        <FlowPreview promptTemplate={selectedTemplate} />
      </div>
    </div>
  );
}
