import React, { ReactElement, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useScript from "react-script-hook";

import { selectIntegrationCloudinary } from "../integrationsSlice";

export interface CloudinaryAsset {
  effective_url: string;
  resource_type: string;
}

export interface CloudinaryContextProps {
  getCloudinaryAsset?: () => Promise<CloudinaryAsset>;
}

const CloudinaryContext = React.createContext<
  CloudinaryContextProps | undefined
>(undefined);

export function useCloudinary(): CloudinaryContextProps {
  const context = React.useContext(CloudinaryContext);
  if (context === undefined) {
    throw new Error(
      "useCloudinary can only be used within a CloudinaryProvider component"
    );
  }
  return context;
}

export function CloudinaryProvider({
  children,
}: {
  children: JSX.Element;
}): ReactElement {
  const integration = useSelector(selectIntegrationCloudinary);

  const [context, setContext] = useState<CloudinaryContextProps>({});

  return (
    <CloudinaryContext.Provider value={context}>
      {integration && (
        <ScriptLoader
          cloudName={integration.config.cloudName as string}
          apiKey={integration.config.apiKey as string}
          onLoaded={setContext}
        />
      )}
      {children}
    </CloudinaryContext.Provider>
  );
}

function ScriptLoader({
  cloudName,
  apiKey,
  onLoaded,
}: {
  cloudName: string;
  apiKey: string;
  onLoaded: (context: CloudinaryContextProps) => void;
}): null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const widget = useRef<any>();

  const awaitingPromiseRef = React.useRef<{
    resolve: (asset: CloudinaryAsset) => void;
    reject: (reason?: string) => void;
  }>();

  function openMediaLibrary() {
    widget.current?.show();
    return new Promise<CloudinaryAsset>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }

  useScript({
    src: "https://media-library.cloudinary.com/global/all.js",
    async: true,
    defer: true,
    checkForExisting: true,
    onload: () => {
      onLoaded({ getCloudinaryAsset: openMediaLibrary });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      widget.current = (window as any).cloudinary.createMediaLibrary(
        {
          cloud_name: cloudName,
          api_key: apiKey,
          multiple: false,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          insertHandler: function (data: any) {
            const asset = data.assets?.[0];
            if (asset) {
              const derivedAsset = asset.derived?.[0];
              const url = derivedAsset?.secure_url ?? asset.secure_url;
              awaitingPromiseRef.current?.resolve({
                effective_url: url,
                resource_type: asset.resource_type,
              });
            }
          },
        }
      );
    },
  });

  return null;
}
