import { createBaseCssVariablesStream, ThemePreview } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import { clamp } from "lodash";
import { equals, pickBy } from "ramda";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm, UseFormMethods, useWatch } from "react-hook-form";
import { BiCircle, BiXCircle } from "react-icons/bi";
import { IoIosArrowRoundDown } from "react-icons/io";
import { useSelector } from "react-redux";

import FilledButton from "../components/buttons/FilledButton";
import FlatButton from "../components/buttons/FlatButton";
import Checkbox, {
  CheckboxLabel,
  CheckboxLabelSubtitle,
  CheckboxLabelTitle,
} from "../components/forms/Checkbox";
import ColorPicker from "../components/forms/ColorPicker";
import FieldError from "../components/forms/FieldError";
import formStyles from "../components/forms/formStyles.module.css";
import Input from "../components/forms/Input";
import MyTippy from "../components/MyTippy";
import { selectIsIntegratedThinkific } from "../features/integrations/integrationsSlice";
import { applySettingsToBaseCss } from "../utils/settings";
import AvatarInput from "./AvatarInput";
import styles from "./SettingsForm.module.css";

interface RevertButtonProps<T> {
  name: T;
  baseSettings: api.Settings;
  baseSettingsDesc: string;
  control: UseFormMethods["control"];
}

function RevertButton<T extends keyof api.Settings>({
  name,
  baseSettings,
  baseSettingsDesc,
  control,
}: RevertButtonProps<T>) {
  const v = useWatch({ control, name });
  return v !== undefined && !equals(v, baseSettings[name]) ? (
    <MyTippy content={`Revert to ${baseSettingsDesc} settings`}>
      <FlatButton
        className={styles.RevertButton__revert}
        onClick={(event) => {
          control.setValue(name, baseSettings[name], { shouldDirty: true });
          event.preventDefault();
        }}
      >
        <BiXCircle size={20} />
      </FlatButton>
    </MyTippy>
  ) : (
    <MyTippy content={`Inherited from ${baseSettingsDesc} settings`}>
      <FlatButton
        isStatic
        className={styles.RevertButton__inherited}
        onClick={(event) => {
          event.preventDefault();
        }}
      >
        <BiCircle size={20} />
      </FlatButton>
    </MyTippy>
  );
}

function WithRevertButton<T extends keyof api.Settings>({
  render,
  ...buttonProps
}: RevertButtonProps<T> & {
  render: (props: { name: T }) => ReactElement;
}) {
  return (
    <div className={styles.WithRevertButton}>
      <div className={styles.WithRevertButton_button}>
        <RevertButton {...buttonProps} />
      </div>
      <div className={styles.WithRevertButton_content}>
        {render({ name: buttonProps.name })}
      </div>
    </div>
  );
}

function MyThemePreview({
  control,
}: {
  control: UseFormMethods["control"];
}): ReactElement {
  const settings = useWatch<api.SettingsPatch>({ control });

  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  useEffect(() => {
    applySettingsToBaseCss($baseCssVariables.current, settings);
  }, [settings]);

  return (
    <ThemePreview
      $baseCssVariables={$baseCssVariables.current}
      avatarSrc={settings?.avatarUrl}
    />
  );
}

export class SettingsFormController {
  constructor(readonly isDirty: () => boolean) {}
}

/**
 * SettingsForm
 * @param settings
 * @param baseSettings
 * @param baseSettingsDesc - Used in 'revert' tooltip
 * @param hiddenSettings - These settings will not be shown
 * @param onSubmit - *Must* throw an error if submission failed
 * @param onError - Called iff `onSubmit` threw an error
 * @param onCancel
 * @param disabled
 * @param isLoading
 * @param controller
 * @constructor
 */
export default function SettingsForm({
  settings,
  baseSettings,
  baseSettingsDesc,
  onSubmit,
  onError,
  onCancel,
  disabled,
  isLoading,
  controller,
}: {
  settings: api.SettingsPatch;
  baseSettings: api.Settings;
  baseSettingsDesc: string;
  onSubmit: (settings: api.SettingsPatch) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void;
  onCancel?: () => void;
  disabled?: boolean | null;
  isLoading?: boolean | null;
  controller?: (c: SettingsFormController) => void;
}): ReactElement | null {
  const {
    control,
    errors,
    formState: { isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm<api.SettingsPatch>();

  useEffect(() => {
    // Without this, the `useWatch` in `RevertButton` does not work correctly
    // when this form is closed and reopened. This is a hack, but it seems to
    // work.
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (controller != null) {
      controller(new SettingsFormController(() => isDirty));
    }
  }, [controller, isDirty]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const _onSubmit = useMemo(
    () => async (data: api.SettingsPatch) => {
      try {
        setIsSubmitting(true);
        const culledData = pickBy(
          (value, key) =>
            !equals(value, baseSettings[key as keyof api.Settings]),
          data
        ) as api.SettingsPatch;
        await onSubmit(culledData);
        reset(getValues());
      } catch (error) {
        onError?.(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onSubmit, onError, reset, getValues, baseSettings]
  );

  const isThinkificIntegrated = useSelector(selectIsIntegratedThinkific);

  const commonFieldProps = {
    baseSettings,
    baseSettingsDesc,
    control,
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <div className={styles.themeSettings}>
        <div className={styles.themeSettings_settings}>
          <WithRevertButton
            {...commonFieldProps}
            name="chatPrimaryColor"
            render={({ name }) => (
              <div className={formStyles.field}>
                <label className={formStyles.label}>Chat primary color</label>
                <Controller
                  name={name}
                  control={control}
                  defaultValue={settings[name] ?? baseSettings[name]}
                  render={({ ref, value, onChange }) => {
                    return (
                      <ColorPicker
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        popoverStyle={{ zIndex: "50" }}
                      />
                    );
                  }}
                />
              </div>
            )}
          />
          <WithRevertButton
            {...commonFieldProps}
            name="chatAccentColor"
            render={({ name }) => (
              <div className={formStyles.field}>
                <label className={formStyles.label}>Chat secondary color</label>
                <Controller
                  name={name}
                  control={control}
                  defaultValue={settings[name] ?? baseSettings[name]}
                  render={({ ref, value, onChange }) => {
                    return (
                      <ColorPicker
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        popoverStyle={{ zIndex: "50" }}
                      />
                    );
                  }}
                />
              </div>
            )}
          />
          <WithRevertButton
            {...commonFieldProps}
            name="avatarUrl"
            render={({ name }) => (
              <div className={formStyles.field}>
                <label className={formStyles.label}>Chat avatar</label>
                <Controller
                  name={name}
                  control={control}
                  defaultValue={settings[name] ?? baseSettings[name]}
                  render={({ value, onChange }) => {
                    return <AvatarInput value={value} onChange={onChange} />;
                  }}
                />
              </div>
            )}
          />
          <WithRevertButton
            {...commonFieldProps}
            name="chatBorderRadius"
            render={({ name }) => (
              <div className={formStyles.field}>
                <label className={formStyles.label}>
                  Chat elements border radius
                </label>
                <Controller
                  name={name}
                  control={control}
                  defaultValue={settings[name] ?? baseSettings[name]}
                  render={({ value, onChange, ref }) => {
                    return (
                      <div className={formStyles.inputRow}>
                        <input
                          type="range"
                          min={0}
                          max={20}
                          className={classNames(
                            formStyles.inputRow_item,
                            styles.chatSliderInput
                          )}
                          value={clamp(parseInt(value, 10), 0, 20)}
                          onChange={(e) => {
                            return onChange(`${e.target.value}px`);
                          }}
                          ref={ref}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            )}
          />
          <WithRevertButton
            {...commonFieldProps}
            name="chatSpacing"
            render={({ name }) => (
              <div className={formStyles.field}>
                <label className={formStyles.label}>
                  Chat elements spacing
                </label>
                <Controller
                  name={name}
                  control={control}
                  defaultValue={settings[name] ?? baseSettings[name]}
                  render={({ value, onChange, ref }) => {
                    return (
                      <div className={formStyles.inputRow}>
                        <input
                          type="range"
                          min={4}
                          max={12}
                          className={classNames(
                            formStyles.inputRow_item,
                            styles.chatSliderInput
                          )}
                          value={clamp(parseInt(value, 10), 4, 12)}
                          onChange={(e) => {
                            return onChange(`${e.target.value}px`);
                          }}
                          ref={ref}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            )}
          />
          <div className={styles.moreSettingsBelow}>
            More settings below
            <IoIosArrowRoundDown />
          </div>
        </div>
        <div className={styles.themeSettings_preview}>
          <MyThemePreview control={control} />
        </div>
      </div>
      <WithRevertButton
        {...commonFieldProps}
        name="chatDefaultDelayMs"
        render={({ name }) => (
          <div className={formStyles.field}>
            <label className={formStyles.label}>
              Default pause between prompts
            </label>
            <div className={formStyles.inputRow}>
              <Input
                name={name}
                type="number"
                styleVariant="outlined"
                className={classNames(
                  formStyles.inputRow_item,
                  styles.chatDefaultDelayInput
                )}
                defaultValue={settings[name] ?? baseSettings[name] ?? undefined}
                ref={register({ required: "Required", valueAsNumber: true })}
              />
              <div className={formStyles.inputRow_item}>milliseconds</div>
            </div>
            {errors?.chatDefaultDelayMs && (
              <FieldError
                error={errors.chatDefaultDelayMs.message ?? "Invalid value"}
              />
            )}
          </div>
        )}
      />
      {isThinkificIntegrated && (
        <WithRevertButton
          {...commonFieldProps}
          name="completedScoreEmailEnabled"
          render={({ name }) => (
            <div className={formStyles.field}>
              <Checkbox
                label={
                  <CheckboxLabel>
                    <CheckboxLabelTitle>
                      Email score to user on completion
                    </CheckboxLabelTitle>
                    <CheckboxLabelSubtitle>
                      When a user finishes this chat <b>and</b> gets a score in
                      the process, they will get an email informing them of
                      their score. The email is only sent if the user has some
                      score.
                    </CheckboxLabelSubtitle>
                  </CheckboxLabel>
                }
                name={name}
                defaultChecked={settings[name] ?? baseSettings[name]}
                ref={register}
              />
            </div>
          )}
        />
      )}
      {isThinkificIntegrated && (
        <WithRevertButton
          {...commonFieldProps}
          name="feedbackEmailEnabled"
          render={({ name }) => (
            <div className={formStyles.field}>
              <Checkbox
                label={
                  <CheckboxLabel>
                    <CheckboxLabelTitle>
                      Email user when feedback is given
                    </CheckboxLabelTitle>
                    <CheckboxLabelSubtitle>
                      When you give a user feedback on any of their chat
                      responses, they will get an email notifying them that they
                      have received feedback.
                    </CheckboxLabelSubtitle>
                  </CheckboxLabel>
                }
                name={name}
                defaultChecked={settings[name] ?? baseSettings[name]}
                ref={register}
              />
            </div>
          )}
        />
      )}
      {isThinkificIntegrated && (
        <WithRevertButton
          {...commonFieldProps}
          name="forceCompleteEnabled"
          render={({ name }) => (
            <div className={formStyles.field}>
              <Checkbox
                label={
                  <CheckboxLabel>
                    <CheckboxLabelTitle>
                      Force user to finish before moving to the next lesson
                    </CheckboxLabelTitle>
                    <CheckboxLabelSubtitle>
                      Until a user finishes this chat, they will be prevented
                      from moving on to the next lesson.
                    </CheckboxLabelSubtitle>
                  </CheckboxLabel>
                }
                name={name}
                defaultChecked={settings[name] ?? baseSettings[name]}
                ref={register}
              />
            </div>
          )}
        />
      )}
      <div className={formStyles.actions}>
        <FilledButton
          disabled={!isDirty || isSubmitting || !!disabled}
          isLoading={isSubmitting || !!isLoading}
        >
          Save
        </FilledButton>
        {onCancel != null && (
          <FilledButton
            disabled={!!disabled}
            onClick={(event) => {
              onCancel();
              event.preventDefault();
            }}
          >
            Cancel
          </FilledButton>
        )}
      </div>
    </form>
  );
}
