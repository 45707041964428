import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrdChatFlowDefinition } from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import { findIndex } from "ramda";

import { AsyncStatus, RootState } from "../../app/store";
import { moveElement } from "../../utils/array";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface EditorState {
  loading: AsyncStatus;
  loadError: ErrorData | null;
  updating: AsyncStatus;
  lastUpdateAction: (keyof api.FlowSequenceUpdateFields)[] | null;
  updateError: ErrorData | null;
  publishing: AsyncStatus;
  publishError: ErrorData | null;
  flowSequence: api.FlowSequence | null;
  currentLanguage: string | null;
}

export const loadFlowSequence = createAsyncThunk(
  "flowSequence/loadFlowSequenceStatus",
  async (id: number, thunkApi) => {
    try {
      return await api.getFlowSequence(id);
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const updateFlowSequence = createAsyncThunk(
  "flowSequence/updateFlowSequenceStatus",
  async (fields: api.FlowSequenceUpdateFields, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flowSequence } = rootState.flowSequenceEditor;
      if (flowSequence != null) {
        const { id } = flowSequence;
        await api.updateFlowSequence(id, fields);
        return fields;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const addFlow = createAsyncThunk(
  "flowSequence/addFlowStatus",
  async (
    { name, definition }: { name: string; definition: OrdChatFlowDefinition },
    thunkApi
  ) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flowSequence } = rootState.flowSequenceEditor;
      if (flowSequence != null) {
        const { id } = flowSequence;
        const flowId: number = await api.addFlowToSequence(
          id,
          name,
          definition
        );
        return {
          id: flowId,
          name,
          version: "1",
          isDirty: false,
        } as api.FlowSequenceFlow;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const moveFlow = createAsyncThunk(
  "flowSequence/moveFlowStatus",
  async ({ from, to }: { from: number; to: number }, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flowSequence } = rootState.flowSequenceEditor;
      if (flowSequence != null) {
        const { id } = flowSequence;
        await api.moveFlowInSequence(id, from, to);
        return { from, to };
      }
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const attachFlow = createAsyncThunk(
  "flowSequence/attachFlowStatus",
  async ({ flowId, at }: { flowId: number; at?: number }, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flowSequence } = rootState.flowSequenceEditor;
      if (flowSequence != null) {
        const { id } = flowSequence;
        const version: string = await api.attachFlowToSequence(id, flowId, at);
        return { flowId, version, at };
      }
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const detachFlow = createAsyncThunk(
  "flowSequence/detachFlowStatus",
  async ({ flowId }: { flowId: number }, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flowSequence } = rootState.flowSequenceEditor;
      if (flowSequence != null) {
        const { id } = flowSequence;
        await api.detachFlowFromSequence(id, flowId);
        return flowId;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const publishFlowSequence = createAsyncThunk(
  "flowSequence/publishStatus",
  async (payload: void, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { flowSequence } = rootState.flowSequenceEditor;
      if (flowSequence != null) {
        await api.publishFlowSequence(flowSequence.id);
        thunkApi.dispatch(loadFlowSequence(flowSequence.id));
      }
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const slice = createSlice({
  name: "flowSequenceEditor",
  initialState: {
    loading: "idle",
    loadError: null,
    updating: "idle",
    lastUpdateAction: null,
    flowSequence: null,
    currentLanguage: null,
  } as EditorState,
  reducers: {
    setCurrentLanguage: (
      state: EditorState,
      action: PayloadAction<string | null>
    ) => {
      state.currentLanguage = action.payload;
    },
  },
  extraReducers: {
    // *** Update ***
    [`${updateFlowSequence.pending}`]: (state: EditorState, action) => {
      state.updateError = null;
      state.updating = "pending";
      state.lastUpdateAction = Object.keys(
        action.meta.arg
      ) as EditorState["lastUpdateAction"];
    },
    [`${updateFlowSequence.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<api.FlowSequenceUpdateFields>
    ) => {
      const fields = action.payload;
      if (state.flowSequence != null) {
        state.updating = "fulfilled";
        state.flowSequence = { ...state.flowSequence, ...fields };
      }
    },
    [`${updateFlowSequence.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.updateError = action.payload;
      state.updating = "rejected";
    },
    // *** Add Flow ***
    [`${addFlow.pending}`]: (state: EditorState) => {
      state.updateError = null;
      state.updating = "pending";
    },
    [`${addFlow.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<api.FlowSequenceFlow>
    ) => {
      state.updating = "fulfilled";
      if (state.flowSequence != null) {
        state.flowSequence.flows.push(action.payload);
        state.flowSequence.isDirty = true;
      }
    },
    [`${addFlow.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.updateError = action.payload;
      state.updating = "rejected";
    },
    // *** Move Flow ***
    [`${moveFlow.pending}`]: (state: EditorState, action) => {
      state.updateError = null;
      state.updating = "pending";
      if (state.flowSequence != null) {
        const { from, to } = action.meta.arg;
        moveElement(state.flowSequence.flows, from, to);
      }
    },
    [`${moveFlow.fulfilled}`]: (state: EditorState) => {
      state.updating = "fulfilled";
      if (state.flowSequence != null) {
        state.flowSequence.isDirty = true;
      }
    },
    [`${moveFlow.rejected}`]: (state: EditorState, action) => {
      state.updateError = action.payload;
      state.updating = "rejected";
      const { from, to } = action.meta.arg;
      if (state.flowSequence != null) {
        moveElement(state.flowSequence.flows, to, from);
      }
    },
    // *** Attach Flow ***
    [`${attachFlow.pending}`]: (state: EditorState) => {
      state.updateError = null;
      state.updating = "pending";
    },
    [`${attachFlow.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<{ flowId: number; version: string; at?: number }>
    ) => {
      state.updating = "fulfilled";
      if (state.flowSequence != null) {
        const [removed] = state.flowSequence.detachedFlows.splice(
          findIndex(
            (flow) => flow.id === action.payload.flowId,
            state.flowSequence.detachedFlows
          ),
          1
        );
        const insertAt = action.payload.at ?? state.flowSequence.flows.length;
        state.flowSequence.flows.splice(insertAt, 0, {
          ...removed,
          version: action.payload.version,
        });
        state.flowSequence.isDirty = true;
      }
    },
    [`${attachFlow.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.updateError = action.payload;
      state.updating = "rejected";
    },
    // *** Detach Flow ***
    [`${detachFlow.pending}`]: (state: EditorState) => {
      state.updateError = null;
      state.updating = "pending";
    },
    [`${detachFlow.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<number>
    ) => {
      state.updating = "fulfilled";
      if (state.flowSequence != null) {
        const [removed] = state.flowSequence.flows.splice(
          findIndex(
            (flow) => flow.id === action.payload,
            state.flowSequence.flows
          ),
          1
        );
        delete removed.version;
        state.flowSequence.detachedFlows.push(removed);
        state.flowSequence.isDirty = true;
      }
    },
    [`${detachFlow.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.updateError = action.payload;
      state.updating = "rejected";
    },
    // *** Publish ***
    [`${publishFlowSequence.pending}`]: (state: EditorState) => {
      state.publishing = "pending";
      state.publishError = null;
    },
    [`${publishFlowSequence.fulfilled}`]: (state: EditorState) => {
      state.publishing = "fulfilled";
    },
    [`${publishFlowSequence.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.publishing = "rejected";
      state.publishError = action.payload;
    },
    // *** Load ***
    [`${loadFlowSequence.pending}`]: (state: EditorState) => {
      state.loading = "pending";
      state.loadError = null;
      state.flowSequence = null;
    },
    [`${loadFlowSequence.fulfilled}`]: (
      state: EditorState,
      action: PayloadAction<api.FlowSequence>
    ) => {
      state.loading = "fulfilled";
      state.flowSequence = action.payload;
      state.currentLanguage = null;
    },
    [`${loadFlowSequence.rejected}`]: (
      state: EditorState,
      action: PayloadAction<ErrorData>
    ) => {
      state.loading = "rejected";
      state.loadError = action.payload;
    },
  },
});

export const { setCurrentLanguage } = slice.actions;

export const selectEditor = (state: RootState): EditorState =>
  state.flowSequenceEditor;

export const selectLoading = (
  state: RootState
): [AsyncStatus, ErrorData | null] => [
  state.flowSequenceEditor.loading,
  state.flowSequenceEditor.loadError,
];

export const selectUpdating = (
  state: RootState
): [AsyncStatus, EditorState["lastUpdateAction"], ErrorData | null] => [
  state.flowSequenceEditor.updating,
  state.flowSequenceEditor.lastUpdateAction,
  state.flowSequenceEditor.updateError,
];

export const selectPublishing = (
  state: RootState
): [AsyncStatus, ErrorData | null] => [
  state.flowSequenceEditor.publishing,
  state.flowSequenceEditor.publishError,
];

export const selectFlowSequenceId = (state: RootState): number | null =>
  state.flowSequenceEditor.flowSequence?.id ?? null;

export const selectFlowSequenceUid = (state: RootState): string | null =>
  state.flowSequenceEditor.flowSequence?.uid ?? null;

export const selectFlowSequenceName = (state: RootState): string | null =>
  state.flowSequenceEditor.flowSequence?.name ?? null;

export const selectFlowSequenceTName = (
  state: RootState
): Record<string, string> | null =>
  state.flowSequenceEditor.flowSequence?.tName ?? null;

export const selectFlowSequenceLanguage = (state: RootState): string | null =>
  state.flowSequenceEditor.flowSequence?.language ?? null;

export const selectFlowSequenceLanguages = (
  state: RootState
): string[] | null => state.flowSequenceEditor.flowSequence?.languages ?? null;

export const selectFlowSequenceFlows = (
  state: RootState
): [api.FlowSequenceFlow[] | null, api.FlowSequenceFlow[] | null] => {
  return [
    state.flowSequenceEditor.flowSequence?.flows ?? null,
    state.flowSequenceEditor.flowSequence?.detachedFlows ?? null,
  ];
};

export const selectFlowSequenceDirty = (state: RootState): boolean | null =>
  state.flowSequenceEditor.flowSequence?.isDirty ?? null;

export const selectFlowSequenceSettings = (
  state: RootState
): api.SettingsPatch | null =>
  state.flowSequenceEditor.flowSequence?.settings ?? null;

export const selectFlowSequenceUserSettings = (
  state: RootState
): api.SettingsPatch | null =>
  state.flowSequenceEditor.flowSequence?.userSettings ?? null;

export const selectFlowSequenceOwnership = (
  state: RootState
): api.Ownership | null =>
  state.flowSequenceEditor.flowSequence?.ownership ?? null;

export const selectFlowSequenceOwner = (
  state: RootState
): api.OwnerDetails | null =>
  state.flowSequenceEditor.flowSequence?.user ?? null;

export const selectCurrentLanguage = (state: RootState): string | null =>
  state.flowSequenceEditor.currentLanguage;

export default slice.reducer;
