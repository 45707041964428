import { AnswerDefinition } from "@xflr6/chatbot";
import { addIndex, map } from "ramda";
import React, {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";

import FlatButton from "../../../../components/buttons/FlatButton";
import { insertAnswer, moveAnswer } from "../../flowEditorSlice";
import * as ops from "../operations";
import { WithKey } from "../operations/types";
import { usePromptEditorState } from "../PromptEditor";
import AnswerEditor, { AnswerEditorCtrl } from "./AnswerEditor";
import styles from "./AnswersEditor.module.css";

export interface AnswersEditorContextProps {
  items: AnswerEditorCtrl[];
}

export const AnswersEditorContext = React.createContext<
  AnswersEditorContextProps | undefined
>(undefined);

export function useAnswersEditor(): AnswersEditorContextProps {
  const context = React.useContext(AnswersEditorContext);
  if (context === undefined) {
    throw new Error(
      "useAnswersEditor can only be used within an AnswersEditor"
    );
  }
  return context;
}

export interface AnswersEditorCtrl {
  items: AnswerEditorCtrl[];
}

export interface AnswersEditorProps {
  answerOptions?: {
    addDisabled?: boolean | null;
    messageDisabled?: boolean | null;
    scoreDisabled?: boolean | null;
    scoreLaterDisabled?: boolean | null;
    nextKeyHidden?: boolean | null;
    quickResponseDisabled?: boolean | null;
  } | null;
  control?: (control?: AnswersEditorCtrl) => void;
}

function AnswersEditor({
  answerOptions,
  control,
}: AnswersEditorProps): ReactElement | null {
  const dispatch = useDispatch();

  const prompt = usePromptEditorState();

  function handleDragEnd(result: DropResult) {
    const { destination, source } = result;
    if (destination == null) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    dispatch(
      moveAnswer({
        promptIndexOrName: prompt.index,
        fromIndex: source.index,
        toIndex: destination.index,
      })
    );
  }

  const itemControls = useRef<AnswerEditorCtrl[]>([]);

  const registerControl = useCallback(
    (c) => (itemControls.current[c.index] = c),
    []
  );

  const deregisterControl = useCallback((key) => {
    const index = itemControls.current.findIndex((c) => c.key === key);
    if (index !== -1) {
      itemControls.current.splice(index, 1);
    }
  }, []);

  useEffect(() => {
    control?.({ items: itemControls.current });

    return function cleanup() {
      control?.();
    };
  }, [control]);

  function addAnswerHandler(): void {
    dispatch(
      insertAnswer({
        promptIndexOrName: prompt.index,
        definition: null,
        index: null,
      })
    );
    setTimeout(() => itemControls.current[0].focus(), 0);
  }

  function buildAnswers(): ReactNode {
    const definitions = prompt.definition.answers;
    if (definitions == null) {
      return (
        <FlatButton className={styles.addAnswer} onClick={addAnswerHandler}>
          <FaPlus size={12} />
          <div className={styles.addAnswer_label}>Add answer</div>
        </FlatButton>
      );
    }
    if (typeof definitions === "string") {
      return <div className={styles.answerRef}>&{definitions}</div>;
    }
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`${prompt.name}.answers`}>
          {(provided) => {
            const defsAsArray = definitions as AnswerDefinition[];
            return (
              <div
                className={styles.prompts}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {addIndex<AnswerDefinition, ReactElement>(map)(
                  (definition, index) => {
                    return (
                      <AnswerEditor
                        key={(definition as WithKey<AnswerDefinition>).__key}
                        promptName={prompt.name}
                        promptIndex={prompt.index}
                        definition={definition}
                        index={index}
                        isLast={index === defsAsArray.length - 1}
                        addDisabled={answerOptions?.addDisabled}
                        messageDisabled={answerOptions?.messageDisabled}
                        scoreDisabled={answerOptions?.scoreDisabled}
                        scoreLaterDisabled={
                          prompt.definition.acceptsMultipleAnswers ||
                          answerOptions?.scoreLaterDisabled
                        }
                        nextKeyHidden={
                          prompt.nextKeysHidden || answerOptions?.nextKeyHidden
                        }
                        quickResponseDisabled={
                          prompt.definition.acceptsMultipleAnswers ||
                          answerOptions?.quickResponseDisabled
                        }
                        quickResponseAltMessagesMode={
                          definition.quickResponse?.repeatAnswers &&
                          !!prompt.definition.quickResponses?.length &&
                          ops.shouldEnablePromptQuickResponses(
                            prompt.definition
                          )
                        }
                        control={registerControl}
                        deregisterControl={deregisterControl}
                      />
                    );
                  },
                  defsAsArray
                )}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <AnswersEditorContext.Provider value={{ items: itemControls.current }}>
      <div className={styles.root}>{buildAnswers()}</div>
    </AnswersEditorContext.Provider>
  );
}

export default memo(AnswersEditor);
