import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";

import { AsyncStatus, RootState } from "../../app/store";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface AuthState {
  loading: AsyncStatus;
  lastAuthAction:
    | "signUpWithEmailAndPassword"
    | "loginWithEmailAndPassword"
    | "loginWithGoogle"
    | null;
  currentUser: api.User | null;
  error: ErrorData | null;
  updating: AsyncStatus;
  updateError: ErrorData | null;
  detailsLoading: AsyncStatus;
  settings: api.SettingsPatch | null;
  defaultSettings: api.Settings | null;
  detailsLoadError: ErrorData | null;
  planLoading: AsyncStatus;
  currentPlan: api.CurrentPlan | null;
  planLoadError: ErrorData | null;
}

export const signUpWithEmailAndPassword = createAsyncThunk(
  "auth/signUpWithEmailAndPasswordStatus",
  async (
    payload: { email: string; password: string; name?: string | null },
    thunkApi
  ) => {
    try {
      return await api.signUpWithEmailAndPassword(
        payload.email,
        payload.password,
        payload.name
      );
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const loginWithEmailAndPassword = createAsyncThunk(
  "auth/loginWithEmailAndPasswordStatus",
  async (payload: { email: string; password: string }, thunkApi) => {
    try {
      return await api.loginWithEmailAndPassword(
        payload.email,
        payload.password
      );
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "auth/loginWithGoogleStatus",
  async (payload: string, thunkApi) => {
    try {
      return await api.loginWithGoogle(payload);
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (payload: api.UserUpdateAttrs, thunkApi) => {
    try {
      return await api.updateProfile(payload);
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const loadDetails = createAsyncThunk(
  "auth/loadDetails",
  async (payload: { force?: boolean } | undefined, thunkApi) => {
    try {
      return await api.getUserDetails();
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  },
  {
    condition(payload, thunkApi): boolean | undefined {
      const { auth } = thunkApi.getState() as { auth: AuthState };
      if (auth.settings != null && !payload?.force) {
        return false;
      }
    },
  }
);

export const loadCurrentPlan = createAsyncThunk(
  "auth/loadCurrentPlan",
  async (payload: { force?: boolean } | undefined, thunkApi) => {
    try {
      return await api.getCurrentPlan();
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  },
  {
    condition(payload, thunkApi): boolean | undefined {
      const { auth } = thunkApi.getState() as { auth: AuthState };
      if (auth.currentPlan != null && !payload?.force) {
        return false;
      }
    },
  }
);

export const slice = createSlice({
  name: "auth",
  initialState: {
    loading: "idle",
    lastAuthAction: null,
    currentUser: api.getCurrentUserFromLocalStorage(),
    error: null,
    updating: "idle",
    updateError: null,
    detailsLoading: "idle",
    settings: null,
    defaultSettings: null,
    detailsLoadError: null,
    planLoading: "idle",
    currentPlan: null,
    planLoadError: null,
  } as AuthState,
  reducers: {
    logout: (state: AuthState) => {
      state.currentUser = null;
      state.settings = null;
      api.setCurrentUserInLocalStorage(null);
      state.loading = "idle";
      state.error = null;
    },
    clearError: (state: AuthState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // ** signUpWithEmailAndPassword
    builder.addCase(signUpWithEmailAndPassword.pending, (state) => {
      state.loading = "pending";
      state.lastAuthAction = "signUpWithEmailAndPassword";
      state.currentUser = null;
      state.error = null;
      api.setCurrentUserInLocalStorage(null);
    });
    builder.addCase(signUpWithEmailAndPassword.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.currentUser = action.payload;
      api.setCurrentUserInLocalStorage(action.payload);
    });
    builder.addCase(signUpWithEmailAndPassword.rejected, (state, action) => {
      state.loading = "rejected";
      state.currentUser = null;
      api.setCurrentUserInLocalStorage(null);
      state.error = action.payload;
    });

    // ** loginWithEmailAndPassword **
    builder.addCase(loginWithEmailAndPassword.pending, (state) => {
      state.loading = "pending";
      state.lastAuthAction = "loginWithEmailAndPassword";
      state.currentUser = null;
      state.error = null;
      api.setCurrentUserInLocalStorage(null);
    });
    builder.addCase(loginWithEmailAndPassword.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.currentUser = action.payload;
      api.setCurrentUserInLocalStorage(action.payload);
    });
    builder.addCase(loginWithEmailAndPassword.rejected, (state, action) => {
      state.loading = "rejected";
      state.currentUser = null;
      api.setCurrentUserInLocalStorage(null);
      state.error = action.payload;
    });

    // ** loginWithGoogle **
    builder.addCase(loginWithGoogle.pending, (state) => {
      state.loading = "pending";
      state.lastAuthAction = "loginWithGoogle";
      state.currentUser = null;
      state.error = null;
      api.setCurrentUserInLocalStorage(null);
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.currentUser = action.payload;
      api.setCurrentUserInLocalStorage(state.currentUser);
    });
    builder.addCase(loginWithGoogle.rejected, (state, action) => {
      state.loading = "rejected";
      state.currentUser = null;
      api.setCurrentUserInLocalStorage(null);
      state.error = action.payload;
    });

    // ** updateProfile **
    builder.addCase(updateProfile.pending, (state) => {
      state.updating = "pending";
      state.error = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updating = "fulfilled";
      if (state.currentUser != null) {
        const { name, settings } = action.meta.arg;
        if (name != null) {
          state.currentUser = {
            ...state.currentUser,
            name,
          };
        }
        if (settings != null) {
          state.settings = settings;
        }
        api.setCurrentUserInLocalStorage(state.currentUser);
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updating = "rejected";
      state.updateError = action.payload;
    });

    // ** loadSettings **
    builder.addCase(loadDetails.pending, (state) => {
      state.detailsLoading = "pending";
      state.detailsLoadError = null;
    });
    builder.addCase(loadDetails.fulfilled, (state, action) => {
      state.detailsLoading = "fulfilled";
      state.settings = action.payload.settings;
      state.defaultSettings = action.payload.defaultSettings;
    });
    builder.addCase(loadDetails.rejected, (state, action) => {
      state.detailsLoading = "rejected";
      state.detailsLoadError = action.payload;
    });

    // ** loadCurrentPlan **
    builder.addCase(loadCurrentPlan.pending, (state) => {
      state.planLoading = "pending";
      state.planLoadError = null;
    });
    builder.addCase(loadCurrentPlan.fulfilled, (state, action) => {
      state.planLoading = "fulfilled";
      state.currentPlan = action.payload;
    });
    builder.addCase(loadCurrentPlan.rejected, (state, action) => {
      state.planLoading = "rejected";
      state.planLoadError = action.payload;
    });
  },
});

export const { logout, clearError } = slice.actions;

export const selectAuth = (state: RootState): AuthState => state.auth;

export default slice.reducer;
