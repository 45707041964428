import {
  Chat,
  ChatFlow,
  ChatFlowDefinition,
  ChatOptions,
  ChatView,
  createBaseCssVariablesStream,
  FlowId,
  MCChatError,
  OrdChatFlowDefinition,
  PromptKey,
} from "@xflr6/chatbot";
import {
  setAnswerSamplesBuilder,
  setAnswerStatsBuilder,
  setMeetingBuilder,
} from "@xflr6/chatbot_customizations";
import * as api from "@xflr6/chatbot-api";
import { debounce, isNaN, merge } from "lodash";
import { clone } from "ramda";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaExclamationTriangle, FaSpinner } from "react-icons/fa";
import { MdMoreVert, MdRefresh } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { distinctUntilChanged, map } from "rxjs/operators";

import FilledButton from "../../../components/buttons/FilledButton";
import FlatButton from "../../../components/buttons/FlatButton";
import Checkbox from "../../../components/forms/Checkbox";
import LoadingLayout from "../../../components/LoadingLayout";
import Menu from "../../../components/menus/Menu";
import MyTippy from "../../../components/MyTippy";
import Popover from "../../../components/Popover";
import { selectEffectiveFlowSettings } from "../../../featuresCommon/selectors";
import {
  CHAT_ID_ARG,
  REMOTE_FLOW_PATTERN_G,
} from "../../../featuresCommon/utils";
import { applySettingsToBaseCss } from "../../../utils/settings";
import useDelayedToggle from "../../../utils/useDelayedToggle";
import {
  computeFlowMaxScore,
  selectCurrentLanguage,
  selectFlowLanguage,
  selectMaxScoreState,
} from "../flowEditorSlice";
import {
  selectEditorUi,
  setIsChatAutoAnswerEnabled,
  setIsChatDefaultDelayIgnored,
} from "../flowEditorUiSlice";
import styles from "./FlowPreview.module.css";

export interface FlowPreviewProps {
  id: number;
  definition: OrdChatFlowDefinition;
  promptIndex: number;
  onHasAnswered?: ((value: boolean) => void) | null;
  onChatStateError?: ((error: unknown) => void) | null;
}

export default function FlowPreview({
  id,
  definition,
  promptIndex,
  onHasAnswered,
  onChatStateError,
}: FlowPreviewProps): ReactElement {
  const dispatch = useDispatch();
  const editorUi = useSelector(selectEditorUi);

  const [hasAnswered, setHasAnswered] = useState(false);
  const [chatStateError, setChatStateError] = useState<unknown>(null);
  const [flowPreviewKey, setFlowPreviewKey] = useState(0);
  const {
    value: isMoreMenuOpen,
    setTrueImmediate: openMoreMenu,
    setFalseWithDelay: closeMoreMenuWithDelay,
    setFalseImmediate: closeMoreMenu,
  } = useDelayedToggle(false, {});

  const effectiveOnHasAnswered = useCallback(
    (value) => {
      setHasAnswered(value);
      onHasAnswered?.(value);
    },
    [onHasAnswered]
  );

  const effectiveOnChatStateError = useCallback(
    (error) => {
      setChatStateError(error);
      onChatStateError?.(error);
    },
    [onChatStateError]
  );

  useEffect(() => {
    effectiveOnChatStateError(null);
  }, [definition, promptIndex, effectiveOnChatStateError]);

  function restartPreview() {
    effectiveOnChatStateError(null);
    setFlowPreviewKey((n) => n + 1);
  }

  return (
    <div className={styles.root}>
      <FlowPreviewInner
        key={flowPreviewKey}
        id={id}
        definition={definition}
        promptIndex={promptIndex}
        onHasAnswered={effectiveOnHasAnswered}
        onChatStateError={effectiveOnChatStateError}
      />
      <div className={styles.floatingMenu}>
        {hasAnswered && (
          <MyTippy content="Restart preview" placement="left">
            <FlatButton
              className={styles.floatingMenu_item}
              onClick={restartPreview}
            >
              <MdRefresh size={20} />
            </FlatButton>
          </MyTippy>
        )}
        <Popover
          positions={["bottom"]}
          align="end"
          reposition={false}
          isOpen={isMoreMenuOpen}
          onClickOutside={closeMoreMenu}
          content={
            <Menu
              closeOnEsc
              onRequestClose={closeMoreMenu}
              onPointerEnter={openMoreMenu}
              onPointerLeave={closeMoreMenu}
            >
              <Checkbox
                label="Auto progress"
                checked={editorUi.isChatAutoAnswerEnabled}
                onChange={(event) => {
                  dispatch(
                    setIsChatAutoAnswerEnabled(event.currentTarget.checked)
                  );
                }}
              />
              <Checkbox
                label="Quicken default pauses"
                checked={editorUi.isChatDefaultDelayIgnored}
                onChange={(event) => {
                  dispatch(
                    setIsChatDefaultDelayIgnored(event.currentTarget.checked)
                  );
                }}
              />
            </Menu>
          }
        >
          <FlatButton
            className={styles.floatingMenu_item}
            onPointerEnter={openMoreMenu}
            onPointerLeave={closeMoreMenuWithDelay}
          >
            <MdMoreVert size={20} />
          </FlatButton>
        </Popover>
      </div>
      {chatStateError != null && (
        <div className={styles.chatStateError}>
          <div className={styles.chatStateError_icon}>
            <FaExclamationTriangle size={18} />
          </div>
          <div className={styles.chatStateError_message}>
            {buildErrorMessage(chatStateError)}
          </div>
          <FilledButton
            className={styles.chatStateError_restartButton}
            onClick={restartPreview}
          >
            Restart
          </FilledButton>
        </div>
      )}
    </div>
  );
}

function buildErrorMessage(chatStateError: unknown): string {
  let message =
    chatStateError instanceof Error
      ? chatStateError.message
      : `${chatStateError}`;
  message = message.replaceAll(
    REMOTE_FLOW_PATTERN_G,
    (match, chatId) => chatId
  );
  if (chatStateError instanceof MCChatError) {
    const httpStatus = chatStateError.underlying?.response?.status;
    if (httpStatus === 403) {
      message += ". You do not have collaborator access to it.";
    }
  }
  return message;
}

function FlowPreviewInner({
  id,
  definition,
  promptIndex,
  onHasAnswered,
  onChatStateError,
}: FlowPreviewProps): ReactElement {
  const dispatch = useDispatch();

  const maxScoreState = useSelector(selectMaxScoreState);

  const debouncedComputeMaxScore = useMemo(
    () => debounce(() => dispatch(computeFlowMaxScore(1000)), 2000),
    [dispatch]
  );

  useEffect(() => {
    if (maxScoreState.shouldCompute && !maxScoreState.calcTooCostly) {
      debouncedComputeMaxScore();
    }
  }, [
    maxScoreState.shouldCompute,
    maxScoreState.calcTooCostly,
    debouncedComputeMaxScore,
  ]);

  const editorUi = useSelector(selectEditorUi);
  const settings = useSelector(selectEffectiveFlowSettings);
  const flowLanguage = useSelector(selectFlowLanguage);
  const currLanguage = useSelector(selectCurrentLanguage);
  const language = useMemo(() => currLanguage ?? flowLanguage, [
    currLanguage,
    flowLanguage,
  ]);

  const [chatViewProps, setChatViewProps] = useState<{
    key: string;
    chat: PreviewChat | null;
  }>({ key: new Date().toISOString(), chat: null });

  const chatDefaultDelayMs = settings?.chatDefaultDelayMs;
  useEffect(() => {
    const chat = new PreviewChat("chat", clone(definition), id, {
      language,
      doTranslation: !!currLanguage,
      defaultMinNextPromptDelayMs: editorUi.isChatDefaultDelayIgnored
        ? 200
        : chatDefaultDelayMs ?? 200,
    });
    chat.isAutoAnswerEnabled = editorUi.isChatAutoAnswerEnabled;
    const subs = chat.state
      .pipe(
        map((state) => !!state?.hasAnswered),
        distinctUntilChanged()
      )
      .subscribe((v) => onHasAnswered?.(v));
    const promptName = definition.promptsArray[promptIndex].name;
    chat.setPrompt(
      PromptKey.fromString(`flow?${CHAT_ID_ARG}=${id}.${promptName}`)
    );
    setChatViewProps({ key: new Date().toISOString(), chat: chat });
    // Called manually, because `chat` will not call it immediately, so any past
    // error will not get cleared.
    onChatStateError?.(null);

    return function cleanup() {
      subs.unsubscribe();
      chat.dispose();
    };
  }, [
    id,
    definition,
    promptIndex,
    onHasAnswered,
    onChatStateError,
    chatDefaultDelayMs,
    editorUi.isChatAutoAnswerEnabled,
    editorUi.isChatDefaultDelayIgnored,
    language,
    currLanguage,
  ]);

  const $baseCssVariables = useRef(createBaseCssVariablesStream());
  useEffect(() => {
    applySettingsToBaseCss($baseCssVariables.current, settings);
  }, [settings]);

  function renderMaxScore(): ReactElement | null {
    const pending =
      (maxScoreState.shouldCompute && !maxScoreState.calcTooCostly) ||
      maxScoreState.computing === "pending";
    if (
      !pending &&
      maxScoreState.computing === "fulfilled" &&
      (maxScoreState.maxScore == null || maxScoreState.maxScore === 0)
    ) {
      return null;
    } else {
      return (
        <div className={styles.maxScore}>
          {maxScoreState.calcTooCostly
            ? "???"
            : maxScoreState.maxScore != null && maxScoreState.maxScore > 0
            ? maxScoreState.maxScore.toFixed(0)
            : null}
          <div className={styles.maxScore_status}>
            {pending && <FaSpinner className={styles.maxScore_spinner} />}
            {maxScoreState.computing === "rejected" && (
              <MyTippy content="Error computing max score">
                <span>
                  {/* Enclosed in `span` for Tippy to work */}
                  <FaExclamationTriangle className={styles.maxScore_error} />
                </span>
              </MyTippy>
            )}
            {maxScoreState.computing !== "rejected" &&
              maxScoreState.calcTooCostly && (
                <MyTippy content="Manual re-computation needed. Click to execute">
                  <span onClick={() => dispatch(computeFlowMaxScore())}>
                    {/* Enclosed in `span` for Tippy to work */}
                    <FaExclamationTriangle className={styles.maxScore_costly} />
                  </span>
                </MyTippy>
              )}
          </div>
        </div>
      );
    }
  }

  return chatViewProps.chat == null ? (
    <LoadingLayout className={styles.loading} />
  ) : (
    <div className={styles.innerRoot}>
      <ChatView
        key={chatViewProps.key}
        chat={chatViewProps.chat}
        showScore
        onChatStateError={onChatStateError}
        skipInitialAnimations
        disableAutoFocus
        disableKeyboardAnswer
        renderMaxScore={renderMaxScore}
        $baseCssVariables={$baseCssVariables.current}
        avatarSrc={settings?.avatarUrl}
      />
    </div>
  );
}

class PreviewChat extends Chat {
  private readonly _flowDef: OrdChatFlowDefinition;

  // `options` are deep-merged
  constructor(
    name: string,
    flowDef: OrdChatFlowDefinition,
    flowId: number,
    options?: ChatOptions
  ) {
    super(
      name,
      merge(
        {
          runMode: "preview",
          answerClickDelayMs: 0,
          defaultMinNextPromptDelayMs: 200,
          confirmDestructiveAlteration: async () => true,
          context: {
            args: {
              originId: [flowId.toString()],
            },
          },
        },
        options
      )
    );
    this._flowDef = flowDef;
  }

  get flowDef(): OrdChatFlowDefinition {
    return this._flowDef;
  }

  handleGetFlow(flowId: FlowId): ChatFlow {
    return new PreviewFlow(flowId, this);
  }
}

class PreviewFlow extends ChatFlow {
  private readonly _flowDef: OrdChatFlowDefinition;

  constructor(id: FlowId, chat: PreviewChat) {
    super(id, chat);
    this._flowDef = chat.flowDef;
  }

  async getDefinition(): Promise<OrdChatFlowDefinition> {
    if (this.id.name === "remote") {
      const chatId = parseInt((this.id.args[CHAT_ID_ARG] ?? [])[0], 10);
      if (isNaN(chatId)) {
        throw new Error("Valid flow id not found in arguments");
      } else {
        return (await api.getFlow(chatId)).definition;
      }
    } else {
      return this._flowDef;
    }
  }

  async onDefinitionProcessed(definition: ChatFlowDefinition) {
    setAnswerSamplesBuilder(this, definition, api.answerSamplesUrlPattern);
    setAnswerStatsBuilder(this, definition, api.answerStatsUrlPattern);
    setMeetingBuilder(this, definition);
  }
}
