import { debounce } from "lodash";
import React, {
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useToasts } from "react-toast-notifications";
import { createEditor, Descendant, Editor, Node, Transforms } from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from "slate-react";

import { EmojiPicker, useEmojiPicker } from "../../components/EmojiPicker";
import { withCorrectVoidBehavior } from "./correctVoidBehaviourPlugin";
import HoveringToolbar from "./HoveringToolbar";
import {
  ImageEditor,
  insertImage,
  useImageInput,
  withImages,
} from "./imagesPlugin";
import styles from "./MessageEditor.module.css";
import { useMessageProvider } from "./MessageProvider";
import MessageStatic from "./MessageStatic";
import { withPreventEmptyEditor } from "./preventEmptyEditorPlugin";
import {
  deserializeMarkdown,
  IS_ANDROID_DEVICE,
  serializeToMarkdown,
} from "./utils";
import {
  dynamicallyInsertVariable,
  insertVariable,
  VariableEditor,
  withVariables,
} from "./variablesPlugin";

export interface MessageEditorCtrl {
  setMessage: (value: string) => void;
  focus: () => void;
}

export interface MessageEditorProps {
  value: string;
  placeholder?: string | null;
  uploadPathPrefix?: string;
  variablesEnabled?: boolean | null;
  onChangeRaw?: ((rawValue: Node[]) => void) | null;
  onChangeDebounced?: ((value: string) => void) | null;
  onKeyDown?: ((event: KeyboardEvent) => boolean) | null;
  autoFocus?: boolean | null;
  control?: (control: MessageEditorCtrl) => void;
}

export const MessageEditorContext = React.createContext({
  isInAtomicEdit: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsInAtomicEdit: (value: boolean) => {
    return;
  },
});

/**
 * Renders an editable message.
 *
 * If `onKeyDown` is returns true, subsequent handling is skipped.
 * @constructor
 */
function MessageEditorInner({
  value: value_,
  placeholder,
  uploadPathPrefix,
  variablesEnabled,
  onChangeRaw,
  onChangeDebounced,
  onKeyDown,
  autoFocus,
  control,
}: MessageEditorProps): ReactElement {
  const identityPlugin = (editor: Editor) => editor;
  const editor = useMemo(
    () =>
      withHistory(
        withReact(
          withPreventEmptyEditor(
            withCorrectVoidBehavior(
              (variablesEnabled ? withVariables : identityPlugin)(
                withImages(createEditor())
              )
            )
          )
        )
      ),
    [variablesEnabled]
  );

  useEffect(() => {
    control?.({
      setMessage: (value: string) => {
        editor.children = deserializeMarkdown(
          value,
          !!variablesEnabled
        ) as Descendant[];
        editor.onChange();
      },
      focus: () => ReactEditor.focus(editor),
    });
  }, [editor, control, variablesEnabled]);

  // Helper function used to debounce callback of `onChangeDebounced`
  const debounced = useMemo(
    () => debounce((func: () => void) => func(), 1000),
    []
  );

  const { addToast } = useToasts();

  const getImage = useImageInput();

  const { getEmoji, pickerProps } = useEmojiPicker();

  const {
    transformAfterSerialize,
    transformBeforeDeserialize,
  } = useMessageProvider();

  const [value, setValue] = useState<Node[]>(
    deserializeMarkdown(transformBeforeDeserialize(value_), !!variablesEnabled)
  );

  const [isInAtomicEdit, setIsInAtomicEdit] = useState(false);

  useEffect(() => {
    setValue(
      deserializeMarkdown(
        transformBeforeDeserialize(value_),
        !!variablesEnabled
      )
    );
  }, [transformBeforeDeserialize, value_, variablesEnabled]);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => ReactEditor.focus(editor), 0);
    }
  }, [autoFocus, editor]);

  async function insertImg() {
    try {
      const selection = editor.selection;
      const imageData = await getImage({
        uploadPathPrefix,
        acceptExternalSources: true,
      });
      if (selection != null) {
        Transforms.select(editor, selection);
        ReactEditor.focus(editor);
      }
      setTimeout(() => insertImage(editor, imageData), 0);
    } catch (reason) {
      const toastMessage =
        reason === "canceled"
          ? "Image insertion canceled"
          : "Image insertion failed! Please try again.";
      const toastAppearance = reason === "canceled" ? "warning" : "error";
      addToast(toastMessage, {
        appearance: toastAppearance,
        autoDismiss: true,
      });
    }
  }

  async function insertEmoji() {
    const selection = editor.selection;
    const emojiData = await getEmoji();
    if (selection != null) {
      Transforms.select(editor, selection);
      ReactEditor.focus(editor);
    }
    if (emojiData != null) {
      setTimeout(() => {
        if (selection != null) {
          Transforms.select(editor, selection);
        }
        Transforms.insertText(editor, emojiData.emoji);
      }, 0);
    }
  }

  async function insertVar() {
    insertVariable(editor, "", true);
    ReactEditor.focus(editor);
  }

  const renderElement = useCallback((props: RenderElementProps) => {
    switch (props.element.type) {
      case "image":
        return <ImageEditor {...props} />;
      case "variable":
        return <VariableEditor {...props} />;
      default:
        return <div {...props.attributes}>{props.children}</div>;
    }
  }, []);

  const [showToolbar, setShowToolbar] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <MessageEditorContext.Provider
      value={{ isInAtomicEdit, setIsInAtomicEdit }}
    >
      <div className={styles.root} ref={ref}>
        <Slate
          editor={editor}
          value={value as Descendant[]}
          onChange={(value) => {
            setValue(value);
            if (!isInAtomicEdit && onChangeRaw != null) {
              onChangeRaw(value);
            }
            debounced(() => {
              if (!isInAtomicEdit && onChangeDebounced != null) {
                onChangeDebounced(
                  transformAfterSerialize(serializeToMarkdown(value))
                );
              }
            });
          }}
        >
          {showToolbar && (
            <HoveringToolbar
              onInsertImageClick={() => insertImg()}
              onInsertEmojiClick={() => insertEmoji()}
              onInsertRecallClick={
                variablesEnabled ? () => insertVar() : undefined
              }
              editorRef={ref}
            />
          )}
          <Editable
            placeholder={placeholder ?? "Enter a message..."}
            renderElement={renderElement}
            onKeyDown={(event) => {
              setShowToolbar(true);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if (onKeyDown?.(event as any)) {
                event.preventDefault();
                return;
              }
              if (event.key === "Escape") {
                ReactEditor.blur(editor);
              } else if (event.key === "}" && variablesEnabled) {
                event.preventDefault();
                dynamicallyInsertVariable(editor);
              }
            }}
            onFocus={() => {
              // `Transforms.select` is important for proper focusing.
              // We move the cursor to the end of the content, but it doesn't
              // really matter where you move it to, just as long as you call
              // `Transform.select`.
              Transforms.select(editor, Editor.end(editor, []));
              setShowToolbar(true);
            }}
            onBlur={() => setShowToolbar(false)}
          />
        </Slate>
      </div>
      <EmojiPicker {...pickerProps} />
    </MessageEditorContext.Provider>
  );
}

export default function MessageEditor(props: MessageEditorProps): ReactElement {
  if (IS_ANDROID_DEVICE) {
    return (
      <MessageStatic
        value={props.value}
        variablesEnabled={props.variablesEnabled}
        editingUnsupportedWarning="Editing this content is not supported on Android devices."
      />
    );
  } else {
    return <MessageEditorInner {...props} />;
  }
}
