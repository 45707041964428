import { mergeLeft, omit } from "ramda";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./MarkerBar.module.css";

export interface MarkerData {
  offsetTop: number;
  color?: string | null;
}

export interface MarkerBarContextProps {
  containerHeight: number;
  markers: Record<string, MarkerData>;
  scrollToMarker: (id: string) => void;
}

export interface MarkerBarUpdateContextProps {
  setContainerHeight: (value: number) => void;
  setMarker: (id: string, data: MarkerData | null) => void;
}

export const MarkerBarContext = React.createContext<
  MarkerBarContextProps | undefined
>(undefined);

export const MarkerBarUpdateContext = React.createContext<
  MarkerBarUpdateContextProps | undefined
>(undefined);

export function useMarkerBar(): MarkerBarContextProps {
  const context = React.useContext(MarkerBarContext);
  if (context === undefined) {
    throw new Error(
      "useMarkerBar can only be used within a MarkerBarProvider component"
    );
  }
  return context;
}

export function useUpdateMarkerBar(): MarkerBarUpdateContextProps {
  const context = React.useContext(MarkerBarUpdateContext);
  if (context === undefined) {
    throw new Error(
      "useUpdateMarkerBar can only be used within a MarkerBarProvider component"
    );
  }
  return context;
}

export function MarkerBarProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [containerHeight, setContainerHeight] = useState<
    MarkerBarContextProps["containerHeight"]
  >(0);
  const [markers, setMarkers] = useState<MarkerBarContextProps["markers"]>({});

  const setMarker = useMemo(() => {
    return (id: string, data: MarkerData | null) => {
      if (data != null) {
        setMarkers(mergeLeft({ [id]: data }));
      } else {
        setMarkers(omit([id]));
      }
    };
  }, [setMarkers]);

  const scrollToMarker = useMemo(() => {
    return (id: string) => {
      document
        .getElementById(markerDomId(id))
        ?.scrollIntoView({ behavior: "smooth" });
    };
  }, []);

  return (
    <MarkerBarContext.Provider
      value={{ containerHeight, markers, scrollToMarker }}
    >
      <MarkerBarUpdateContext.Provider
        value={{ setContainerHeight, setMarker }}
      >
        {children}
      </MarkerBarUpdateContext.Provider>
    </MarkerBarContext.Provider>
  );
}

export function MarkerBar(): ReactElement {
  const { containerHeight, markers } = useMarkerBar();
  const ref = useRef<HTMLDivElement>(null);

  const height = ref.current?.offsetHeight;

  return (
    <div className={styles.markerBar}>
      <div ref={ref} className={styles.markerBar_track}>
        {height == null || containerHeight === 0
          ? null
          : Object.entries(markers).map(([id, markerData]) => (
              <div
                key={id}
                className={styles.markerBar_notch}
                style={{
                  top: Math.floor(
                    (markerData.offsetTop * height) / containerHeight
                  ),
                  backgroundColor: markerData.color ?? "transparent",
                }}
              />
            ))}
      </div>
    </div>
  );
}

export function MarkerContainer({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { setContainerHeight } = useUpdateMarkerBar();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (ref.current != null) {
        setContainerHeight(ref.current.offsetHeight);
      } else {
        setContainerHeight(0);
      }
    }, 3000);
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, [setContainerHeight]);

  return (
    <div ref={ref} className={styles.markerContainer}>
      {children}
    </div>
  );
}

export function Marker({
  id,
  color,
}: {
  id: string;
  color?: string | null;
}): ReactElement {
  const { setMarker } = useUpdateMarkerBar();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (ref.current != null) {
        setMarker(id, { offsetTop: ref.current.offsetTop, color });
      } else {
        setMarker(id, null);
      }
    }, 3000);
    return function cleanup() {
      setMarker(id, null);
      clearInterval(intervalId);
    };
  }, [id, color, setMarker]);

  return <div ref={ref} id={markerDomId(id)} />;
}

const markerDomId = (id: string) => `__marker${id}`;
