import * as api from "@xflr6/chatbot-api";
import React, { ReactElement, useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { AsyncStatus } from "../../app/store";
import LoadingLayout, {
  ErrorLayout,
  NoResultsLayout,
} from "../../components/LoadingLayout";
import AddFlowButton from "./AddFlowButton";
import FlowItem from "./FlowItem";
import styles from "./FlowSequenceEditor.module.css";
import {
  loadFlowSequence,
  moveFlow,
  selectFlowSequenceFlows,
  selectLoading,
  selectUpdating,
} from "./flowSequenceEditorSlice";
import LanguageSelect from "./LanguageSelect";

export interface FlowSequenceEditorProps {
  flowSequenceId: number;
}

const DROPPABLE_ID = "flows-attached";

export default function FlowSequenceEditor(
  props: FlowSequenceEditorProps
): ReactElement | null {
  const dispatch = useDispatch();

  const [loading, loadError] = useSelector(selectLoading);
  const [updating, updateError] = useSelector(selectUpdating);
  const [attachedFlows, detachedFlows] = useSelector(selectFlowSequenceFlows);

  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(loadFlowSequence(props.flowSequenceId));
  }, [props.flowSequenceId, dispatch]);

  useEffect(() => {
    if (updating === "rejected" && updateError) {
      addToast(`Failed to update: ${updateError}`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [updating, updateError, addToast]);

  async function handleDragEnd(result: DropResult) {
    const { destination, source } = result;
    if (destination == null) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (source.droppableId === DROPPABLE_ID) {
      dispatch(moveFlow({ from: source.index, to: destination.index }));
    }
  }

  if (loading === "pending") {
    return <LoadingLayout />;
  }

  if (loading === "rejected") {
    return <ErrorLayout message={loadError} />;
  }

  if (attachedFlows == null) return null;

  return (
    <div className={styles.root}>
      <div className={styles.topBar}>
        <LanguageSelect />
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        {attachedListUI(attachedFlows, updating, !!detachedFlows?.length)}
        {!!detachedFlows?.length && (
          <>
            <div className={styles.detachedDivider}>Detached Flows</div>
            <div className={styles.updatingWrapper}>
              {detachedFlows.map((flow, index) => (
                <FlowItem
                  key={`${flow.id}`}
                  flow={flow}
                  index={index}
                  isDetached
                />
              ))}
              {updating === "pending" && (
                <div className={styles.updating}>
                  <LoadingLayout />
                </div>
              )}
            </div>
          </>
        )}
      </DragDropContext>
    </div>
  );
}

function attachedListUI<T extends api.FlowSequenceFlow>(
  flowSequence: T[],
  updating: AsyncStatus,
  hasDetachedFlows: boolean
) {
  if (flowSequence.length === 0) {
    if (hasDetachedFlows) {
      return (
        <NoResultsLayout
          className={styles.addOrAttachFlowLayout}
          message="No flows. Add or attach one!"
          footer={<AddFlowButton showConfirmDialog />}
        />
      );
    } else {
      return (
        <NoResultsLayout
          message="No flows. Add one!"
          footer={<AddFlowButton />}
        />
      );
    }
  }

  return (
    <>
      <Droppable droppableId={DROPPABLE_ID}>
        {(provided) => {
          return (
            <div
              className={styles.updatingWrapper}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {flowSequence.map((flow, index) => (
                <FlowItem
                  key={`${flow.id}-${flow.version ?? "-"}`}
                  flow={flow}
                  index={index}
                />
              ))}
              {provided.placeholder}
              {updating === "pending" && (
                <div className={styles.updating}>
                  <LoadingLayout />
                </div>
              )}
            </div>
          );
        }}
      </Droppable>
      <div className={styles.addMoreFlowsContainer}>
        <AddFlowButton showConfirmDialog />
      </div>
    </>
  );
}
