import React, { ReactElement, useEffect, useRef } from "react";
import useScript from "react-script-hook";

export interface CredentialResponse {
  credential: string; // This is the id token
  select_by:
    | "auto"
    | "user"
    | "user_1tap"
    | "user_2tap"
    | "btn"
    | "btn_confirm"
    | "btn_add_session"
    | "btn_confirm_add_session";
}

export interface IdConfiguration {
  client_id: string;
  auto_select?: boolean;
  callback?: (response: CredentialResponse) => void;
  login_uri?: string;
  native_callback?: (response: CredentialResponse) => void;
  cancel_on_tap_outside?: boolean;
  prompt_parent_id?: string;
  nonce?: string;
  context?: "signin" | "signup" | "use";
  state_cookie_domain?: string;
  ux_mode?: "popup" | "redirect";
  allowed_parent_origin?: string | string[];
  intermediate_iframe_close_callback?: () => void;
}

export interface GsiButtonConfiguration {
  type?: "standard" | "icon";
  theme?: "outline" | "filled_blue" | "filled_black";
  size?: "large" | "medium" | "small";
  text?: "signin_with" | "signup_with" | "continue_with";
  shape?: "rectangular" | "pill" | "circle" | "square";
  logo_alignment?: "left" | "center";
  width?: number;
  locale?: string;
}

// TODO Complete GoogleIdentity and spin off into a package
export default function GoogleIdentity({
  buttonConfig,
  showOneTapPrompt,
  onScriptLoadError,
  ...idConfig
}: IdConfiguration & {
  buttonConfig?: GsiButtonConfiguration;
  showOneTapPrompt?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScriptLoadError?: (error: any) => void;
}): ReactElement {
  useEffect(() => {
    const needsCallback =
      idConfig.ux_mode == null || idConfig.ux_mode === "popup";
    if (needsCallback && idConfig.callback == null) {
      throw new Error("`callback` prop missing");
    }
  }, [idConfig.callback, idConfig.ux_mode]);

  const google = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const google = (window as any).google;
    if (!google) {
      throw new Error("`window.google` not present");
    }
    return google;
  };

  const [scriptLoading, scriptLoadError] = useScript({
    src: "https://accounts.google.com/gsi/client",
    checkForExisting: true,
    async: true,
    defer: true,
    onload: () => {
      google().accounts.id.initialize(idConfig);
      if (showOneTapPrompt) {
        google().accounts.id.prompt();
      }
    },
  });

  useEffect(() => {
    if (scriptLoadError) {
      onScriptLoadError?.(scriptLoadError);
    }
  }, [scriptLoadError, onScriptLoadError]);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scriptLoading && !scriptLoadError && divRef.current) {
      google().accounts.id.renderButton(divRef.current, {
        width: divRef.current.clientWidth,
        ...buttonConfig,
      });
    }
  }, [scriptLoading, scriptLoadError, divRef, buttonConfig]);

  return <div ref={divRef} />;
}
