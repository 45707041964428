import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../components/buttons/FilledButton";
import { DirtyDot } from "../../components/WithDirtyDot";
import {
  publishFlow,
  selectEditor,
  selectHasUnsavedChanges,
} from "./flowEditorSlice";
import styles from "./PublishButton.module.css";

export default function PublishButton(): ReactElement | null {
  const dispatch = useDispatch();

  const editor = useSelector(selectEditor);
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);

  const canPublish =
    editor.flow?.ownership === "own" &&
    editor.flow.isDirty &&
    editor.flow.flowSequence == null &&
    !hasUnsavedChanges;
  if (canPublish) {
    return (
      <FilledButton
        className={styles.root}
        onClick={() => dispatch(publishFlow())}
        disabled={editor.publishing === "pending"}
        isLoading={editor.publishing === "pending"}
      >
        <DirtyDot />
        &nbsp;Publish
      </FilledButton>
    );
  } else {
    return null;
  }
}
