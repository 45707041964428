import {
  Button,
  HistoryComponent,
  HistoryItem,
  MessageView,
  PositionInfo,
  PromptInput,
  WithCssVariables,
} from "@xflr6/chatbot";
import React, { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as MeetingIcon } from "./meetingIcon.svg";
import styles from "./MeetingMessage.module.css";
import ModalPage from "./ModalPage";
import { getMeetingVendor, MeetingMessageShape } from "./utils";

function WherebyMeeting({
  message,
}: {
  message: MeetingMessageShape;
}): ReactElement {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ModalPage
        title={t("Meeting")}
        titleAction={
          <Button
            size="sm"
            onClick={() => {
              setIsModalOpen(false);
              window.open(message.url, "_blank");
            }}
          >
            <Trans>Pop-out</Trans>
          </Button>
        }
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsModalOpen(false)}
        contentElement={(props, contentElement) => (
          <WithCssVariables>
            <div {...props}>{contentElement}</div>
          </WithCssVariables>
        )}
        noBodySpacing
      >
        <iframe
          className={styles.iframe}
          src={message.url}
          title="Whereby Meeting"
          allow="camera; microphone; fullscreen; speaker; display-capture"
        />
      </ModalPage>
      <div className={styles.message}>
        <div className={styles.icon}>
          <MeetingIcon />
        </div>
        <Button onClick={() => setIsModalOpen(true)}>
          <Trans>Open Meeting</Trans>
        </Button>
      </div>
    </>
  );
}

function DefaultMeeting({
  message,
}: {
  message: MeetingMessageShape;
}): ReactElement {
  return (
    <div className={styles.message}>
      <div className={styles.icon}>
        <MeetingIcon />
      </div>
      <Button
        onClick={() => {
          window.open(message.url, "_blank");
        }}
      >
        <Trans>Open Meeting</Trans>
      </Button>
    </div>
  );
}

export default function MeetingMessage({
  historyItem,
  positionInfo,
  promptInput,
  skipAnimation,
}: {
  historyItem: HistoryItem;
  positionInfo: PositionInfo;
  promptInput: PromptInput | null;
  skipAnimation: boolean | null;
}): ReactElement {
  const message = (historyItem.cleanMessage as unknown) as MeetingMessageShape;
  const meetingVendor = getMeetingVendor(message.url);

  return (
    <HistoryComponent
      historyItem={historyItem}
      positionInfo={positionInfo}
      promptInput={promptInput}
    >
      <MessageView
        direction={historyItem.direction}
        positionInfo={positionInfo}
        skipAnimation={skipAnimation}
      >
        {meetingVendor === "whereby" ? (
          <WherebyMeeting message={message} />
        ) : (
          <DefaultMeeting message={message} />
        )}
      </MessageView>
    </HistoryComponent>
  );
}
