import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "@xflr6/chatbot-api";
import { mergeDeepRight } from "ramda";

import { AsyncStatus, RootState } from "../../app/store";
import { ErrorData, getSerializableError } from "../../utils/error";

export interface IntegrationsState {
  loading: AsyncStatus;
  updating: AsyncStatus;
  lastUpdateAction: [api.IntegrationType, "add" | "update" | "delete"] | null;
  error: ErrorData | null;
  integrations: api.Integration[] | null;
}

export const loadIntegrations = createAsyncThunk(
  "integrations/loadIntegrationsStatus",
  async (payload: { noReload?: boolean } | undefined, thunkApi) => {
    try {
      return await api.getIntegrations();
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  },
  {
    condition(payload, thunkApi): boolean | undefined {
      const rootState = thunkApi.getState() as RootState;
      if (rootState.integrations.integrations != null && payload?.noReload) {
        return false;
      }
    },
  }
);

export const addIntegration = createAsyncThunk(
  "integrations/addIntegrationStatus",
  async (payload: api.Integration, thunkApi) => {
    try {
      return await api.addIntegration(payload.type, payload.config);
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const updateIntegration = createAsyncThunk(
  "integrations/updateIntegrationStatus",
  async (payload: api.Integration, thunkApi) => {
    try {
      return await api.updateIntegration(payload.type, payload.config);
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const deleteIntegration = createAsyncThunk(
  "integrations/deleteIntegrationStatus",
  async (payload: { type: api.IntegrationType; index?: number }, thunkApi) => {
    try {
      return await api.deleteIntegration(payload.type, payload.index);
    } catch (error) {
      return thunkApi.rejectWithValue(getSerializableError(error));
    }
  }
);

export const slice = createSlice({
  name: "auth",
  initialState: {
    loading: "idle",
    updating: "idle",
    lastUpdateAction: null,
    error: null,
    integrations: null,
  } as IntegrationsState,
  reducers: {},
  extraReducers: (builder) => {
    // *** loadIntegrations ***
    builder.addCase(loadIntegrations.pending, (state) => {
      state.loading = "pending";
      state.integrations = null;
      state.error = null;
    });
    builder.addCase(loadIntegrations.fulfilled, (state, action) => {
      state.loading = "fulfilled";
      state.integrations = action.payload;
      state.error = null;
    });
    builder.addCase(loadIntegrations.rejected, (state, action) => {
      state.loading = "rejected";
      state.error = action.payload;
    });

    // *** addIntegration ***
    builder.addCase(addIntegration.pending, (state, action) => {
      state.updating = "pending";
      state.lastUpdateAction = [action.meta.arg.type, "add"];
      state.error = null;
    });
    builder.addCase(addIntegration.fulfilled, (state, action) => {
      state.updating = "fulfilled";
      const integration = action.payload ?? action.meta.arg;
      state.integrations = [
        ...(state.integrations?.filter(
          (i) => i.type !== action.meta.arg.type
        ) ?? []),
        integration,
      ];
    });
    builder.addCase(addIntegration.rejected, (state, action) => {
      state.updating = "rejected";
      state.error = action.payload;
    });

    // *** updateIntegration ***
    builder.addCase(updateIntegration.pending, (state, action) => {
      state.updating = "pending";
      state.lastUpdateAction = [action.meta.arg.type, "update"];
      state.error = null;
    });
    builder.addCase(updateIntegration.fulfilled, (state, action) => {
      state.updating = "fulfilled";
      if (state.integrations == null) {
        throw new Error("This shouldn't happen");
      }
      const integration = action.payload ?? action.meta.arg;
      const index = state.integrations.findIndex(
        (i) => i.type === integration.type
      );
      if (index === -1) {
        throw new Error("This shouldn't happen");
      }
      state.integrations[index] = mergeDeepRight(
        state.integrations[index],
        integration
      );
      state.error = null;
    });
    builder.addCase(updateIntegration.rejected, (state, action) => {
      state.updating = "rejected";
      state.error = action.payload;
    });

    // *** deleteIntegration ***
    builder.addCase(deleteIntegration.pending, (state, action) => {
      state.updating = "pending";
      state.lastUpdateAction = [action.meta.arg.type, "delete"];
      state.error = null;
    });
    builder.addCase(deleteIntegration.fulfilled, (state, action) => {
      state.updating = "fulfilled";
      const integration = action.payload;
      state.integrations = [
        ...(state.integrations ?? []).filter(
          (i) => i.type !== action.meta.arg.type
        ),
        ...(integration?.config ? [integration] : []),
      ];
    });
    builder.addCase(deleteIntegration.rejected, (state, action) => {
      state.updating = "rejected";
      state.error = action.payload;
    });
  },
});

export const selectIntegrations = (state: RootState): IntegrationsState =>
  state.integrations;

export const selectIntegration = (
  state: RootState,
  type: string
): api.Integration | undefined =>
  state.integrations.integrations?.find((i) => i.type === type);

export const selectIntegrationThinkific = (
  state: RootState
): api.Integration | undefined => selectIntegration(state, "thinkific");

export const selectIntegrationCloudinary = (
  state: RootState
): api.Integration | undefined => selectIntegration(state, "cloudinary");

export const areAnyTypesIntegrated = (
  integrations: api.Integration[] | null | undefined,
  types: string[]
): boolean => !!integrations?.some((i) => types.includes(i.type));

export const selectIsIntegratedThinkific = (state: RootState): boolean =>
  areAnyTypesIntegrated(state.integrations.integrations, ["thinkific"]);

export const selectIsIntegratedCloudinary = (state: RootState): boolean =>
  areAnyTypesIntegrated(state.integrations.integrations, ["cloudinary"]);

export default slice.reducer;
