import React, { ReactElement, ReactNode, useMemo, useState } from "react";

import Modal from "../../../../components/Modal";
import PromptTemplates from "./PromptTemplates";
import { PromptTemplate } from "./templates";

type PromptTemplatesContextProps = () => Promise<PromptTemplate | null>;

const PromptTemplatesContext = React.createContext<
  PromptTemplatesContextProps | undefined
>(undefined);

export function usePromptTemplates(): PromptTemplatesContextProps {
  const context = React.useContext(PromptTemplatesContext);
  if (context === undefined) {
    throw new Error(
      "usePromptTemplates can only be used within a PromptTemplatesProvider component"
    );
  }
  return context;
}

export function PromptTemplatesProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: (template: PromptTemplate | null) => void;
  }>();

  const getPromptTemplate = useMemo(
    () => () => {
      setIsOpen(true);
      return new Promise<PromptTemplate | null>((resolve) => {
        awaitingPromiseRef.current = { resolve };
      });
    },
    []
  );

  const onSelect = useMemo(
    () => (template: PromptTemplate) => {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve(template);
      }
      setIsOpen(false);
    },
    []
  );

  const onRequestClose = useMemo(
    () => () => {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve(null);
      }
      setIsOpen(false);
    },
    []
  );

  return (
    <PromptTemplatesContext.Provider value={getPromptTemplate}>
      <Modal
        title="Select a Prompt Template"
        style={{
          content: {
            width: "800px",
            maxWidth: "calc(100% - var(--spacingDouble))",
            height: "600px",
            maxHeight: "calc(100% - var(--spacingDouble))",
          },
        }}
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        noBodySpacing
      >
        <PromptTemplates style={{ height: "100%" }} onSelect={onSelect} />
      </Modal>
      {children}
    </PromptTemplatesContext.Provider>
  );
}
