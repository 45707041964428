import React, { ReactElement } from "react";
import Select from "react-select";

import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";

type Option = { value: string | null; label: string };

const OPTIONS: Option[] = [
  { value: null, label: "Text" },
  { value: "number", label: "Number" },
  { value: "tel", label: "Telephone #" },
];

function getOption(value?: string | null): Option | undefined {
  return OPTIONS.find((o) => o.value === (value ?? null));
}

export default function CustomInputDisplayType(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  return (
    <Select
      options={OPTIONS}
      value={getOption(prompt.definition.inputDisplayType)}
      onChange={(selectedOption) => {
        updateFields({
          inputDisplayType: (selectedOption as Option | undefined)?.value,
        });
      }}
    />
  );
}
