import { Prompt } from "@xflr6/chatbot";

import { baseUrl } from "./utils";

export const answerSamplesUrlPattern = new RegExp(
  `^${baseUrl}/flows/(.*)/prompt/(?<promptName>.*)/samples`
);

export function buildAnswerSamplesUrl(
  flowId: number | string,
  promptName: string,
  language: string | null | undefined
): string {
  const _promptNameStr = promptName.match(Prompt.variablePattern)
    ? promptName
    : encodeURIComponent(promptName);
  return `${baseUrl}/flows/${flowId}/prompt/${_promptNameStr}/samples${
    language ? `/${language}` : ""
  }`;
}

export const answerStatsUrlPattern = new RegExp(
  `^${baseUrl}/flows/(.*)/prompt/(?<promptName>.*)/stats`
);

export function buildAnswerStatsUrl(
  flowId: number | string,
  promptName: string,
  language: string | null | undefined
): string {
  const _promptNameStr = promptName.match(Prompt.variablePattern)
    ? promptName
    : encodeURIComponent(promptName);
  return `${baseUrl}/flows/${flowId}/prompt/${_promptNameStr}/stats${
    language ? `/${language}` : ""
  }`;
}

export const evaluationsUrlPattern = new RegExp(
  `^${baseUrl}.*/evaluations/.*$`
);

export function buildEvaluationsUrl(
  flowId: number | string,
  identifier: string,
  promptKeyStr: string
): string {
  // We don't use RegExp#test because Prompt.variablePattern is global.
  // RegExp#test called multiple times on the same global regular expression
  // instance will advance past the previous match, instead of starting from
  // scratch.
  const _identifier = identifier.match(Prompt.variablePattern)
    ? identifier
    : encodeURIComponent(identifier);
  const _promptKeyStr = promptKeyStr.match(Prompt.variablePattern)
    ? promptKeyStr
    : encodeURIComponent(promptKeyStr);
  return `${baseUrl}/flows/${flowId}/history/${_identifier}/evaluations/${_promptKeyStr}`;
}
