import { v4 } from "uuid";

const THINKIFIC_APP_STATE_KEY = "thinkificAppState";

export function getFreshState(): string {
  const state = `df-${v4().split("-")[4]}`;
  localStorage.setItem(THINKIFIC_APP_STATE_KEY, state);
  return state;
}

export function getState(): string | null {
  return localStorage.getItem(THINKIFIC_APP_STATE_KEY);
}
