import classNames from "classnames";
import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import buttonStyles from "../../components/buttons/Button.module.css";
import FlatButton from "../../components/buttons/FlatButton";
import { loadCurrentPlan, selectAuth } from "./authSlice";
import styles from "./CurrentPlan.module.css";

export default function CurrentPlan(): ReactElement | null {
  const dispatch = useDispatch();

  const { planLoading, currentPlan } = useSelector(selectAuth);

  useEffect(() => {
    dispatch(loadCurrentPlan());
  }, [dispatch]);

  if (currentPlan != null) {
    const { plan, usage } = currentPlan;
    const isLimitCrossed = usage > plan.chatLimit;
    const isLimitNearing = !isLimitCrossed && usage / plan.chatLimit > 0.7;
    return (
      <div className={styles.root}>
        <div className={styles.plan}>
          You are currently on the{" "}
          <span className={styles.plan_name}>{plan.name}</span> plan.
        </div>
        <div className={styles.usage}>
          <span
            className={classNames(styles.usage_actual, {
              [styles.usage_actual__crossed]: isLimitCrossed,
              [styles.usage_actual__nearing]: isLimitNearing,
            })}
          >
            {usage}
          </span>{" "}
          of {plan.chatLimit} chats used up this {plan.durationUnit}.
        </div>
        {isLimitCrossed && (
          <div className={styles.limitCrossedMessage}>
            Don&apos;t worry. Your service will continue uninterrupted, and you
            will not be charged extra! We will contact you to upgrade your plan.
          </div>
        )}
        <div className={styles.pricingPlansLinkWrapper}>
          <a
            className={buttonStyles.hasButton}
            href="https://www.dialogform.in/#pricing-plans"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FlatButton>Available Pricing Plans</FlatButton>
          </a>
        </div>
      </div>
    );
  } else if (planLoading === "pending") {
    return <div className={styles.loadingStatus}>Loading plan details...</div>;
  } else if (planLoading === "rejected") {
    return (
      <div className={styles.loadingStatus}>Failed to fetch plan details</div>
    );
  } else {
    return null;
  }
}
