import { equals } from "ramda";
import { Element, Node, Range, Text } from "slate";
import { Editor } from "slate";
import UAParser from "ua-parser-js";

import {
  destructureAugmentedImageUrl,
  ImageElement,
  MARKDOWN_IMAGE_LINE,
  serializeImageElement,
} from "./imagesPlugin";
import { newVariableNode, VARIABLE_REGEX } from "./variablesPlugin";

export const IS_ANDROID_DEVICE = /android/i.test(
  new UAParser(navigator.userAgent).getOS().name ?? ""
);

// *** Private ***

function deserializeMarkdownLine(line: string, withVariables: boolean): Node {
  const imageMatch = line.match(MARKDOWN_IMAGE_LINE);
  if (imageMatch != null) {
    return {
      type: "image",
      imageProps: {
        ...imageMatch.groups,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...destructureAugmentedImageUrl(imageMatch.groups!.src),
      },
      children: [{ text: "" }],
    };
  } else if (withVariables) {
    VARIABLE_REGEX.lastIndex = 0;
    const resultLine: Node = { type: "paragraph", children: [] };
    let match: RegExpExecArray | null;
    let processed = 0;
    while ((match = VARIABLE_REGEX.exec(line)) !== null) {
      const [wholeMatch, at, content] = match;
      resultLine.children.push({ text: line.slice(processed, match.index) });
      resultLine.children.push(newVariableNode(content, at === "@"));
      processed = match.index + wholeMatch.length;
    }
    resultLine.children.push({ text: line.slice(processed) });
    return resultLine;
  } else {
    return { type: "paragraph", children: [{ text: line }] };
  }
}

function serializeMarkdownLine(nodes: Node[]): string {
  return nodes
    .map((n) =>
      Element.isElement(n) && n.type === "variable"
        ? `{{${n.isPrompt ? "@" : ""}${n.variableName ?? ""}}}`
        : Node.string(n as Node)
    )
    .join("");
}

// *** Serialization/deserialization ***

/**
 * Serializes the slate editor contents into Markdown.
 */
export function serializeToMarkdown(nodes: Node[]): string {
  return nodes
    .map((n) => {
      if (Element.isElement(n)) {
        return n.type === "image"
          ? serializeImageElement(n as ImageElement)
          : serializeMarkdownLine(n.children);
      } else if (Text.isText(n)) {
        // This case should not happen, because when we serialize the markdown,
        // we ensure that all top-level nodes are Elements. However, we include
        // it for completeness, in case we ever decide to serialize differently.
        return n.text;
      } else {
        // This case should not happen, but we include it for completeness
        return "";
      }
    })
    .join("\n");
}

/**
 * Deserializes Markdown for hydrating the slate editor.
 */
export function deserializeMarkdown(
  markdownStr: string,
  withVariables: boolean
): Node[] {
  return markdownStr
    .split("\n")
    .map((line) => deserializeMarkdownLine(line, withVariables));
}

// *** Helpers - for our data model (NOT generic!) ***

/**
 * Whether the editor has only a single line.
 */
export function hasSingleLine(editor: Editor): boolean {
  return editor.children.length === 1;
}

/**
 * Whether the range is at a new (empty) line.
 */
export function isAtNewLine(range: Range, editor: Editor): boolean {
  let result = false;
  const { anchor, focus } = range;
  const firstElementInLine = anchor.path.length >= 2 && anchor.path[1] === 0;
  if (
    anchor.offset === 0 &&
    anchor.path.length < 3 &&
    firstElementInLine &&
    equals(anchor, focus)
  ) {
    const node = Node.get(editor, anchor.path);
    if (equals(node, { text: "" })) {
      result = true;
    }
  }
  return result;
}

const NON_WRITING_NODES = ["image", "variable"];

export function isInWriting(range: Range, editor: Editor): boolean {
  const [match] = Editor.nodes(editor, {
    match: (n) => Element.isElement(n) && NON_WRITING_NODES.includes(n.type),
  });
  return match == null;
}

/**
 * Whether the cursor is between two words (or at line edges).
 */
export function isAtWordGap(range: Range, editor: Editor): boolean {
  if (!Range.isCollapsed(range)) {
    return false;
  }
  if (!isInWriting(range, editor)) {
    return false;
  }
  const [start] = Range.edges(range);
  const before = Editor.before(editor, start, { unit: "character" });
  const beforeRange = before && Editor.range(editor, before, start);
  const beforeChar = beforeRange && Editor.string(editor, beforeRange);
  return (
    beforeChar == null ||
    ["", " ", "'", '"', "$", "#", "&", ":", "/", "~", "(", "["].includes(
      beforeChar
    )
  );
}

export function isEmptyValue(nodes: Node[]): boolean {
  if (!nodes?.length) return true;
  if (nodes.length > 1) return false;
  const child = nodes[0];
  return (
    equals(child, { text: "" }) ||
    (Element.isElement(child) && isEmptyValue(child.children))
  );
}

export function canAddImage(editor: Editor): boolean {
  return !!editor.selection && isAtNewLine(editor.selection, editor);
}

export function canAddEmoji(editor: Editor): boolean {
  return !!editor.selection && isInWriting(editor.selection, editor);
}

export function canAddRecall(editor: Editor): boolean {
  return !!editor.selection && isAtWordGap(editor.selection, editor);
}
