import { assignTranslation, getTranslation } from "@xflr6/chatbot-engine";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import Checkbox from "../../../../components/forms/Checkbox";
import MessageEditor from "../../../slateMessageEditor/MessageEditor";
import { selectFlowId } from "../../flowEditorSlice";
import {
  usePromptConfigEditorOps,
  usePromptConfigEditorState,
} from "../PromptConfigEditor";
import TranslationEditor from "../TranslationEditor";
import styles from "./AnswerExternally.module.css";

export default function AnswerExternally(): ReactElement {
  const prompt = usePromptConfigEditorState();
  const { updateFields } = usePromptConfigEditorOps();

  const flowId = useSelector(selectFlowId);

  return (
    <>
      <Checkbox
        label="Pause here and handover to admin"
        checked={prompt.definition.answerExternally ?? false}
        onChange={(event) =>
          updateFields({ answerExternally: event.target.checked })
        }
      />
      {prompt.definition.answerExternally && (
        <>
          <div className={styles.label}>&quot;Chat paused&quot; message</div>
          <div className={styles.editor}>
            <MessageEditor
              value={prompt.definition.extAnswerPendingMessage ?? ""}
              placeholder="Customize message..."
              uploadPathPrefix={`flows/${flowId}/`}
              onChangeDebounced={(value) => {
                if (
                  (prompt.definition.extAnswerPendingMessage ?? "") === value
                ) {
                  return;
                }
                updateFields({
                  extAnswerPendingMessage: value === "" ? undefined : value,
                });
              }}
            />
          </div>
          <TranslationEditor>
            {(language) => {
              const translation = `${
                getTranslation(
                  prompt.definition.tExtAnswerPendingMessage,
                  language
                ) ?? ""
              }`;
              return (
                <div className={styles.translationEditor}>
                  <MessageEditor
                    value={translation}
                    placeholder=""
                    uploadPathPrefix={`flows/${flowId}/`}
                    onChangeDebounced={(value) => {
                      if (value === translation) {
                        return;
                      }
                      updateFields({
                        tExtAnswerPendingMessage: assignTranslation(
                          prompt.definition.tExtAnswerPendingMessage,
                          language,
                          value
                        ),
                      });
                    }}
                  />
                </div>
              );
            }}
          </TranslationEditor>
          <div className={styles.label}>&quot;Chat resumed&quot; message</div>
          <div className={styles.editor}>
            <MessageEditor
              value={prompt.definition.extAnswerFulfilledMessage ?? ""}
              placeholder="Customize message..."
              uploadPathPrefix={`flows/${flowId}/`}
              onChangeDebounced={(value) => {
                if (
                  (prompt.definition.extAnswerFulfilledMessage ?? "") === value
                ) {
                  return;
                }
                updateFields({
                  extAnswerFulfilledMessage: value === "" ? undefined : value,
                });
              }}
            />
          </div>
          <TranslationEditor>
            {(language) => {
              const translation = `${
                getTranslation(
                  prompt.definition.tExtAnswerFulfilledMessage,
                  language
                ) ?? ""
              }`;
              return (
                <div className={styles.translationEditor}>
                  <MessageEditor
                    value={translation}
                    placeholder=""
                    uploadPathPrefix={`flows/${flowId}/`}
                    onChangeDebounced={(value) => {
                      if (value === translation) {
                        return;
                      }
                      updateFields({
                        tExtAnswerFulfilledMessage: assignTranslation(
                          prompt.definition.tExtAnswerFulfilledMessage,
                          language,
                          value
                        ),
                      });
                    }}
                  />
                </div>
              );
            }}
          </TranslationEditor>
        </>
      )}
    </>
  );
}
