import {
  HistoryItemView,
  HistoryItemViewProps,
  JsonObject,
} from "@xflr6/chatbot";
import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Node } from "slate";

import FilledButton from "../../components/buttons/FilledButton";
import FlatButton from "../../components/buttons/FlatButton";
import Input from "../../components/forms/Input";
import MessageEditor from "../../features/slateMessageEditor/MessageEditor";
import MessageStatic from "../../features/slateMessageEditor/MessageStatic";
import { serializeToMarkdown } from "../../features/slateMessageEditor/utils";
import { AdminInputsIcon } from "./AdminInputsIcon";
import MessageFooter from "./MessageFooter";
import Notes from "./Notes";
import styles from "./SentHistoryItemView.module.css";

function InputForm({
  editorView,
  editorClassName,
  staticView,
  staticClassName,
  editButtonLabel,
  statusIcon,
  getSubmissionData,
  submitErrorToast,
  onSubmit,
}: {
  editorView: ReactElement;
  editorClassName?: string | null;
  staticView?: ReactElement | null;
  staticClassName?: string | null;
  editButtonLabel: string;
  statusIcon?: ReactElement | null;
  getSubmissionData: () => api.ChatResponseHistoryItemPatch;
  submitErrorToast: string;
  onSubmit?: (data: api.ChatResponseHistoryItemPatch) => Promise<void>;
}): ReactElement {
  const { addToast } = useToasts();

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  async function submit() {
    const data = getSubmissionData();
    try {
      setIsUpdating(true);
      await onSubmit?.(data);
      setIsEditing(false);
    } catch (error) {
      addToast(submitErrorToast, { appearance: "error", autoDismiss: true });
    } finally {
      setIsUpdating(false);
    }
  }

  if (isEditing) {
    return (
      <MessageFooter
        direction="sent"
        bodyClassName={classNames(styles.inputForm, editorClassName)}
        body={editorView}
        actions={
          <>
            <FilledButton
              onClick={() => setIsEditing(false)}
              disabled={isUpdating}
            >
              Cancel
            </FilledButton>
            <FilledButton onClick={submit} disabled={isUpdating}>
              {isUpdating ? "Saving..." : "Save"}
            </FilledButton>
          </>
        }
      />
    );
  } else {
    return (
      <MessageFooter
        direction="sent"
        bodyClassName={classNames(styles.inputForm, staticClassName)}
        body={staticView}
        actions={
          <FlatButton onClick={() => setIsEditing(true)}>
            <div className={styles.editButton}>
              {editButtonLabel}
              <div className={styles.editButton_icon}>{statusIcon}</div>
            </div>
          </FlatButton>
        }
      />
    );
  }
}

function StatusIcon(props: {
  hidden?: boolean;
  complete: boolean;
}): ReactElement {
  return (
    <AdminInputsIcon
      className={props.hidden ? styles.statusIcon__hidden : undefined}
      pending={!props.complete}
    />
  );
}

export type SentHistoryItemViewProps = HistoryItemViewProps & {
  flowId: number;
  onUpdateHistory?: (
    index: number,
    data: api.ChatResponseHistoryItemPatch
  ) => Promise<void>;
};

export default function SentHistoryItemView(
  props: SentHistoryItemViewProps
): ReactElement | null {
  const [custom, setCustom] = useState<JsonObject | null>();
  const [maxSuperScore, setMaxSuperScore] = useState<number | null>();

  useEffect(() => {
    const { promptKey, originalMessage, message } = props.historyItem;
    props.getPromptInput(promptKey).then((promptInput) => {
      setCustom(promptInput.custom);
      if (props.historyItem.scoreLater) {
        const answerMessage = originalMessage ?? message;
        const answerObject = promptInput.getAnswerObj(answerMessage);
        if (answerObject != null) setMaxSuperScore(answerObject.score);
      }
    });
  }, [props]);

  const rawComment = useRef<Node[]>();
  const superScoreInput = React.createRef<HTMLInputElement>();

  function serializeComment() {
    if (rawComment.current == null) return props.historyItem.comment;
    const result = serializeToMarkdown(rawComment.current).trim();
    return result.length > 0 ? result : null;
  }

  function serializeSuperScore() {
    if (superScoreInput.current == null) return null;
    const result = parseInt(superScoreInput.current.value, 10);
    return isNaN(result) ? null : result;
  }

  const positionInfoIndex = props.positionInfo.index;
  if (positionInfoIndex == null) return null;

  return (
    <>
      <HistoryItemView {...props} />
      {custom?.feedback && (
        <div
          style={{ position: "relative", top: -100 }}
          id={`__history${props.positionInfo.index}_feedback`}
        />
      )}
      {!props.historyItem.answerExternally && (
        <InputForm // Feedback form
          staticView={
            props.historyItem.comment != null ? (
              <div className={styles.feedback}>
                <MessageStatic value={props.historyItem.comment} />
              </div>
            ) : null
          }
          staticClassName={styles.__staticFeedback}
          editButtonLabel={
            props.historyItem.comment != null ? "Edit Feedback" : "Add Feedback"
          }
          editorView={
            <div className={styles.feedback}>
              <MessageEditor
                value={props.historyItem.comment ?? ""}
                placeholder="Enter feedback here..."
                uploadPathPrefix={`flows/${props.flowId}/`}
                onChangeRaw={(value) => (rawComment.current = value)}
                autoFocus
              />
            </div>
          }
          editorClassName={styles.__editorFeedback}
          statusIcon={
            <StatusIcon
              hidden={!custom?.feedback}
              complete={props.historyItem.comment != null}
            />
          }
          getSubmissionData={() => ({ comment: serializeComment() })}
          submitErrorToast="Failed to save feedback."
          onSubmit={async (data) => {
            await props.onUpdateHistory?.(positionInfoIndex, data);
          }}
        />
      )}
      {props.historyItem.scoreLater && (
        <div
          style={{ position: "relative", top: -100 }}
          id={`__history${props.positionInfo.index}_scoreLater`}
        />
      )}
      {props.historyItem.scoreLater && (
        <InputForm // Score form
          staticView={
            props.historyItem.superScore != null ? (
              <div className={styles.superScore}>
                Score&nbsp;
                <Input
                  disabled
                  styleVariant="outlined"
                  className={styles.superScore_Input}
                  defaultValue={props.historyItem.superScore}
                  placeholder="---"
                />
                {maxSuperScore != null && <span> / {maxSuperScore}</span>}
              </div>
            ) : null
          }
          staticClassName={styles.__staticSuperScore}
          editButtonLabel={
            props.historyItem.superScore != null ? "Edit Score" : "Assign Score"
          }
          editorView={
            <div className={styles.superScore}>
              Score&nbsp;
              <Input
                type="number"
                styleVariant="outlined"
                className={styles.superScore_Input}
                defaultValue={props.historyItem.superScore ?? undefined}
                placeholder="---"
                ref={superScoreInput}
              />
              {props.historyItem.score != null && (
                <span> / {props.historyItem.score}</span>
              )}
              {maxSuperScore != null && <span> / {maxSuperScore}</span>}
            </div>
          }
          editorClassName={styles.__editorSuperScore}
          statusIcon={
            <StatusIcon complete={props.historyItem.superScore != null} />
          }
          getSubmissionData={() => ({ superScore: serializeSuperScore() })}
          submitErrorToast="Failed to save score."
          onSubmit={async (data) => {
            await props.onUpdateHistory?.(positionInfoIndex, data);
          }}
        />
      )}
      {custom?.notes != null && (
        <Notes direction="sent" notes={custom?.notes as string} />
      )}
    </>
  );
}
