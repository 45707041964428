import { JsonPresent, OrdChatFlowDefinition } from "@xflr6/chatbot";
import { v4 } from "uuid";

import { WithKey } from "./types";

/**
 * Sets uuid keys on prompt quick responses that don't have one.
 * Keys are needed to be able to correctly render these responses.
 */
export function hydrateAltMessageItemKeys(
  flowDef: OrdChatFlowDefinition
): void {
  flowDef.promptsArray.forEach(({ definition }) => {
    if (definition.quickResponses && Array.isArray(definition.quickResponses)) {
      definition.quickResponses.forEach((alternativeMsg) => {
        const KeyedAlternativeMsg = alternativeMsg as WithKey<JsonPresent>;
        if (KeyedAlternativeMsg.__key == null) {
          KeyedAlternativeMsg.__key = v4();
        }
      });
    }
  });
}
