import { assignTranslation, PromptMessageItem } from "@xflr6/chatbot";
import classNames from "classnames";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaHourglassStart, FaRegTrashAlt } from "react-icons/fa";
import { GrDrag } from "react-icons/gr";
import { MdMoreVert } from "react-icons/md";
import { useDispatch } from "react-redux";

import FlatButton from "../../../components/buttons/FlatButton";
import Checkbox from "../../../components/forms/Checkbox";
import Input from "../../../components/forms/Input";
import Menu from "../../../components/menus/Menu";
import MenuItem from "../../../components/menus/MenuItem";
import Popover from "../../../components/Popover";
import useDelayedToggle from "../../../utils/useDelayedToggle";
import {
  EditorUpdateMessageItemFields,
  removeMessageItem,
  updateMessageItemFields,
} from "../flowEditorSlice";
import InsertMessageItemButton from "./InsertMessageItemButton";
import MessageEditor, { MessageEditorCtrl } from "./MessageEditor";
import styles from "./MessageItemEditor.module.css";
import { WithKey } from "./operations/types";

export interface MessageItemEditorCtrl {
  key: string;
  index: number;
  focus: () => void;
}

export interface MessageItemEditorProps {
  promptIndex: number;
  messageItem: PromptMessageItem;
  index: number;
  isLast: boolean;
  promptHasAnswers: boolean;
  control?: (control: MessageItemEditorCtrl) => void;
  deregisterControl?: (controlKey: string) => void;
}

export function MessageItemEditor({
  promptIndex,
  messageItem,
  index,
  isLast,
  promptHasAnswers,
  control,
  deregisterControl,
}: MessageItemEditorProps): ReactElement {
  const dispatch = useDispatch();

  const [isHovering, setIsHovering] = useState<boolean>(false);
  const {
    value: isActionMenuOpen,
    setTrueWithDelay: openActionMenuWithDelay,
    setTrueImmediate: openActionMenu,
    setFalseWithDelay: closeActionMenuWithDelay,
    setFalseImmediate: closeActionMenu_,
    toggleImmediate: toggleActionMenu,
  } = useDelayedToggle(false, {});
  const closeActionMenu = useCallback(() => {
    closeActionMenu_();
    setIsHovering(false);
  }, [closeActionMenu_]);

  function updateFields(fields: EditorUpdateMessageItemFields["fields"]): void {
    dispatch(
      updateMessageItemFields({
        promptIndexOrName: promptIndex,
        messageIndex: index,
        fields,
      })
    );
  }

  function removeMessageItemHandler(): void {
    dispatch(
      removeMessageItem({
        promptIndexOrName: promptIndex,
        index: index,
      })
    );
  }

  const editorControl = useRef<MessageEditorCtrl>();

  const registerEditorControl = useCallback(
    (c) => (editorControl.current = c),
    []
  );

  const __key = (messageItem as WithKey<PromptMessageItem>).__key;

  useEffect(() => {
    control?.({
      key: __key,
      index: index,
      focus: () => editorControl.current?.focus(),
    });
  }, [control, __key, index]);

  useEffect(() => {
    return function cleanup() {
      deregisterControl?.(__key);
    };
  }, [deregisterControl, __key]);

  return (
    <Draggable draggableId={__key} index={index}>
      {(provided) => (
        <div
          className={classNames(styles.root, {
            [styles.root__hovering]: isHovering,
          })}
          {...provided.draggableProps}
          ref={provided.innerRef}
          onPointerOver={() => setIsHovering(true)}
          onPointerLeave={() => setIsHovering(false)}
        >
          {isHovering && index === 0 && (
            <InsertMessageItemButton
              promptIndex={promptIndex}
              index={index}
              isAbove
            />
          )}
          <div className={styles.messageItem}>
            <div className={styles.dragHandle} {...provided.dragHandleProps}>
              <GrDrag size={14} />
            </div>
            <div className={styles.editor}>
              <div className={styles.editor_step}>
                Step {index + 1}
                <Popover
                  positions={["bottom", "top"]}
                  align="end"
                  reposition
                  isOpen={isActionMenuOpen}
                  onClickOutside={closeActionMenu}
                  containerClassName={styles.actionMenu}
                  content={
                    <Menu
                      autoClose
                      closeOnEsc
                      onRequestClose={closeActionMenu}
                      onPointerEnter={openActionMenu}
                      onPointerLeave={closeActionMenu}
                    >
                      {(!promptHasAnswers ||
                        (!isLast && !messageItem.repeatAnswers)) &&
                        messageItem.minNextPromptDelayMs == null && (
                          <MenuItem
                            onClick={() => {
                              updateFields({ minNextPromptDelayMs: 1000 });
                            }}
                          >
                            <FaHourglassStart size={14} />
                            &nbsp;Add Pause
                          </MenuItem>
                        )}
                      <MenuItem
                        autoClose={false}
                        onClick={removeMessageItemHandler}
                      >
                        <FaRegTrashAlt />
                        &nbsp;Delete
                      </MenuItem>
                    </Menu>
                  }
                >
                  <FlatButton
                    className={styles.actionMenuButton}
                    onPointerEnter={openActionMenuWithDelay}
                    onPointerLeave={closeActionMenuWithDelay}
                  >
                    <MdMoreVert size={18} />
                  </FlatButton>
                </Popover>
              </div>
              <MessageEditor
                message={messageItem.message}
                contentType={messageItem.contentType}
                onChange={(message) => {
                  updateFields({ message });
                }}
                onKeyDown={(event) => {
                  if (event.ctrlKey || event.metaKey) {
                    if (event.key === "/") {
                      toggleActionMenu();
                      return true;
                    }
                  }
                  return false;
                }}
                translations={messageItem.tMessage}
                onTranslationChange={(language, message) => {
                  updateFields({
                    tMessage: assignTranslation(
                      messageItem.tMessage,
                      language,
                      message
                    ),
                  });
                }}
                control={registerEditorControl}
              />
              {promptHasAnswers && (
                <Checkbox
                  className={styles.acceptAnswer}
                  label="Ask user for answer"
                  inputClassName={styles.acceptAnswer_input}
                  disabled={isLast}
                  checked={isLast || (messageItem.repeatAnswers ?? false)}
                  onChange={(event) => {
                    updateFields({ repeatAnswers: event.target.checked });
                  }}
                />
              )}
            </div>
            {messageItem.minNextPromptDelayMs != null &&
              !(promptHasAnswers && messageItem.repeatAnswers) && (
                <div className={styles.delay}>
                  <div className={styles.delay_text}>
                    <FaHourglassStart size={12} />
                    &nbsp;Pause for
                  </div>
                  <div className={styles.delay_input}>
                    <Input
                      styleVariant="outlined"
                      type="number"
                      value={`${messageItem.minNextPromptDelayMs}`}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      onBlurValue={(_value) => {
                        const value = parseInt(_value, 10);
                        if (isNaN(value)) {
                          updateFields({ minNextPromptDelayMs: null });
                        } else if (value !== messageItem.minNextPromptDelayMs) {
                          updateFields({ minNextPromptDelayMs: value });
                        }
                      }}
                      blurOnEnterOrEsc
                    />
                  </div>
                  <div className={styles.delay_text}>milliseconds</div>
                </div>
              )}
          </div>
          {isHovering && (
            <InsertMessageItemButton
              promptIndex={promptIndex}
              index={index + 1}
            />
          )}
        </div>
      )}
    </Draggable>
  );
}
