import classNames from "classnames";
import React, { ReactElement, useState } from "react";
import { FaEquals, FaLessThanEqual } from "react-icons/fa";

import FlatButton from "../../../../components/buttons/FlatButton";
import MyTippy from "../../../../components/MyTippy";
import useUpdateEffect from "../../../../utils/useUpdateEffect";
import styles from "./ScoreEditor.module.css";

export interface ScoreEditorProps {
  initialScore?: number | null;
  initialScoreLater?: boolean | null;
  scoreLaterDisabled?: boolean | null;
  onChangeScore?: ((value: number | null | undefined) => void) | null;
  onChangeScoreLater?: ((value: boolean | null | undefined) => void) | null;
}

export default function ScoreEditor(props: ScoreEditorProps): ReactElement {
  const [isEditing, setIsEditing] = useState<boolean>();
  const [score, setScore] = useState<number | null | undefined>(
    props.initialScore
  );
  const [scoreLater, setScoreLater] = useState<boolean | null | undefined>(
    props.initialScoreLater
  );

  function updateScore(inputValue: string): void {
    let scoreNum: number | null = parseInt(inputValue, 10);
    if (isNaN(scoreNum)) {
      scoreNum = null;
    }
    setScore(scoreNum);
    if (scoreNum == null) {
      setScoreLater(null);
    }
  }

  useUpdateEffect(() => {
    if (props.onChangeScore != null) {
      props.onChangeScore(score);
    }
  }, [score]);

  useUpdateEffect(() => {
    if (props.onChangeScoreLater != null) {
      props.onChangeScoreLater(scoreLater);
    }
  }, [scoreLater]);

  const buildStaticScore = () => (
    <FlatButton
      className={styles.static_score}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      {props.initialScore ?? "---"}
    </FlatButton>
  );

  const buildStatic = () =>
    props.scoreLaterDisabled ? (
      <div className={styles.static}>
        <FlatButton
          disabled
          className={classNames(
            styles.static_later,
            styles.static_later__disabled
          )}
        >
          <FaEquals size={9} />
        </FlatButton>
        {buildStaticScore()}
      </div>
    ) : (
      <div
        className={classNames(styles.static, {
          [styles.__isLaterActive]: scoreLater,
        })}
      >
        <MyTippy
          delay={[300, 0]}
          content={
            <ul className={styles.tipContent}>
              <li>
                <FaEquals size={10} />: Score awarded instantly
              </li>
              <li>
                <FaLessThanEqual size={10} />: Score awarded later, manually
              </li>
            </ul>
          }
        >
          <FlatButton
            className={styles.static_later}
            onClick={() => {
              setScoreLater((value) => !value);
            }}
          >
            {scoreLater ? <FaLessThanEqual size={9} /> : <FaEquals size={9} />}
          </FlatButton>
        </MyTippy>
        {buildStaticScore()}
      </div>
    );

  const buildEditor = () => {
    return (
      <div className={styles.editor}>
        <input
          type="number"
          defaultValue={score ?? 0}
          autoFocus
          onFocus={(event) => {
            event.target.select();
          }}
          onBlur={(event) => {
            setIsEditing(false);
            updateScore(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              setIsEditing(false);
            } else if (event.key === "Enter") {
              setIsEditing(false);
              updateScore((event.target as HTMLInputElement).value);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {buildStatic()}
      {isEditing && buildEditor()}
    </div>
  );
}
