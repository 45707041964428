import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import MenuItem, { MenuItemProps } from "./MenuItem";

type MenuItemLinkProps = Omit<MenuItemProps, "onClick"> & { to: string };

export default function MenuItemLink({
  to,
  ...restProps
}: MenuItemLinkProps): ReactElement {
  const history = useHistory();

  return <MenuItem {...restProps} onClick={() => history.push(to)} />;
}
