import { AnyChatFlowDefinition, ChatJson } from "@xflr6/chatbot";
import axios from "axios";

import { buildAuthHeader } from "../auth";
import { buildEvaluationsUrl } from "../remoteUrls";
import { IdentifierOrigin, SettingsPatch } from "../sharedTypes";
import { baseUrl } from "../utils";

export interface ChatResponse {
  id: number;
  definition: AnyChatFlowDefinition;
  identifier: string;
  identifierOrigin: IdentifierOrigin;
  history: ChatJson | null;
  score: number | null;
  completed: boolean;
  createdAt: string;
  evaluations: Record<string, string>;
  settings: SettingsPatch;
  userSettings: SettingsPatch;
}

export type ChatResponseHistoryItemPatch = Partial<{
  comment: string | null;
  superScore: number | null;
}>;

export type Evaluation = {
  body: string;
};

export async function getChatResponse(
  id: number,
  identifier: string
): Promise<ChatResponse> {
  const _identifier = encodeURIComponent(identifier);
  const response = await axios.get(
    `${baseUrl}/flows/${id}/history/${_identifier}`,
    {
      headers: { ...buildAuthHeader() },
    }
  );
  return response.data;
}

export async function updateChatResponseHistory(
  id: number,
  identifier: string,
  index: number,
  data: ChatResponseHistoryItemPatch,
  flowSequenceId?: number
): Promise<void> {
  const _identifier = encodeURIComponent(identifier);
  await axios.post(
    `${baseUrl}/flows/${id}/history/${_identifier}/${index}${
      flowSequenceId ? `?flowSequenceId=${flowSequenceId}` : ""
    }`,
    data,
    { headers: { ...buildAuthHeader() } }
  );
}

export async function updateManagedChatResponseHistory(
  id: number,
  identifier: string,
  history: ChatJson,
  flowSequenceId?: number
): Promise<void> {
  const _identifier = encodeURIComponent(identifier);
  await axios.post(
    `${baseUrl}/flows/${id}/history/${_identifier}/${
      flowSequenceId ? `?flowSequenceId=${flowSequenceId}` : ""
    }`,
    { history },
    { headers: { ...buildAuthHeader() } }
  );
}

export async function destroyChatResponse(
  id: number,
  identifier: string
): Promise<void> {
  const _identifier = encodeURIComponent(identifier);
  await axios.delete(`${baseUrl}/flows/${id}/history/${_identifier}`, {
    headers: { ...buildAuthHeader() },
  });
}

export async function getEvaluation(
  flowId: number,
  identifier: string,
  promptKeyStr: string
): Promise<Evaluation | null> {
  const response = await axios.get(
    buildEvaluationsUrl(flowId, identifier, promptKeyStr),
    { headers: { ...buildAuthHeader() } }
  );
  return response.data;
}

export async function updateEvaluation(
  flowId: number,
  identifier: string,
  promptKeyStr: string,
  content: string,
  flowSequenceId?: number
): Promise<void> {
  await axios.post(
    `${buildEvaluationsUrl(flowId, identifier, promptKeyStr)}${
      flowSequenceId ? `?flowSequenceId=${flowSequenceId}` : ""
    }`,
    { content },
    { headers: { ...buildAuthHeader() } }
  );
}
