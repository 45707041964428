import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import buttonStyles from "../../components/buttons/Button.module.css";
import FilledButton from "../../components/buttons/FilledButton";
import listStyles from "../../components/listStyles.module.css";
import modalStyles from "../../components/Modal.module.css";
import { selectAuth } from "../auth/authSlice";
import styles from "./E2eTestForm.module.css";
import { buildShareUrl, EmbedConfig } from "./EmbedForm";
import { selectFlowUid } from "./flowEditorSlice";
import { EmbedFormData } from "./flowEditorUiSlice";

export default function E2eTestForm(): ReactElement | null {
  const { control, watch } = useForm<EmbedFormData>();
  const { currentUser } = useSelector(selectAuth);
  const flowUid = useSelector(selectFlowUid);

  if (currentUser == null || flowUid == null) {
    return null;
  }

  const formData = watch();

  return (
    <div>
      <div className={styles.info}>
        Test all aspects of this flow:
        <ul className={listStyles.ul}>
          <li>Put yourself in the user&apos;s shoes and start chatting</li>
          <li>
            Then put yourself back in admin shoes to view and interact with your
            chat history via the <b>STATS &gt; CHATS</b> tab
            <br />
            (search for {currentUser.email})
          </li>
        </ul>
      </div>
      <EmbedConfig
        formControl={control}
        excludedFields={new Set(["height", "width", "identifier"])}
      />
      <div className={modalStyles.actions}>
        <a
          className={buttonStyles.hasButton}
          href={buildShareUrl(flowUid, {
            ...formData,
            identifier: currentUser.email,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FilledButton>Launch Chat</FilledButton>
        </a>
      </div>
    </div>
  );
}
