import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

export type EmbedMode = "iframe" | "webComponent";

export interface EmbedFormData {
  height: string;
  width: string;
  identifier: string;
  language: string;
  showName: boolean;
  showScore: boolean;
  disableAutoAnswer: boolean;
  disableKeyboardAnswer: boolean;
}

export interface EditorUiState {
  embedMode: EmbedMode;
  embedFormData: EmbedFormData;
  isPromptConfigOpen: boolean;
  isChatAutoAnswerEnabled: boolean;
  isChatDefaultDelayIgnored: boolean;
}

const INITIAL_STATE: EditorUiState = {
  embedMode: "iframe",
  embedFormData: {
    height: "",
    width: "",
    identifier: "",
    language: "",
    showName: false,
    showScore: true,
    disableAutoAnswer: false,
    disableKeyboardAnswer: false,
  },
  isPromptConfigOpen: window.innerWidth >= 1200,
  isChatAutoAnswerEnabled: true,
  isChatDefaultDelayIgnored: true,
};

/**
 * The slice of state is persisted to localStorage.
 * See /src/app/store.ts for implementation details.
 */
export const slice = createSlice({
  name: "flowEditorUi",
  initialState: INITIAL_STATE,
  reducers: {
    setEmbedMode: (state: EditorUiState, action: PayloadAction<EmbedMode>) => {
      state.embedMode = action.payload;
    },
    updateEmbedFormData: (
      state: EditorUiState,
      action: PayloadAction<Partial<EmbedFormData>>
    ) => {
      state.embedFormData = { ...state.embedFormData, ...action.payload };
    },
    setIsPromptConfigOpen: (
      state: EditorUiState,
      action: PayloadAction<boolean>
    ) => {
      state.isPromptConfigOpen = action.payload;
    },
    setIsChatAutoAnswerEnabled: (
      state: EditorUiState,
      action: PayloadAction<boolean>
    ) => {
      state.isChatAutoAnswerEnabled = action.payload;
    },
    setIsChatDefaultDelayIgnored: (
      state: EditorUiState,
      action: PayloadAction<boolean>
    ) => {
      state.isChatDefaultDelayIgnored = action.payload;
    },
  },
});

export const {
  setEmbedMode,
  updateEmbedFormData,
  setIsPromptConfigOpen,
  setIsChatAutoAnswerEnabled,
  setIsChatDefaultDelayIgnored,
} = slice.actions;

export const selectEditorUi = (state: RootState): EditorUiState =>
  state.flowEditorUi;

export const selectIsPromptConfigOpen = (state: RootState): boolean =>
  state.flowEditorUi.isPromptConfigOpen;

export default slice.reducer;
