import React, { ReactElement } from "react";

import styles from "./FieldError.module.css";

export interface FieldErrorProps {
  error?: string | null;
}

/**
 * Can be rendered below an `Input` field.
 * Renders nothing if null/undefined error is given.
 * @constructor
 */
export default function FieldError(
  props: FieldErrorProps
): ReactElement | null {
  return props.error != null ? (
    <div className={styles.root}>{props.error}</div>
  ) : null;
}
