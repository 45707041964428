import { debounce } from "lodash";
import React, { ReactElement, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilledButton from "../../components/buttons/FilledButton";
import {
  selectEditor,
  selectHasUnsavedChanges,
  updateFlowDefinition,
} from "./flowEditorSlice";
import styles from "./SaveButton.module.css";

export interface SaveButtonProps {
  saveDebounceMs?: number | null;
}

export default function SaveButton(props: SaveButtonProps): ReactElement {
  const dispatch = useDispatch();

  const editor = useSelector(selectEditor);
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);

  const debouncedSaveFlow = useMemo(
    () =>
      debounce(
        () => dispatch(updateFlowDefinition()),
        props.saveDebounceMs ?? 5000
      ),
    [dispatch, props.saveDebounceMs]
  );

  useEffect(() => {
    if (
      (editor.definitionLastEditedAt ?? 0) >
      (editor.definitionLastUpdatedAt ?? 0)
    ) {
      debouncedSaveFlow();
    }
  }, [
    editor.definitionLastEditedAt,
    editor.definitionLastUpdatedAt,
    debouncedSaveFlow,
  ]);

  return (
    <FilledButton
      className={styles.root}
      onClick={() => dispatch(updateFlowDefinition())}
      disabled={!hasUnsavedChanges || editor.definitionUpdating === "pending"}
      isLoading={editor.definitionUpdating === "pending"}
    >
      {hasUnsavedChanges ? "Save" : "Saved"}
    </FilledButton>
  );
}
