import { OrdChatFlowDefinition } from "@xflr6/chatbot";

import { moveElement } from "../../../../utils/array";
import { MCFlowEditorError } from "../../errors";

export function moveAnswer(
  promptIndex: number,
  fromIndex: number,
  toIndex: number,
  flowDef: OrdChatFlowDefinition
): void {
  const promptDef = flowDef.promptsArray[promptIndex].definition;
  const answers = promptDef.answers;
  if (!Array.isArray(answers)) {
    throw new MCFlowEditorError(
      `Prompt at index ${promptIndex} has non-array answers: ${answers}`
    );
  }
  moveElement(answers, fromIndex, toIndex);
}
