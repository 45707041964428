import classNames from "classnames";
import React, { ReactElement, useContext } from "react";
import { Editor, Element as SlateElement, Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";

import FilledButton from "../../../components/buttons/FilledButton";
import Input from "../../../components/forms/Input";
import PromptKeySelect from "../../flowEditor/edit/PromptKeySelect";
import { MessageEditorContext } from "../MessageEditor";
import styles from "./VariableEditor.module.css";

function updateElement(
  editor: Editor,
  element: SlateElement,
  newProperties: Partial<SlateElement>
) {
  const path = ReactEditor.findPath(editor, element);
  Transforms.setNodes(editor, newProperties, { at: path });
}

export default function VariableEditor({
  attributes,
  children,
  element,
}: RenderElementProps): ReactElement {
  const editor = useSlateStatic();
  const focused = useFocused();
  const selected = useSelected();
  const highlight = focused && selected;

  const messageEditorContext = useContext(MessageEditorContext);

  return (
    <span className={styles.root} contentEditable={false} {...attributes}>
      <span
        className={classNames(styles.brackets, {
          [styles.brackets__selected]: highlight,
        })}
      >
        {"{{"}
      </span>
      <span className={styles.inputWrap}>
        {element.isPrompt ? (
          <PromptKeySelect
            initialPromptKey={(element.variableName as string) || null}
            // TODO Get related promptIndex and pass here (is hardcoded now)
            promptIndex={0}
            onChange={(promptKey) =>
              updateElement(editor, element, {
                variableName: promptKey,
              })
            }
          />
        ) : (
          <Input
            styleVariant="outlined"
            className={styles.input}
            value={element.variableName as string}
            onFocus={() => messageEditorContext.setIsInAtomicEdit(true)}
            onBlur={() => messageEditorContext.setIsInAtomicEdit(false)}
            onChange={(event) =>
              updateElement(editor, element, {
                variableName: event.target.value.replace(/\s/g, ""),
              })
            }
          />
        )}
      </span>
      <FilledButton
        className={styles.typeChangeButton}
        onClick={() =>
          updateElement(editor, element, {
            variableName: null,
            isPrompt: !element.isPrompt,
          })
        }
      >
        {element.isPrompt ? "@" : "_"}
      </FilledButton>
      <span
        className={classNames(styles.brackets, {
          [styles.brackets__selected]: highlight,
        })}
      >
        {"}}"}
      </span>
      {children}
    </span>
  );
}
