import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import _TranslationEditor, {
  TranslationEditorProps,
} from "../../../featuresCommon/TranslationEditor";
import { selectCurrentLanguage } from "../flowEditorSlice";

export default function TranslationEditor({
  language,
  children,
  ...restProps
}: TranslationEditorProps): ReactElement | null {
  const globalLanguage = useSelector(selectCurrentLanguage);
  const effectiveLanguage = language ?? globalLanguage;

  return (
    <_TranslationEditor language={effectiveLanguage} {...restProps}>
      {children}
    </_TranslationEditor>
  );
}
