import * as api from "@xflr6/chatbot-api";
import classNames from "classnames";
import { equals } from "ramda";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoCheckmarkDone } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { RootState, useAppDispatch } from "../../app/store";
import buttonStyles from "../../components/buttons/Button.module.css";
import FilledButton from "../../components/buttons/FilledButton";
import LoadingLayout, {
  ErrorLayout,
  SelectItemLayout,
} from "../../components/LoadingLayout";
import { getBestEffortErrorMessage } from "../../utils/error";
import CloudinaryConfig from "./cloudinary/CloudinaryConfig";
import styles from "./IntegrationConfigs.module.css";
import {
  deleteIntegration,
  loadIntegrations,
  selectIntegration,
  selectIntegrations,
} from "./integrationsSlice";
import LtiConfig from "./lti/LtiConfig";
import ThinkificConfig from "./thinkific/ThinkificConfig";
import WebhookConfig from "./webhook/WebhookConfig";

export function IntegratedIcon({
  size,
}: {
  size?: string | number;
}): ReactElement {
  return (
    <div className={styles.integratedIcon}>
      <IoCheckmarkDone size={size} />
    </div>
  );
}

/**
 * Use this to create individual integration config forms
 * @constructor
 */
export function IntegrationConfig(props: {
  type: api.IntegrationType;
  renderAddForm: () => ReactNode;
  renderEditForm?: (integration: api.Integration) => ReactNode;
  showManageButton?: boolean;
  // If not provided, a default 'Remove' button is shown
  renderRemoveButton?: (onClick: () => void) => ReactNode;
  // If not provided, a default confirm message is shown
  confirmRemoveMessage?: string;
  onIntegrationRemoved?: VoidFunction;
}): ReactElement {
  const dispatch = useAppDispatch();

  const { updating, lastUpdateAction } = useSelector(selectIntegrations);

  const integration = useSelector((state: RootState) =>
    selectIntegration(state, props.type)
  );

  const removeIntegration = useMemo(
    () => () => dispatch(deleteIntegration({ type: props.type })),
    [dispatch, props.type]
  );

  return (
    <div className={styles.integrationConfig}>
      <div className={styles.integrationConfig_title}>
        {api.getIntegrationTypeTitle(props.type)}
      </div>
      {integration != null ? (
        <div className={styles.integrated}>
          <div className={styles.integrated_message}>
            <IoCheckmarkDone size={20} />
            Integration configured
          </div>
          {props.renderEditForm && (
            <div className={styles.integrated_editForm}>
              {props.renderEditForm(integration)}
            </div>
          )}
          <div className={styles.integrated_actions}>
            {props.showManageButton && (
              <Link
                to={`/integrations/${props.type}`}
                className={buttonStyles.hasButton}
              >
                <FilledButton>Manage</FilledButton>
              </Link>
            )}
            {props.renderRemoveButton != null ? (
              props.renderRemoveButton(removeIntegration)
            ) : (
              <FilledButton
                onClick={async () => {
                  const confirmMessage =
                    props.confirmRemoveMessage ?? "Are you sure?";

                  if (window.confirm(confirmMessage)) {
                    await removeIntegration();
                    props.onIntegrationRemoved?.();
                  }
                }}
                disabled={updating === "pending"}
                isLoading={
                  updating === "pending" &&
                  equals(lastUpdateAction, [props.type, "delete"])
                }
              >
                Remove Integration
              </FilledButton>
            )}
          </div>
        </div>
      ) : (
        props.renderAddForm()
      )}
    </div>
  );
}

const LS_INTEGRATION_CONFIGS_ITEM_KEY = "integrationConfigsItem";

function getStoredSelectedIntegration(): api.IntegrationType | null {
  const v = localStorage.getItem(LS_INTEGRATION_CONFIGS_ITEM_KEY);
  return api.INTEGRATION_TYPES.find((t) => t === v) ?? null;
}

export default function IntegrationConfigs(): ReactElement | null {
  const dispatch = useDispatch();
  const { loading, updating, error, integrations } = useSelector(
    selectIntegrations
  );
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(loadIntegrations());
  }, [dispatch]);

  const wasJustOpened = useRef(true);
  useEffect(() => {
    if (wasJustOpened.current) {
      wasJustOpened.current = false;
      return;
    }
    if (updating === "rejected" && error != null) {
      addToast(
        `Failed to update integration: ${getBestEffortErrorMessage(error)}`,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }, [updating, error, addToast]);

  const [
    selectedIntegration,
    setSelectedIntegration,
  ] = useState<api.IntegrationType | null>(getStoredSelectedIntegration());

  useEffect(() => {
    if (selectedIntegration != null) {
      localStorage.setItem(
        LS_INTEGRATION_CONFIGS_ITEM_KEY,
        selectedIntegration
      );
    } else {
      localStorage.removeItem(LS_INTEGRATION_CONFIGS_ITEM_KEY);
    }
  }, [selectedIntegration]);

  function renderConfig() {
    switch (selectedIntegration) {
      case "thinkific":
        return (
          <div className={styles.integrationConfigWrapper}>
            <ThinkificConfig />
          </div>
        );
      case "webhook":
        return (
          <div className={styles.integrationConfigWrapper}>
            <WebhookConfig />
          </div>
        );
      case "lti":
        return (
          <div className={styles.integrationConfigWrapper}>
            <LtiConfig />
          </div>
        );
      case "cloudinary":
        return (
          <div className={styles.integrationConfigWrapper}>
            <CloudinaryConfig />
          </div>
        );
      default:
        return <SelectItemLayout message="Select an integration" />;
    }
  }

  if (loading === "rejected") {
    return <ErrorLayout message="Error loading integrations" />;
  } else if (loading === "pending") {
    return <LoadingLayout />;
  } else if (integrations == null) {
    return null;
  } else {
    return (
      <div className={styles.root}>
        <div className={styles.itemsColumn}>
          {api.INTEGRATION_TYPES.map((type) => (
            <div
              key={type}
              className={classNames(styles.item, {
                [styles.item__selected]: selectedIntegration === type,
              })}
              onClick={() => setSelectedIntegration(type)}
            >
              <div className={styles.item_info}>
                <div className={styles.item_info_title}>
                  {api.getIntegrationTypeTitle(type)}
                </div>
              </div>
              {integrations?.find((i) => i.type === type) && (
                <IntegratedIcon size={20} />
              )}
            </div>
          ))}
        </div>
        <div className={styles.integrationConfigColumn}>{renderConfig()}</div>
      </div>
    );
  }
}
