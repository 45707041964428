import { PromptDefinition } from "@xflr6/chatbot";

export function promptHasRepeatableAnswers(
  definition: PromptDefinition
): boolean {
  return (
    Array.isArray(definition.answers) &&
    definition.answers.some((answer) => answer.quickResponse?.repeatAnswers)
  );
}
